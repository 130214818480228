import { useSelector } from "react-redux";

import { getIntegrationCard, remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import Icon from "../Icon";

const IconView = ({ item }) => {
  const currentWidth = useWidth();
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const account = accountByDS?.[item?.data_source]?.[0];
  const ds = dataSourceById?.[item?.data_source]?.[0];
  const card = getIntegrationCard({ ds });

  return (
    <>
      {account?.bank_details?.logo_url ? (
        <img
          component="img"
          style={{
            width: remToPx(currentWidth, 3),
            height: remToPx(currentWidth, 1.5),
            objectFit: "contain",
            p: "1rem",
          }}
          src={account?.bank_details?.logo_url}
          alt="Logo"
        />
      ) : card?.icon ? (
        <Icon
          icon={card?.icon}
          fontSize={"1.3rem"}
          color="inherit"
          style={{
            "& svg": {
              width: remToPx(currentWidth, 3),
              height: remToPx(currentWidth, 1.5),
            },
          }}
        ></Icon>
      ) : (
        <img
          component="img"
          style={{
            width: remToPx(currentWidth, 4),
            height: remToPx(currentWidth, 2),
            objectFit: "contain",
            p: "1rem",
          }}
          src={card?.img}
          alt="Logo"
        />
      )}
    </>
  );
};

export default IconView;
