import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { formatValue } from "react-currency-input-field";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Color, Fonts } from "../../Helper";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import CustomizedAxisTick from "./CustomizedAxisTick";



const TooltipItemView = ({ title, value, color }) => {
  return (
    <Typography
      sx={{
        display: "inline-flex",
        fontSize: "0.7rem",
        alignItems: "center",
        fontFamily: Fonts.Text,
        fontWeight: 400,
      }}
    >
      <span
        style={{
          width: "0.5rem",
          height: "0.5rem",
          borderRadius: 5,
          backgroundColor: color,
          marginRight: "0.5rem",
        }}
      />
      {title}:<b style={{ position: "absolute", right: "0.5rem" }}>{value}</b>
    </Typography>
  );
};

const CustomTooltipScatter = (props) => {
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  let { active, payload, type, title } = props;
  if (active && payload && payload.length) {
    let due_date_12M = payload[0]?.payload?.due_date_12M;
    let due_date_prev = payload[0]?.payload?.due_date_prev;
    let personalCost_12M_employees =
      payload[0]?.payload[`${type}_12M_employees`];
    let personalCost_prev_employees =
      payload[0]?.payload[`${type}_prev_employees`];
    let personalCost_12M_freelancers =
      payload[0]?.payload[`${type}_12M_freelancers`];
    let personalCost_prev_freelancers =
      payload[0]?.payload[`${type}_prev_freelancers`];
    return (
      <Paper
        sx={{
          width: "14.375rem",
          height: "fix-content",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          p: "0.5rem",
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              display: "inline-flex",
              fontSize: "0.9rem",
              fontFamily: Fonts.Text,
              fontWeight: 600,
              textOverflow: "ellipsis",
              whiteSpace: "pre",
              overflow: "hidden",
            }}
          >
            {title}
          </Typography>
        </Box>
        <TooltipItemView
          title={due_date_prev}
          color={Color.purple100}
          value={formatValue({
            value: String(
              parseFloat(personalCost_prev_employees ?? 0).toFixed(2)
            ),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={due_date_prev}
          color={Color.theme.blue[100]}
          value={formatValue({
            value: String(
              parseFloat(personalCost_prev_freelancers ?? 0).toFixed(2)
            ),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={due_date_12M}
          color={Color.purple500}
          value={formatValue({
            value: String(
              parseFloat(personalCost_12M_employees ?? 0).toFixed(2)
            ),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={due_date_12M}
          color={Color.theme.blue[800]}
          value={formatValue({
            value: String(
              parseFloat(personalCost_12M_freelancers ?? 0).toFixed(2)
            ),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
      </Paper>
    );
  }

  return null;
};

const DashBoardLineChart = React.memo(
  ({ data, type = "personalCost", title = "Personnel costs" }) => {
    const currentWidth = useWidth();
    return (
      <ResponsiveContainer width="100%" height="99%">
        <LineChart
          data={data}
          margin={{
            top: remToPx(currentWidth, 0.3125),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="due_date_12M"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                formatted={false}
              />
            }
            type="category"
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.25)}
                dx={-remToPx(currentWidth, 1.625)}
                formatted
                type={type}
              />
            }
            type="number"
          />
          <Tooltip
            cursor={{ fill: "transparent", strokeDasharray: "3 3" }}
            content={<CustomTooltipScatter type={type} title={title} />}
          />
          <Line
            dataKey={`${type}_12M_employees`}
            stroke={Color.purple500}
            strokeWidth={3}
          />
          <Line
            dataKey={`${type}_prev_employees`}
            stroke={Color.purple100}
            strokeDasharray="5 5"
            strokeWidth={3}
          />
          <Line
            dataKey={`${type}_12M_freelancers`}
            stroke={Color.theme.blue[800]}
            strokeWidth={3}
          />
          <Line
            dataKey={`${type}_prev_freelancers`}
            stroke={Color.theme.blue[100]}
            strokeDasharray="5 5"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data && prev.height === next.height;
  }
);
export default DashBoardLineChart;
