import {
  Typography,
  useTheme,
  Popover,
  Tooltip,
  Link,
  Box,
} from "@mui/material";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { forwardRef } from "react";
import { useRef } from "react";

import { setStepIndex, setSteps } from "../../store/slices/tour";
import { Color, Constant, Images } from "../../Helper";
import Translate from "../../hooks/HOC/Translate";

const HelpOverlay = forwardRef(
  (
    {
      text = "",
      path = "",
      tourKey,
      image = null,
      showRight = false,
      data = null,
      hideDefault = false,
      hideHeader = false,
      fontSize = "1.6rem",
      supportView = null,
      tooltipTitle = "Documentation & Insights",
      wrapperStyle,
    },
    ref
  ) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const popoverRef = useRef();
    const { t } = useTranslation();
    // const joyRideStatus = useSelector(
    //   (state) => state.globalSlice?.joyRideStatus
    // );
    const [anchorEl, setAnchorEl] = useState(null);
    // const [checked, setChecked] = useState(false);
    const canOpen = Boolean(anchorEl);
    // const handleChange = () => {
    //   if (tourKey) {
    //     setChecked(true);
    //     let joyRideStatusFilter = joyRideStatus?.filters;
    //     let filterObj = {
    //       ...joyRideStatus,
    //       data_type: "transaction",
    //       filters: { ...joyRideStatusFilter, [tourKey]: false },
    //     };
    //     dispatch(setJoyRideStatus(filterObj));
    //     setAnchorEl(false);
    //     setChecked(false);
    //   }
    // };

    const onClickHelp = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpen = (startTour = false) => {
      setAnchorEl(popoverRef.current);
      if (startTour) {
        setTimeout(() => {
            dispatch(setSteps({ key: "kanban", t }));
            dispatch(setStepIndex(17));
            dispatch(startTour());
        }, 500);
      }
    };

    useImperativeHandle(ref, () => ({
      handleClose() {
        handleClose();
      },
      openManualy(flag) {
        handleOpen(flag);
      },
    }));

    //render function
    const CommonView = ({
      image,
      title,
      link,
      linkText,
      linkTextView,
      description,
      style,
      text,
    }) => {
      const theme = useTheme();
      const onClickImage = () => {
        window.open(link, "_blank");
      };

      return (
        <Box
          sx={{
            display: "inline-flex",
            width: "100%",
            position: "relative",
            mt: "2rem",
            ...style,
          }}
        >
          <img
            src={image ?? Images.image}
            alt="alt_image"
            onClick={onClickImage}
            style={{
              width: "12rem",
              height: "8rem",
              borderRadius: 4,
              border: `1px solid rgb(128 128 128 / 19%)`,
              objectFit: "fill",
              aspectRatio: "2/1",
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "2.5rem",
              width: "100%",
            }}
          >
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              <Link
                color="inherit"
                href={link}
                target="_blank"
                sx={{
                  fontSize: "1.3rem",
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                {linkText ? t(linkText) : linkTextView}
              </Link>{" "}
              {title && (
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    ml: "1rem",
                  }}
                >
                  {title} Knowledge
                </Typography>
              )}
            </Box>
            <Typography
              sx={{
                fontSize: "0.85rem",
                color: theme.palette.primary.main,
                fontWeight: 400,
              }}
            >
              <Translate i18nkey={description} values={{ label: text }} />
            </Typography>
          </Box>
        </Box>
      );
    };

    return (
      <>
        {canOpen && (
          <Popover
            id={"HelpOverlay"}
            open={canOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: showRight ? "right" : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: showRight ? "left" : "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: 1.5,
                  boxShadow: Constant.OverlayBoxShadow,
                  mt: "1rem",
                },
              },
            }}
          >
            <Box
              className="kanban_11_step"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "33vw",
                pt: "2.5rem",
                px: "2.5rem",
                pb: "2rem",
              }}
            >
              {hideHeader ? null : (
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: "1.5rem",
                    fontWeight: 600,

                    textTransform: "initial",
                  }}
                >
                  {t(text)}
                </Typography>
              )}
              {!hideDefault && (
                <CommonView
                  image={image}
                  linkText={`${text} - Documentation`}
                  link={`https://finban.io/documentation/${path}/`}
                  description={"Get more Information and Tips & Tricks"}
                  text={text}
                />
              )}
              {data?.map((item) => {
                if (!item.hide) {
                  return (
                    <CommonView
                      key={item.id}
                      image={item?.image}
                      linkText={item?.linkText}
                      linkTextView={item?.linkTextView}
                      link={item?.link}
                      description={item?.description}
                    />
                  );
                }
                return null;
              })}

              <Box
                sx={{ mt: "2rem", display: "flex", flexDirection: "column" }}
              >
                {supportView}
                {/* {tourKey && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={` Re-Activate ${path}-Onboarding`}
                    sx={{ color: Color.themeColor2, fontWeight: 300 }}
                  />
                )} */}
              </Box>
            </Box>
          </Popover>
        )}
        <Tooltip title={t(tooltipTitle)} arrow placement="top">
          <TipsAndUpdatesTwoToneIcon
            ref={popoverRef}
            onClick={onClickHelp}
            sx={{
              fontSize: fontSize,
              color: Color.theme.amber[300],
              cursor: "pointer",
              mx: "1rem",
              "&:hover": {
                color: Color.theme.amber[500],
              },
              ...wrapperStyle,
            }}
          />
        </Tooltip>
      </>
    );
  }
);
export default HelpOverlay;
