import {
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Button,
  Stack,
  List,
  Grid,
  Box,
} from "@mui/material";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Outlet } from "react-router";

import DeleteConfirmationModal from "../../../components/Model/DeleteConfirmationModal";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import { Card, Item } from "../../../Styles/Pages/sheets.style";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import useSubscriptions from "../../../hooks/useSubscriptions";
import HelpOverlay from "../../../components/HelpOverlay.js";
import { setCostUnits } from "../../../store/slices/global";
import { setLoading } from "../../../store/slices/appmain";
import { Fonts, Images, Color } from "../../../Helper";
import AddButton from "../../../components/AddButton";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";

const CostUnit = () => {
  let itemId = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();
  const theme = useTheme();
  //redux state
  const costunits = useSelector((state) => state.globalSlice.costunits);

  //state
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [datasetItem, setdatasetItem] = useState();
  const [error, setError] = useState(null);

  //life cycle method
  useEffect(() => {
    getCostUnitsApi(false);
  }, []);

  //Api
  const deleteCostUnitByIdApi = async (id) => {
    await APICall("delete", EndPoints.costunits + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        let data = costunits?.filter((o1) => o1.uuid !== id);
        dispatch(setCostUnits(data));
        enqueueSnackbar(t("CostUnit_Deleted_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const updateCostUnitByID = async (id, obj) => {
    await APICall("patch", EndPoints.costunits + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...costunits];
          let index = data?.findIndex((o1) => o1.uuid === id);
          data[index] = response.data;
          dispatch(setCostUnits(data));
        }
      }
    );
  };

  const addCostUnitApi = async (obj) => {
    await APICall("post", EndPoints.costunits, obj).then((response) => {
      if (response.status === 201 && response.data) {
        let data = [...costunits, response.data];
        dispatch(setCostUnits(data));
        enqueueSnackbar(t("New_Costunit_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const getCostUnitsApi = async (loading = true) => {
    if (loading) dispatch(setLoading(true));
    await APICall("get", EndPoints.costunits).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setCostUnits(data));
        dispatch(setLoading(false));
      }
    });
  };

  //function
  const onClickAddNewCU = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      let obj = {
        title: `Cost Unit ${costunits?.length + 1}`,
        note: "",
        alias: `ALS`,
      };
      addCostUnitApi(obj);
    }
  };

  const onClickDeleteDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item.uuid;
    setIsDeleteOpen(true);
  };

  const onOk = () => {
    setIsDeleteOpen(false);
    deleteCostUnitByIdApi(itemId.current);
  };

  const onClickEditDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item.uuid;
    setdatasetItem(item);
    setOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let errorText = null;
    if (name === "title" && value.trim() === "") {
      errorText = "Title can not be empty";
    }

    if (name === "alias" && value.length > 3) {
      return null;
    }

    setdatasetItem({ ...datasetItem, [name]: value });
    setError({ ...error, [name]: errorText });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = () => {
    if (!datasetItem?.alias || datasetItem?.alias.length !== 3) {
      let errorText = null;
      if (datasetItem?.alias.trim() === "") {
        errorText = "Alias can not be empty";
      }
      if (datasetItem?.alias.length !== 3) {
        errorText = "Add only 3 letter in alias";
      }
      setError({ ...error, alias: errorText });
      return null;
    } else {
      setOpen(false);
      updateCostUnitByID(itemId.current, {
        title: datasetItem?.title,
        note: datasetItem?.note,
        alias: datasetItem?.alias,
      });
    }
  };

  const onCloseDelete = () => {
    setIsDeleteOpen(false);
  };

  //Render Function
  const activeBoards = () => (
    <>
      <Grid
        container
        spacing={2}
        elevation={1}
        sx={{
          display: "inline",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "80%",
          }}
        >
          <Grid item xs={3}>
            <Typography
              sx={{
                mb: "0.5rem",
                pl: "1rem",
                textAlign: "left",
                fontSize: "0.9375rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Title")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                mb: "0.5rem",
                pl: "1rem",
                textAlign: "left",
                fontSize: "0.9375rem",
                textTransform: "capitalize",
              }}
              variant="h6"
              component="div"
            >
              {t("Note")}
            </Typography>
          </Grid>
        </Box>
        {costunits?.length > 0 ? (
          <Card
            elevation={1}
            sx={{
              width: "80%",
              maxHeight: {
                s1920: "43rem",
                s2133: "52rem",
                s2560: "56rem",
                s1536: "49rem",
                s1440: "50rem",
                s1366: "51.5rem",
                s1280: "60rem",
              },
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
                background: "transparent" /* make scrollbar transparent */,
              },
            }}
          >
            <List sx={{ padding: "0px" }}>
              {costunits?.map((item, index) => {
                return (
                  <Item key={item?.uuid} elevation={1}>
                    <ListItem
                      divider
                      sx={{
                        backgroundColor: Color.white,
                        "& .actionBtn": {
                          backgroundColor: Color.white,
                          borderRadius: 1,
                          width: "1.8rem",
                          height: "1.8rem",
                          marginLeft: "0.5rem",
                          minWidth: "1.8rem",
                          display: "none",
                          border: `1px solid ${Color.tailwind.slate[300]}`,
                          "&: hover": {
                            backgroundColor: Color.tailwind.slate[100],
                          },
                          "& .actionBtnIcon": {
                            backgroundColor: "transparent",
                            color: Color.black,
                            fontSize: "1.2rem",
                            margin: 0,
                          },
                        },
                        "& .itemText": {
                          flex: "none",
                          "& span": {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "pre",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            width: "fit-content",
                            fontFamily: theme.typography.fontFamily,
                          },
                          textAlign: "left",
                        },
                        "&: hover": {
                          backgroundColor: theme.palette.color.slate[50],
                          "& .actionBtn": {
                            display: "flex",
                          },
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={3}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemText
                          primary={item?.title}
                          className={"itemText"}
                        />
                        <Button
                          onClick={(e) => onClickEditDataSet(e, item)}
                          className={"actionBtn"}
                        >
                          <ModeEditRoundedIcon className={"actionBtnIcon"} />
                        </Button>
                        <Button
                          className={"actionBtn"}
                          onClick={(e) => onClickDeleteDataSet(e, item, index)}
                        >
                          <DeleteIcon className={"actionBtnIcon"} />
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItemText
                          primary={item?.note}
                          className={"itemText"}
                        />
                      </Grid>
                    </ListItem>
                  </Item>
                );
              })}
            </List>
          </Card>
        ) : (
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: Fonts.Text,
              color: Color.grey,
            }}
          >
            {t("No_Data_Found")}
          </Typography>
        )}
      </Grid>
    </>
  );

  const editModal = () => {
    return (
      <CustomModal
        textAdd="Save"
        heading={t("Cost_Unit")}
        onClose={handleClose}
        onAdd={onSave}
        modalType={"edit"}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "40rem",
            height: "fit-content",
            p: "2.5rem",
            backgroundColor: Color.white,
          }}
        >
          <TitleInput
            value={datasetItem?.title || ""}
            name="title"
            onChange={handleEditChange}
            helperText={error?.title}
            error={Boolean(error?.title)}
            hideTitle
            label={t("Title")}
            likeGoogle
            variant="filled"
          />
          <DescriptionInput
            hideTitle
            rows={null}
            fontSize={"1rem"}
            value={datasetItem?.note || ""}
            onChange={handleEditChange}
            name="note"
            label={t("Note_Optional")}
            likeGoogle
            variant="filled"
            sx={{ mt: "2rem" }}
          />
          <TitleInput
            value={datasetItem?.alias || ""}
            name="alias"
            onChange={handleEditChange}
            helperText={error?.alias}
            error={Boolean(error?.alias)}
            hideTitle
            label={t("Alias")}
            likeGoogle
            variant="filled"
            sx={{ mt: "2rem" }}
          />
        </Box>
      </CustomModal>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.appThemeBg,
        textAlign: ["-webkit-center", "-moz-center"],
        pt: "2.5rem",
        position: "relative",
      }}
    >
      <DeleteConfirmationModal
        open={isDeleteOpen}
        onClose={onCloseDelete}
        onOk={onOk}
        message={`${t("Are_you_sure_delete_this")} ${t("Cost_Unit")}?`}
        type="delete"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "80%",
          mt: "2.5rem",
        }}
      >
        <Typography
          sx={{
            color: Color.black,
            fontSize: "2.5rem",
            fontWeight: 500,
          }}
        >
          {t("Cost_Unit_plural")}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ position: "relative" }}
        >
          <AddButton
            tooltipLabel={t("Add Cost Unit")}
            label={t("Add Cost Unit")}
            onClick={onClickAddNewCU}
            hideKey
          />
          <HelpOverlay
            showRight
            hideHeader
            text={t("Cost_Unit")}
            path={"cost-units"}
            image={Images.cost_unit_insights}
            wrapperStyle={{
              position: "absolute",
              right: "-3.5rem",
            }}
          />
        </Stack>
      </Box>

      {activeBoards()}
      {editModal()}
      <Outlet />
    </Box>
  );
};
export default CostUnit;
