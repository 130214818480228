import {
  useImperativeHandle,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Typography,
  Collapse,
  useTheme,
  Divider,
  Tooltip,
  Box,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useTranslation } from "react-i18next";
import { TbCircles } from "react-icons/tb";
import { useSelector } from "react-redux";

import { Color, Constant, Fonts } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";
import ToggleButton from "./ToggleButton";
import StateChip from "../StateChip";
import Icon from "../Icon";

const StateView = forwardRef(
  (
    {
      cardItem,
      onClick,
      width = "45rem",
      chipWidth = "10rem",
      chipHeight = "2.2rem",
      ml = "3.55rem",
      titleLeft = "1.2rem",
      tab,
    },
    _ref
  ) => {
    let isFirstIteration = useRef(true);
    const theme = useTheme();
    const { t } = useTranslation();
    const state = useSelector((state) => state.globalSlice?.state);
    const stateByTitle = useSelector(
      (state) => state.globalSlice?.stateByTitle
    );

    const PLANNED = stateByTitle["Planned"]?.[0];
    const BOOKED = stateByTitle["Booked"]?.[0];
    const isStaffTab =
      tab === "employee" || tab === "loan" || tab === "leasing";

    //state
    const [isExpanded, setIsExpanded] = useState(false);

    //functions
    const handleClickBtn = () => {
      setIsExpanded((prev) => !prev);
    };

    const handleClickInfo = () => {
      var win = window.open("https://finban.io/documentation/states", "_blank");
      win.focus();
    };

    useImperativeHandle(_ref, () => ({
      resetExpand() {
        isFirstIteration.current = true;
      },
    }));

    const getDisabledState = useCallback(
      (item) => {
        return (
          (cardItem.source === 2 &&
            !["Booked", "Ignored"].includes(item?.title)) ||
          (cardItem.source === 4 &&
            Constant.excludeForOpenState.includes(item?.title)) ||
          (cardItem?.recurring_rule &&
            (isStaffTab
              ? cardItem?.state === "Booked"
                ? item.title !== "Booked"
                : !Constant.staffState?.includes(item.title)
              : cardItem?.state === "Booked"
                ? item.title !== "Booked"
                : item.title !== "Planned"))
        );
      },
      [cardItem?.recurring_rule, cardItem.source, cardItem?.state, isStaffTab]
    );

    useEffect(() => {
      if (Constant?.miscStates?.includes(cardItem?.state) && !isExpanded) {
        setIsExpanded(true);
      }
    }, [cardItem?.state]);

    return (
      <>
        <Box
          ref={_ref}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            pr: "1.5rem",
            mt: "1.5rem",
            mb: "1.5rem",
          }}
        >
          <Icon
            icon={<TbCircles />}
            fontSize={"1.3rem"}
            color={Color.tailwind.blueGrey[700]}
            style={{
              marginRight: "1.7rem",
              marginLeft: titleLeft,
            }}
          ></Icon>
          <Typography
            variant="h6"
            component={"h6"}
            fontSize={"0.9rem"}
            color="color.mainTitle"
            fontWeight={"fontWeightMediumBold"}
          >
            {" "}
            {t("State")}
          </Typography>
          <Tooltip
            title={t("Click here to access our guide")}
            placement="top"
            arrow
          >
            <HelpOutlineOutlinedIcon
              onClick={handleClickInfo}
              sx={{
                fontSize: "1rem",
                cursor: "pointer",
                ml: "0.5rem",
                color: theme.palette.primary.main,
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            ml: ml,
            pr: "1.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isStaffTab ? (
            <Box
              sx={{
                display: "inline-flex",
                height: "max-content",
                alignItems: "center",
                flexWrap: "wrap",
                width: width,
              }}
            >
              {state?.map((item) => {
                if (cardItem?.state === "Booked") {
                  if (item?.title !== "Booked") {
                    return null;
                  }
                } else {
                  if (!Constant.staffState.includes(item.title)) {
                    return null;
                  }
                }
                return (
                  <StateChip
                    key={item?.uuid}
                    title={item?.title}
                    width={chipWidth}
                    height={chipHeight}
                    isSelected={cardItem?.state === item?.title}
                    onClick={onClick}
                    defaultShade={100}
                    disabled={getDisabledState(item)}
                    isEmployeeState={cardItem?.state !== "Booked"}
                    sx={{ ml: "0.5rem", mb: "0.5rem" }}
                  />
                );
              })}
            </Box>
          ) : null}

          {isStaffTab ? null : (
            <Collapse
              orientation="vertical"
              in={isExpanded}
              collapsedSize={"33rem"}
            >
              <Divider
                textAlign="left"
                sx={{
                  mb: "1rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  color: getTailwindColor("slate", 300),
                }}
              >
                {t("planned_state_separator_title")}
              </Divider>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  width: width,
                  flexWrap: "wrap",
                }}
              >
                <StateChip
                  key={PLANNED?.uuid}
                  title={PLANNED?.title}
                  width={chipWidth}
                  height={chipHeight}
                  sx={{ ml: "0.5rem", mb: "0.5rem" }}
                  isSelected={cardItem?.state === PLANNED?.title}
                  defaultShade={100}
                  onClick={onClick}
                  disabled={getDisabledState(PLANNED)}
                />
              </Box>
              <Divider
                textAlign="left"
                sx={{
                  mt: "2rem",
                  mb: "1rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  color: getTailwindColor("slate", 300),
                }}
              >
                {t("open_state_separator_title")}
              </Divider>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: width,
                }}
              >
                {state?.map((item) => {
                  if (!Constant.leadState.includes(item.title)) {
                    return null;
                  }
                  return (
                    <StateChip
                      key={item?.uuid}
                      title={item?.title}
                      width={chipWidth}
                      height={chipHeight}
                      sx={{ ml: "0.5rem", mb: "0.5rem" }}
                      isSelected={cardItem?.state === item?.title}
                      onClick={onClick}
                      defaultShade={100}
                      disabled={getDisabledState(item)}
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  flexWrap: "wrap",
                  my: "0.2rem",
                  width: width,
                }}
              >
                {state?.map((item) => {
                  if (!Constant.offerState.includes(item?.title)) {
                    return null;
                  }

                  return (
                    <StateChip
                      key={item?.uuid}
                      title={item?.title}
                      width={chipWidth}
                      height={chipHeight}
                      sx={{ ml: "0.5rem", mb: "0.5rem" }}
                      isSelected={cardItem?.state === item?.title}
                      onClick={onClick}
                      defaultShade={100}
                      disabled={getDisabledState(item)}
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: width,
                }}
              >
                {state?.map((item) => {
                  if (!Constant.invoiceState.includes(item.title)) {
                    return null;
                  }

                  return (
                    <StateChip
                      key={item?.uuid}
                      title={item?.title}
                      width={chipWidth}
                      height={chipHeight}
                      sx={{ ml: "0.5rem", mb: "0.5rem" }}
                      isSelected={cardItem?.state === item?.title}
                      onClick={onClick}
                      defaultShade={100}
                      disabled={getDisabledState(item)}
                    />
                  );
                })}
              </Box>

              <Divider
                textAlign="left"
                sx={{
                  mt: "2rem",
                  mb: "1rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  color: getTailwindColor("slate", 300),
                }}
              >
                {t("booked_state_separator_title")}
              </Divider>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  width: width,
                  flexWrap: "wrap",
                }}
              >
                <StateChip
                  key={BOOKED?.uuid}
                  title={BOOKED?.title}
                  width={chipWidth}
                  height={chipHeight}
                  sx={{ ml: "0.5rem", mb: "0.5rem" }}
                  isSelected={cardItem?.state === BOOKED?.title}
                  defaultShade={100}
                  onClick={onClick}
                  disabled={getDisabledState(BOOKED)}
                />
              </Box>

              <Divider
                textAlign="left"
                sx={{
                  mt: "2rem",
                  mb: "1rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  color: getTailwindColor("slate", 300),
                }}
              >
                {t("misc_state_separator_title")}
              </Divider>
              <Box
                sx={{
                  display: "inline-flex",
                  height: "max-content",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: width,
                  mb: "0.5rem",
                }}
              >
                {state?.map((item) => {
                  if (!Constant?.miscStates.includes(item?.title)) {
                    return null;
                  }

                  return (
                    <StateChip
                      key={item?.uuid}
                      title={item?.title}
                      width={chipWidth}
                      height={chipHeight}
                      sx={{ ml: "0.5rem", mb: "0.5rem" }}
                      isSelected={cardItem?.state === item?.title}
                      onClick={onClick}
                      defaultShade={100}
                      disabled={getDisabledState(item)}
                    />
                  );
                })}
              </Box>
            </Collapse>
          )}

          {isStaffTab ? null : (
            <ToggleButton isExpanded={isExpanded} onClick={handleClickBtn} />
          )}
        </Box>
      </>
    );
  }
);

export default StateView;
