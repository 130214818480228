import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import IntegrationsDialogView from "../Header/DatasetHeader/IntegrationsDialogView";
import { setDataSource, setOnBoardingList } from "../../store/slices/global";
import DSConnections from "../../Pages/Settings/DataSource/DSConnections";
import { setIsOnBoardingOverlayOpen } from "../../store/slices/common";
import EndPoints from "../../APICall/EndPoints";
import initialData from "../../Helper/data";
import { Constant } from "../../Helper";
import APICall from "../../APICall";

const OnBoardingFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dsRef = useRef(null);
  const isDSChanged = useRef(false);
  const [isOpen, setIsOpen] = useState(null);

  const onBoardingList = useSelector((state) => {
    return state?.globalSlice?.onBoardingList;
  });
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        const data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  const updateTodoById = async (uuid, payload) => {
    await APICall("patch", `${EndPoints.todos}${uuid}/`, payload).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  //functions
  const updateCheckedProperty = async (uuid, payload) => {
    let parentUpdatedObj = null;
    const traverseAndUpdate = (nodes) => {
      return nodes?.map((node) => {
        if (node?.uuid === uuid) {
          return { ...node, ...payload };
        }
        if (node?.children?.length > 0) {
          const children = traverseAndUpdate(node?.children);
          const isAllChecked = children?.every((child) => child?.checked);
          if (isAllChecked && !node?.checked) {
            parentUpdatedObj = {
              uuid: node?.uuid,
              payload: { checked: isAllChecked },
            };
          }
          if (!isAllChecked && node?.checked) {
            parentUpdatedObj = {
              uuid: node?.uuid,
              payload: { checked: isAllChecked },
            };
          }
          return { ...node, checked: isAllChecked, children: children };
        }
        return node;
      });
    };

    const updatedData = traverseAndUpdate(onBoardingList);
    dispatch(setOnBoardingList(updatedData));
    await updateTodoById(uuid, payload);
    if (parentUpdatedObj) {
      await updateTodoById(parentUpdatedObj?.uuid, parentUpdatedObj?.payload);
    }
  };

  const findObjectByTitle = (data, title) => {
    if (data?.length > 0) {
      for (const child of data) {
        if (child.title === title) {
          return child;
        }
        const result = findObjectByTitle(child?.children, title);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const updateOnboardingList = async (obj) => {
    if (obj?.uuid) {
      await updateCheckedProperty(obj?.uuid, obj?.payload || { checked: true });
    }
    if (obj?.isAccount) {
      const titleToFind = "Connect your Bank Account";
      const foundObject = findObjectByTitle(onBoardingList, titleToFind);
      if (foundObject) {
        updateCheckedProperty(
          foundObject?.uuid,
          obj?.payload || { checked: true }
        );
      }
    }
  };

  const onClickAction = async (item) => {
    if (item?.button_id === "add_bank_account") {
      const card =
        Constant.integrations.IntegrationsByName?.["FinApi_Account"]?.[0];
      if (card) {
        dsRef.current?.handleClickCard(card);
      }
    } else {
      updateOnboardingList(item);
      dispatch(setIsOnBoardingOverlayOpen(false));
    }
    if (item?.button_id === "access-staff-view") {
      const dataset = dataSetData?.uuid || dataSetList?.[0]?.uuid;
      navigate(`/${initialData?.path?.organization}/${dataset}/employees`);
    }
    if (item?.button_id === "access-list-view-to-cat") {
      const dataset = dataSetData?.uuid || dataSetList?.[0]?.uuid;
      navigate(`/${initialData?.path?.organization}/${dataset}/list/bulk`);
    }
    if (item?.button_id === "open-add-integration-overlay") {
      setIsOpen("assign");
    }
  };

  const handleClickClose = () => {
    setIsOpen(false);
    if (isDSChanged.current) {
      getDataSource();
    }
  };

  useImperativeHandle(_ref, () => ({
    updateOnboardingList,
    onClickAction,
  }));

  return (
    <>
      {Boolean(isOpen) ? (
        <IntegrationsDialogView
          open={Boolean(isOpen)}
          type={isOpen}
          handleClickClose={handleClickClose}
          isDSChanged={isDSChanged}
        />
      ) : null}
      <DSConnections ref={dsRef} />
    </>
  );
});

export default OnBoardingFunctions;
