import {
  eachMonthOfInterval,
  startOfMonth,
  isSameMonth,
  endOfMonth,
  subMonths,
  subYears,
  format,
} from "date-fns";
import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import { alpha, Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { styled } from "@mui/styles";

import {
  formatAmount,
  Highlighted,
  getShare,
  remToPx,
} from "../../../../Helper/data";
import DashBoardLineChart from "../../../../components/Charts/DashBoardLineChart";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import Placeholder from "../../../../components/Placeholder";
import { Color, Fonts, Images } from "../../../../Helper";
import EmployeeHeader from "../Component/EmployesHeader";
import WarnView from "../../../../components/WarnView";
import initialData from "./../../../../Helper/data";
import useWidth from "../../../../hooks/useWidth";

const PREFIX = "Employess";
const classes = {
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
};
const Root = styled("div")(({ width, height }) => ({
  [`& .${classes.paper}`]: {
    "& .css-mcts47-MuiPaper-root": {
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    // width: width,
    borderRadius: "5px",
    backgroundColor: Color.white,
    maxHeight: "85%",
    overflowY: "auto",
    height: height,
    position: "relative",
    "& MuiDataGrid-virtualScroller": {
      width: width,
    },
    "& .super-app-theme--cell": {
      backgroundColor: alpha(Color.headerIconLight, 0.3),
    },
  },
  [`& .${classes.table}`]: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
}));

const Staff = () => {
  let currentDate = useMemo(() => new Date(), []);
  const currentWidth = useWidth();
  const s1745 = useMediaQuery("(min-width:1745px)");
  let width = remToPx(currentWidth, s1745 ? 125 : 115);
  let height = remToPx(currentWidth, 43.85);
  const [isSubscriptionValid] = useSubscriptions();

  //redux
  const monthlyTransactions = useSelector(
    (state) => state.datasetSlice?.monthlyTransactions
  );

  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isFetching = useSelector((state) => state.appSlice.isFetching);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);
  //states
  const [toggle, setToggle] = useState(["Employees", "Freelancers"]);
  const [showInfo, setShowInfo] = useState(false);
  const [rows, setRows] = useState([]);
  const [chartData, setChartData] = useState([]);
  const categoriesId = useMemo(() => {
    let filters = selectionCategories?.filter(
      (o1) =>
        o1.type === 2 &&
        toggle?.some((o2) => o2?.toLowerCase() === o1?.label?.toLowerCase())
    );
    return filters;
  }, [selectionCategories, toggle]);

  const totalRevenue = useMemo(() => {
    let total = monthlyTransactions
      ?.filter(
        (o1) =>
          o1.month && !initialData?.calculationExcludeStates?.includes(o1.state)
      )
      .reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
        0
      );
    return total;
  }, [monthlyTransactions]);

  let data = useMemo(() => {
    return [
      {
        id: 1,
        title: "Personnel costs",
        key: "personalCost",
        key_fre: "personalCost_fre",
        key_emp: "personalCost_emp",
        total: "Total",
        employees: "Employees",
        freelancers: "Freelancers",
        info: true,
      },
      {
        id: 2,
        title: "Personnel costs / revenue",
        key: "personnelCosts_Revenue",
        key_fre: "personnelCosts_Revenue_fre",
        key_emp: "personnelCosts_Revenue_emp",
        total: "Total",
        employees: "Employees",
        freelancers: "Freelancers",
      },
      {
        id: 3,
        title: "Personnel cost productivity",
        subTitle: `(revenue per $ salary cost)`,
        key: "PersonnelCostProductivity",
        key_fre: "PersonnelCostProductivity_fre",
        key_emp: "PersonnelCostProductivity_emp",
        total: "Total",
        employees: "Employees",
        freelancers: "Freelancers",
      },
    ];
  }, [monthlyTransactions]);
  let key = ["currentYear", "preYear", "longGrowth", "preMonth", "shortGrowth"];
  //data
  const Column = useMemo(
    () => [
      {
        field: "title",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.175,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                fontSize: "1rem",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  color: Color.theme.blueGrey[900],
                  fontWeight: 500,
                  fontFamily: Fonts?.Text,
                  fontSize: "1rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {params.row.title}
                {params?.row?.info &&
                (showInfo === "error" || showInfo === "warning") ? (
                  <WarnView
                    showInfo={showInfo}
                    errorKey={"common_content_no_transctions"}
                    warnKey={"staff_warning_uncategorized"}
                    iconStyle={{ mt: "0.5rem", ml: "0.5rem" }}
                  />
                ) : null}
              </span>

              {toggle?.length === 2 && (
                <>
                  <span style={{ color: Color.purple500 }}>
                    {params.row.employees}{" "}
                    {params?.row?.info && showInfo?.emp ? (
                      <WarnView
                        showInfo={"warning"}
                        warnKey={"staff_warning_uncategorized_emp"}
                        iconStyle={{ mt: "0.5rem", ml: "0.5rem" }}
                      />
                    ) : null}
                  </span>
                  <span style={{ color: Color.theme.blue[800] }}>
                    {params.row.freelancers}{" "}
                    {params?.row?.info && showInfo?.fre ? (
                      <WarnView
                        showInfo={"warning"}
                        warnKey={"staff_warning_uncategorized_fre"}
                        iconStyle={{ mt: "0.5rem", ml: "0.5rem" }}
                      />
                    ) : null}
                  </span>
                </>
              )}
              <span
                style={{
                  color: Color.grey500,
                }}
              >
                {params?.row?.subTitle}
              </span>
            </div>
          );
        },
      },

      {
        field: "currentYear",
        headerName: "Financial year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.14,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {toggle?.length === 2 ? (
                params?.value ? (
                  <>
                    <span style={{ textAlign: "left" }}>{params?.value}</span>
                    <span
                      style={{ textAlign: "left", color: Color.employeeColor }}
                    >
                      {params?.row?.currentYear_emp}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: Color.freelancerColor,
                      }}
                    >
                      {params?.row?.currentYear_fre}
                    </span>
                  </>
                ) : (
                  ""
                )
              ) : (
                params?.value || ""
              )}
            </span>
          );
        },
      },
      {
        field: "preYear",
        headerName: "Previous year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.12,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {toggle?.length === 2 ? (
                params?.value ? (
                  <>
                    <span style={{ textAlign: "left" }}>{params?.value}</span>
                    <span
                      style={{ textAlign: "left", color: Color.employeeColor }}
                    >
                      {params?.row?.preYear_emp}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: Color.freelancerColor,
                      }}
                    >
                      {params?.row?.preYear_fre}
                    </span>
                  </>
                ) : (
                  ""
                )
              ) : (
                params?.value || ""
              )}
            </span>
          );
        },
      },
      {
        field: "longGrowth",
        headerName: "Growth in the long term",
        subTitle: "Current year compared to previous year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.135,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let number_fre = parseFloat(params.row?.longGrowth_fre).toFixed(1);
          let number_emp = parseFloat(params.row?.longGrowth_emp).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;
          let value_fre =
            number_fre > 0 ? `+${number_fre} %` : `${number_fre} %`;
          let value_emp =
            number_emp > 0 ? `+${number_emp} %` : `${number_emp} %`;
          return (
            <span
              style={{
                color: Color.theme.green[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "1rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {toggle?.length === 2 ? (
                params?.value || params?.value === 0 ? (
                  <>
                    <span style={{ textAlign: "left" }}>{value}</span>
                    <span
                      style={{ textAlign: "left", color: Color.employeeColor }}
                    >
                      {value_fre}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: Color.freelancerColor,
                      }}
                    >
                      {value_emp}
                    </span>
                  </>
                ) : (
                  ""
                )
              ) : toggle?.length === 0 ? (
                ""
              ) : params?.value || params?.value === 0 ? (
                value
              ) : (
                ""
              )}
            </span>
          );
        },
      },
      {
        field: "preMonth",
        headerName: "Previous Month",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.13,
        editable: false,
        sortable: false,
        type: "text",
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {toggle?.length === 2 ? (
                params?.value ? (
                  <>
                    <span style={{ textAlign: "left" }}>{params?.value}</span>
                    <span
                      style={{ textAlign: "left", color: Color.employeeColor }}
                    >
                      {params?.row?.preMonth_emp}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: Color.freelancerColor,
                      }}
                    >
                      {params?.row?.preMonth_fre}
                    </span>
                  </>
                ) : (
                  ""
                )
              ) : (
                params?.value || ""
              )}
            </span>
          );
        },
      },
      {
        field: "shortGrowth",
        headerName: "Growth short-term",
        subTitle: "3-month average compared with prior-year period",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.24,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let number_fre = parseFloat(params.row?.shortGrowth_fre).toFixed(1);
          let number_emp = parseFloat(params.row?.shortGrowth_emp).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;
          let value_fre =
            number_fre > 0 ? `+${number_fre} %` : `${number_fre} %`;
          let value_emp =
            number_emp > 0 ? `+${number_emp} %` : `${number_emp} %`;
          return (
            <span
              style={{
                color: Color.theme.green[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "1rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {toggle?.length === 2 ? (
                params?.value || params?.value === 0 ? (
                  <>
                    <span style={{ textAlign: "left" }}>{value}</span>
                    <span
                      style={{ textAlign: "left", color: Color.employeeColor }}
                    >
                      {value_fre}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: Color.freelancerColor,
                      }}
                    >
                      {value_emp}
                    </span>
                  </>
                ) : (
                  ""
                )
              ) : toggle?.length === 0 ? (
                ""
              ) : params?.value || params?.value === 0 ? (
                value
              ) : (
                ""
              )}
            </span>
          );
        },
      },
    ],
    [
      appliedFilterlist?.reports?.report?.start_date,
      appliedFilterlist?.reports?.report?.end_date,
      appliedFilterlist?.reports?.report?.selectedState,
      appliedFilterlist?.reports?.report?.selectedScenarios,
      showInfo,
      toggle?.length,
      width,
    ]
  );

  useEffect(() => {
    if (
      isSubscriptionValid({ showMessage: true }) &&
      subscription?.staffs_chart &&
      isAllHeaderApiFetched &&
      !isFetching &&
      categoriesId &&
      appliedFilterlist?.reports?.report &&
      appliedFilterlist?.reports?.report?.start_date &&
      appliedFilterlist?.reports?.report?.end_date
    ) {
      let yearGrowthObj = null;
      key.forEach((element, index) => {
        let obj = null;
        if (index === 0) {
          obj = getCurrentYear(totalRevenue);
          yearGrowthObj = {
            ...yearGrowthObj,
            current: obj,
          };
        }
        if (index === 1) {
          obj = getPreYear(totalRevenue);
          yearGrowthObj = {
            ...yearGrowthObj,
            prev: obj,
          };
        }
        if (index === 2) {
          obj = getYearDifference(yearGrowthObj);
        }
        if (index === 3) {
          obj = getPrevMonthData(totalRevenue);
        }
        if (index === 4) {
          obj = get3MonthShare(totalRevenue);
        }
        if (obj) {
          data.forEach((item) => {
            item[element] = obj[item?.key];
            item[`${element}_fre`] = obj[item?.key_fre];
            item[`${element}_emp`] = obj[item?.key_emp];
          });
        }
      });
      getChartData();
    }
    setRows(data);
  }, [
    monthlyTransactions,
    categoriesId,
    appliedFilterlist?.reports?.report,
    isAllHeaderApiFetched,
    isFetching,
    subscription?.staffs_chart,
  ]);

  //function
  const getPercentageChange = (oldNumber, newNumber) => {
    var decreaseValue = parseFloat(newNumber) - parseFloat(oldNumber);
    let divide = 1;
    divide =
      oldNumber === 0
        ? parseFloat(newNumber) < 0
          ? -1
          : 1
        : parseFloat(oldNumber);
    return ((Math.abs(decreaseValue) / divide) * 100.0).toFixed(1);
  };

  const getGrowth = (prevPer, current) => {
    let Growth = 100;
    if (prevPer !== 0) {
      Growth = getPercentageChange(prevPer, current);
    }
    if ((prevPer === 0 && current === 0) || current === 0) {
      Growth = 0;
    }
    return Growth;
  };

  const getCurrentYear = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios, start_date, end_date } =
        appliedFilterlist?.reports?.report;
      const currentYearStartDate = start_date;
      const currentYearEndDate = end_date;
      let personalCost_fre = null;
      let personalCost_emp = null;
      let personnelCosts_Revenue_fre = null;
      let personnelCosts_Revenue_emp = null;
      let PersonnelCostProductivity_fre = null;
      let PersonnelCostProductivity_emp = null;
      let filterData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          categoriesId?.some((o2) => o2?.value === o1.category) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(currentYearStartDate) &&
          new Date(o1.month) <= new Date(currentYearEndDate)
      );
      let personalCost = filterData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let personnelCosts_Revenue = getShare(totalRevenue, personalCost);
      let PersonnelCostProductivity =
        personalCost === 0 ? personalCost : totalRevenue / personalCost;
      let obj = {
        personalCost: formatAmount({
          amount: String(parseFloat(personalCost).toFixed(1)),
        }),
        personnelCosts_Revenue: `${personnelCosts_Revenue} %`,
        PersonnelCostProductivity: formatAmount({
          amount: String(parseFloat(PersonnelCostProductivity).toFixed(1)),
        }),
        personalCost_fre,
        personalCost_emp,
        personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp,
      };

      if (toggle?.length === 2) {
        let empData = filterData.filter(
          (o1) => o1.category === categoriesId[0]?.value
        );
        let freData = filterData.filter(
          (o1) => o1.category === categoriesId[1]?.value
        );
        personalCost_fre = freData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personalCost_emp = empData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personnelCosts_Revenue_fre = getShare(totalRevenue, personalCost_fre);
        personnelCosts_Revenue_emp = getShare(totalRevenue, personalCost_emp);
        PersonnelCostProductivity_fre =
          personalCost_fre === 0
            ? personalCost_fre
            : totalRevenue / personalCost_fre;
        PersonnelCostProductivity_emp =
          personalCost_emp === 0
            ? personalCost_emp
            : totalRevenue / personalCost_emp;

        let detailObj = {
          personalCost_fre: formatAmount({
            amount: String(parseFloat(personalCost_fre).toFixed(1)),
          }),
          personalCost_emp: formatAmount({
            amount: String(parseFloat(personalCost_emp).toFixed(1)),
          }),
          personnelCosts_Revenue_fre: `${personnelCosts_Revenue_fre} %`,
          personnelCosts_Revenue_emp: `${personnelCosts_Revenue_emp} %`,
          PersonnelCostProductivity_fre: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_fre).toFixed(1)
            ),
          }),
          PersonnelCostProductivity_emp: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_emp).toFixed(1)
            ),
          }),
        };
        obj = { ...obj, ...detailObj };
      }
      obj = {
        ...obj,
        personalCost_orignal: personalCost,
        personnelCosts_Revenue_orignal: personnelCosts_Revenue,
        PersonnelCostProductivity_orignal: PersonnelCostProductivity,
        personalCost_fre_orignal: personalCost_fre,
        personalCost_emp_orignal: personalCost_emp,
        personnelCosts_Revenue_fre_orignal: personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp_orignal: personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre_orignal: PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp_orignal: PersonnelCostProductivity_emp,
      };
      return obj;
    },
    [
      categoriesId,
      toggle?.length,
      appliedFilterlist?.reports?.report,
      monthlyTransactions,
    ]
  );

  const getPreYear = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios, start_date, end_date } =
        appliedFilterlist?.reports?.report;
      const preYearStartDate = subYears(new Date(start_date), 1);
      const preYearEndDate = subYears(new Date(end_date), 1);
      let personalCost_fre = null;
      let personalCost_emp = null;
      let personnelCosts_Revenue_fre = null;
      let personnelCosts_Revenue_emp = null;
      let PersonnelCostProductivity_fre = null;
      let PersonnelCostProductivity_emp = null;
      let filterData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          categoriesId?.some((o2) => o2?.value === o1.category) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(preYearStartDate) &&
          new Date(o1.month) <= new Date(preYearEndDate)
      );
      let personalCost = filterData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let personnelCosts_Revenue = getShare(totalRevenue, personalCost);
      let PersonnelCostProductivity =
        personalCost === 0 ? personalCost : totalRevenue / personalCost;
      let obj = {
        personalCost: formatAmount({
          amount: String(parseFloat(personalCost).toFixed(1)),
        }),
        personnelCosts_Revenue: `${personnelCosts_Revenue} %`,
        PersonnelCostProductivity: formatAmount({
          amount: String(parseFloat(PersonnelCostProductivity).toFixed(1)),
        }),
        personalCost_fre,
        personalCost_emp,
        personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp,
      };

      if (toggle?.length === 2) {
        let empData = filterData.filter(
          (o1) => o1.category === categoriesId[0]?.value
        );
        let freData = filterData.filter(
          (o1) => o1.category === categoriesId[1]?.value
        );
        personalCost_fre = freData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personalCost_emp = empData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personnelCosts_Revenue_fre = getShare(totalRevenue, personalCost_fre);
        personnelCosts_Revenue_emp = getShare(totalRevenue, personalCost_emp);
        PersonnelCostProductivity_fre =
          personalCost_fre === 0
            ? personalCost_fre
            : totalRevenue / personalCost_fre;
        PersonnelCostProductivity_emp =
          personalCost_emp === 0
            ? personalCost_emp
            : totalRevenue / personalCost_emp;

        let detailObj = {
          personalCost_fre: formatAmount({
            amount: String(parseFloat(personalCost_fre).toFixed(1)),
          }),
          personalCost_emp: formatAmount({
            amount: String(parseFloat(personalCost_emp).toFixed(1)),
          }),
          personnelCosts_Revenue_fre: `${personnelCosts_Revenue_fre} %`,
          personnelCosts_Revenue_emp: `${personnelCosts_Revenue_emp} %`,
          PersonnelCostProductivity_fre: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_fre).toFixed(1)
            ),
          }),
          PersonnelCostProductivity_emp: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_emp).toFixed(1)
            ),
          }),
        };
        obj = { ...obj, ...detailObj };
      }
      obj = {
        ...obj,
        personalCost_orignal: personalCost,
        personnelCosts_Revenue_orignal: personnelCosts_Revenue,
        PersonnelCostProductivity_orignal: PersonnelCostProductivity,
        personalCost_fre_orignal: personalCost_fre,
        personalCost_emp_orignal: personalCost_emp,
        personnelCosts_Revenue_fre_orignal: personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp_orignal: personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre_orignal: PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp_orignal: PersonnelCostProductivity_emp,
      };
      return obj;
    },
    [
      categoriesId,
      monthlyTransactions,
      toggle?.length,
      appliedFilterlist?.reports?.report,
    ]
  );

  const getYearDifference = ({ current, prev }) => {
    let personalCost = getGrowth(
      prev?.personalCost_orignal,
      current?.personalCost_orignal
    );
    let personalCost_fre = getGrowth(
      prev?.personalCost_fre_orignal,
      current?.personalCost_fre_orignal
    );
    let personalCost_emp = getGrowth(
      prev?.personalCost_emp_orignal,
      current?.personalCost_emp_orignal
    );
    let personnelCosts_Revenue = getShare(
      prev?.PersonnelCostProductivity_orignal ?? 0,
      current?.PersonnelCostProductivity_orignal ?? 0
    );
    let personnelCosts_Revenue_fre = getShare(
      prev?.personnelCosts_Revenue_fre_orignal ?? 0,
      current?.personnelCosts_Revenue_fre_orignal ?? 0
    );
    let personnelCosts_Revenue_emp = getShare(
      prev?.personnelCosts_Revenue_emp_orignal ?? 0,
      current?.personnelCosts_Revenue_emp_orignal ?? 0
    );
    let PersonnelCostProductivity = getGrowth(
      prev?.PersonnelCostProductivity_orignal,
      current?.PersonnelCostProductivity_orignal
    );
    let PersonnelCostProductivity_fre = getGrowth(
      prev?.PersonnelCostProductivity_fre_orignal,
      current?.PersonnelCostProductivity_fre_orignal
    );
    let PersonnelCostProductivity_emp = getGrowth(
      prev?.PersonnelCostProductivity_fre_orignal,
      current?.PersonnelCostProductivity_fre_orignal
    );

    let obj = {
      personalCost,
      personnelCosts_Revenue,
      PersonnelCostProductivity,
      personalCost_fre,
      personalCost_emp,
      personnelCosts_Revenue_fre,
      personnelCosts_Revenue_emp,
      PersonnelCostProductivity_fre,
      PersonnelCostProductivity_emp,
    };
    return obj;
  };

  const getPrevMonthData = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios } =
        appliedFilterlist?.reports?.report;
      const result = subMonths(currentDate, 1);
      const prevMonthStartDate = startOfMonth(result);
      const prevMonthEndDate = endOfMonth(result);
      let personalCost_fre = null;
      let personalCost_emp = null;
      let personnelCosts_Revenue_fre = null;
      let personnelCosts_Revenue_emp = null;
      let PersonnelCostProductivity_fre = null;
      let PersonnelCostProductivity_emp = null;

      let filterData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          categoriesId?.some((o2) => o2?.value === o1.category) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prevMonthStartDate) &&
          new Date(o1.month) <= new Date(prevMonthEndDate)
      );

      let personalCost = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(Math.abs(Math.abs(item?.outflow ?? 0))),
        0
      );
      let personnelCosts_Revenue = getShare(totalRevenue, personalCost);
      let PersonnelCostProductivity =
        personalCost === 0 ? personalCost : totalRevenue / personalCost;
      let obj = {
        personalCost: formatAmount({
          amount: String(parseFloat(personalCost).toFixed(1)),
        }),
        personnelCosts_Revenue: `${personnelCosts_Revenue} %`,
        PersonnelCostProductivity: formatAmount({
          amount: String(parseFloat(PersonnelCostProductivity).toFixed(1)),
        }),
        personalCost_fre,
        personalCost_emp,
        personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp,
      };

      if (toggle?.length === 2) {
        let empData = filterData.filter(
          (o1) => o1.category === categoriesId[0]?.value
        );
        let freData = filterData.filter(
          (o1) => o1.category === categoriesId[1]?.value
        );
        personalCost_fre = freData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personalCost_emp = empData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        );
        personnelCosts_Revenue_fre = getShare(totalRevenue, personalCost_fre);
        personnelCosts_Revenue_emp = getShare(totalRevenue, personalCost_emp);
        PersonnelCostProductivity_fre =
          personalCost_fre === 0
            ? personalCost_fre
            : totalRevenue / personalCost_fre;
        PersonnelCostProductivity_emp =
          personalCost_emp === 0
            ? personalCost_emp
            : totalRevenue / personalCost_emp;

        let detailObj = {
          personalCost_fre: formatAmount({
            amount: String(parseFloat(personalCost_fre).toFixed(1)),
          }),
          personalCost_emp: formatAmount({
            amount: String(parseFloat(personalCost_emp).toFixed(1)),
          }),
          personnelCosts_Revenue_fre: `${personnelCosts_Revenue_fre} %`,
          personnelCosts_Revenue_emp: `${personnelCosts_Revenue_emp} %`,
          PersonnelCostProductivity_fre: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_fre).toFixed(1)
            ),
          }),
          PersonnelCostProductivity_emp: formatAmount({
            amount: String(
              parseFloat(PersonnelCostProductivity_emp).toFixed(1)
            ),
          }),
        };
        obj = { ...obj, ...detailObj };
      }

      return obj;
    },
    [
      appliedFilterlist?.reports?.report?.selectedState,
      appliedFilterlist?.reports?.report?.selectedScenarios,
      categoriesId,
      currentDate,
      monthlyTransactions,
      toggle?.length,
    ]
  );

  const get3MonthShare = useCallback(
    (totalRevenue) => {
      let { selectedState, selectedScenarios } =
        appliedFilterlist?.reports?.report;
      const result = subMonths(currentDate, 3);
      const prev3MonthStartDate = startOfMonth(result);
      const prevPer3MonthStartDate = subYears(prev3MonthStartDate, 1);
      const endDate = endOfMonth(currentDate);
      const pervPerEndDate = subYears(endDate, 1);

      let personalCost_fre = null;
      let personalCost_emp = null;
      let personnelCosts_Revenue_fre = null;
      let personnelCosts_Revenue_emp = null;
      let PersonnelCostProductivity_fre = null;
      let PersonnelCostProductivity_emp = null;

      let current3MonthData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          categoriesId?.some((o2) => o2?.value === o1.category) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prev3MonthStartDate) &&
          new Date(o1.month) <= new Date(endDate)
      );
      let current3MonthTotal =
        current3MonthData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;
      let prevPer3MonthData = monthlyTransactions?.filter(
        (o1) =>
          o1.month &&
          o1.category &&
          !initialData?.calculationExcludeStates?.includes(o1.state) &&
          categoriesId?.some((o2) => o2?.value === o1.category) &&
          selectedState?.some((o2) => o2 === o1.state) &&
          selectedScenarios?.some((o2) => o2 === o1.scenario) &&
          new Date(o1.month) >= new Date(prevPer3MonthStartDate) &&
          new Date(o1.month) <= new Date(pervPerEndDate)
      );
      let prevPer3MonthTotal =
        prevPer3MonthData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
          0
        ) / 3;
      let personalCost = getGrowth(prevPer3MonthTotal, current3MonthTotal);

      let prevPreShare = getShare(totalRevenue, prevPer3MonthTotal);
      let currentShare = getShare(totalRevenue, current3MonthTotal);
      let personnelCosts_Revenue = prevPreShare - currentShare;

      let prevPreProd =
        prevPer3MonthTotal === 0
          ? prevPer3MonthTotal
          : totalRevenue / prevPer3MonthTotal;
      let currentProd =
        current3MonthTotal === 0
          ? current3MonthTotal
          : totalRevenue / current3MonthTotal;
      let PersonnelCostProductivity = getPercentageChange(
        prevPreProd,
        currentProd
      );

      if (toggle?.length === 2) {
        let current3MonthEmpTotal =
          current3MonthData
            .filter((o1) => o1.category === categoriesId[0]?.value)
            .reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
              0
            ) / 3;
        let current3MonthFreTotal =
          current3MonthData
            .filter((o1) => o1.category === categoriesId[1]?.value)
            .reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
              0
            ) / 3;
        let prevPer3MonthEmpTotal =
          prevPer3MonthData
            .filter((o1) => o1.category === categoriesId[0]?.value)
            .reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
              0
            ) / 3;
        let prevPer3MonthFreTotal =
          prevPer3MonthData
            .filter((o1) => o1.category === categoriesId[1]?.value)
            .reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
              0
            ) / 3;
        personalCost_fre = 100;
        personalCost_emp = 100;
        if (prevPer3MonthFreTotal !== 0) {
          personalCost_fre = getPercentageChange(
            prevPer3MonthFreTotal,
            current3MonthFreTotal
          );
        }
        if (prevPer3MonthEmpTotal !== 0) {
          personalCost_emp = getPercentageChange(
            prevPer3MonthEmpTotal,
            current3MonthEmpTotal
          );
        }
        if (prevPer3MonthFreTotal === 0 && current3MonthFreTotal === 0) {
          personalCost_fre = 0;
        }
        if (prevPer3MonthEmpTotal === 0 && current3MonthEmpTotal === 0) {
          personalCost_emp = 0;
        }

        let prevPreShareFre = getShare(totalRevenue, prevPer3MonthFreTotal);
        let currentShareFre = getShare(totalRevenue, current3MonthFreTotal);
        personnelCosts_Revenue_fre = prevPreShareFre - currentShareFre;
        let prevPreShareEmp = getShare(totalRevenue, prevPer3MonthEmpTotal);
        let currentShareEmp = getShare(totalRevenue, current3MonthEmpTotal);
        personnelCosts_Revenue_emp = prevPreShareEmp - currentShareEmp;

        let prevPreProdFre =
          prevPer3MonthFreTotal === 0
            ? prevPer3MonthFreTotal
            : totalRevenue / prevPer3MonthFreTotal;
        let currentProdFre =
          current3MonthFreTotal === 0
            ? current3MonthFreTotal
            : totalRevenue / current3MonthFreTotal;
        PersonnelCostProductivity_fre = getPercentageChange(
          prevPreProdFre,
          currentProdFre
        );
        let prevPreProdEmp =
          prevPer3MonthEmpTotal === 0
            ? prevPer3MonthEmpTotal
            : totalRevenue / prevPer3MonthEmpTotal;
        let currentProdEmp =
          current3MonthEmpTotal === 0
            ? current3MonthEmpTotal
            : totalRevenue / current3MonthEmpTotal;
        PersonnelCostProductivity_emp = getPercentageChange(
          prevPreProdEmp,
          currentProdEmp
        );
      }
      let obj = {
        personalCost,
        personnelCosts_Revenue,
        PersonnelCostProductivity,
        personalCost_fre,
        personalCost_emp,
        personnelCosts_Revenue_fre,
        personnelCosts_Revenue_emp,
        PersonnelCostProductivity_fre,
        PersonnelCostProductivity_emp,
      };
      return obj;
    },
    [
      appliedFilterlist?.reports?.report?.selectedState,
      appliedFilterlist?.reports?.report?.selectedScenarios,
      categoriesId,
      currentDate,
      monthlyTransactions,
      toggle?.length,
    ]
  );

  const getChartData = useCallback(() => {
    let { selectedState, selectedScenarios, start_date, end_date } =
      appliedFilterlist?.reports?.report;
    let last12M_start_date = new Date(start_date);
    let last12M_end_date = new Date(end_date);
    let preYear_start_date = new Date(subYears(last12M_start_date, 1));
    let filterData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        o1.category &&
        !initialData?.calculationExcludeStates?.includes(o1.state) &&
        selectedState?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(preYear_start_date) &&
        new Date(o1.month) <= new Date(last12M_end_date)
    );
    const result = eachMonthOfInterval({
      start: last12M_start_date,
      end: last12M_end_date,
    });
    let dummy = [];
    categoriesId?.forEach((category) => {
      result.forEach((element, index) => {
        let due_date_prev = subYears(element, 1);
        let totalCurrent = filterData
          ?.filter(
            (o1) =>
              o1.category === category?.value &&
              isSameMonth(new Date(o1.month), element)
          )
          .reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
            0
          );
        let totalPrev = filterData
          ?.filter(
            (o1) =>
              o1.category === category?.value &&
              isSameMonth(new Date(o1.month), due_date_prev)
          )
          .reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
            0
          );
        let personnelCosts_Revenue_12M = getShare(totalRevenue, totalCurrent);
        let personnelCosts_Revenue_prev = getShare(totalRevenue, totalPrev);
        let PersonnelCostProductivity_12M =
          totalCurrent === 0 ? totalCurrent : totalRevenue / totalCurrent;
        let PersonnelCostProductivity_prev =
          totalPrev === 0 ? totalPrev : totalRevenue / totalPrev;

        dummy[index] = {
          ...dummy[index],
          id: index,
          due_date_12M: format(element, "MM-yyyy"),
          due_date_prev: format(due_date_prev, "MM-yyyy"),
          [`personalCost_12M_${category?.label?.toLowerCase()}`]: totalCurrent,
          [`personalCost_prev_${category?.label?.toLowerCase()}`]: totalPrev,
          [`personnelCosts_Revenue_12M_${category?.label?.toLowerCase()}`]:
            personnelCosts_Revenue_12M / 100,
          [`personnelCosts_Revenue_prev_${category?.label?.toLowerCase()}`]:
            personnelCosts_Revenue_prev / 100,
          [`PersonnelCostProductivity_12M_${category?.label?.toLowerCase()}`]:
            PersonnelCostProductivity_12M,
          [`PersonnelCostProductivity_prev_${category?.label?.toLowerCase()}`]:
            PersonnelCostProductivity_prev,
        };
      });
    });

    let staffData = 0;

    if (toggle.length === 2) {
      let empData = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            categoriesId[0]?.value === item.category
              ? item?.outflow_count ?? 0
              : 0
          ),
        0
      );
      let freData = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            categoriesId[1]?.value === item.category
              ? item?.outflow_count ?? 0
              : 0
          ),
        0
      );
      staffData = Number(empData) + Number(freData);
      setShowInfo({
        emp: empData === 0,
        fre: freData === 0,
        type: "warning",
      });
    }
    if (toggle.length === 1) {
      staffData = filterData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            categoriesId?.includes(item.category) ? item?.outflow_count ?? 0 : 0
          ),
        0
      );
      if (monthlyTransactions?.length !== 0 && staffData === 0) {
        setShowInfo("warning");
      } else {
        setShowInfo(false);
      }
    }
    if (monthlyTransactions?.length === 0) {
      setShowInfo("error");
    }
    setChartData(dummy);
  }, [
    appliedFilterlist?.reports?.report,
    monthlyTransactions,
    totalRevenue,
    categoriesId,
  ]);

  if (
    !appliedFilterlist?.reports?.report?.start_date ||
    !appliedFilterlist?.reports?.report?.end_date
  ) {
    return null;
  }

  //RenderFunction
  const headerCell = (props) => {
    const { headerName: title, subTitle, field } = props;
    let value = subTitle;

    if (field === "preMonth") {
      const result = subMonths(currentDate, 1);
      const prevMonthStartDate = format(startOfMonth(result), "dd.MM.yyyy");
      const prevMonthEndDate = format(endOfMonth(result), "dd.MM.yyyy");
      value = `${prevMonthStartDate} - ${prevMonthEndDate}`;
    }
    if (field === "currentYear") {
      const currentYearStartDate = format(
        new Date(appliedFilterlist?.reports?.report?.start_date),
        "dd.MM.yyyy"
      );
      const currentYearEndDate = format(
        new Date(appliedFilterlist?.reports?.report?.end_date),
        "dd.MM.yyyy"
      );
      value = `${currentYearStartDate} - ${currentYearEndDate}`;
    }
    if (field === "preYear") {
      const preYearStartDate = format(
        subYears(new Date(appliedFilterlist?.reports?.report?.start_date), 1),
        "dd.MM.yyyy"
      );
      const preYearEndDate = format(
        subYears(new Date(appliedFilterlist?.reports?.report?.end_date), 1),
        "dd.MM.yyyy"
      );
      value = `${preYearStartDate} - ${preYearEndDate}`;
    }
    return (
      <Typography
        component="div"
        variant="h6"
        sx={{
          width: "15%",
          color: Color?.blueGrey800,
          fontFamily: Fonts?.Text,
          fontSize: "0.9rem",
          fontweight: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {title}
        <Typography
          component="span"
          variant="span"
          sx={{
            color: Color?.grey500,
            fontFamily: Fonts?.Text,
            fontSize: ".7rem",
            fontweight: 400,
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  const ChartIndicator = ({ title, color = Color.black, type = "solid" }) => {
    return (
      <Typography sx={{ display: "flex", flexDirection: "column", mx: "1rem" }}>
        {title}
        <span
          style={{
            height: "0.15625rem",
            width: "100%",
            borderBottom: `3px ${type} ${color}`,
          }}
        />
      </Typography>
    );
  };

  return (
    <Root width={width} height={height}>
      <Box sx={{ width: width }}>
        {!subscription?.staffs_chart ? (
          <Placeholder
            image={Images.staff}
            insightText={"Staffs"}
            // description={
            //   subscription?.staffs_chart && showInfo && toggle.length === 2 ? (
            //     <span>
            //       {" "}
            //       Not enough data. Please assign the relevant transactions to
            //       the categories "Employees" and "Freelancers" to get insights.
            //       <Link
            //         color="inherit"
            //         href={"https://finban.io/documentation/categories/staff"}
            //         target="_blank"
            //         sx={{
            //           fontFamily: Fonts.Text,
            //           color: Color.themeColor2,
            //         }}
            //       >
            //         Learn more about Categories & Staff.
            //       </Link>
            //     </span>
            //   ) : null
            // }
            height={height + remToPx(currentWidth, 6.25)}
            width={width}
            style={{
              mt: "1.5rem",
            }}
          />
        ) : (
          <>
            <EmployeeHeader
              width={width}
              toggle={toggle}
              setToggle={setToggle}
              insightsOptions={[
                {
                  id: 1,
                  image: Images.staff,
                  linkText: (
                    <Highlighted
                      text="Staff - Documentation"
                      highlight="Staff"
                    />
                  ),
                  text: "Staff",
                  link: `https://finban.io/documentation/reports/staff/mitarbeiter-freelancer/`,
                  description: "Learn how to use staff",
                },
              ]}
            />
            <Box
              sx={{
                display: "flex",
                width: width,
                justifyContent: "space-between",
              }}
            >
              <Paper
                className={classes.paper}
                sx={{
                  width: "70%",
                }}
              >
                <DataGrid
                  className={classes.table}
                  rows={rows}
                  columns={Column}
                  rowHeight={remToPx(currentWidth, 13.175)}
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                />
              </Paper>

              <Box
                sx={{
                  flex: 1,
                  width: "30%",
                  height: height,
                  border: `2px solid ${Color.theme.grey[200]}`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    height: 50,
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <ChartIndicator
                    title={"Last 12 Month"}
                    // color={Color?.themeColor}
                  />
                  <ChartIndicator
                    title={"previous year "}
                    color={Color?.grey500}
                    type={"dotted"}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    borderBlock: `2px solid ${Color.theme.grey[200]}`,
                  }}
                >
                  <DashBoardLineChart data={chartData} />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    borderBottom: `2px solid ${Color.theme.grey[200]}`,
                  }}
                >
                  <DashBoardLineChart
                    data={chartData}
                    type="personnelCosts_Revenue"
                    title="Personnel costs / revenue"
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DashBoardLineChart
                    data={chartData}
                    type="PersonnelCostProductivity"
                    title="Personnel cost productivity"
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Root>
  );
};

export default memo(Staff);
