import {
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  format,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useIsFetching } from "@tanstack/react-query";
import { Box, alpha } from "@mui/material";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { produce } from "immer";

import {
  calculatePercentageChange,
  getTransactionByParams,
  getTailwindColor,
} from "../../../../../Helper/data";
import useDebounce from "../../../../../hooks/3-useDebounce/useDebounce";
import { setPopupStatus2 } from "../../../../../store/slices/datasets";
import { GlobalContext } from "../../../../../GlobalContextWrapper";
import { setLoading } from "../../../../../store/slices/appmain";
import { collectUUIDs } from "../../../../../Helper/functions";
import { Color, Constant } from "../../../../../Helper";
import Cell from "./Cell";

const RowWrapper = styled(Box)`
  display: flex;
  width: fit-content;
  &:hover {
    & ${(props) => `#${props?.$rowId}-row`} {
      z-index: 7;
      background-color: ${alpha(Color.themeColor2, 0.05)};
      background: ${alpha(Color.themeColor2, 0.05)};
    }
  }
`;

const Table = ({
  rows = [],
  columns = [],
  borderRadius = 0,
  name = "inflow",
  style,
  onCellChanged,
  position,
  selectedCell,
  setSelectedCell,
}) => {
  const keys = useRef({
    row_keys: [],
    column_keys: [],
  });
  const commonFunctionRef = useRef(null);

  //redux
  const searchText = useSelector((state) => state.boardSlice?.searchText);
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);

  const level1Category = useMemo(() => {
    return [];
    // return selectionCategories
    //   .filter((o1) => o1.depth === 1)
    //   .map((o1) => o1.parent);
  }, [selectionCategories]);

  //state
  const [data, setData] = useState({});
  const [expanded, setExpanded] = useState([name, ...level1Category]);
  const isHeaderExpanded = expanded?.includes(name);

  const active_key_array = useMemo(() => {
    let array = [name];
    keys.current?.row_keys?.forEach((rowId) => {
      const rowData = data ? data[rowId] : null;
      const cell = rowData?.["column-start"];
      if (!rowData || !cell || !expanded?.includes(cell?.parentRowId)) {
        return null;
      }
      array.push(rowId);
    });

    if (!expanded.includes(name)) {
      array = [name];
    }
    return array;
  }, [data, expanded, name, selectionCategoriesByID]);

  //lifecycle
  useEffect(() => {
    const obj = {};
    keys.current = {
      row_keys: rows?.map((o1) => o1?.rowId),
      column_keys: columns?.map((o1) => o1?.columnId),
    };
    rows?.forEach((row, rowIndex) => {
      obj[row?.rowId] = {};

      columns?.forEach((column, index) => {
        if (obj[row?.rowId]) {
          obj[row?.rowId][column?.columnId] = {
            rowHeight: row?.rowHeight,
            rowId: row?.rowId,
            mergeCell: row?.mergeCell,
            backgroundColor: row?.rowBackgroundColor,
            color: row?.color,
            parentRowId: row?.parentRowId,
            hasChildren: row?.hasChildren,
            depth: row?.depth,
            isHeader: row?.isHeader,
            disableSelection: row?.disableSelection,
            pattern: row?.pattern,
            position: row?.position,
            isLastCategoryChildCell: row?.isLastCategoryChildCell,

            editable: row?.cells?.[index]?.editable,
            isSticky: column?.isSticky,
            gridWidth: column?.gridWidth,
            cellId: column?.item,
            isFirstCell: index === 0,
            isFirstRowCell: rowIndex === 0,
            isLastCell: index === columns.length - 1,
            ...row?.cells?.[index],
          };
        }
      });
    });
    setData(obj);
  }, [columns, rows]);

  useDebounce(
    () => {
      if (searchText?.length) {
        let includedCategory = selectionCategories
          .filter((o1) => o1.hasChildren)
          .map((o1) => o1.value);
        setExpanded([name, ...includedCategory]);
      } else {
        setExpanded([name, ...level1Category]);
      }
    },
    500,
    [searchText, selectionCategories]
  );

  //functions
  const onChangedValue = ({ rowId, columnId, cell }, value, uuid) => {
    setData((prevData) =>
      produce(prevData, (modifiedData) => {
        if (!modifiedData?.[rowId]) modifiedData[rowId] = {};

        if (
          modifiedData?.[rowId]?.[columnId]?.item?.transaction_type ===
            "expense" &&
          !value?.toString()?.includes("-")
        ) {
          value = -value;
        }

        const total_value =
          (modifiedData[rowId][columnId]?.item?.total?.planned || 0) -
          Number(
            modifiedData[rowId][columnId]?.item?.total?.scenario_planned || 0
          ) +
          Number(value);
        const compareValue =
          modifiedData[rowId][columnId]?.item?.compareValue || 0;
        const _value =
          Math.abs(compareValue) >= Math.abs(total_value) ||
          modifiedData[rowId][columnId]?.item?.isPastMonth
            ? compareValue
            : total_value;
        const _value_style =
          Math.abs(compareValue) >= Math.abs(total_value) ||
          modifiedData[rowId][columnId]?.item?.isPastMonth
            ? "normal"
            : "italic";

        modifiedData[rowId][columnId].fontStyle = _value_style;
        modifiedData[rowId][columnId].item.value = _value;

        if (highlightedScenarios?.length > 0 && value) {
          const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
          modifiedData[rowId][columnId].backgroundColor = modifiedData[rowId][
            columnId
          ]?.item?.isPastMonth
            ? Color.white
            : modifiedData[rowId][columnId]?.item?.isCurrentMonth
              ? getTailwindColor(_scenario?.color || "slate", 100)
              : getTailwindColor(_scenario?.color || "slate", 50);
          modifiedData[rowId][columnId].item.scenarios = [_scenario?.uuid];
        } else {
          modifiedData[rowId][columnId].backgroundColor = modifiedData[rowId][
            columnId
          ]?.item?.isCurrentMonth
            ? getTailwindColor("slate", 50)
            : Color.white;
        }
        modifiedData[rowId][columnId].item.bookedValue = 0;
        modifiedData[rowId][columnId].item.openValue = 0;
        modifiedData[rowId][columnId].item.plannedValue = total_value;
        modifiedData[rowId][columnId].item.scenario_planned = value;
        modifiedData[rowId][columnId].item.total.planned = total_value;
        if (value) {
          modifiedData[rowId][columnId].item.count.plannedCount =
            modifiedData[rowId][columnId].item.count.plannedCount === 0
              ? 1
              : modifiedData[rowId][columnId].item.count.plannedCount;
        }
        if (modifiedData[rowId][columnId].item.count.plannedCount >= 1) {
          const percentageChange = calculatePercentageChange(
            value,
            modifiedData[rowId][columnId]?.item?.compareValue || 0
          );
          modifiedData[rowId][columnId].item.percentageChange =
            percentageChange;
        }
        if (uuid) {
          modifiedData[rowId][columnId].item.uuid = uuid;
        }
      })
    );
    if (onCellChanged) onCellChanged({ rowId, columnId, value, uuid, cell });
  };

  const onDoubleClick = ({ cellKey, cell }) => {
    if (!cell?.isColumnStartCell && !cell?.disableSelection) {
      setSelectedCell(cellKey);
    }
  };

  return (
    <div
      style={{
        position: position ? position : "unset",
        top: "0px",
        zIndex: position ? 10 : 0,
        ...style,
      }}
    >
      <CommonFunctions
        ref={commonFunctionRef}
        setSelectedCell={setSelectedCell}
        setExpanded={setExpanded}
        rows={rows}
      />
      <div
        key={name}
        style={{
          display: "flex",
          width: "fit-content",
        }}
      >
        {keys.current?.column_keys?.map((key) => {
          const cell = data?.[name]?.[key] || null;
          if (!cell) {
            return null;
          }
          return (
            <Cell
              key={`${cell?.rowId}-${cell?.cellId}`}
              cell={cell || null}
              expanded={expanded}
              onChangedValue={onChangedValue}
              selectedCell={selectedCell}
              borderRadius={borderRadius}
              onClick={commonFunctionRef.current?.onClick}
              onClickArrow={commonFunctionRef.current?.onClickArrow}
              onDoubleClick={onDoubleClick}
              onClickMagicIcon={commonFunctionRef.current?.onClickMagicIcon}
              commonFunctionRef={commonFunctionRef}
              isLastRowCell={
                active_key_array?.[active_key_array?.length - 1] === name
              }
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          transition: `max-height 0.3s ease-in-out`,
          maxHeight: isHeaderExpanded ? "10000px" : "0px",
        }}
      >
        {keys?.current?.row_keys?.map((rowId) => {
          const rowData = data?.[rowId];
          if (!rowData || rowId === name) {
            return null;
          }

          return (
            <RowWrapper $rowId={rowId} key={`${rowId}-row`}>
              {keys.current?.column_keys?.map((key) => {
                const cell = rowData?.[key];
                if (!cell || !expanded?.includes(cell?.parentRowId)) {
                  return null;
                }

                return (
                  <Cell
                    key={`${cell?.rowId}-${cell?.cellId}`}
                    cell={cell || null}
                    expanded={expanded}
                    onChangedValue={onChangedValue}
                    selectedCell={selectedCell}
                    borderRadius={borderRadius}
                    onClick={commonFunctionRef.current?.onClick}
                    onClickArrow={commonFunctionRef.current?.onClickArrow}
                    onDoubleClick={onDoubleClick}
                    commonFunctionRef={commonFunctionRef}
                    onClickMagicIcon={
                      commonFunctionRef.current?.onClickMagicIcon
                    }
                    isLastRowCell={
                      active_key_array[active_key_array.length - 1] === rowId
                    }
                  />
                );
              })}
            </RowWrapper>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

const CommonFunctions = forwardRef(
  ({ setSelectedCell, setExpanded, rows }, ref) => {
    const dispatch = useDispatch();
    const globalContext = useContext(GlobalContext);
    const seqFunctionRef = globalContext?.seqFunctionRef;
    //redux
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const recurring_rules = useSelector(
      (state) => state.globalSlice?.recurring_rules
    );
    const scenarioByTitle = useSelector(
      (state) => state.globalSlice.scenarioByTitle
    );
    const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
    const highlightedScenarios = useSelector(
      (state) => state.boardSlice?.highlightedScenarios
    );
    const selectionCategoriesByID = useSelector(
      (state) => state.categorySlice?.selectionCategoriesByID
    );

    const isTableFetching = useIsFetching({
      queryKey: [
        "transactions",
        {
          dataset: dataSetData?.uuid,
          // apiType: "monthly",
        },
      ],
    });

    //functions
    const getConnectedScenarios = ({ _scenario }) => {
      let array = [];
      array.push(_scenario);
      let _scenario_ele = scenarioById?.[_scenario]?.[0];
      function checkScenario(ref_scenario) {
        if (ref_scenario) {
          let _scenario_ele = scenarioByTitle?.[ref_scenario]?.[0];
          array.push(_scenario_ele?.uuid);
          checkScenario(_scenario_ele?.reference);
        }
      }
      checkScenario(_scenario_ele?.reference);
      return array;
    };

    const onClick = ({ cellKey, cell, isEditing }) => {
      // console.log("🚀 / onClick / cell:", cell);
      if (!isEditing) {
        if (!cell?.isColumnStartCell && !cell?.disableSelection) {
          setSelectedCell(cellKey);
        }
        const isTableHeader = Boolean(
          cell?.rowId === "inflow" || cell?.rowId === "outflow"
        );
        if (
          (cell.isHeader ? isTableHeader && !cell?.isColumnStartCell : true) &&
          cell?.rowId !== "VAT_Imputation"
        ) {
          let openTab = null;
          let overlayTab = null;
          let showDateRange = false;
          let listViewPath = false;
          let showToCategorizeViewFilter = false;
          let hideAccessInListViewFilter = false;
          let multiCategory = [];
          let dates = {};
          if (cell.hasChildren) {
            const category = selectionCategoriesByID?.[cell?.rowId]?.[0];
            if (category) {
              collectUUIDs(category, multiCategory);
            }
          }

          // else if (isTableHeader) {
          //   // hideAccessInListViewFilter = true;
          //   let start_date = format(
          //     startOfMonth(new Date(cell?.item?.date)),
          //     "yyyy-MM-dd"
          //   );
          //   let end_date = format(
          //     endOfMonth(new Date(cell?.item?.date)),
          //     "yyyy-MM-dd"
          //   );
          //   showDateRange = false;
          //   overlayTab = "category";
          //   dates = {
          //     start_date,
          //     end_date,
          //   };
          // }
          if (cell?.isColumnStartCell) {
            hideAccessInListViewFilter = true;
            let start_date = format(
              new Date(dataSetData?.start_date),
              "yyyy-MM-dd"
            );
            let end_date = format(
              endOfMonth(
                addMonths(new Date(start_date), Constant.column_count)
              ),
              "yyyy-MM-dd"
            );
            showDateRange = true;
            overlayTab = "category";
            dates = {
              start_date,
              end_date,
            };
          } else {
            const open = Math.abs(cell?.item?.total?.open);
            const booked = Math.abs(cell?.item?.total?.booked);
            const planned = Math.abs(cell?.item?.total?.planned);
            if (cell?.item?.isPastMonth) {
              listViewPath = booked >= open ? "bank" : "open";
              openTab = booked >= open ? "Booked" : "Open";
            }
            if (cell?.item?.isCurrentMonth) {
              listViewPath =
                (open !== 0 || booked !== 0) && open + booked >= planned
                  ? open > booked
                    ? "open"
                    : "bank"
                  : "budget";
              openTab =
                (open !== 0 || booked !== 0) && open + booked >= planned
                  ? open > booked
                    ? "Open"
                    : "Booked"
                  : cell?.item?.pastTotal && cell?.item?.pastTotal !== 0
                    ? "Open"
                    : "Planned";
            }
            if (cell?.item?.isFutureMonth) {
              listViewPath = "open";
              if (open === 0 && cell?.item?.total?.planned === 0) {
                openTab = "Planned";
                listViewPath = "budget";
              } else {
                openTab = open >= planned ? "Open" : "Planned";
                listViewPath = open >= planned ? "open" : "budget";
              }
            }
            if (cell?.hasChildren && !cell?.isHeader) {
              const category = selectionCategoriesByID?.[cell?.rowId]?.[0];
              if (category) {
                collectUUIDs(category, multiCategory);
              }
            }
          }
          if (
            cell?.rowId === "uncategorized" ||
            cell?.rowId === "uncategorizedInflow" ||
            cell?.rowId === "uncategorizedOutflow"
          ) {
            showToCategorizeViewFilter = true;
          }
          dispatch(
            setPopupStatus2({
              open: true,
              overlay_type: "drawer_modal",
              anchor: "right",
              payload: {
                hideAccessInListViewFilter,
                overlayTab: overlayTab,
                openTab: openTab,
                cell: {
                  date: cell?.item?.date,
                  isCurrentMonth: cell?.item?.isCurrentMonth,
                  isPastMonth: cell?.item?.isPastMonth,
                  isFutureMonth: cell?.item?.isFutureMonth,
                  pastTotal: cell?.item?.pastTotal,
                  rowId: cell?.hasChildren ? null : cell?.rowId,
                  transaction_type: cell?.item?.transaction_type,
                  start_date: dates?.start_date,
                  end_date: dates?.end_date,
                  showBudgetStatistics: !cell?.isColumnStartCell,
                  hasChildren: cell?.hasChildren,
                  fetchPastOpen: cell?.item?.isCurrentMonth,
                  withUrl: true,
                  showDateRange,
                  multiCategory,
                  listViewPath,
                  showToCategorizeViewFilter,
                },
              },
            })
          );
        }
      }
    };

    const onClickArrow = (e, { cellKey, cell, isEditing }) => {
      e.stopPropagation();
      if (cell?.isColumnStartCell && cell.hasChildren) {
        setExpanded((prev) => {
          if (prev.includes(cell?.rowId)) {
            let ids = [cell?.rowId];
            const getIds = (rowId) => {
              let filterData = rows.filter(
                (o1) => o1.hasChildren && o1?.parentRowId === rowId
              );
              ids = [...ids, ...filterData.map((o1) => o1.rowId)];
              filterData?.forEach((o1) => {
                getIds(o1?.rowId);
              });
            };
            getIds(cell?.rowId);

            return prev.filter((o1) => ids.every((o2) => o2 !== o1));
          }
          return [...prev, cell?.rowId];
        });
      }
    };

    const onClickMagicIcon = async (e, cell) => {
      e.stopPropagation();
      const category =
        cell?.rowId === "uncategorizedInflow" ||
        cell?.rowId === "uncategorizedOutflow"
          ? null
          : cell?.rowId;
      // dispatch(setLoading(true));
      const transaction_type = cell?.item?.transaction_type;
      const scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0]?.title;
      const selected_scenario_uuid = getConnectedScenarios({
        _scenario: highlightedScenarios[0],
      });
      let params = {};
      if (transaction_type === "income") {
        params.min_gross_value = 0;
      } else {
        params.max_gross_value = -0.001;
      }

      let response = await getTransactionByParams({
        dataset: dataSetData?.uuid,
        category: category ? [category] : [],
        scenario: [highlightedScenarios?.[0]],
        startDate: format(
          addMonths(new Date(cell?.item?.date), 1),
          "yyyy-MM-dd"
        ),
        categorized: category ? true : false,
        is_recurred: true,
        ...params,
      });
      let recurring_rules_list = response
        ? [...new Set(response?.results?.map((o1) => o1.recurring_rule))]
        : [];
      if (recurring_rules_list?.length > 1) {
        dispatch(setLoading(false));
        return;
      }
      let start_date = format(startOfMonth(new Date()), "yyyy-MM-dd");

      if (recurring_rules_list?.length === 1) {
        let cardItem = response?.results.find(
          (o1) => o1.recurring_rule === recurring_rules_list[0]
        );
        let recurring_Obj = recurring_rules.find(
          (o1) => o1.uuid === recurring_rules_list[0]
        );

        let isSeqInFuture = response?.results?.find(
          (o1) => o1.due_date === recurring_Obj?.start_date
        );

        if (Boolean(isSeqInFuture)) {
          seqFunctionRef?.current?.onSaveRecurrence({
            transaction_type: cell?.item?.transaction_type,
            cardItem: cardItem,
            recurring_Obj: {
              ...recurring_Obj,
              name: "Advanced Sequence",
              recurring_type: "advanced",
              source: 2,
              average: 2,
              period: 12,
              repetition: 1,
              category: category,
              start_date: format(
                addMonths(new Date(start_date), 1),
                "yyyy-MM-dd"
              ),
              end_date: format(
                addMonths(new Date(start_date), 12),
                "yyyy-MM-dd"
              ),
            },
            selected_scenarios: selected_scenario_uuid,
          });
        } else {
          if (recurring_Obj) {
            let deleteIds = response?.results.map((o1) => o1.uuid);
            seqFunctionRef?.current?.onSaveRecurrence({
              transaction_type: cell?.item?.transaction_type,
              cardItem: {
                recurring_rule: null,
                scenario: scenario,
                category: category,
                state: "Planned",
                source: "1",
                due_date: start_date,
                data_source: dataSetData?.internal_data_source,
              },
              recurring_Obj: {
                name: "Advanced Sequence",
                recurring_type: "advanced",
                source: 2,
                average: 2,
                period: 12,
                repetition: 1,
                category: category,
                start_date: format(
                  addMonths(new Date(start_date), 1),
                  "yyyy-MM-dd"
                ),
                end_date: format(
                  addMonths(new Date(start_date), 12),
                  "yyyy-MM-dd"
                ),
              },
              oldRecurringObj: {
                ...recurring_Obj,
                end_date: format(
                  subMonths(
                    new Date(recurring_Obj?.end_date),
                    response?.results?.length || 0
                  ),
                  "yyyy-MM-dd"
                ),
              },
              deleteIds: deleteIds,
              selected_scenarios: selected_scenario_uuid,
            });
          }
        }

        return;
      }
      if (recurring_rules_list?.length === 0) {
        seqFunctionRef?.current?.onSaveRecurrence({
          transaction_type: cell?.item?.transaction_type,
          cardItem: {
            recurring_rule: null,
            scenario: scenario,
            category: category,
            state: "Planned",
            source: "1",
            due_date: start_date,
            data_source: dataSetData?.internal_data_source,
          },
          recurring_Obj: {
            name: "Advanced Sequence",
            recurring_type: "advanced",
            source: 2,
            average: 2,
            period: 12,
            repetition: 1,
            category: category,
            start_date: format(
              addMonths(new Date(start_date), 1),
              "yyyy-MM-dd"
            ),
            end_date: format(
              addMonths(new Date(cell?.item?.date), 12),
              "yyyy-MM-dd"
            ),
          },
          selected_scenarios: selected_scenario_uuid,
        });
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        onClick(obj) {
          onClick(obj);
        },
        onClickArrow(e, obj) {
          onClickArrow(e, obj);
        },
        onClickMagicIcon(e, cell) {
          onClickMagicIcon(e, cell);
        },
        isTableFetching,
      }),
      [isTableFetching]
    );
    return null;
  }
);
