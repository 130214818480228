import { Box, Link, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useState } from "react";

import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import initialData, { isPlanExpired, remToPx } from "../../../Helper/data";
import SankryChart from "../../../components/Charts/SankryChart";
import Placeholder from "../../../components/Placeholder";
import { Color, Fonts, Images } from "../../../Helper";
import useWidth from "../../../hooks/useWidth";

const IncomeStatement = () => {
  const [sankeyData, setSankeyData] = useState({ links: [], nodes: [] });
  const currentWidth = useWidth();
  const dispatch = useDispatch();
  let width = remToPx(currentWidth, 93.75);
  let height = remToPx(currentWidth, 43.75);
  //redux
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const monthlyTransactions = useSelector(
    (state) => state.datasetSlice?.monthlyTransactions
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const refreshData = useSelector((state) => state.appSlice.refreshData);
  const isFetching = useSelector((state) => state.appSlice.isFetching);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  const planExpired = useMemo(() => {
    return isPlanExpired(subscription);
  }, [subscription]);

  //lifeCycle Method
  useEffect(() => {
    if (
      !planExpired &&
      subscription?.income_statement_chart &&
      isAllHeaderApiFetched &&
      !isFetching
    ) {
      if (monthlyTransactions?.length !== 0) {
        buildSankeyChart();
      }
    } else {
      setSankeyData({ links: [], nodes: [] });
    }
  }, [
    planExpired,
    subscription,
    isAllHeaderApiFetched,
    isFetching,
    selectionCategories,
    monthlyTransactions,
    refreshData,
  ]);

  useUpdateEffect(() => {
    setSankeyData({ links: [], nodes: [] });
  }, [dataSetData?.uuid]);

  //functions
  const buildSankeyChart = () => {
    let operatingExpensesIds = [];
    let taxIds = [];
    let Cosr_Ids = [];
    let otherIds = [];

    selectionCategories.forEach((o1) => {
      if (o1.type === 2 && o1.cost_category === "operating expenses") {
        operatingExpensesIds.push(o1.value);
      }
      if (o1.type === 2 && o1.cost_category === "tax") {
        taxIds.push(o1.value);
      }
      if (
        o1.type === 2 &&
        (o1.cost_category === "sale" || o1.cost_category === "revenue")
      ) {
        Cosr_Ids.push(o1.value);
      }
      if (o1.type === 2 && !o1.cost_category) {
        otherIds.push(o1.value);
      }
    });

    let grossProfitIds = [...Cosr_Ids, ...otherIds];
    let expenseIds = [...Cosr_Ids, ...operatingExpensesIds, ...otherIds];
    let totalRevenue = 0;

    let catNodes = [];
    let arrayLinks = [];
    catNodes.push({
      name: "Uncategorized",
      color: Color.unCategorizedCardBg,
      value: null,
    });

    let filterData = monthlyTransactions.filter(
      (o1) => !initialData?.calculationExcludeStates2?.includes(o1.state)
    );
    selectionCategories.forEach((element) => {
      if (element?.type === 1) {
        let found = filterData?.find((o1) => o1.category === element?.value);
        if (found) {
          catNodes.push({
            name: element?.label,
            color: element?.color,
            value: element?.value,
          });
        }
      }
    });

    let inflowData = filterData?.filter(
      (o1) => o1.month && o1.inflow_count > 0
    );
    let outflowData = filterData?.filter(
      (o1) => o1.month && o1.outflow_count > 0
    );
    let nodes = [...catNodes];
    catNodes?.forEach((element, index) => {
      let categoryTransactions = inflowData?.filter(
        (o1) => o1.category === element.value
      );
      let totalTransactionsCount = filterData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(item?.inflow_count ?? 0),
        0
      );
      let totalCategoryTransactionsCount = categoryTransactions?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(item?.inflow_count ?? 0),
        0
      );
      if (
        index === 0 &&
        totalCategoryTransactionsCount >= totalTransactionsCount / 2
      ) {
        setSankeyData({ links: [], nodes: [] });

        return null;
      }

      const sum = categoryTransactions?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
        0
      );

      totalRevenue = totalRevenue + sum;
      arrayLinks.push({
        source: index,
        target: catNodes?.length,
        value: sum,
      });
    });
    nodes.push({
      name: "Revenue",
      color: Color.theme.blue[500],
      totalRevenue: totalRevenue,
    });

    let grossProfitData = outflowData?.filter(
      (o1) => o1.category && grossProfitIds.includes(o1.category)
    );
    if (grossProfitData?.length > 0) {
      const grossProfitTotal = grossProfitData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
        0
      );
      let gProfitTotal = totalRevenue - Math.abs(grossProfitTotal);
      // console.log("🚀  gProfitTotal", gProfitTotal);
      // let gProfitTotal = totalRevenue - grossProfitTotal;

      arrayLinks.push({
        source: catNodes?.length,
        target: catNodes?.length + 1,
        // value: gProfitTotal,
        value: Math.abs(gProfitTotal),
      });
      nodes.push({
        name: "Gross Profit",
        // color: gProfitTotal < 0 ? Color.theme.red[500] : Color.theme.green[500],
        color: Color.theme.green[500],
      });
      let source = nodes?.length - 1;
      let expenseData = outflowData?.filter(
        (o1) => o1.category && expenseIds.includes(o1.category)
      );
      if (expenseData?.length > 0) {
        const expenseTotal = expenseData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
          0
        );
        let oProfitTotal = totalRevenue - Math.abs(expenseTotal);
        // console.log("🚀  oProfitTotal", oProfitTotal);
        arrayLinks.push({
          source: source,
          target: nodes?.length,
          value: Math.abs(oProfitTotal),
        });
        nodes.push({
          name: "Operating Profit",
          color: Color.theme.green[500],
          // color:
          //   oProfitTotal < 0 ? Color.theme.red[500] : Color.theme.green[500],
        });
        let taxData = outflowData?.filter(
          (o1) => o1.month && o1.category && taxIds.includes(o1.category)
        );
        if (taxData?.length > 0) {
          const taxTotal = taxData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
            0
          );
          let netProfitTotal = oProfitTotal - taxTotal;

          arrayLinks.push({
            source: nodes?.length - 1,
            target: nodes?.length,
            value: Math.abs(netProfitTotal),
          });
          nodes.push({
            name: "Net Profit",
            color: Color.theme.green[500],
            // color:
            //   netProfitTotal < 0
            //     ? Color.theme.red[500]
            //     : Color.theme.green[500],
          });
        }
      }

      let operatingExpenseData = outflowData?.filter(
        (o1) => o1.category && operatingExpensesIds.includes(o1.category)
      );
      if (operatingExpenseData?.length > 0) {
        const operatingExpenseTotal = operatingExpenseData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
          0
        );
        arrayLinks.push({
          source: source,
          target: nodes?.length,
          // value: operatingExpenseTotal,
          value: Math.abs(operatingExpenseTotal),
        });
        // console.log("🚀  operatingExpenseTotal", operatingExpenseTotal);
        nodes.push({ name: "Operating Expense", color: Color.theme.red[500] });
        let operatingExpensesCat = selectionCategories?.filter((o1) =>
          operatingExpensesIds.includes(o1.value)
        );
        let childsource = nodes?.length - 1;
        operatingExpensesCat.forEach((element) => {
          const total = operatingExpenseData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(
                element.value === item.category ? item?.outflow ?? 0 : 0
              ),
            0
          );
          arrayLinks.push({
            source: childsource,
            target: nodes?.length,
            // value: total,
            value: Math.abs(total),
          });
          nodes.push({
            name: element.label,
            color: element?.color ?? Color.theme.grey[500],
          });
        });
      }
    }

    let Cosr_Data = outflowData?.filter(
      (o1) => o1.category && Cosr_Ids.includes(o1.category)
    );
    if (Cosr_Data?.length > 0) {
      const Cosr_total = Cosr_Data?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
        0
      );

      arrayLinks.push({
        source: catNodes?.length,
        target: nodes?.length,
        value: Math.abs(Cosr_total),
      });
      nodes.push({
        name: "Cost of Revenue/Sales",
        color: Color.theme.red[500],
      });
    }

    let otherData = outflowData?.filter(
      (o1) => o1.month && o1.category && otherIds.includes(o1.category)
    );
    if (otherData?.length > 0) {
      const othertotal = otherData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
        0
      );
      arrayLinks.push({
        source: catNodes?.length,
        target: nodes?.length,
        value: Math.abs(othertotal),
      });
      nodes.push({
        name: "Others",
        color: Color.theme.red[500],
      });
    }
   
    // console.log("🚀 ~  nodes", nodes, arrayLinks);
    setSankeyData({ links: arrayLinks, nodes: nodes });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        sx={{
          fontFamily: Fonts.Text,
          fontWeight: 600,
          fontSize: "2.5rem",
          my: "1rem",
          color: Color.themeColor2,
          position: "absolute",
          // top: 8,
          // left: "31%",
          top: "3.5%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
      >
        Income Statement of{" "}
        <span style={{ color: Color?.themeColor2 }}>{dataSetData?.title}</span>
      </Typography>
      <Paper
        sx={{
          "& .MuiPaper-root": {
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          },
          width: width,
          height: height,
          borderRadius: "5px",
          backgroundColor: Color.white,
          maxHeight: "80%",
          // height: "47rem",
          position: "relative",
          pt: "6rem",
        }}
      >
        {monthlyTransactions?.length > 0 ? (
          <>
            {planExpired || !subscription?.income_statement_chart ? (
              <Placeholder
                image={Images.income_statement_placeholder}
                insightText={"Company"}
                description={
                  !planExpired ? (
                    <span>
                      {" "}
                      Please{" "}
                      <Link
                        color="inherit"
                        href={"/settings/subscription"}
                        target="_blank"
                        sx={{
                          fontFamily: Fonts.Text,
                          color: Color.themeColor2,
                        }}
                      >
                        Upgrade
                      </Link>{" "}
                      your plan to see this chart
                    </span>
                  ) : null
                }
              />
            ) : sankeyData?.links?.length > 0 &&
              sankeyData?.nodes?.length > 0 ? (
              <SankryChart data={sankeyData} />
            ) : null}
          </>
        ) : (
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.theme.grey[400],
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Data Found
          </Typography>
        )}
      </Paper>
    </Box>
  );
};
export default IncomeStatement;
