import {
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { startOfMonth, format, isSameMonth, addMonths } from "date-fns";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { TbSquarePercentage } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { v4 as v4uuid } from "uuid";

import {
  calculateLoanTenure,
  calculateEndDate,
} from "../../../Helper/functions";
import DescriptionInput from "../../Overlay/DescriptionInput";
import DateInputPicker from "../../Overlay/DateInputPicker";
import TailwindButton from "../../Overlay/TailwindButton";
import { formatDateToLocal } from "../../../Helper/data";
import EndPoints from "../../../APICall/EndPoints";
import { SpinLoader } from "../../ComponentLoader";
import TitleInput from "../../Overlay/TitleInput";
import MenuView from "../../Overlay/MenuView";
import CustomPopover from "../../PopOver";
import APICall from "../../../APICall";
import theme from "../../../theme";
import Icon from "../../Icon";

const menuOptions = [
  {
    value: 1,
    label: "Edit",
    icon: <ModeEditIcon />,
  },

  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];
const VariableRatesView = ({
  modalType,
  recurring_Obj,
  setRecurring_Obj,
  setIsRecurrenceUpdated,
  VariableRatesItemList,
  PausedDuration,
  showError,
  setShowError,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const menuItem = useRef({});
  const formData = useRef({ defaultData: {}, formType: "add" });

  //state
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);

  //api
  const getVariableRatesList = async (id) => {
    setIsListLoading(true);
    await APICall(
      "get",
      `${EndPoints.recurring_rules}${id}/interest_rates/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        setList(
          response.data.results?.sort(
            (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
          )
        );
        setIsListLoading(false);
      }
    });
  };

  const deleteVariableRateById = async (uuid) => {
    let data = null;
    await APICall(
      "delete",
      `${EndPoints.recurring_rules}${recurring_Obj?.uuid}/interest_rates/${uuid}/`
    ).then((response) => {
      if (response.status === 204) {
        removeItem(uuid);
      }
    });

    return data;
  };

  //lifecycle
  useEffect(() => {
    if (recurring_Obj?.uuid && modalType === "edit") {
      getVariableRatesList(recurring_Obj?.uuid);
    }
  }, [modalType, recurring_Obj?.uuid]);

  useEffect(() => {
    if (VariableRatesItemList) {
      VariableRatesItemList.current = list;
      let obj = {};

      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  }, [list]);

  //functions
  const onClickAdd = (e) => {
    if (setShowError) setShowError(null);

    formData.current.defaultData = {
      uuid: v4uuid(),
      start_date: null,
      isDateDisabled: list?.length === 0 ? true : false,
    };
    if (list?.length === 0) {
      formData.current.defaultData.start_date = format(
        new Date(recurring_Obj?.start_date),
        "yyyy-MM-dd"
      );
      formData.current.defaultData.expected_start_date =
        recurring_Obj?.start_date;
    } else {
      const lastEndDate = list?.[list?.length - 1]?.end_date;
      formData.current.defaultData.expected_start_date = lastEndDate;
      formData.current.defaultData.start_date = lastEndDate
        ? format(addMonths(new Date(lastEndDate), 1), "yyyy-MM-dd")
        : null;
    }
    formData.current.formType = "add";
    setAnchorEl(e.currentTarget);
  };

  const onClickMenu = ({ e, setLoading, item }) => {
    if (setShowError) setShowError(null);

    menuItem.current = {
      setLoading,
      item,
    };
    if (
      item?.uuid !==
      VariableRatesItemList.current?.[VariableRatesItemList.current?.length - 1]
        ?.uuid
    ) {
      menuItem.current.disabledValue = [2];
    }
    setAnchorElMenu(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickMenuItem = async (e, _value) => {
    const { item, setLoading } = menuItem.current;

    if (_value === 1) {
      let expected_start_date = recurring_Obj?.start_date;
      const index = list?.findIndex((o1) => o1?.uuid === item?.uuid);
      if (index !== -1) {
        expected_start_date = list?.[index - 1]?.end_date;
      }
      formData.current.formType = "edit";
      formData.current.defaultData = { ...item, expected_start_date };
      setAnchorEl(e.currentTarget);
    }
    if (_value === 2) {
      closeMenu();
      setLoading(item?.uuid);
      if (modalType === "edit") {
        setIsRecurrenceUpdated(true);
        await deleteVariableRateById(item?.uuid);
      } else {
        removeItem(item?.uuid);
      }
    }
  };

  const removeItem = (uuid) => {
    setList((prev) =>
      prev
        ?.filter((o1) => o1?.uuid !== uuid)
        ?.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date))
    );
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const expected_end_date = useMemo(() => {
    if (recurring_Obj?.loan_calculation_method === 2) {
      return calculateEndDate({
        months: recurring_Obj?.payment_default,
        start_date: recurring_Obj?.start_date,
        extraMonth: PausedDuration?.current?.length || 0,
      });
    } else {
      const LoanTenure = calculateLoanTenure({
        principal: Math.abs(recurring_Obj?.value || 0),
        downPayment: Math.abs(recurring_Obj?.deposit_value || 0),
        annualInterestRate: Math.abs(recurring_Obj?.interest_rate || 0),
        emi: Math.abs(recurring_Obj?.average_value || 0),
        VariableRatesItemList: VariableRatesItemList?.current,
        start_date: recurring_Obj?.start_date,
      });

      return calculateEndDate({
        months: LoanTenure,
        start_date: recurring_Obj?.start_date,
        extraMonth: PausedDuration?.current?.length || 0,
      });
    }
  }, [
    PausedDuration,
    VariableRatesItemList,
    recurring_Obj?.average_value,
    recurring_Obj?.deposit_value,
    recurring_Obj?.interest_rate,
    recurring_Obj?.loan_calculation_method,
    recurring_Obj?.payment_default,
    recurring_Obj?.start_date,
    recurring_Obj?.value,
  ]);

  return (
    <Stack sx={{ width: "100%", mb: "2rem", ml: "1rem", pl: "0.5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Icon
          icon={<TbSquarePercentage />}
          color={theme.palette.color.blueGrey[700]}
          fontSize={"1.4rem"}
          style={{
            position: "absolute",
            left: "-3.25rem",
          }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          color={!!showError?.variable_rate ? "error" : "color.mainTitle"}
          sx={{
            fontSize: "0.9rem",
          }}
        >
          {t("loan_form_variable_rate")}
        </Typography>
        <Tooltip title={t("loan_form_variable_rate_tooltip")} placement="top" arrow>
          <HelpOutlineOutlinedIcon
            sx={{
              fontSize: "1rem",
              cursor: "pointer",
              ml: "0.5rem",
              mt: "1px",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      </div>

      <Grid
        container
        columnsSpacing={6}
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {list?.map((item, index) => {
          let start_date_error = false;
          let end_date_error = false;
          const prevEndDate = list?.[index - 1]?.end_date;
          if (prevEndDate) {
            if (
              !isSameMonth(
                new Date(item?.start_date),
                addMonths(new Date(prevEndDate), 1)
              )
            ) {
              start_date_error = true;
            }
          }
          if (index === 0) {
            if (
              !isSameMonth(
                new Date(item?.start_date),
                new Date(recurring_Obj?.start_date)
              )
            ) {
              start_date_error = true;
            }
          }
          if (index === list?.length - 1) {
            if (
              !isSameMonth(
                new Date(item?.end_date),
                new Date(recurring_Obj?.end_date)
              )
            ) {
              end_date_error = true;
            }
          }
          return (
            <ItemView
              key={item?.uuid}
              item={item}
              start_date_error={start_date_error}
              end_date_error={end_date_error}
              onClickMenu={onClickMenu}
            />
          );
        })}
      </Grid>
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={closeMenu}
          onClickItem={onClickMenuItem}
          disabledValue={menuItem.current?.disabledValue}
        />
      ) : null}
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        width={"fit-content"}
        border="none"
        justifyContent="flex-start"
        iconSize={"1.375rem"}
        icon
        iconStyle={{ p: 0 }}
        paperStyle={{
          border: 0,
          cursorCol: "pointer",
        }}
        iconView={
          <Button
            disabled={
              isListLoading || !recurring_Obj?.start_date || !expected_end_date
            }
            onClick={onClickAdd}
            size="medium"
            fontWeight={"fontWeightMediumBold"}
            sx={{
              width: "fit-content",
              height: "fit-content",
              mt: "1.5rem",
              fontSize: "0.85rem",
              color: theme.palette.color.slate[700],
              backgroundColor: theme.palette.color.slate[100],
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
              },
            }}
          >
            {t("loan_form_add_variable_rate")}
          </Button>
        }
        hoverBG={"transparent"}
      >
        <FormOverlay
          modalType={modalType}
          formType={formData?.current?.formType}
          menuItem={menuItem}
          defaultData={formData?.current?.defaultData}
          recurring_Obj={recurring_Obj}
          setList={setList}
          onClosePopOver={onClosePopOver}
          setIsRecurrenceUpdated={setIsRecurrenceUpdated}
          expected_end_date={expected_end_date}
        />
      </CustomPopover>
    </Stack>
  );
};

export default memo(VariableRatesView);

const FormOverlay = ({
  modalType,
  formType,
  menuItem,
  defaultData,
  recurring_Obj,
  setList,
  onClosePopOver,
  setIsRecurrenceUpdated,
  expected_end_date,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [item, setItem] = useState({
    uuid: defaultData?.uuid,
    interest_rate: defaultData?.interest_rate,
    note: defaultData?.note,
    start_date: defaultData?.start_date,
    end_date: defaultData?.end_date,
  });

  //api
  const addVariableRate = async (recurring_uuid, obj) => {
    setIsLoading(true);
    await APICall(
      "post",
      `${EndPoints.recurring_rules}${recurring_uuid}/interest_rates/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        setIsLoading(false);
        onClosePopOver();
        setList((prev) =>
          [...prev, response.data]?.sort(
            (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
          )
        );
      }
    });
  };

  const updateVariableRate = async (recurring_uuid, pause_uuid, obj) => {
    setIsLoading(true);
    await APICall(
      "patch",
      `${EndPoints.recurring_rules}${recurring_uuid}/interest_rates/${pause_uuid}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        menuItem.current = { ...menuItem.current, item: response.data };
        setIsLoading(false);
        onClosePopOver();
        setList((prev) =>
          prev
            ?.map((o1) => (o1?.uuid === item?.uuid ? { ...response.data } : o1))
            ?.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date))
        );
      }
    });
  };

  //functions
  const onChange = (e) => {
    const { name, value } = e.target;
    setError({});
    setItem((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeRangeDate = (value, name) => {
    if (value?.toString() !== "Invalid Date") {
      const obj = {
        [name]: format(startOfMonth(new Date(value)), "yyyy-MM-dd"),
      };

      setError({});
      setItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onClickSave = () => {
    let errorText = "";
    let name = "";

    if (!item?.end_date) {
      name = "end_date";
      errorText = t("please_add_variable_rate_end_date");
    }
    if (!item?.interest_rate) {
      name = "interest_rate";
      errorText = t("please_add_interest_rate");
    }
    if (errorText) {
      setError((prev) => ({ ...prev, [name]: errorText }));
      return;
    }
    if (modalType === "edit") {
      setIsRecurrenceUpdated(true);

      if (formType === "add") {
        addVariableRate(recurring_Obj?.uuid, item);
      } else {
        updateVariableRate(recurring_Obj?.uuid, item?.uuid, item);
      }
    } else {
      onClosePopOver();
      if (formType === "add") {
        setList((prev) =>
          [...prev, item]?.sort(
            (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
          )
        );
      } else {
        menuItem.current = { ...menuItem.current, item };
        setList((prev) =>
          prev
            ?.map((o1) => (o1?.uuid === item?.uuid ? { ...item } : o1))
            ?.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date))
        );
      }
    }
  };

  return (
    <Box
      sx={{
        width: "30rem",
        minHeight: "17rem",
        position: "relative",
        p: "1.5rem",
        borderRadius: theme.borderRadius.main,
      }}
    >
      <TitleInput
        type="number"
        fontSize="0.9rem"
        name="interest_rate"
        value={item?.interest_rate}
        onChange={onChange}
        error={error?.["interest_rate"]}
        helperText={error?.["interest_rate"]}
        hideTitle
        likeGoogle
        label={t("loan_form_interest_rate")}
        tooltip={t("loan_form_interest_rate_tooltip")}
        variant="filled"
        sx={{ width: "100%" }}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <DateInputPicker
          name="start_date"
          label={t("loan_form_variable_rate_start_date")}
          tooltip={t("loan_form_variable_rate_start_date_tooltip")}
          error={error?.["start_date"]}
          helperText={error?.["start_date"]}
          disabled={isSameMonth(
            new Date(recurring_Obj?.start_date),
            new Date(item?.start_date)
          )}
          minDate={
            defaultData.expected_start_date
              ? addMonths(new Date(defaultData.expected_start_date), 1)
              : null
          }
          maxDate={expected_end_date ? new Date(expected_end_date) : null}
          value={item?.start_date}
          onChange={onChangeRangeDate}
          variant="filled"
          hidePickerBlurEffect
          likeGoogle
          hideTitle
          fontSize="0.9rem"
          sx={{ width: "48%" }}
        />
        <DateInputPicker
          name="end_date"
          label={t("loan_form_variable_rate_end_date")}
          tooltip={t("loan_form_variable_rate_end_date_tooltip")}
          minDate={new Date(item?.start_date)}
          maxDate={expected_end_date ? new Date(expected_end_date) : null}
          error={error?.["end_date"]}
          helperText={error?.["end_date"]}
          value={item?.end_date}
          disabled={!item?.start_date}
          onChange={onChangeRangeDate}
          variant="filled"
          hidePickerBlurEffect
          likeGoogle
          hideTitle
          fontSize="0.9rem"
          sx={{ width: "48%" }}
        />
      </Stack>

      <DescriptionInput
        maxRows={3}
        value={item?.note}
        onChange={onChange}
        name="note"
        label={t("Note")}
        hideTitle
        likeGoogle
        variant="filled"
      />
      <Stack
        direction="row"
        sx={{ width: "100%", justifyContent: "flex-end", mt: "2rem" }}
      >
        <TailwindButton
          loading={isLoading}
          text={formType === "edit" ? t("Update") : t("Add")}
          onClick={onClickSave}
        />
      </Stack>
    </Box>
  );
};

const ItemView = ({ item, start_date_error, end_date_error, onClickMenu }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        minHeight: "3rem",
        width: "100%",
        ml: "-1rem",
        "&:hover": {
          cursor: "pointer",
          "& #trash-icon": {
            visibility: "visible",
          },
        },
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          color:
            start_date_error || end_date_error
              ? theme.palette.color.red[500]
              : theme.palette.color.slate[700],
          fontWeight: "fontWeightMediumBold",
          overflow: "hidden",
          fontSize: "0.8rem",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <b style={{ marginRight: "0.5rem" }}>~</b>
        {item?.interest_rate} %
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          color:
            start_date_error || end_date_error
              ? theme.palette.color.red[500]
              : theme.palette.color.description,
          overflow: "hidden",
          fontSize: "0.8rem",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          pr: "1rem",
        }}
      >
        <Tooltip arrow placement="top" followCursor title={item?.note}>
          {item?.note}
        </Tooltip>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            color: start_date_error
              ? theme.palette.color.red[500]
              : theme.palette.color.slate[700],
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: "0.8rem",
            marginRight: "0.25rem",
          }}
        >
          {formatDateToLocal(item?.start_date, "MMM yyyy")}
        </span>
        {" - "}
        <span
          style={{
            color: end_date_error
              ? theme.palette.color.red[500]
              : theme.palette.color.slate[700],
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: "0.8rem",
            marginLeft: "0.25rem",
          }}
        >
          {formatDateToLocal(item?.end_date, "MMM yyyy")}
        </span>

        <div
          style={{
            width: "2.5rem",
            paddingLeft: "0.5rem",
            marginTop: "-2px",
          }}
        >
          <MenuViewWrapper item={item} onClickMenu={onClickMenu} />
        </div>
      </Grid>
    </Stack>
  );
};

export const MenuViewWrapper = ({ onClickMenu, item }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    // setLoading(uuid);
    onClickMenu({
      e,
      setLoading,
      item,
    });
  };

  if (loading === item?.uuid) {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SpinLoader size={18} />
      </div>
    );
  }

  return (
    <IconButton
      aria-label="more"
      id="trash-icon"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        background: "transparent !important",
        mb: "0.25rem",
        p: 0,
        visibility: "hidden",
        cursor: "pointer",
      }}
    >
      <MoreVertIcon
        sx={{
          fontSize: "1.2rem",
          color: theme.palette.color.slate[600],
        }}
      />
    </IconButton>
  );
};
