import {
  ListItemText,
  Typography,
  ListItem,
  Button,
  List,
  Grid,
  Box,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { TwitterPicker } from "react-color";
import { useSnackbar } from "notistack";
import { Outlet } from "react-router";

import {
  getTransactionByParams,
  thinScrollbarStyle,
  getTailwindColor,
} from "../../../Helper/data";
import {
  setAppliedFilterslist,
  setScenario,
} from "../../../store/slices/global";
import { setLoading, setStageLoadingText } from "../../../store/slices/appmain";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import ActionViewWrapper from "../../../components/ActionViewWrapper.js";
import OverlayHeader from "../../../components/Overlay/OverlayHeader";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import { Card, Item } from "../../../Styles/Pages/sheets.style";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import useSubscriptions from "../../../hooks/useSubscriptions";
import HelpOverlay from "../../../components/HelpOverlay.js";
import { setdatasetList } from "../../../store/slices/board";
import AddButton from "../../../components/AddButton";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import { Fonts, Images } from "../../../Helper";
import { queryClient } from "../../../App";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";

const scenarioTheme = {
  "#03a9f4": "lightBlue",
  "#64748b": "slate",
  "#ff7c0f": "orange",
  "#525252": "stone",
  "#6b7280": "grey",
  "#ef4444": "red",
  "#d69e2e": "yellow",
  "#38a169": "green",
  "#3b82f6": "blue",
  "#6366f1": "indigo",
  "#f59e0b": "amber",
  "#84cc16": "lime",
  "#10b981": "emerald",
  "#14b8a6": "teal",
  "#06b6d4": "cyan",
  "#0ea5e9": "sky",
  "#8b5cf6": "violet",
  "#a855f7": "purple",
  "#d946ef": "fuchsia",
  "#ec4899": "pink",
  "#fb7185": "rose",
};
const Scenarios = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let itemId = useRef(null);
  let isDataUpdated = useRef(false);
  const [isSubscriptionValid] = useSubscriptions();
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux state
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const scenario = useSelector((state) => state.globalSlice.scenario);

  //state
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [datasetItem, setDataSetItem] = useState();
  const [showColorPallets, setShowColorPallets] = useState(false);

  //life cycle method
  useEffect(() => {
    getScenarioApi(false);
    return () => {
      abortController.abort();
    };
  }, []);

  //Api
  const getScenarioApi = async (loading = true) => {
    if (loading) dispatch(setLoading(true));
    await APICall("get", EndPoints.scenarios).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        data.sort((a, b) => (a.position > b.position ? 1 : -1));
        dispatch(setScenario(data));
      }
      dispatch(setLoading(false));
    });
  };

  const getScenarioById = async (UUID) => {
    await APICall("get", EndPoints.scenarios + `${UUID}/`, null, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (aborted === false) {
        if (response.status === 404) {
          updateDataSetsFilters(itemId.current);
          let updatedScenario = scenario?.filter((o1) => o1.uuid !== UUID);
          dispatch(setPopupStatus3(null));
          dispatch(setStageLoadingText(null));
          dispatch(setScenario(updatedScenario));
          enqueueSnackbar(t("Scenario_Deleted_Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setTimeout(() => {
            getScenarioById(UUID);
          }, 2000);
        }
      }
    });
  };

  const addScenarioApi = async (obj) => {
    await APICall("post", EndPoints.scenarios, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar(t("New_Scenarios_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        dispatch(setScenario([...scenario, response.data]));
      }
    });
  };

  const deleteScenarioByIdApi = async (_scenario) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    await APICall("delete", EndPoints.scenarios + `${_scenario?.uuid}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          getScenarioById(_scenario?.uuid);
        }
      }
    );
  };

  const updateScenarioByID = async (id, obj, type = "update") => {
    await APICall("patch", EndPoints.scenarios + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (isDataUpdated.current) {
            isDataUpdated.current = false;
            dispatch(setAppliedFilterslist(null));

            updateDataSetsFilters(itemId.current, response.data);
            global.allowFetch = { Inflow: [], Outflow: [] };
            let options = {
              predicate: (query) =>
                query.queryKey[0] === "transactions" ||
                query.queryKey[0] === "List",
            };
            queryClient.resetQueries(options, {
              cancelRefetch: true,
            });
          }
          if (type === "update") {
            getScenarioApi(false);
          }
        }
      }
    );
  };

  const batchUpdateScenario = async (payload) => {
    await APICall("put", EndPoints.scenarios + `batch_update/`, payload).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const updateDataSetByID = async (id, obj, fetch = false) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (fetch) {
            setTimeout(() => {
              getDataSetsApi();
            }, 500);
          }
        }
      }
    );
  };

  const getDataSetsApi = async () => {
    await APICall("get", EndPoints.datasets).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        data.sort((a, b) => (a.position > b.position ? 1 : -1));
        dispatch(setdatasetList(data));
      }
    });
  };

  //function
  const updateDataSetsFilters = (_scenario) => {
    let updatedDataSets = [];
    dataSetList?.forEach((data) => {
      if (
        data?.filters?.list?.selectedScenarios?.includes(_scenario?.title) ||
        data?.filters?.kanban?.selectedScenarios?.includes(_scenario?.title) ||
        data?.filters?.reports?.selectedScenarios?.includes(_scenario?.title)
      ) {
        let filters = {
          list: {
            ...data?.filters?.list,
            selectedScenarios: getAvailableScenarios(
              data?.filters?.list?.selectedScenarios
            ),
          },
          kanban: {
            ...data?.filters?.kanban,
            selectedScenarios: getAvailableScenarios(
              data?.filters?.kanban?.selectedScenarios
            ),
          },
          reports: {
            ...data?.filters?.reports,
            selectedScenarios: getAvailableScenarios(
              data?.filters?.reports?.selectedScenarios
            ),
          },
        };
        let updatedData = {
          ...data,
          filters: filters,
        };
        updatedDataSets.push(updatedData);
      }
    });
    updatedDataSets?.forEach((data, index) => {
      updateDataSetByID(
        data?.uuid,
        {
          filters: data?.filters,
        },
        index === updatedDataSets?.length - 1
      );
    });
  };

  const getAvailableScenarios = (oldArray) => {
    if (oldArray?.length > 0) {
      return oldArray?.filter((o1) => o1 !== itemId.current?.title);
    } else {
      return oldArray;
    }
  };

  const onClickAddNewDataSet = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (scenario?.length < 10) {
        const maxPosition = scenario?.reduce(
          (max, { position }) => Math.max(max, position),
          0
        );
        let obj = {
          title: `Scenario ${(scenario?.length || 0) + 1}`,
          note: "note",
          color: Color.themeColor2,
          position: (Number(maxPosition) || 0) + 1,
          reference: null,
          style: "solid",
        };
        addScenarioApi(obj);
      }
    }
  };

  const onClickDeleteDataSet = async (e, item) => {
    e.stopPropagation();
    if (!item?.can_be_deleted) {
      dispatch(
        setPopupStatus3({
          open: true,
          overlay_type: "delete",
          onConfirm: onOkWarn,
          payload: {
            title: t("Attention"),
            message: t(`scenario_delete_message`),
            confirmText: t("Ok"),
            hideCancel: true,
          },
        })
      );
      return;
    }

    itemId.current = item;
    let response = await getTransactionByParams({
      page: 1,
      page_size: 1,
      scenario: [item.uuid],
    });

    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onOk,
        payload: {
          message:
            response?.count > 0 ? (
              <Translate
                i18nkey={"scenario_delete_message"}
                values={{ count: response?.count }}
              />
            ) : (
              `${t("Are_you_sure_delete_this")} ${t("Scenario")}?`
            ),
        },
      })
    );
  };

  const onOk = () => {
    deleteScenarioByIdApi(itemId.current);
  };

  const onOkWarn = () => {
    dispatch(setPopupStatus3(null));
  };

  const onClickEditDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item;
    setOpen(true);
    setDataSetItem(item);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      isDataUpdated.current = true;
    }
    let errorText = null;
    if (name === "title" && value.trim() === "") {
      errorText = "Title can not be empty";
    }
    setDataSetItem({ ...datasetItem, [name]: value });
    setError({ ...error, [name]: errorText });
    if (errorText) {
      return;
    }
  };

  const handleClose = () => {
    updateScenarioByID(itemId.current?.uuid, datasetItem);
    setOpen(false);
  };

  const onClickColor = () => {
    setShowColorPallets(!showColorPallets);
  };

  const handleColorChangeComplete = (color) => {
    setShowColorPallets(false);
    setDataSetItem({
      ...datasetItem,
      colorHex: color.hex,
      color: scenarioTheme[color?.hex] ? scenarioTheme[color?.hex] : color.hex,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(scenario);
    let [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let array = [];
    items?.forEach((element, i) => {
      array.push({ ...element, position: i });
    });

    dispatch(setScenario(array));
    const payload = array.map((item) => {
      const { uuid, position } = item;
      return { uuid, position };
    });
    batchUpdateScenario(payload);
  };

  //Render Function
  const ListItemView = ({ item, provided }) => {
    return (
      <Item
        elevation={1}
        sx={{
          order: item?.position,
        }}
      >
        <ListItem
          id="ListItem"
          divider
          sx={{
            py: 0,
            "&: hover": {
              "#actionBtn": {
                display: "flex",
              },
              backgroundColor: getTailwindColor("purple", 100),
            },
            backgroundColor: Color.white,
            "& .itemText": {
              textAlign: "left",
              fontWeight: 600,
              paddingLeft: "2rem",
              "& .MuiTypography-root": {
                fontFamily: Fonts.Text,
              },
            },
          }}
        >
          <Grid item xs={0.5}>
            {item?.can_be_deleted && (
              <div {...provided?.dragHandleProps}>
                <DragIndicatorIcon
                  sx={{
                    fontSize: "1.375rem",
                    color: Color.tailwind.grey[400],
                    cursor: "grab",
                  }}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={0.75} className={"itemText"}>
            <CircleIcon
              sx={{
                fontSize: "1.375rem",
                color: item?.color?.includes("#")
                  ? item?.color
                  : getTailwindColor(item?.color, 500),
              }}
            />
          </Grid>
          <Grid item xs={3} className={"itemText"}>
            <ListItemText
              primary={item?.title === "Base" ? t(item?.title) : item?.title}
            />
          </Grid>
          <Grid item xs={4} className={"itemText"}>
            <ListItemText
              primary={item?.note}
              sx={{ "& span": { fontSize: "0.8rem" } }}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ActionViewWrapper
              id="actionBtn"
              sx={{
                display: "none",
              }}
            >
              {(item?.can_be_deleted ||
                (!item?.can_be_deleted && item.title === "Default")) && (
                <>
                  <Button
                    onClick={(e) => onClickEditDataSet(e, item)}
                    className={"actionBtn"}
                  >
                    <ModeEditRoundedIcon className={"actionBtnIcon"} />
                  </Button>
                  <Button
                    className={"actionBtn"}
                    onClick={(e) => onClickDeleteDataSet(e, item)}
                  >
                    <DeleteIcon className={"actionBtnIcon"} />
                  </Button>
                </>
              )}
            </ActionViewWrapper>
          </Grid>
        </ListItem>
      </Item>
    );
  };

  const ActiveBoards = () => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "70%",
          my: "2.5rem",
        }}
      >
        <Typography
          sx={{
            color: Color.black,
            fontSize: "2.5rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
          }}
        >
          {t("Scenarios")}
          <HelpOverlay
            showRight
            text={t("Scenarios")}
            path={"scenarios"}
            image={Images.states_insight}
          />
        </Typography>
        <AddButton
          tooltipLabel={t("Add_Scenarios")}
          label={t("Add_Scenarios")}
          onClick={onClickAddNewDataSet}
        />
      </Box>

      <Grid
        container
        elevation={1}
        sx={{
          width: "70%",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "70%",
          }}
        >
          <Grid item xs={1} className={"itemText"}></Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                mb: "0.5rem",
                pl: "2.75rem",
                textAlign: "left",
                fontSize: "1rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Title")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                mb: "0.5rem",
                pl: "2.5rem",
                textAlign: "left",
                fontSize: "1rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Note")}
            </Typography>
          </Grid>
        </Box>
        {scenario?.length > 0 ? (
          <Card
            elevation={1}
            sx={{
              width: "70%",
              maxHeight: {
                s1920: "43rem",
                s2133: "52rem",
                s2560: "56rem",
                s1536: "47rem",
                s1440: "50rem",
                s1366: "51.5rem",
                s1280: "60rem",
              },
              overflowY: "scroll",
              ...thinScrollbarStyle,
            }}
          >
            <List sx={{ padding: "0px" }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {scenario?.map((item, index) => {
                        if (index === 0) {
                          return <ListItemView key={item?.uuid} item={item} />;
                        } else {
                          return (
                            <Draggable
                              key={item?.uuid}
                              draggableId={item?.uuid}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <ListItemView
                                    item={item}
                                    provided={provided}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </List>
          </Card>
        ) : (
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: Fonts.Text,
              color: Color.grey,
            }}
          >
            {t("No_Data_Found")}
          </Typography>
        )}
      </Grid>
    </>
  );

  const editModal = useMemo(() => {
    return (
      <CustomModal
        textAdd="Save"
        hideClose
        hideAction
        onClose={handleClose}
        open={open}
      >
        <OverlayHeader
          subTitle={t("Scenario")}
          onClose={handleClose}
        />

        <Box
          sx={{
            display: "flex",
            backgroundColor: Color.BodyBG,
            flexDirection: "column",
            width: "30rem",
            height: "fit-content",
            p: "1rem 2.2rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TitleInput
              value={datasetItem?.title || ""}
              name="title"
              onChange={handleEditChange}
              helperText={error?.title}
              error={Boolean(error?.title)}
              disabled={!datasetItem?.can_be_deleted}
              hideTitle
              label={t("Title")}
              likeGoogle
              variant="filled"
            />
          </Box>
          <Box sx={{ width: "100%", mt: "1rem" }}>
            <DescriptionInput
              name="note"
              value={datasetItem?.note || ""}
              onChange={handleEditChange}
              disabled={!datasetItem?.can_be_deleted}
              hideTitle
              rows={null}
              label={t("Note_Optional")}
              likeGoogle
              variant="filled"
            />
          </Box>
          <Box
            onClick={onClickColor}
            sx={{
              height: "1.875rem",
              backgroundColor:
                datasetItem?.colorHex ||
                getTailwindColor(datasetItem?.color, 500) ||
                Color.themeColor,
              borderRadius: 1,
              mb: "0.75rem",
              mt: "2rem",
              ml: "0.85rem",
              cursor: "pointer",
            }}
          ></Box>
          <Box
            sx={{
              ml: "1rem",
              input: {
                display: "none",
              },
            }}
          >
            {showColorPallets ? (
              <TwitterPicker
                triangle="top-left"
                colors={Color.scenarioTheme}
                color={
                  datasetItem?.colorHex ||
                  datasetItem?.color ||
                  Color.themeColor
                }
                onChangeComplete={handleColorChangeComplete}
                styles={{ hash: { display: "none", color: "red" } }}
              />
            ) : null}
          </Box>
        </Box>
      </CustomModal>
    );
  }, [error?.title, open, datasetItem, showColorPallets]);

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.appThemeBg,
        textAlign: ["-webkit-center", "-moz-center"],
        pt: "2.5rem",
        position: "relative",
      }}
    >
      <ActiveBoards />
      {editModal}
      <Outlet />
    </Box>
  );
};
export default Scenarios;
