import {
  formatDistanceStrict,
  eachMonthOfInterval,
  differenceInMonths,
  eachDayOfInterval,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  addYears,
  setDate,
  subDays,
  addDays,
  getDate,
  isValid,
  format,
} from "date-fns";
import {
  Typography,
  Container,
  useTheme,
  Divider,
  Tooltip,
  Paper,
  Stack,
  Chip,
  Grid,
} from "@mui/material";
import {
  useImperativeHandle,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import EventRepeatRoundedIcon from "@mui/icons-material/EventRepeatRounded";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDateRange } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { FiLayers } from "react-icons/fi";
import { TbPlug } from "react-icons/tb";
import { styled } from "@mui/styles";
import { v4 as v4uuid } from "uuid";
import _ from "underscore";

import {
  updateBatchTransactions,
  buildUrlFromParams,
  formatAmount,
  cloneDeep,
  remToPx,
} from "../../../../Helper/data";
import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import SearchFilterView from "../../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import DateRangePicker from "../../../../components/Overlay/ThemeFilter/Component/DateRangePicker";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import CategoryTreeSelect from "../../../../components/Overlay/CategoryTreeSelect";
import ThemeToggle from "../../../../components/ToggleGroupeView/ThemeToggle";
import TailwindButton from "../../../../components/Overlay/TailwindButton";
import { useDeferredTimeout } from "../../../../hooks/useDeferredTimeout";
import { setStageLoadingText } from "../../../../store/slices/appmain";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import RulesCommonView from "../../../Settings/Rules/RulesCommonView";
import ComponentLoader from "../../../../components/ComponentLoader";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import { setPopupStatus2 } from "../../../../store/slices/datasets";
import { setContractData } from "../../../../store/slices/staff";
import BarCharts from "../../../../components/Charts/BarCharts";
import CategoryChip from "../../../../components/CategoryChip";
import EndPoints from "../../../../APICall/EndPoints";
import { Color, Fonts } from "../../../../Helper";
import useWidth from "../../../../hooks/useWidth";
import APICall from "../../../../APICall";
import store from "../../../../store";
import theme from "../../../../theme";
import CountView from "./CountView";
import PieWidget from "./PieWidget";

const calender = [
  { title: "irregular", color: Color.purple, colorbg: Color.purple50 },
  { title: "daily", color: Color.pink, colorbg: Color.pink50 },
  { title: "weekly", color: Color.cyan, colorbg: Color.cyan50 },
  { title: "monthly", color: Color.blue, colorbg: Color.blue50 },
  { title: "yearly", color: Color.indigo, colorbg: Color.indigo50 },
];
const FrequencyOptions = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "irregular",
    label: "Irregular",
  },
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "yearly",
    label: "Yearly",
  },
];
const FilterOptions = [
  {
    uuid: "category",
    title: "add_category_filter",
    icon: <LocalOfferIcon />,
  },
  {
    uuid: "date",
    title: "add_date_filter",
    icon: <MdOutlineDateRange />,
  },
  {
    uuid: "ds",
    title: "add_ds_filter",
    icon: <FiLayers />,
  },
];

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader, .MuiDataGrid-columnSeparator":
    {
      minHeight: `${theme.typography.pxToRem(46)} !important`,
      height: `${theme.typography.pxToRem(46)} !important`,
      border: 0,
    },
  "& .MuiDataGrid-sortIcon": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.color.violet[50],
  },
  "& .MuiDataGrid-cell": {
    // border: 0,
    borderColor: theme.palette.color.slate[100],
    padding: "0 6px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.color.slate[700],
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: `${theme.typography.pxToRem(46)} !important`,
  },
}));
const RecurringView = ({ transaction_type = "expense" }) => {
  const { t } = useTranslation();
  const commonRef = useRef(null);

  //redux
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  //state
  const [loading, setLoading] = useState(true);
  const [frequency, setFrequency] = useState("all");

  const onChangeFrequency = (e, value) => {
    if (value && value !== frequency) {
      setFrequency(value);
    }
  };
  if (!isAllHeaderApiFetched) {
    return null;
  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Container
        sx={{
          width: "100%",
          minHeight: "calc(100% - 6rem)",
          maxWidth: "100% !important",
          height: "100%",
          pb: "4rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...theme.thinScrollBar,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: "2rem", mb: "0.5rem", width: "90%" }}
        >
          <ThemeToggle
            value={frequency}
            onChange={onChangeFrequency}
            options={FrequencyOptions}
          />
          <TailwindButton
            onClick={commonRef?.current?.onClickRefresh}
            text={t("Refresh")}
            variant="contained"
            color="primary"
            sx={{ padding: "0.5rem 1rem" }}
          />
        </Stack>

        <ListView
          transaction_type={transaction_type}
          loading={loading}
          setLoading={setLoading}
          commonRef={commonRef}
          frequency={frequency}
        />
      </Container>
    </div>
  );
};

export default RecurringView;

const ListView = ({
  loading,
  setLoading,
  commonRef,
  transaction_type,
  frequency,
}) => {
  const contractData = useSelector((state) => state.staffSlice?.contractData);

  return (
    <Paper
      sx={{
        width: "90%",
        minHeight: contractData?.length === 0 ? "calc(100% - 5rem)" : "none",
        backgroundColor: "color.white",
        py: 8,
        borderRadius: theme.borderRadius.main,
      }}
    >
      <FilterView
        commonRef={commonRef}
        frequency={frequency}
        transaction_type={transaction_type}
        setLoading={setLoading}
      />
      <Grid
        container
        rowSpacing={5}
        columnSpacing={5}
        sx={{
          width: "100%",
          height: "fit-content",
          my: "2rem",
          px: "1.45rem",
        }}
      >
        <Grid item xs={12} isTablet={4} s1280={3}>
          <CategoryWidget loading={loading} />
        </Grid>
        <Grid item xs={12} isTablet={8} s1280={4.5}>
          <CostWidget loading={loading} />
        </Grid>
        <Grid item xs={12} isTablet={12} s1280={4.5}>
          <NextPaymentWidget loading={loading} />
        </Grid>
      </Grid>

      <GridView
        loading={loading}
        commonRef={commonRef}
        transaction_type={transaction_type}
      />
    </Paper>
  );
};

const GridView = ({ commonRef, loading, transaction_type }) => {
  const dispatch = useDispatch();
  const rowData = useRef(null);
  const rulesRef = useRef(null);

  const contractData = useSelector((state) => state.staffSlice?.contractData);
  const [anchorEl, setAnchorEl] = useState(null);

  const getRowId = useCallback((row) => row?.uuid, []);

  const getDifferencesDay = (next) => {
    let date = null;
    if (next && next !== "Invalid Date") {
      date = format(new Date(next), "dd-MM-yyyy");
      const differenceDay = differenceInDays(new Date(next), new Date());
      if (differenceDay > 1 && differenceDay <= 8) {
        date = `Probably In ${differenceDay} days`;
      }
      if (differenceDay === 1) {
        date = "Tomorrow";
      }
      if (differenceDay === 0) {
        date = "Today";
      }
    }
    return date;
  };

  const onClickCategory = (event, params) => {
    event.stopPropagation();
    rowData.current = params;
    setAnchorEl(event.currentTarget);
  };

  const onCloseDropDown = () => {
    setAnchorEl(null);
  };

  const onClickCategoryTitle = (e, item) => {
    let array = [];
    rowData.current?.items?.forEach((element) => {
      array.push({
        uuid: element?.uuid,
        category: item?.uuid,
      });
    });
    setAnchorEl(null);
    updateCardsBatch(array, rowData.current, item);
  };

  //api
  const updateCardsBatch = async (array, row, category) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    const response = await updateBatchTransactions(array);
    const rulesError = response?.find((o1) =>
      o1?.category?.[0]?.includes("Cannot set category.")
    );
    if (rulesError) {
      dispatch(setStageLoadingText(null));
      rulesRef.current?.onOpen({
        payload: array,
        response,
        message: response?.[0].category?.[0],
        rule_type: response?.data?.type?.[0],
        transaction_rule: rulesError?.transaction_rule?.[0],
        title: row.title,
        transaction_type: row?.value >= 0 ? 1 : 2,
        category: category?.uuid,
      });
    } else {
      if (response) {
        if (commonRef?.current?.onClickRefresh) {
          commonRef?.current?.onClickRefresh();
        }
        dispatch(setStageLoadingText(null));
      }
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      cellClassName: "super-app-theme--cell",
      flex: 2,
      editable: false,
      renderCell: TitleCell,
      valueGetter: (value, row) => {
        return value?.row?.title;
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 0.8,
      cellClassName: "super-app-theme--cell",
      editable: false,
      renderCell: (params) => {
        return (
          <Chip
            icon={
              <EventRepeatRoundedIcon
                sx={{
                  color: `${params?.value?.color} !important`,
                  fontSize: "1.1rem",
                }}
              />
            }
            label={params?.value?.title}
            sx={{
              color: params?.value?.color,
              backgroundColor: params?.value?.colorbg,
              fontColor: params?.value?.color,
              fontSize: "0.75rem",
              px: "0.25rem",
              py: "0.35rem",
              height: "auto",
              "&:hover": {
                backgroundColor: params?.value?.colorbg,
              },
            }}
          />
        );
      },
    },
    {
      field: "next",
      headerName: "Next Payment",
      cellClassName: "super-app-theme--cell",
      flex: 1,
      editable: false,
      type: "date",
      renderCell: (params) => {
        let date = getDifferencesDay(params?.row?.next);
        return (
          <Typography
            variant="h6"
            sx={{
              color: date?.includes("Probably")
                ? Color.purple
                : date?.includes("Tomorrow") || date?.includes("Today")
                  ? Color.red
                  : Color.black,
              fontWeight:
                date?.includes("Probably") ||
                date?.includes("Tomorrow") ||
                date?.includes("Today")
                  ? 800
                  : 500,
              fontSize: "0.8rem",
            }}
          >
            {date}
          </Typography>
        );
      },
      valueGetter: (params) => {
        return params?.row?.next && params?.row?.next !== "Invalid Date"
          ? new Date(params?.row?.next)
          : null;
      },
    },

    {
      field: "value",
      headerName: "Value",
      cellClassName: "super-app-theme--cell",
      flex: 1,
      editable: false,
      valueGetter: ({ value }) => parseFloat(value),
      renderCell: (params) => {
        return (
          <Typography
            variant="h6"
            component={"h6"}
            sx={{
              color: params?.value < 0 ? Color.red : Color.green,
              fontWeight: 500,
              fontSize: "0.8rem",
            }}
          >
            {formatAmount({
              amount: params?.value,
            })}
          </Typography>
        );
      },
    },
    {
      field: "sum",
      headerName: "Sum",
      cellClassName: "super-app-theme--cell",
      flex: 0.5,
      editable: false,
      valueGetter: ({ value }) => parseFloat(value),
      renderCell: (params) => {
        return (
          <Typography
            variant="h6"
            component={"h6"}
            sx={{
              color: params?.value < 0 ? Color.red : Color.green,
              fontWeight: 800,
              fontFamily: Fonts.Text,
              fontSize: "0.8rem",
            }}
          >
            {formatAmount({
              amount: params?.value,
            })}
          </Typography>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      cellClassName: "super-app-theme--cell",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <strong>
            <CategoryChip
              onClick={(e) => onClickCategory(e, params?.row)}
              categoryId={params.value}
            />
          </strong>
        );
      },
    },
  ];

  const onRowClick = async (params) => {
    const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;

    dispatch(
      setPopupStatus2({
        open: true,
        overlay_type: "drawer_modal",
        anchor: "right",
        payload: {
          hideAccessInListViewFilter: true,
          overlayTab: "category",
          cell: {
            transaction_type,
            title: params.row?.title,
            multiState: [stateByTitle?.["Booked"]?.[0]?.uuid],
            withUrl: true,
            rowId: params.row?.category || "uncategorized",
          },
        },
      })
    );
  };

  if (loading || !contractData || contractData?.length === 0) {
    return (
      <ComponentLoader
        loading={loading}
        isSkeleton
        skeletonCount={3}
        placeHolderHeight="4.5rem"
        placeHolderWidth="35%"
        showBankButton
        skeltonSx={{
          transform: "scale(1,0.9)",
        }}
        sx={{
          m: 8,
          width: loading ? "auto" : "42%",
          // height: loading ? "10rem" : "calc(100% - 20rem)",
        }}
        key1={`contract_list_no_data_text_01`}
        key2={`contract_list_no_data_text_02`}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        paddingInline: "2rem",
      }}
    >
      <RulesCommonView ref={rulesRef} />

      <CategoryTreeSelect
        anchorEl={anchorEl}
        handleClosePopOver={onCloseDropDown}
        onClickCategoryTitle={onClickCategoryTitle}
        type={
          transaction_type === "income"
            ? 1
            : transaction_type === "expense"
              ? 2
              : 3
        }
      />
      <StyledDataGrid
        rows={contractData || []}
        columns={columns}
        getRowId={getRowId}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        disableRowSelectionOnClick
        disableCellSelectionOnClick
        disableDensitySelector
        density="compact"
        autoHeight
        rowHeight={60}
        hideFooter
        onRowClick={onRowClick}
        sx={{ border: 0 }}
      />
    </div>
  );
};

const CategoryWidget = ({ loading }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const contractData = useSelector((state) => state.staffSlice?.contractData);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );

  //state
  const [data, setData] = useState([]);

  useDebounce(
    () => {
      if (!loading) {
        const TransactionsByCategory = _.groupBy(
          contractData,
          ({ category }) => category || "UnCategorized"
        );

        let array = [];
        Object.keys(TransactionsByCategory).forEach((key) => {
          const total = TransactionsByCategory[key].reduce(
            (a, b) => a + Number(b.sum),
            0
          );
          const category = selectionCategoriesByID?.[key]?.[0];
          let title = category?.title;
          if (category?.immutable) {
            title = t(category?.title);
          } else if (!category?.title) {
            title = t(key);
          }
          array.push({
            uuid: key,
            title: title,
            value: Math.abs(parseFloat(total)?.toFixed(0)),
            color: category?.color || theme.palette.color.slate[500],
          });
        });

        array.sort((a, b) => b.value - a.value);
        setData(array);
      }
    },
    300,
    [loading, contractData, selectionCategoriesByID],
    true
  );

  if (!loading && data?.length === 0) {
    return null;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        title="contract_category_pie"
        loading={loading}
        options={data}
        sx={{
          flex: 1,
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          ml: "0.5rem",
        }}
      />
    </div>
  );
};

const CostWidget = ({ loading }) => {
  const theme = useTheme();
  const contractData = useSelector((state) => state.staffSlice?.contractData);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  //functions
  useDebounce(
    () => {
      if (!loading) {
        const dates = [];

        const filterData = contractData?.filter((o1) => !o1?.isIrregular);
        const allData = filterData?.reduce((acc, obj) => {
          return acc.concat(obj?.items);
        }, []);
        allData?.forEach((item) => {
          dates.push(new Date(item?.due_date));
        });

        const minDate = dates?.reduce(
          (min, date) => (min < date ? min : date),
          new Date(Infinity)
        );
        const maxDate = dates?.reduce(
          (max, date) => (max > date ? max : date),
          new Date(-Infinity)
        );

        if (!isValid(minDate) || !isValid(maxDate)) {
          setChartData([]);
          setChartKeys([]);
          return;
        }

        const TransactionsByDates = _.groupBy(allData, ({ due_date }) =>
          format(new Date(due_date), "yyyy-MM")
        );
        let RangeDates = [];
        const monthsDiff = differenceInMonths(maxDate, minDate);
        if (monthsDiff < 11) {
          let count = Math.floor(12 - monthsDiff / 2);
          const startAdjustment = subMonths(minDate, count);
          const endAdjustment = addMonths(maxDate, count);

          // Create a new RangeDates array based on adjusted start and end dates
          const newStartDate =
            startAdjustment < minDate ? startAdjustment : minDate;
          const newEndDate = endAdjustment > maxDate ? endAdjustment : maxDate;

          RangeDates = eachMonthOfInterval({
            start: startOfMonth(newStartDate),
            end: endOfMonth(newEndDate),
          });
        } else {
          RangeDates = eachMonthOfInterval({
            start: minDate,
            end: maxDate,
          });
        }
        const total_key = "total_key";
        let chartKeyArray = [];
        let monthArray = [];

        const categories = [
          ...new Set(
            filterData?.map((item) => item?.category || "UnCategorized")
          ),
        ];

        RangeDates?.forEach((date) => {
          const formattedDate = format(date, "yyyy-MM");
          const monthData = TransactionsByDates?.[formattedDate] || [];
          const TransactionsByCategory = _.groupBy(
            monthData,
            (item) => item?.category || "UnCategorized"
          );

          let obj = { due_date: formattedDate };
          categories?.forEach((key) => {
            const total = (TransactionsByCategory?.[key] || [])?.reduce(
              (a, b) => a + Number(b?.gross_value),
              0
            );
            obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
          });
          Object.keys(obj).forEach((key) => {
            if (key !== "due_date" && key !== total_key) {
              obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
            }
          });
          monthArray.push(obj);
        });
        categories?.forEach((category_uuid) => {
          const category = selectionCategoriesByID?.[category_uuid]?.[0];

          chartKeyArray.push({
            key: category?.title || "UnCategorized",
            color: category?.color || "slate",
            dataKey: category_uuid || "UnCategorized",
            stackId: "a",
            fill: category?.color || theme.palette.color.slate[500],
            shade: 500,
          });
        });

        chartKeyArray.push({
          key: total_key,
          color: theme.palette.color.slate[500],
          dataKey: total_key,
          stackId: "a",
          fill: theme.palette.color.slate[500],
          shade: 500,
          hideBar: true,
          isBold: true,
          showTopSeparator: true,
        });
        setChartKeys(chartKeyArray);
        setChartData(monthArray);
      }
    },
    300,
    [loading, contractData, selectionCategoriesByID],
    true
  );
  if (!loading && chartData?.length === 0) {
    return null;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AreaChart
        heading={"contract_category_cost_development"}
        loading={loading}
        chartKeys={chartKeys}
        chartData={chartData}
        style={{
          width: "100%",
          height: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", s1280: "block" },
          opacity: 0.6,
          ml: "0.5rem",
        }}
      />
    </div>
  );
};

const NextPaymentWidget = ({ loading }) => {
  const theme = useTheme();
  const contractData = useSelector((state) => state.staffSlice?.contractData);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  //functions
  useDebounce(
    () => {
      if (!loading) {
        const filterData = contractData?.filter(
          (item) => item?.next && !item?.isIrregular
        );
        const TransactionsByDates = _.groupBy(filterData || [], ({ next }) =>
          format(next, "yyyy-MM-dd")
        );
        const RangeDates =
          filterData?.length > 0
            ? eachDayOfInterval({
                start: new Date(),
                end: addDays(new Date(), 44),
              })
            : [];
        const total_key = "total_key";
        let chartKeyArray = [];
        let monthArray = [];

        const categories = [
          ...new Set(
            filterData?.map((item) => item?.category || "UnCategorized")
          ),
        ];

        RangeDates?.forEach((date) => {
          const formattedDate = format(date, "yyyy-MM-dd");
          const monthData = TransactionsByDates?.[formattedDate] || [];
          const TransactionsByCategory = _.groupBy(
            monthData,
            (item) => item?.category || "UnCategorized"
          );

          let obj = { due_date: formattedDate };
          categories?.forEach((key) => {
            const total = (TransactionsByCategory?.[key] || [])?.reduce(
              (a, b) => a + Number(b?.value),
              0
            );
            obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
          });
          Object.keys(obj).forEach((key) => {
            if (key !== "due_date" && key !== total_key) {
              obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
            }
          });
          monthArray.push(obj);
        });
        categories?.forEach((category_uuid) => {
          const category = selectionCategoriesByID?.[category_uuid]?.[0];

          chartKeyArray.push({
            key: category?.title || "UnCategorized",
            color: category?.color || "slate",
            dataKey: category_uuid || "UnCategorized",
            stackId: "a",
            fill: category?.color || theme.palette.color.slate[500],
            shade: 500,
          });
        });

        chartKeyArray.push({
          key: total_key,
          color: theme.palette.color.slate[500],
          dataKey: total_key,
          stackId: "a",
          fill: theme.palette.color.slate[500],
          shade: 500,
          hideBar: true,
          isBold: true,
          showTopSeparator: true,
        });
        setChartKeys(chartKeyArray);
        setChartData(monthArray);
      }
    },
    300,
    [loading, contractData, selectionCategoriesByID],
    true
  );
  if (!loading && chartData?.length === 0) {
    return null;
  }
  return (
    <AreaChart
      heading={"contract_next_payment_cost_development"}
      loading={loading}
      chartKeys={chartKeys}
      chartData={chartData}
      style={{
        width: "100%",
        height: "18rem",
      }}
    />
  );
};

const AreaChart = ({ style, loading, chartData, chartKeys, heading }) => {
  const currentWidth = useWidth();
  const { t } = useTranslation();

  return (
    <div style={style}>
      {loading ? (
        <ComponentLoader
          loading
          isSkeleton
          height="18rem"
          skeletonCount={1}
          placeHolderHeight="18rem"
          sx={{
            width: "100%",
          }}
          skeltonSx={{
            transform: "scale(1,1)",
          }}
        />
      ) : chartData?.length > 0 ? (
        <Stack
          direction="column"
          alignItems={"start"}
          sx={{ width: "100%", height: "90%" }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"fontWeightMediumBold"}
            color="color.slate.700"
            mt="0.25rem"
            mb="1rem"
            display={"flex"}
            alignItems={"start"}
            justifyContent={"space-between"}
            width="100%"
            lineHeight={"normal"}
            textAlign={"left"}
          >
            {t(heading)}
          </Typography>
          <BarCharts
            data={chartData || []}
            hideDefault
            barsData={cloneDeep(chartKeys || [])}
            tooltipProps={{
              position: { x: -remToPx(currentWidth, 21), y: -35 },
            }}
            yAxisProps={{
              dataKey: undefined,
            }}
            chartProps={{
              margin: {
                left: -4,
              },
            }}
          />
        </Stack>
      ) : null}
    </div>
  );
};

const FilterView = ({ commonRef, frequency, setLoading, transaction_type }) => {
  const isFilterOpen = useRef(false);

  const [date, setDateObj] = useState({ start_date: null, end_date: null });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [Ds, setDs] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  //functions
  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    if (selectedCategory?.length > 0 && !_selectedIds?.includes("category")) {
      setSelectedCategory([]);
    }
    if (Ds?.length > 0 && !_selectedIds?.includes("ds")) {
      setDs([]);
    }
    if (
      !date?.start_date &&
      !date?.end_date &&
      !_selectedIds?.includes("date")
    ) {
      setDateObj({ start_date: null, end_date: null });
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        px: 8,
        mb: "1.5rem",
      }}
    >
      <CommonFunctions
        ref={commonRef}
        transaction_type={transaction_type}
        selectedCategory={selectedCategory}
        searchText={searchText}
        frequency={frequency}
        setLoading={setLoading}
        date={date}
        Ds={Ds}
      />
      <SearchView searchText={searchText} setSearchText={setSearchText} />

      {isIncludedInFilterList?.length > 0 ? null : (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      )}
      {isIncludedInFilterList?.map((item) => {
        if (item === "category") {
          return (
            <CategoryFilter
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              removeFilter={removeFilter}
              filterKey={"category"}
            />
          );
        }
        if (item === "date") {
          return (
            <DateFilter
              setDateObj={setDateObj}
              date={date}
              removeFilter={removeFilter}
              filterKey={"date"}
            />
          );
        }
        if (item === "ds") {
          return (
            <DsFilter
              setDs={setDs}
              Ds={Ds}
              removeFilter={removeFilter}
              filterKey={"ds"}
            />
          );
        }
        return null;
      })}

      {isIncludedInFilterList?.length > 0 ? (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      ) : null}

      <AddFilterPopOver
        anchorEl={anchorEl}
        onClose={onClose}
        selectedIds={isIncludedInFilterList}
        options={FilterOptions}
      />
    </Stack>
  );
};

const SearchView = ({ searchText, setSearchText }) => {
  //functions
  const onChangeSearch = (e) => {
    setSearchText(e?.target?.value);
  };

  const onClickClear = (e) => {
    setSearchText("");
  };

  return (
    <SearchFilterView
      active={searchText?.length > 0}
      value={searchText}
      color={"slate"}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const CategoryFilter = ({
  selectedCategory,
  setSelectedCategory,
  removeFilter,
  filterKey,
}) => {
  const onChangeValue = (value) => {
    setSelectedCategory(value);
  };

  const onClickReset = (e) => {
    e.stopPropagation();
    removeFilter(filterKey);
    onChangeValue([]);
  };

  return (
    <CategoryFilterView
      selectedIds={selectedCategory}
      categoryType={3}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
    />
  );
};

const DsFilter = ({ Ds, setDs, removeFilter, filterKey }) => {
  const optionsById = useRef({});

  //state
  const [options, setOptions] = useState([]);

  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  useEffect(() => {
    let array = [];
    datasetAccountList?.forEach((o1) => {
      if (o1.type === 12) {
        array.push({
          uuid: o1.uuid,
          hideIcon: true,
          title: o1?.title,
        });
      }
    });
    optionsById.current = _.groupBy(array, "uuid");
    setOptions(array);
    setDs([]);
  }, [datasetAccountList]);

  //functions
  const onClickReset = () => {
    removeFilter(filterKey);
    setDs([]);
  };

  const onChangeValue = (value) => {
    setDs(value);
  };

  return (
    <ThemeFilter
      heading="DS Filter"
      tooltip="tooltip_ds_filter"
      icon={<TbPlug style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={Ds}
      isActive={Ds?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
    />
  );
};

const DateFilter = ({ date, setDateObj, removeFilter, filterKey }) => {
  //api
  const onOk = (payload, onCloseDatePopOver) => {
    setDateObj(payload);
    onCloseDatePopOver();
  };

  const onClickReset = (e) => {
    removeFilter(filterKey);
    setDateObj({
      start_date: null,
      end_date: null,
    });
  };

  return (
    <DateRangePicker
      autoClose={false}
      start_date={date?.start_date}
      end_date={date?.end_date}
      defaultStart={null}
      defaultEnd={null}
      onOk={onOk}
      onClickReset={onClickReset}
      showClear
    />
  );
};

const TitleCell = (params) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const item = params?.row;

  return (
    <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Tooltip title={item?.title} arrow placement="top">
        <Typography
          variant="subtitle2"
          fontWeight={"fontWeightMediumBold"}
          color="color.slate.700"
          sx={{
            maxWidth: "24rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {item?.title}
        </Typography>
      </Tooltip>

      <CountView
        tooltip={t("staff_seq_count", {
          count: item?.occurrence,
        })}
        count={item?.occurrence || 0}
        color={theme.palette.color.slate[400]}
        bg={theme.palette.color.slate[100]}
        style={{
          width: "1.7rem",
        }}
      />
    </div>
  );
};

const CommonFunctions = forwardRef(
  (
    {
      date,
      selectedCategory,
      setLoading,
      frequency,
      transaction_type,
      searchText,
      Ds,
    },
    _ref
  ) => {
    const dispatch = useDispatch();
    const originalData = useRef([]);

    //redux
    const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);

    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const BookedId = stateByTitle?.["Booked"]?.[0]?.uuid;

    //data
    const from_date = date?.start_date
      ? format(new Date(date?.start_date), "yyyy-MM-dd")
      : null;
    const to_date = date?.end_date
      ? format(new Date(date?.end_date), "yyyy-MM-dd")
      : null;

    //state
    const DeferredSearchText = useDeferredTimeout({ value: searchText });

    //api
    const fetchBulkList = async (obj) => {
      let { next, page_size, ...rest } = obj;
      let buildListParams = null;
      let url = "";
      if (!next) {
        buildListParams = {
          page_size,
          categorized: "unknown",
          transaction_type: "same",
          is_reconciled: false,
          title: "similar",
          gross_value: "different",
          category_uuid: "same",
          state: [BookedId],
          source: 2,
          ...rest,
        };
        if (Ds?.length > 0) {
          Ds?.forEach((o1) => {
            url = url + `&data_source=${o1}`;
          });
        } else {
          buildListParams.dataset = dataSetData?.uuid;
        }
        if (!dataSetData?.use_global_categories) {
          buildListParams.category_dataset = dataSetData?.uuid;
        } else {
          buildListParams.global_category = true;
        }
      }

      let result = null;
      await APICall(
        "get",
        next
          ? next?.replace("/api/", "")
          : EndPoints.transactionBulkList +
              buildUrlFromParams(buildListParams) +
              url,
        undefined,
        { doNotHandleError: true }
      ).then((response) => {
        if (response.status === 200 && response.data) {
          const array = response.data?.results?.map((o1) => {
            return {
              ...o1,
              uuid: v4uuid(),
            };
          });
          result = {
            ...response.data,
            results: array,
          };
        }
      });
      return result;
    };

    const BulkList = useInfiniteQuery({
      queryKey: [
        "transactions",
        {
          dataset: dataSetData?.uuid,
          apiType: "RecurringTransactions",
          transaction_type,
          from_date,
          to_date,
          Ds,
        },
      ],

      queryFn: ({ pageParam = null, signal }) => {
        let params = {
          next: pageParam,
          page_size: 30,
        };
        if (from_date) {
          params.from_date = from_date;
        }
        if (to_date) {
          params.to_date = to_date;
        }
        if (transaction_type === "income") {
          params.min_gross_value = 0;
        }
        if (transaction_type === "expense") {
          params.max_gross_value = -0.001;
        }
        const response = fetchBulkList(params);
        if (response) {
          return response;
        }
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.next ?? undefined;
      },
      select: (data) => {
        const flatData = data?.pages?.map((o1) => o1?.results)?.flat();
        return flatData;
      },
      priority: 3,
      staleTime: 600000,
      backgroundFetch: true,
      keepPreviousData: true,
      enabled: !!dataSetData?.uuid && !!BookedId,
    });
    const isFetching = useDeferredTimeout({ value: BulkList?.isFetching });

    useDebounce(
      () => {
        setLoading(isFetching);
      },
      300,
      [isFetching]
    );

    useEffect(() => {
      if (!BulkList?.isFetching && BulkList?.hasNextPage) {
        setTimeout(() => {
          BulkList?.fetchNextPage();
        }, 0);
      }
    }, [BulkList]);

    useDebounce(
      () => {
        setTimeout(() => {
          BulkList?.refetch();
        }, 0);
      },
      300,
      [date]
    );

    useEffect(() => {
      if (
        BulkList?.data &&
        !BulkList?.isFetching &&
        !BulkList?.hasNextPage &&
        frequency
      ) {
        updateRecurringData();
      }
    }, [
      BulkList?.data,
      BulkList?.isFetching,
      BulkList?.hasNextPage,
      frequency,
      selectedCategory,
      DeferredSearchText,
    ]);

    //function
    const onClickRefresh = () => {
      BulkList.refetch();
    };

    const updateRecurringData = () => {
      setLoading(true);
      let recurringAllData = [];
      let recurringArray = [];
      BulkList?.data?.forEach((element) => {
        if (
          transaction_type === "expense" &&
          element?.key?.transaction_type === "outflow"
        ) {
          recurringAllData.push(getRecurringData(element));
        }
        if (
          transaction_type === "income" &&
          element?.key?.transaction_type === "inflow"
        ) {
          recurringAllData.push(getRecurringData(element));
        }
      });

      originalData.current = recurringArray;
      const filterData = recurringAllData?.filter(
        (o1) =>
          (frequency === "daily"
            ? o1.isDaily
            : frequency === "weekly"
              ? o1.isWeek
              : frequency === "monthly"
                ? o1.isMonth
                : frequency === "yearly"
                  ? o1.isYear
                  : frequency === "irregular"
                    ? o1.isIrregular
                    : true) &&
          (selectedCategory?.length > 0
            ? selectedCategory?.includes(
                o1?.category || "unCategorized_category"
              )
            : true) &&
          (DeferredSearchText?.length > 0
            ? o1?.title
                ?.toLowerCase()
                ?.includes(DeferredSearchText?.toLowerCase())
            : true)
      );

      dispatch(setContractData(filterData));
      setLoading(false);
    };

    const getRecurringData = (element) => {
      const ThresholdCount = Math.round(element?.items?.length * 0.9);

      const sum = element?.items?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.gross_value ?? 0),
        0
      );
      const item = element?.items?.[0];
      const date = format(new Date(item?.due_date), "yyyy-MM-dd");
      let items = [];
      element?.items?.forEach((o1, index) => {
        const currentDate =
          element?.items?.[index + 1]?.due_date || element?.items?.length <= 1
            ? new Date(o1?.due_date)
            : new Date(element?.items?.[index - 1]?.due_date);
        const nextDate = element?.items?.[index + 1]?.due_date
          ? new Date(element?.items?.[index + 1]?.due_date)
          : new Date(o1?.due_date);

        const monthInfo = formatDistanceStrict(currentDate, nextDate, {
          unit: "month",
          roundingMethod: "round",
        });
        const yearInfo = formatDistanceStrict(currentDate, nextDate, {
          unit: "year",
          roundingMethod: "round",
        });
        const dayInfo = formatDistanceStrict(currentDate, nextDate, {
          unit: "day",
          roundingMethod: "round",
        });

        items.push({ ...o1, monthInfo, yearInfo, dayInfo });
      });
      items.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
      let averageDates = items?.filter((o1) => o1.due_date);
      const dates = averageDates.slice(
        averageDates.length - 3,
        averageDates.length
      );
      const finalDay = getAverage(dates);

      const DayData = items?.filter((o1) => o1.dayInfo === "1 day");
      const WeekData = items?.filter((o1) => o1.dayInfo === "7 days");
      const MonthData = items?.filter((o1) => o1.monthInfo === "1 month");
      const YearData = items?.filter((o1) => o1.yearInfo === "1 year");

      let isDaily = false;
      let isWeek = false;
      let isMonth = false;
      let isYear = false;
      let isIrregular = true;
      let frequency = calender?.[0];
      let next = null;

      if (DayData?.length >= ThresholdCount) {
        isDaily = true;
        isIrregular = false;
        frequency = calender?.[1];
        next = addDays(new Date(items?.[items?.length - 1]?.due_date), 1);
      }
      if (WeekData?.length >= ThresholdCount) {
        isWeek = true;
        isIrregular = false;
        frequency = calender?.[2];
        next = addDays(new Date(items?.[items?.length - 1]?.due_date), 7);
      }
      if (MonthData?.length >= ThresholdCount) {
        isMonth = true;
        isIrregular = false;
        frequency = calender?.[3];
        next = setDate(
          addMonths(new Date(items?.[items?.length - 1]?.due_date), 1),
          finalDay
        );
      }
      if (YearData?.length >= ThresholdCount) {
        isYear = true;
        isIrregular = false;
        frequency = calender?.[4];
        next = setDate(
          addYears(new Date(items?.[items?.length - 1]?.due_date), 1),
          finalDay
        );
      }

      const obj = {
        date,
        items,
        isDaily,
        isYear,
        isWeek,
        isMonth,
        isIrregular,
        frequency,
        next,
        first: items?.[0]?.due_date,
        uuid: element?.uuid,
        title: element?.key?.title,
        occurrence: element?.items?.length,
        category: element?.key?.category_uuid,
        value: item?.gross_value,
        subDate: subDays(new Date(item?.due_date), 2),
        addDate: addDays(new Date(item?.due_date), 2),
        sum: parseFloat(sum)?.toFixed(1),
      };

      return obj;
    };

    const getAverage = (dates) => {
      let dayTotal = 0;
      dates?.forEach((element) => {
        dayTotal = dayTotal + getDate(new Date(element?.due_date));
      });
      return Math.ceil(dayTotal / dates?.length);
    };

    //lifecycle
    useImperativeHandle(_ref, () => ({
      onClickRefresh,
    }));

    return null;
  }
);
