import React from "react";

export default function ImageSlider({
  data,
  children,
  showDots = false,
  delay = 8000,
}) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data?.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  let height = 375;
  let width = 470;
  return (
    <div
      style={{
        margin: "0 auto",
        overflow: "hidden",
        maxWidth: width,
        maxHeight: height - 10,
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          transition: "ease 1000ms",
          transform: `translate3d(0, ${-index * height}px, 0)`,
          // transform: `translate3d(${-index * 100}%,0, 0)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>

      {showDots && (
        <div style={{ textAlign: "center" }}>
          {data?.map((_, idx) => (
            <div
              key={idx}
              style={{
                display: "inline-block",
                height: 20,
                width: 20,
                borderRadius: "50%",
                cursor: "pointer",
                margin: "15px 7px 0px",
                backgroundColor: index === idx ? "#6a0dad" : "#c4c4c4",
              }}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
}
