import {
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  Tooltip,
  Button,
  Box,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { TbChartAreaLineFilled, TbCircles } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import startOfYear from "date-fns/startOfYear";
import { alpha } from "@mui/material/styles";
import endOfYear from "date-fns/endOfYear";
import addYears from "date-fns/addYears";
import subYears from "date-fns/subYears";
import format from "date-fns/format";
import { useState } from "react";

import CustomerScenarioToggle from "../../../../components/ToggleGroupeView/CustomerScenarioToggle";
import CustomerStateToggle from "../../../../components/ToggleGroupeView/CustomerStateToggle";
import RoundFilterWrapper from "../../../../components/RoundFilterWrapper/index";
import HelpOverlay from "../../../../components/HelpOverlay.js/index.js";
import { updateFilters } from "../../../../store/slices/global";
import Icon from "../../../../components/Icon";
import { Color } from "../../../../Helper";

const EmployeeHeader = ({
  width = 1500,
  setToggle,
  toggle,
  hideOption = false,
  hideInsight = false,
  hideStateOption = false,
  hideDateChange = false,
  insightsOptions = [],
}) => {
  const skipCount = 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const start_date = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.reports?.start_date
  );
  const end_date = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.reports?.end_date
  );
  const selectedStates = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.reports?.selectedStates
  );
  const selectedScenarios = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.reports?.selectedScenarios
  );

  const appliedFilterlist = {
    reports: {
      start_date: start_date,
      end_date: end_date,
      selectedStates: selectedStates,
      selectedScenarios: selectedScenarios,
    },
  };

  //state
  const [activeFilter, setActiveFilter] = useState(null);

  //functions
  const handleToggle = (event, newFormats) => {
    setToggle(newFormats);
  };

  const onClickRightArrow2 = () => {
    let start_date = null;
    let end_date = null;
    start_date = format(
      addYears(new Date(appliedFilterlist?.reports?.start_date), skipCount),
      "yyyy-MM-dd"
    );
    end_date = format(
      addYears(new Date(appliedFilterlist?.reports?.end_date), skipCount),
      "yyyy-MM-dd"
    );
    dispatch(
      updateFilters({
        parent_key: "reports",
        obj: { start_date: start_date, end_date: end_date },
      })
    );
  };

  const onClickHome = () => {
    const start_date = format(startOfYear(new Date()), "yyyy-MM-dd");
    const end_date = format(endOfYear(new Date()), "yyyy-MM-dd");
    dispatch(
      updateFilters({
        parent_key: "reports",
        obj: { start_date: start_date, end_date: end_date },
      })
    );
  };

  const onClickLeftArrow2 = () => {
    let start_date = null;
    let end_date = null;
    start_date = format(
      subYears(new Date(appliedFilterlist?.reports?.start_date), skipCount),
      "yyyy-MM-dd"
    );
    end_date = format(
      subYears(new Date(appliedFilterlist?.reports?.end_date), skipCount),
      "yyyy-MM-dd"
    );
    dispatch(
      updateFilters({
        parent_key: "reports",
        obj: { start_date: start_date, end_date: end_date },
      })
    );
  };

  const onUpdateState = (value) => {
    dispatch(
      updateFilters({
        parent_key: "reports",
        obj: { selectedStates: value },
      })
    );
  };

  const onUpdateScenario = (value) => {
    dispatch(
      updateFilters({
        parent_key: "reports",
        obj: { selectedScenarios: value },
      })
    );
  };

  const onOpenDropDown = (name) => {
    setActiveFilter(name);
  };

  const onCloseDropDown = () => {
    setActiveFilter(null);
  };

  //render Function
  return (
    <>
      <Box
        sx={{
          width: width,
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "end",
          my: "1rem",

          "& .MuiOutlinedInput-notchedOutline": {
            border: `0px none ${Color.headerIconLight}`,
          },
          "& .Mui-selected": {
            "& p": {
              color: alpha(Color.themeColor, 0.75),
              fontWeight: 500,
            },
            backgroundColor: `${alpha(Color.themeColor, 0.1)} !important`,
          },
          "& .MuiToggleButtonGroup-grouped": {
            margin: "0.25rem",
            border: 0,
            "&:hover": {
              backgroundColor: alpha(Color.themeColor, 0.1),
            },
            "&:not(:first-of-type)": {
              borderRadius: 1,
            },
            "&:first-of-type": {
              borderRadius: 1,
            },
          },
        }}
      >
        {!hideOption && (
          <RoundFilterWrapper opacity={0.8} mx={"0.5rem"}>
            <Tooltip
              title={t("Toggle Employees  & Freelancers")}
              disableFocusListener
              placement="top"
              arrow
            >
              <PeopleIcon
                sx={{
                  fontSize: "1rem",
                  color: alpha(Color.themeColor, 0.7),
                  mr: "0.5rem",
                }}
              />
            </Tooltip>

            <ToggleButtonGroup
              size="small"
              value={toggle}
              onChange={handleToggle}
              aria-label="text formatting"
            >
              <ToggleButton value="Employees" aria-label="Employees">
                <Tooltip
                  title={t("Employees")}
                  disableFocusListener
                  placement="top"
                  arrow
                >
                  <p>{t("Employees")}</p>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="Freelancers" aria-label="Freelancers">
                <Tooltip
                  title={t("Freelancers")}
                  disableFocusListener
                  placement="top"
                  arrow
                >
                  <p>{t("Freelancers")}</p>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </RoundFilterWrapper>
        )}
        {!hideDateChange && (
          <RoundFilterWrapper mx={1}>
            <ButtonGroup variant="text" aria-label="text button group">
              <Button
                onClick={onClickLeftArrow2}
                sx={{
                  borderColor: `${alpha(Color.themeColor2, 0.1)} !important`,
                }}
              >
                <KeyboardDoubleArrowLeftIcon
                  sx={{ color: alpha(Color.themeColor2, 0.75) }}
                />
              </Button>

              <Button
                onClick={onClickHome}
                sx={{
                  borderColor: `${alpha(Color.themeColor2, 0.1)} !important`,
                }}
              >
                <HomeIcon sx={{ color: alpha(Color.themeColor2, 0.75) }} />
              </Button>

              <Button
                onClick={onClickRightArrow2}
                sx={{
                  borderColor: `${alpha(Color.themeColor2, 0.1)} !important`,
                }}
              >
                <KeyboardDoubleArrowRightIcon
                  sx={{ color: alpha(Color.themeColor2, 0.75) }}
                />
              </Button>
            </ButtonGroup>
          </RoundFilterWrapper>
        )}
        {!hideStateOption && (
          <>
            <RoundFilterWrapper
              mx={0}
              hideClear
              selected={appliedFilterlist?.reports?.selectedStates?.length > 0}
              active={activeFilter === "state"}
            >
              <Box
                variant="h6"
                sx={{
                  mr: "0.5rem",
                  fontSize: "1rem",
                  color: Color.themeColor,
                  opacity: activeFilter === "state" ? 1 : 0.3,
                }}
              >
                <Icon icon={<TbCircles />} fontSize={"1.375rem"}></Icon>
              </Box>
              <Tooltip
                title={t("Group States")}
                disableFocusListener
                placement="top"
                arrow
              >
                <CustomerStateToggle
                  value={appliedFilterlist?.reports?.selectedStates || []}
                  onUpdateState={onUpdateState}
                  active={activeFilter === "state"}
                  onOpen={() => onOpenDropDown("state")}
                  onClose={onCloseDropDown}
                />
              </Tooltip>
            </RoundFilterWrapper>
            <RoundFilterWrapper
              hideClear
              selected={
                appliedFilterlist?.reports?.selectedScenarios?.length > 0
              }
              active={activeFilter === "scenario"}
            >
              <Box
                variant="h6"
                sx={{
                  mr: "0.5rem",
                  fontSize: "1rem",
                  color: Color.themeColor,
                  opacity: activeFilter === "scenario" ? 1 : 0.3,
                }}
              >
                <Icon
                  icon={<TbChartAreaLineFilled />}
                  fontSize={"1.375rem"}
                ></Icon>
              </Box>
              <Tooltip
                title={t("Group Scenarios")}
                disableFocusListener
                placement="top"
                arrow
              >
                <CustomerScenarioToggle
                  value={appliedFilterlist?.reports?.selectedScenarios || []}
                  onUpdateState={onUpdateScenario}
                  active={activeFilter === "scenario"}
                  onOpen={() => onOpenDropDown("scenario")}
                  onClose={onCloseDropDown}
                />
              </Tooltip>
            </RoundFilterWrapper>
          </>
        )}
        {!hideInsight && (
          <HelpOverlay
            hideDefault
            data={insightsOptions}
            fontSize="1.4rem"
          />
        )}
      </Box>
    </>
  );
};
export default EmployeeHeader;
