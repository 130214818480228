import {
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
  Stack,
  Card,
  Box,
} from "@mui/material";
import React, { useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { Outlet } from "react-router";

import ReportsPagesOverlay from "./ReportWidget/Component/ReportsPagesOverlay";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import CommonFunctions from "./ReportWidget/Component/CommonFunctions";
import UpgradePlanCard from "../../../components/UpgradePlanCard";
import { Color, Constant, Fonts, Images } from "../../../Helper";
import { setReportFilter } from "../../../store/slices/report";
import useSubscriptions from "../../../hooks/useSubscriptions";
import ActionButton from "../../../components/ActionButton";
import { thinScrollbarStyle } from "../../../Helper/data";
import useStatusHook from "../../../hooks/useStatusHook";
import CustomPopover from "../../../components/PopOver";
import AddButton from "../../../components/AddButton";
import ThemeTabs from "../../../components/ThemeTabs";
import EndPoints from "../../../APICall/EndPoints";
import { cloneDeep } from "../../../Helper/data";
import ReportWidget from "./ReportWidget";
import APICall from "../../../APICall";

const RangeTypeOptions = [
  { rangeType: "Monthly", comparison: "Last Month" },
  { rangeType: "Quarterly", comparison: "Last Quarter" },
  { rangeType: "Annually", comparison: "Last Year" },
];

const ReportCategory = Constant?.ReportCategory;
const AllReportCategory = ReportCategory?.map((item) => item?.title);
const ReportWidgetOptions = Constant?.ReportWidgetOptions;

const Reports = () => {
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  if (
    isFirstLoginOverlayOpen ||
    isAccountSelectOverlayOpen ||
    !isAllHeaderApiFetched
  ) {
    return null;
  }

  return (
    <>
      {/* <OldReport /> */}
      <NewReport />
      <Outlet />
    </>
  );
};

export default Reports;

const NewReport = () => {
  const commonRef = useRef(null);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        overflowY: "auto",
        overflowX: "hidden",
        ...thinScrollbarStyle,
      }}
    >
      <ReportHeader commonRef={commonRef} />
      <ReportWidget />
      <CommonFunctions ref={commonRef} showLoader />
    </Box>
  );
};

const ReportHeader = ({ commonRef }) => {
  const { t } = useTranslation();
  //state
  const [listAnchor, setListAnchor] = useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "1.5rem",
      }}
    >
      <div style={{ width: "20rem" }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flex: 1,
        }}
      >
        <PageView />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <ManagePages listAnchor={listAnchor} setListAnchor={setListAnchor} />
          <AddButtonView commonRef={commonRef} listAnchor={listAnchor} />
          <FinbanInfoBulb
            url="https://finban.io/documentation/reports/"
            sx={{ ml: "1rem" }}
          />
        </Stack>
      </Stack>
      <div style={{ width: "20rem" }} />
    </Box>
  );
};

const PageView = () => {
  const dispatch = useDispatch();
  const commonRef = useRef(null);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const ReportPages = useStatusHook("ReportPages");
  const pagesList = ReportPages?.data;

  //functions
  const handleToggle = (e, value) => {
    let _page = pagesList?.find((p) => p.uuid === value);
    let _widgets = JSON.parse(JSON.stringify(_page?.widgets));
    dispatch(
      setReportFilter({
        key: "Page",
        value: _page,
      })
    );
    commonRef.current?.updatePageLayout({
      widgets: cloneDeep(_widgets),
    });
    global.reportManualUpdate = true;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <CommonFunctions ref={commonRef} />
      {Page?.uuid && pagesList?.length > 0 ? (
        <ThemeTabs
          options={pagesList}
          tab={Page?.uuid}
          onChange={handleToggle}
          truncateText
          truncateTextCount={20}
          doNotTranslate
        />
      ) : null}
    </Box>
  );
};

const FilterWidgetView = ({ item, commonRef }) => {
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();
  //redux
  const Page = useSelector((state) => state.reportSlice.Page);

  //state
  const [loading, setLoading] = useState(false);

  const addReportPageMutation = useMutation({
    mutationFn: (obj) => addNewWidget(obj),
  });

  //api
  const addNewWidget = async (obj) => {
    setLoading(true);
    await APICall(
      "post",
      `${EndPoints.reporting_dashboards}${Page?.uuid}/widgets/`,
      obj
    )
      .then((response) => {
        if (response.status === 201 && response.data) {
          let result = response.data;
          let _widgets = [...(Page.widgets || [])].sort(
            (a, b) => a.position - b.position
          );
          if (!obj?.isWidgetsAlreadyAdded) {
            _widgets.push(result);
            commonRef?.current?.updatePageWidgets({
              widgets: _widgets,
              updateLayout: true,
              isAdd: true,
            });
          } else {
            _widgets.splice(result?.position - 1, 0, result);
            commonRef?.current?.updatePageWidgets({
              widgets: _widgets,
              updateLayout: true,
              updateBatchPosition: true,
            });
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  //functions
  const onAdd = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      setLoading(true);
      let isWidgetsAlreadyAdded = Page?.widgets?.filter(
        (o1) => o1?.type === item?.type
      );

      let obj = {
        type: item?.type,
        width: item?.width,
        height: item?.height,
        start_date: item?.start_date || null,
        end_date: item?.end_date || null,
        position: (Page?.widgets?.length || 0) + 1,
        isWidgetsAlreadyAdded: isWidgetsAlreadyAdded?.length > 0,
      };
      if (isWidgetsAlreadyAdded?.length > 0) {
        const maxPosition = isWidgetsAlreadyAdded?.reduce(
          (max, { position }) => Math.max(max, position),
          0
        );
        obj.position = maxPosition + 1;
      }

      addReportPageMutation.mutate(obj);
    }
  };

  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "18rem",
        height: "13rem",
        mb: "1rem",
        position: "relative",
        borderRadius: "4px !important",
        backgroundColor: Color.tailwind.slate[50],
        "&:hover": {
          "& #add_button": {
            display: "flex !important",
            cursor: "pointer",
          },
        },
      }}
    >
      <div
        style={{
          height: "60%",
        }}
      >
        {!item?.comingSoon ? null : (
          <p
            style={{
              fontFamily: Fonts.Text,
              fontSize: "1rem",
              fontWeight: 600,
              color: Color.tailwind.slate[700],
            }}
          >
            {t("Coming_Soon")}
          </p>
        )}
        <img
          src={Images?.[item?.type]}
          alt={item?.type}
          width={"100%"}
          height={"100%"}
          loading={"lazy"}
          decoding={"async"}
          referrerPolicy={"no-referrer"}
          style={{
            paddingTop: "1rem",
            paddingInline: "1rem",
            objectFit: "cover",
            objectPosition: "left Top",
          }}
        />
      </div>
      <div
        style={{
          height: "40%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: Color.white,
          position: "relative",
          paddingInline: "1rem",
          paddingBottom: "0.5rem",
        }}
      >
        <p
          style={{
            fontFamily: Fonts.Text,
            fontSize: "0.9rem",
            fontWeight: 600,
            color: Color.tailwind.slate[700],
            marginBlock: "0.7em",
          }}
        >
          {t(item?.type)}
        </p>
        <CategoryChain widgetCategoryList={item?.categories} />
        {item?.comingSoon ? null : (
          <Button
            loading={loading}
            onClick={onAdd}
            id="add_button"
            style={{
              display: loading ? "flex" : "none",
              color: Color.tailwind.slate[700],
              fontSize: "0.8rem",
              fontWeight: 600,
              fontFamily: Fonts.Text,
              borderRadius: 16,
              border: `1px solid ${Color.tailwind.slate[700]}`,
              width: "fit-content",
              marginRight: "0.25rem",
              padding: "0.4rem",
              lineHeight: "0.8rem",
              height: "1.8rem",
              position: "absolute",
              bottom: "0.5rem",
              right: "0.5rem",
              backgroundColor: Color.white,
            }}
          >
            {loading ? "" : t("Add")}
          </Button>
        )}
      </div>
    </Card>
  );
};

const CategoryChain = ({ widgetCategoryList }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {widgetCategoryList?.map((item) => {
        return (
          <div
            key={item}
            style={{
              color: Color.tailwind.slate[400],
              fontSize: "0.6rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              borderRadius: 4,
              border: `1px solid ${Color.tailwind.slate[400]}`,
              width: "fit-content",
              marginRight: "0.25rem",
              padding: "0.15rem",
              lineHeight: "0.6rem",
              marginBottom: "0.25rem",
              backgroundColor: Color.white,
            }}
          >
            {t(item)}
          </div>
        );
      })}
    </div>
  );
};

const LeftView = ({ widgetCategory, setWidgetCategory }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onChange = (e, item) => {
    let _widgetsCategory = [];

    if (e.target.value === "All") {
      if (widgetCategory?.length === ReportCategory?.length) {
        _widgetsCategory = [];
      } else {
        _widgetsCategory = AllReportCategory;
      }
    } else {
      if (widgetCategory.includes(e.target.value)) {
        _widgetsCategory = widgetCategory?.filter(
          (item) => item !== e.target.value
        );
      } else {
        _widgetsCategory = [...widgetCategory, e.target.value];
      }
    }
    setWidgetCategory(_widgetsCategory);
  };

  const onClickCard = () => {
    window.open(`https://finban.io/finanzielle-metriken/`, "_blank");
  };

  const onClickLink = () => {
    window.location.href = `mailto:support@finban.io?subject=${t("report_widget_mailto_sub_msg")}`;
  };

  return (
    <Box
      sx={{
        width: "45%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "1.5rem",
        backgroundColor: Color.tailwind.slate[50],
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CheckItemView
          item={{ id: "All", title: "All" }}
          key={"All"}
          checked={widgetCategory?.length === ReportCategory?.length}
          onChange={onChange}
        />
        {ReportCategory?.map((item) => {
          return (
            <CheckItemView
              item={item}
              key={item?.title}
              checked={widgetCategory?.includes(item?.title)}
              onChange={onChange}
            />
          );
        })}
      </div>
      <div>
        <UpgradePlanCard
          onClick={onClickCard}
          title="report_add_btn_link_title"
          description="report_add_btn_link_desc"
          showButton={false}
          sx={{
            height: "9rem",
            marginBottom: "1rem",
            boxShadow: "none",
            border: `1px solid ${theme.palette.color.violet[400]}`,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: `${theme.palette.color.violet[100]} !important`,
            },
          }}
        />
        <Button onClick={onClickLink} sx={{ width: "fit-content" }}>
          <Typography
            sx={{
              color: Color.themeColor,
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              fontWeight: 500,
              textAlign: "left",
              textDecoration: "underline",
            }}
          >
            {t("Check our Metrics Page")}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};

const CheckItemView = ({ item, checked, onChange }) => {
  const { t } = useTranslation();
  const count =
    item?.title === "All"
      ? ReportWidgetOptions?.length
      : ReportWidgetOptions?.filter((o1) =>
          o1.categories?.includes(item?.title)
        )?.length;
  return (
    <FormControlLabel
      label={`${t(item?.title)} (${count})`}
      sx={{
        mt: "0.5rem",
        "& .MuiTypography-root": {
          fontWeight: 400,
          fontFamily: Fonts.Text,
          fontSize: "0.9rem",
          color: Color.tailwind.slate[700],
        },
        "& .MuiCheckbox-root.Mui-checked": {
          color: "transparent",
        },
        "& .MuiSvgIcon-root": {
          fill: Color.tailwind.slate[700],
        },
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e, item)}
          value={item?.title}
          size="small"
          sx={{
            color: Color.tailwind.slate[700],
            py: "0.2rem",
          }}
        />
      }
    />
  );
};

const AddButtonView = ({ commonRef, listAnchor }) => {
  const { t } = useTranslation();
  const [widgetCategory, setWidgetCategory] = useState(AllReportCategory);
  const [anchorEl, setAnchorEl] = useState(null);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );

  const RightView = useMemo(() => {
    return (
      <Box
        sx={{
          width: "60%",
          display: "inline",
          height: "100%",
          overflowY: "auto",
          flexDirection: "column",
          alignItems: "end",
          p: "1.5rem",
          ...thinScrollbarStyle,
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
        }}
      >
        {ReportWidgetOptions?.map((item, index) => {
          if (
            !beta_enabled &&
            (item.type === "profitability" ||
              item.type === "burn-rate-12-month")
          ) {
            return null;
          }
          if (
            widgetCategory?.some((_category) =>
              item?.categories?.includes(_category)
            )
          ) {
            return (
              <FilterWidgetView key={index} item={item} commonRef={commonRef} />
            );
          } else {
            return null;
          }
        })}
      </Box>
    );
  }, [widgetCategory, beta_enabled, commonRef]);

  if (!Page) return null;

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const onClickPopOver = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      onClose={onClosePopOver}
      button={
        <AddButton
          onClick={onClickPopOver}
          tooltipLabel={t("Add Widget")}
          label={t("Add Widget")}
          doNotListenKey={!!listAnchor}
          isNKey
        />
      }
    >
      <Box
        sx={{
          width: "42rem",
          height: "70vh",
          display: "flex",
          overflowY: "auto",
        }}
      >
        <LeftView
          widgetCategory={widgetCategory}
          setWidgetCategory={setWidgetCategory}
        />
        {RightView}
      </Box>
    </CustomPopover>
  );
};

const ManagePages = ({ listAnchor, setListAnchor }) => {
  const pageListOverlayRef = useRef(null);

  //functions
  const onClickManage = () => {
    setListAnchor(pageListOverlayRef.current);
  };

  const handleClosePopOver = () => {
    setListAnchor(null);
  };
  return (
    <>
      <ActionButton
        ref={pageListOverlayRef}
        onClick={onClickManage}
        label={"Manage Pages"}
        buttontype="manage2"
        sx={{ mr: "1rem" }}
        className="reports_step_0"
      />
      <ReportsPagesOverlay
        anchorEl={listAnchor}
        handleClosePopOver={handleClosePopOver}
      />
    </>
  );
};
