import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { Box } from "@mui/material";

import AvailableView from "./AvailableView";
import { Color } from "../../../Helper";

const DataSource = () => {
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  if (!isAllHeaderApiFetched) {
    return null;
  }
  return (
    <Box
      sx={{
        backgroundColor: Color.appThemeBg,
        position: "relative",
        overflowY: "auto",
        height: "100%",
        pt: "3.5rem",
        pl: "90px",
        // maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AvailableView defaultSection={10} />
      <Outlet />
    </Box>
  );
};

export default DataSource;
