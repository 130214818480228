import {
  ListItemText,
  Typography,
  ListItem,
  Skeleton,
  useTheme,
  Divider,
  Popover,
  Tooltip,
  Button,
  Drawer,
  alpha,
  Grid,
  List,
  Tabs,
  Box,
  Tab,
} from "@mui/material";
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  subYears,
  format,
} from "date-fns";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { MdCompareArrows } from "react-icons/md";
import { BsFillTagsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";
import { PiPlusBold } from "react-icons/pi";
import { useNavigate } from "react-router";
import { IoUnlink } from "react-icons/io5";
import enUS from "date-fns/locale/en-US";
import { TbList } from "react-icons/tb";
import de from "date-fns/locale/de";

import {
  getAllTransactionsByParams,
  calculatePercentageChange,
  getTransactionByParams,
  thinScrollbarStyle,
  getDefaultFilters,
  getTailwindColor,
  formatAmount,
  truncate,
  remToPx,
} from "../../Helper/data";
import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
  setPopupStatus,
} from "../../store/slices/datasets";
import SearchFilterView from "../Overlay/ThemeFilter/Component/SearchFilterView";
import useUpdateEffect from "../../hooks/4-useUpdateEffect/useUpdateEffect";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import CustomPopoverScratch from "../PopOver/CustomPopover";
import { setRefreshData } from "../../store/slices/appmain";
import { updateFilters } from "../../store/slices/global";
import { Color, Constant, Fonts } from "../../Helper";
import Translate from "../../hooks/HOC/Translate";
import initialData from "./../../Helper/data";
import NoDataInfoView from "./NoDataInfoView";
import useWidth from "../../hooks/useWidth";
import AddPresetCard from "./AddPresetCard";
import ScenarioChip from "../ScenarioChip";
import { queryClient } from "../../App";
import StateChip from "../StateChip";
import IconView from "./IconView";
import store from "../../store";
import Icon from "../Icon";

const tabs = [
  {
    value: "Booked",
    label: "Booked",
    tooltip: "tooltip_Booked_tab",
  },
  {
    value: "Open",
    label: "Open Positions",
    tooltip: "tooltip_Open_tab",
  },
  {
    value: "Planned",
    label: "Planning Values",
    tooltip: "tooltip_Planned_tab",
  },
];

const bookedState = initialData?.bookedState?.filter(
  (o2) => o2 !== "Invoice paid"
);
const othersState = [...bookedState, ...initialData?.plannedState];
const employee = initialData?.recurringTypeTabs.find(
  (o1) => o1.value === "employee"
);
const loan = initialData?.recurringTypeTabs.find((o1) => o1.value === "loan");
const leasing = initialData?.recurringTypeTabs.find(
  (o1) => o1.value === "leasing"
);
const DrawerModal = () => {
  const transactions = useRef({
    Booked: [],
    Booked_count: 0,
    Open: [],
    Open_count: 0,
    Planned: [],
    Planned_count: 0,
  });
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const budgetRef = useRef(null);
  const oldUuid = useRef(null);
  let cellInfo = useRef(null);
  let isListUpdated = useRef(false);
  let isListExpanded = useRef(false);
  let showExpandButton = useRef(false);
  let allData = useRef([]);

  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentWidth = useWidth();
  const theme = useTheme();
  //redux
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );

  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );

  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const [searchText, setSearchText] = useState("");

  const cell = popupStatus2?.payload?.cell;
  const _color =
    scenarioById?.[highlightedScenarios?.[0]]?.[0]?.color || "grey";
  const HighlightedColor = getTailwindColor(_color, 50);

  let category_title = cell?.rowId;
  if (
    cell?.rowId === "uncategorized" ||
    cell?.rowId === "uncategorizedInflow" ||
    cell?.rowId === "uncategorizedOutflow"
  ) {
    category_title = "UnCategorized";
  } else {
    category_title = selectionCategoriesByID?.[cell?.rowId]?.[0]?.label;
  }

  if (cell?.isStaffView) {
    category_title = cell?.staffName;
  }

  //state
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(true);

  //api
  const getDataByUrl = async (url) => {
    if (cell?.page_size) {
      const data = await getTransactionByParams({
        dataset: dataSetData?.uuid,
        category_dataset: use_global_categories ? null : dataSetData?.uuid,
        is_reconciled: false,
        order_by: "-due_date",
        url,
      });
      return data?.results || [];
    } else {
      return await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        category_dataset: use_global_categories ? null : dataSetData?.uuid,
        is_reconciled: false,
        order_by: "-due_date",
        url,
      });
    }
  };

  //functions
  const updateOverlay = (params) => {
    isListUpdated.current =
      params?.from_edit_form || params?.from_reconcile_overlay;

    setLoading(true);
    let url = "&is_split=false&order_by=-due_date&detailed=1";
    let url2 = "";

    if (cell.transaction_type === "income") {
      url = url + "&min_gross_value=0";
    }
    if (cell.transaction_type === "expense") {
      url = url + "&max_gross_value=-0.001";
    }

    if (cell?.state) {
      const _state = stateByTitle?.[cell?.state]?.[0]?.uuid;
      if (_state) {
        url = url + `&state=${_state}`;
      }
    }
    if (cell?.scenario) {
      const _scenario = scenarioByTitle?.[cell?.scenario]?.[0]?.uuid;
      if (_scenario) {
        url = url + `&scenario=${_scenario}`;
      }
    }
    if (cell?.page_size) {
      url = url + `&page_size=${cell.page_size}`;
    }
    if (cell?.title) {
      url = url + `&similar_title=${cell.title}`;
    }
    if (cell?.hasOwnProperty("is_recurred")) {
      url = url + `&is_recurred=${cell.is_recurred}`;
    }
    if (cell?.source) {
      url = url + `&source=${cell.source}`;
    }
    if (cell?.data_source) {
      url = url + `&data_source=${cell.data_source}`;
    }
    if (cell?.contact) {
      url = url + `&contact=${cell.contact}`;
    }
    if (cell?.recurring_rule?.length > 0) {
      cell?.recurring_rule?.forEach((id) => {
        url = url + `&recurring_rule=${id}`;
      });
    }
    if (cell?.multiState?.length > 0) {
      cell?.multiState?.forEach((id) => {
        url = url + `&state=${id}`;
      });
    }
    if (cell?.multiScenario?.length > 0) {
      cell?.multiScenario?.forEach((id) => {
        url = url + `&scenario=${id}`;
      });
    }
    if (cell?.multiCostUnit?.length > 0) {
      cell?.multiCostUnit?.forEach((id) => {
        url = url + `&cost_unit=${id}`;
      });
    }
    if (cell?.multiCategory?.length > 0) {
      cell?.multiCategory?.forEach((element) => {
        url = url + `&category=${element}`;
      });
    }
    if (cell?.includesNullCategory) {
      url = url + `&categorized=unknown`;
    }
    if (cell?.rowId) {
      if (
        cell?.rowId === "uncategorized" ||
        cell?.rowId === "uncategorizedInflow" ||
        cell?.rowId === "uncategorizedOutflow"
      ) {
        url = url + "&categorized=false";
      } else {
        url = url + `&category=${cell?.rowId}`;
      }
    }
    url2 = url;
    if (cell?.date) {
      const start_date = format(
        startOfMonth(new Date(cell?.date)),
        "yyyy-MM-dd"
      );
      const end_date = format(endOfMonth(new Date(cell?.date)), "yyyy-MM-dd");
      url =
        url +
        `&from_payment_date=${start_date}&to_payment_date=${end_date}&missing_date=false`;
    }
    if (cell?.end_date) {
      const end_date = format(new Date(cell?.end_date), "yyyy-MM-dd");
      url = url + `&to_payment_date=${end_date}`;
    }
    if (cell?.start_date) {
      const start_date = format(new Date(cell?.start_date), "yyyy-MM-dd");
      url = url + `&from_payment_date=${start_date}`;
    }
    if (cell?.dateRange) {
      const start_date = format(
        new Date(dataSetData?.start_date),
        "yyyy-MM-dd"
      );
      const end_date = format(
        endOfMonth(addMonths(new Date(start_date), Constant.column_count)),
        "yyyy-MM-dd"
      );

      url =
        url +
        `&from_payment_date=${start_date}&to_payment_date=${end_date}&missing_date=false`;
    }
    if (cell?.withUrl) {
      if (cell?.fetchPastOpen) {
        Constant?.openState?.forEach((title) => {
          const id = stateByTitle?.[title]?.[0]?.uuid;
          url2 = url2 + `&state=${id}`;
        });
        url2 =
          url2 +
          `&from_payment_date=${format(
            startOfMonth(subMonths(new Date(), Constant.Past_Month)),
            "yyyy-MM-dd"
          )}&to_payment_date=${format(
            endOfMonth(subMonths(new Date(cell?.date), 1)),
            "yyyy-MM-dd"
          )}`;
        getDataByUrl(url2).then((results1) => {
          getDataByUrl(url).then((results) => {
            allData.current = [...results1, ...results];
            updateList(allData.current);
          });
        });
      } else {
        getDataByUrl(url).then((results) => {
          allData.current = results;

          updateList(allData.current);
        });
      }
    }
    if (isListUpdated.current) {
      setTimeout(() => {
        resetKanban();
      }, 0);
    }
  };

  const updateList = (results) => {
    let ref_array = [];
    let array = [];
    let rest_array = [];
    let includedRecId = [];

    let _results =
      popupStatus2?.payload?.overlayTab === "category"
        ? results?.reverse()
        : results;
    _results?.forEach((o1) => {
      if (
        o1.recurring_rule ? !includedRecId.includes(o1.recurring_rule) : true
      ) {
        if (o1.recurring_rule) {
          includedRecId.push(o1.recurring_rule);
        }
        let position = scenarioById?.[o1?.scenario_uuid]?.[0]?.position;
        if (popupStatus2?.payload?.overlayTab === "category") {
          ref_array.push({
            ...o1,
            scenario_position: position,
            highlight: true,
          });
        } else {
          if (initialData.calculationExcludeStates2?.includes(o1.state)) {
            rest_array.push({
              ...o1,
              extra: true,
              scenario_position: position,
            });
          } else {
            if (selected_scenario_uuid?.includes(o1.scenario_uuid)) {
              ref_array.push({
                ...o1,
                scenario_position: position,
                highlight: true,
              });
            } else {
              array.push({ ...o1, rest: true, scenario_position: position });
            }
          }
        }
      }
    });
    if (ref_array?.length === 0) {
      ref_array.push({
        error: t("No transactions in this scenario found"),
        scenario_position:
          scenarioById?.[selected_scenario_uuid?.[0]]?.[0]?.position || 0,
        highlight: true,
      });
    }
    ref_array.sort((a, b) => b.scenario_position - a.scenario_position);
    rest_array.sort((a, b) => a.scenario_position - b.scenario_position);
    array.sort((a, b) => a.scenario_position - b.scenario_position);
    let data = [...ref_array, ...array, ...rest_array];
    if (searchText?.length > 0) {
      data = data?.filter((o1) =>
        o1?.title?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    }
    if (popupStatus2?.payload?.overlayTab === "category") {
      transactions.current = {
        category: data,
      };
    } else {
      const Booked = data?.filter((o1) => bookedState?.includes(o1.state));
      const Open = data?.filter((o1) => !othersState.includes(o1.state));
      const Planned = data?.filter((o1) =>
        initialData?.plannedState.includes(o1.state)
      );
      transactions.current = {
        Booked,
        Booked_count: Booked?.filter((o1) => o1.highlight && !o1.error)?.length,
        Open,
        Open_count: Open?.filter((o1) => o1.highlight && !o1.error)?.length,
        Planned,
        Planned_count: Planned?.filter((o1) => o1.highlight && !o1.error)
          ?.length,
      };
    }
    updateSelectedTab();
    setLoading(false);
  };

  const updateSelectedTab = () => {
    if (tab && transactions.current[tab]) {
      const lessData = transactions.current[tab]?.filter((o1) => !o1.extra);
      if (lessData?.length === transactions.current[tab]?.length) {
        isListExpanded.current = true;
        showExpandButton.current = false;
      } else {
        isListExpanded.current = false;
        showExpandButton.current = true;
      }
      setTransactionList(lessData);
    } else if (cellInfo.current?.overlayTab) {
      setTab(cellInfo.current?.overlayTab);
    } else if (cellInfo.current?.openTab) {
      if (
        transactions.current?.Booked?.length === 0 &&
        transactions.current?.Open?.length === 0 &&
        transactions.current?.Planned?.length === 0
      ) {
        setTab("Planned");
      } else {
        setTab(cellInfo.current?.openTab);
      }
      cellInfo.current.openTab = false;
    } else if (transactions.current?.Booked?.length > 0) {
      setTab("Booked");
    } else if (transactions.current?.Open?.length > 0) {
      setTab("Open");
    } else if (transactions.current?.Planned?.length > 0) {
      setTab("Planned");
    } else {
      handleChange("", false);
    }
  };

  const handleChange = (e, value) => {
    setTab(value);
  };

  const onClose = () => {
    store.dispatch(setPopupStatus2(null));
    // if (isListUpdated.current) {
    //   resetKanban();
    // }
  };

  const onClickList = (item) => {
    oldUuid.current = item?.uuid;
    let obj = {
      ...item,
      due_date:
        item?.due_date && item?.due_date !== "Invalid date"
          ? format(new Date(item?.due_date), "yyyy-MM-dd")
          : null,
      invoice_date:
        item?.invoice_date && item?.invoice_date !== "Invalid date"
          ? format(new Date(item?.invoice_date), "yyyy-MM-dd")
          : null,
    };

    store.dispatch(
      setTransactionsOverlayStatus({
        open: true,
        overlay_type: "drawer_modal_edit_form",
        anchor: "right",
        payload: {
          modalType: "edit",
          transaction_type: cell?.transaction_type,
          updateOverlay: updateOverlay,
          hideEditFormTabBar: cell?.hideEditFormTabBar,
          isStaffView: cell?.isStaffView,
          item: obj,
        },
      })
    );
  };

  const onClickReconcile = (e) => {
    store.dispatch(
      setPopupStatus({
        id: "simple-popper",
        from: "column_reconcile",
        open: true,
        payload: {
          updateOverlay: updateOverlay,
          type: "reconcile_overlay",
          startDate: cell?.isCurrentMonth
            ? subYears(new Date(cell?.date), 2)
            : cell?.date,
          endDate: cell?.date,
          withUrl: true,
          title: t("Reconciliation"),
        },
      })
    );
  };

  const resetKanban = async () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    dispatch(setRefreshData(Date.now()));
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
  };

  const getConnectedScenarios = ({ _scenario }) => {
    let array = [];
    array.push(_scenario?.uuid);
    function checkScenario(ref_scenario) {
      if (ref_scenario) {
        const _ref_scenario = scenarioById?.[ref_scenario]?.[0];
        array.push(_ref_scenario?.uuid);

        checkScenario(_ref_scenario?.reference);
      }
    }
    checkScenario(_scenario?.reference);
    return array;
  };

  const onClickButton = () => {
    if (transactions.current[tab]?.length === transactionList?.length) {
      isListExpanded.current = false;
      setTransactionList(transactions.current[tab]?.filter((o1) => !o1.extra));
    } else {
      isListExpanded.current = true;
      setTransactionList(transactions.current[tab]);
    }
  };

  const onClickLink = () => {
    window.open(
      "https://finban.io/documentation/ist-zahlen-offene-posten-und-planwerte/",
      "_blank"
    );
    // if (budgetRef.current) {
    //   budgetRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const selected_scenario_uuid = useMemo(() => {
    if (highlightedScenarios) {
      const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
      return getConnectedScenarios({ _scenario });
    } else {
      return [];
    }
  }, [highlightedScenarios, scenarioById]);

  //lifeCycle
  useEffect(() => {
    if (cell && open) {
      cellInfo.current = { ...popupStatus2?.payload };
      updateOverlay();
    }
  }, [cell, open]);

  useDebounce(
    () => {
      updateList(allData.current);
    },
    500,
    [searchText]
  );

  //lifeCycle
  useEffect(() => {
    if (popupStatus2?.open && popupStatus2?.overlay_type === "drawer_modal") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [popupStatus2?.open, popupStatus2?.overlay_type]);

  useUpdateEffect(() => {
    if (tab && open) {
      isListExpanded.current = false;
      const lessData = transactions.current[tab]?.filter((o1) => !o1.extra);
      if (lessData?.length === transactions.current[tab]?.length) {
        showExpandButton.current = false;
      } else {
        showExpandButton.current = true;
      }
      setTransactionList(lessData);
    }
  }, [tab]);

  //render Function
  const TransactionsViewList = ({ index, style }) => {
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    let item = transactionList[index];
    const _scenario_color =
      scenarioByTitle?.[item?.scenario]?.[0]?.color || "slate";

    const color = getTailwindColor(
      stateByTitle?.[item?.state]?.[0]?.color || "slate",
      700
    );

    const recurring_Obj = recurring_rulesById?.[item?.recurring_rule]?.[0];

    const start_date = item?.recurring_rule
      ? recurring_Obj?.start_date
        ? format(new Date(recurring_Obj?.start_date), "MMM yy", {
            locale: profile?.locale === "de_DE" ? de : enUS,
          })
        : null
      : item?.due_date
        ? format(new Date(item?.due_date), "dd MMM yy", {
            locale: profile?.locale === "de_DE" ? de : enUS,
          })
        : null;
    const end_date =
      item?.recurring_rule && recurring_Obj?.end_date
        ? format(new Date(recurring_Obj?.end_date), "MMM yy")
        : null;
    let top = style?.top;

    if (item.rest) {
      top = style?.top + 30;
    }
    if (item.extra) {
      top = style?.top + 50;
    }
    let isLast = false;
    if (item.highlight && !isLast) {
      let nextItem = transactionList[index + 1];
      if (!nextItem?.highlight || !nextItem) {
        isLast = true;
      } else {
        isLast = false;
      }
    }
    const isLate =
      (item.recurring_rule
        ? new Date(recurring_Obj?.start_date)
        : new Date(item?.due_date)) < new Date() &&
      !initialData.calculationExcludeStates.includes(item?.state) &&
      !initialData.bookedState.includes(item?.state) &&
      !initialData.plannedState.includes(item?.state);
    const isStaff =
      item?.contact && recurring_Obj?.category && recurring_Obj?.employee_type;
    const isFixedInterestLoan = recurring_Obj?.recurring_type === "loan";
    const isLeasing = recurring_Obj?.recurring_type === "leasing";
    return (
      <ListItem
        style={{ ...style, top: top }}
        onClick={item?.error ? undefined : () => onClickList(item, index)}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          width: style?.width,
          cursor: item?.error ? "text" : "pointer",
          pl: "0.5rem",
          minHeight: "2.5rem",
          // borderRadius: "10px",
          borderBottomLeftRadius: isLast ? 10 : 0,
          borderBottomRightRadius: isLast ? 10 : 0,
          position: "relative",
          backgroundColor: item?.highlight ? HighlightedColor : Color.white,
          "&:hover": {
            backgroundColor: _scenario_color?.includes("#")
              ? getTailwindColor(_scenario_color, 100)
              : alpha(getTailwindColor(_scenario_color, 100), 0.85),
          },
          "& .itemText": {
            display: "flex",
            justifyContent: item?.error ? "center" : "left",
            alignItems: item?.error ? "center" : "left",
            color: color,
            "& span": {
              // width: "fit-content",
              fontWeight: 600,
              fontSize: "0.85rem",
              fontFamily: Fonts.Text,
              overflow: "hidden",
              whiteSpace: "pre",
              textOverflow: "ellipsis",
            },
          },
        }}
      >
        {item?.error ? null : (
          <Grid item xs={2.5} sx={{ pl: "1.5rem" }}>
            <Tooltip title={isLate ? t("Late") : ""} placement="top">
              <Box
                sx={{
                  py: "0.5rem",
                  px: "0.5rem",
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: Fonts?.Text,
                  fontSize: "0.7rem",
                  fontWeight: 600,
                  backgroundColor: Color.white,
                  borderRadius: 2,
                  textTransform: "initial",
                  color: isLate
                    ? Color.tailwind.red[500]
                    : theme.palette.primary.main,
                }}
              >
                {`${start_date}${end_date ? ` -> ${end_date}` : ""}`}
              </Box>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={item?.error ? 12 : 2.5}>
          <ListItemText
            className={"itemText"}
            primary={item?.error ? item?.error : truncate(item?.title, 17)}
          />
        </Grid>
        {item?.error ? null : (
          <Grid
            item
            xs={2}
            sx={{
              textAlign: "center",
            }}
          >
            <StateChip
              title={item?.state}
              isEmployeeState={isStaff || isLeasing || isFixedInterestLoan}
              hideBorder
              hideIcon
              shade="100"
              width="6rem"
              height="1.6rem"
              fontSize="0.8rem"
            />
          </Grid>
        )}
        {item?.error ? null : (
          <Grid
            item
            xs={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            <ScenarioChip
              title={item?.scenario}
              hideBorder
              shade="100"
              width="6rem"
              height="1.6rem"
              fontSize="0.8rem"
            />
          </Grid>
        )}
        {item?.error ? null : (
          <Grid item xs={2}>
            <ListItemText
              className={"itemText"}
              sx={{
                justifyContent: "flex-end !important",
                color: `${
                  item?.gross_value < 0
                    ? Color.tailwind.red[500]
                    : Color.tailwind.green[500]
                } !important`,
              }}
              primary={formatAmount({
                amount: item?.gross_value,
              })}
            />
          </Grid>
        )}
        {item?.error ? null : (
          <Grid
            item
            xs={1.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              position: "relative",
            }}
          >
            {item.state === "Planned" ||
            dataSetData?.internal_data_source === item?.data_source ? null : (
              <IconView item={item} />
            )}
            {isStaff || isFixedInterestLoan || isLeasing ? (
              <span style={{ color: theme.palette.color.slate[500] }}>
                {isStaff
                  ? employee?.icon
                  : isFixedInterestLoan
                    ? loan?.icon
                    : leasing?.icon}
              </span>
            ) : null}

            {item?.is_note_highlighted ? (
              <div
                id="note-icon"
                style={{
                  borderBottom: `20px solid ${getTailwindColor("amber", 300)}`,
                  borderLeft: `20px solid transparent`,
                  width: 0,
                  height: 0,
                  display: item?.is_note_highlighted ? "flex" : "none",
                  position: "absolute",
                  zIndex: 20,
                  border: "none !important",
                }}
              ></div>
            ) : null}
            {item.reconciled ? (
              <Tooltip arrow placement="top" title={t("Reconciled")}>
                <CompareArrowsIcon
                  sx={{
                    fontSize: "1rem",
                    color: theme.palette.primary.main,
                    position: "absolute",
                    right: -20,
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
        )}
      </ListItem>
    );
  };

  const ITEM_SIZE = remToPx(currentWidth, 3.2);
  let topHeaderHeight = "10rem"; //10rem
  let headerHeight = "7rem"; //7rem
  let footerHeight = "7rem"; //7rem
  let extraSpace = "4rem";

  if (popupStatus2?.payload?.overlayTab === "category") {
    topHeaderHeight = "6rem";
    footerHeight = "0rem";
  }

  const innerElementType = forwardRef(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height)}px`,
      }}
      {...rest}
    />
  ));

  return (
    <Drawer
      onClick={(event) => {
        event.stopPropagation();
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      transitionDuration={300}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }}
      PaperProps={{
        sx: {
          "& .MuiTabs-root": {
            minHeight: "auto",
          },
          borderRadius: 0,
          ...thinScrollbarStyle,
        },
      }}
      SlideProps={{
        in: true,
        unmountOnExit: true,
      }}
      sx={{
        zIndex: 1303,
      }}
    >
      <div
        style={{
          width: "50rem",
          height: "100%",
        }}
      >
        <div
          style={{
            height: `calc(100% - ${footerHeight})`,
            padding: "2rem",
            position: "relative",
          }}
        >
          <TopHeader //10rem
            cell={cell}
            topHeaderHeight={topHeaderHeight}
            category_title={category_title}
            tab={tab}
            transactions={transactions}
            handleChange={handleChange}
            onClickLink={onClickLink}
            onClose={onClose}
          />
          <Header //7rem
            headerHeight={headerHeight}
            transactionList={transactions.current?.[tab]}
            tab={tab}
            selected_scenario_uuid={selected_scenario_uuid}
            onClickReconcile={onClickReconcile}
            updateOverlay={updateOverlay}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "100%",
                height: `calc(100vh - ${footerHeight} - ${topHeaderHeight} - ${headerHeight} - 4rem)`,
              }}
            >
              {Array.from({ length: 6 }).map((item, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={"2rem"}
                  sx={{ my: "0.5rem" }}
                />
              ))}
            </Box>
          ) : transactions.current?.[tab]?.length === 0 ? (
            <Box
              sx={{
                backgroundColor: HighlightedColor,
                width: transactionList?.length > 12 ? "99.7%" : "100%",
                height: `calc(100vh - ${footerHeight} - ${topHeaderHeight} - ${headerHeight} - 4rem)`,
                overflow: "auto",
              }}
            >
              <NoDataInfoView
                tab={tab}
                updateOverlay={updateOverlay}
                disabled={popupStatus2?.payload?.cell?.hasChildren}
                hideButton
                wrapperStyle={{
                  backgroundColor: "transparent",
                  width: tab === "Planned" ? "auto" : "90%",
                }}
              />
            </Box>
          ) : (
            <Grid
              container
              sx={{
                display: "inline-flex",
                justifyContent: "center",
              }}
            >
              <List
                ref={listRef}
                sx={{
                  padding: "0px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: `calc(100vh - ${footerHeight} - ${topHeaderHeight} - ${headerHeight} - ${extraSpace} - 4rem)`,
                  position: "relative",
                  overflow: "hidden",
                  "& .task-list": {
                    ...thinScrollbarStyle,
                  },
                }}
              >
                {transactionList?.length > 0 ? (
                  <Box
                    sx={{
                      backgroundColor: HighlightedColor,
                      width: "100%",
                      display: "inline-flex",
                      alignItems: "center",
                      cursor: "pointer",
                      pl: "0.5rem",
                      pt: "0.5rem",
                      height: "3rem",
                      position: "relative",
                      "& .itemText": {
                        alignItems: "left",
                        "& span": {
                          fontWeight: 600,
                          fontSize: "0.85rem",
                          fontFamily: Fonts.Text,
                          overflow: "hidden",
                          whiteSpace: "pre",
                          textOverflow: "ellipsis",
                        },
                      },
                    }}
                  >
                    <Grid item xs={2.5} sx={{ pl: "1.5rem" }}>
                      <ListItemText
                        className={"itemText"}
                        primary={t("Due Date")}
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <ListItemText
                        className={"itemText"}
                        primary={t("Title")}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <ListItemText
                        className={"itemText"}
                        primary={t("State")}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <ListItemText
                        className={"itemText"}
                        primary={t("Scenario")}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "right" }}>
                      <ListItemText
                        className={"itemText"}
                        primary={t("Amount")}
                      />
                    </Grid>
                    <Grid item xs={1.85}></Grid>
                  </Box>
                ) : null}
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 2rem)",
                  }}
                >
                  <AutoSizer>
                    {({ height, width }) => (
                      <FixedSizeList
                        innerElementType={innerElementType}
                        itemCount={transactionList?.length || 0}
                        itemSize={ITEM_SIZE}
                        // height={HEIGHT}
                        // width={"100%"}
                        height={height}
                        width={width}
                        className="task-list"
                      >
                        {TransactionsViewList}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                </div>
              </List>
              {showExpandButton.current ? (
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={onClickButton}
                    size="medium"
                    sx={{
                      fontFamily: Fonts.Text,
                      fontWeight: 600,
                      width: "fit-content",
                      height: "fit-content",
                      textTransform: "initial",
                    }}
                  >
                    {isListExpanded.current
                      ? t("right_list_overlay_show_less_link_text")
                      : t("right_list_overlay_show_more_link_text")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          )}
        </div>
        {tab && !loading && cell?.showBudgetStatistics ? (
          <BudgetPlanStatistics //7rem
            footerHeight={footerHeight}
            transactions={transactions.current}
            tab={tab}
            ref={budgetRef}
            cell={cell}
          />
        ) : null}
      </div>
    </Drawer>
  );
};

export default DrawerModal;

const TopHeader = ({
  onClickLink,
  onClose,
  handleChange,
  cell,
  category_title,
  tab,
  transactions,
  topHeaderHeight,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //redux
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: topHeaderHeight,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "1.1rem",
          fontWeight: 500,
          textTransform: "uppercase",
          display: "flex",
          justifyContent:
            cell?.date || cell?.showDateRange || cell?.isStaffView
              ? "space-between"
              : "flex-end",
          alignItems: "center",
          width: "100%",
          color: Color.tailwind.slate[500],
        }}
      >
        {cell?.date
          ? format(new Date(cell?.date), "MMMM yyyy", {
              locale: profile?.locale === "de_DE" ? de : enUS,
            })
          : ""}
        {cell?.showDateRange && cell?.start_date
          ? format(new Date(cell?.start_date), "MMM yy", {
              locale: profile?.locale === "de_DE" ? de : enUS,
            })
          : ""}
        {cell?.showDateRange && cell?.end_date
          ? ` -> ${format(new Date(cell?.end_date), "MMM yy", {
              locale: profile?.locale === "de_DE" ? de : enUS,
            })}`
          : ""}
        {cell?.isStaffView ? t("Staff") : ""}
        <CloseIcon
          onClick={onClose}
          sx={{
            cursor: "pointer",
            color: Color.tailwind.slate[500],
          }}
        />
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "2rem",
          fontWeight: 700,
          display: "block",
          color: theme.palette.primary.main,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {category_title}
      </Typography>

      {popupStatus2?.payload?.overlayTab === "category" ? null : (
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          aria-label="wrapped label tabs"
          sx={{
            mt: "1rem",
            "& .MuiTabs-flexContainer": {
              alignItems: "center",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },

            "& .MuiButtonBase-root": {
              minHeight: "auto",
              flex: "1 1 auto",
              // minWidth: "31%",
              fontSize: "1.3rem",
              mr: "1.3rem",
              color: theme.palette.color.slate[600],
              fontFamily: Fonts.Text,
              textTransform: "initial",
              borderRadius: theme.borderRadius.main,
              p: "0.5rem",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[50],
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.2rem",
              },
            },
            ".Mui-selected": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary[50],
              fontWeight: 600,
              "&:hover": {
                backgroundColor: theme.palette.primary[50],
              },
            },
          }}
        >
          {tabs?.map((item) => {
            return (
              <Tab
                key={item?.value}
                value={item?.value}
                label={
                  <Tooltip arrow title={t(item?.tooltip)} placement="top">
                    <span style={{ position: "relative" }}>
                      {`${t(item?.label)}(${
                        transactions.current[`${item?.value}_count`] || 0
                      })`}
                    </span>
                  </Tooltip>
                }
              />
            );
          })}
          <HelpOutlineIcon
            onClick={onClickLink}
            sx={{
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
          />
        </Tabs>
      )}
    </div>
  );
};

const ButtonView = ({
  onClick,
  disabled,
  label,
  icon,
  sx,
  hideOutline,
  tooltip,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip arrow title={tooltip ? t(tooltip) : ""} placement="top">
      <Button
        onClick={onClick}
        disabled={disabled}
        sx={{
          px: "0.4rem",
          py: "0.25rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "fit-content",
          border: `2px solid ${
            disabled
              ? Color.tailwind.slate[300]
              : theme.palette.color.slate[700]
          }`,
          backgroundColor: hideOutline
            ? theme.palette.color.slate[700]
            : Color.white,
          color: hideOutline ? Color.white : theme.palette.color.slate[700],
          borderRadius: theme.borderRadius.main,
          fontFamily: Fonts.Text,
          fontSize: "0.8rem",
          lineHeight: "1rem",
          textTransform: "initial",
          "&:hover": {
            backgroundColor: hideOutline
              ? theme.palette.color.slate[700]
              : theme.palette.primary[50],
          },
          ...sx,
        }}
      >
        <Icon
          icon={icon}
          fontSize={"0.9rem"}
          color={
            disabled
              ? Color.tailwind.slate[300]
              : hideOutline
                ? Color.white
                : theme.palette.color.slate[700]
          }
          style={{
            marginRight: "0.25rem",
          }}
        ></Icon>

        {t(label)}
      </Button>
    </Tooltip>
  );
};

const AddOverlay = ({ disabled, tab, updateOverlay, backgroundColor }) => {
  return (
    <CustomPopoverScratch
      button={
        <ButtonView
          disabled={disabled}
          label={"Add"}
          icon={<PiPlusBold />}
          sx={{ mb: "0.5rem", ml: "0.5rem" }}
        />
      }
    >
      <AddOverlayView
        tab={tab}
        updateOverlay={updateOverlay}
        disabled={disabled}
        backgroundColor={backgroundColor}
      />
    </CustomPopoverScratch>
  );
};

const AddOverlayView = ({
  disabled,
  tab,
  updateOverlay,
  popup_state,
  backgroundColor,
}) => {
  const { t } = useTranslation();

  //redux
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);

  const cell = popupStatus2?.payload?.cell;
  let categoryId = null;
  if (
    cell?.rowId !== "uncategorized" &&
    cell?.rowId !== "uncategorizedInflow" &&
    cell?.rowId !== "uncategorizedOutflow"
  ) {
    categoryId = cell.rowId;
  }

  return (
    <Box
      sx={{
        p: "1.5rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: backgroundColor,
      }}
    >
      <AddPresetCard
        disabled={disabled}
        tab={tab}
        transaction_type={cell?.transaction_type}
        preset_type="AddTransaction"
        due_date={cell?.date}
        categoryId={categoryId}
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        minCardHeight={"7rem"}
      />
      <Divider
        textAlign="left"
        sx={{
          width: "100%",
          mt: "1.5rem",
          mb: "0.5rem",
          fontFamily: Fonts.Text,
          fontWeight: 500,
          fontSize: "0.8rem",
          color: getTailwindColor("slate", 300),
        }}
      >
        {t("Preset")}
      </Divider>
      <AddPresetCard
        disabled={disabled}
        transaction_type={cell?.transaction_type}
        preset_type="RecurringPlanning"
        due_date={cell?.date}
        categoryId={categoryId}
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        minCardHeight={"7rem"}
      />
      {cell?.transaction_type === "expense" ? (
        <AddPresetCard
          preset_type="EmployeePlanning"
          popup_state={popup_state}
          disabled={disabled}
          transaction_type={cell?.transaction_type}
          due_date={cell?.date}
          categoryId={categoryId}
          updateOverlay={updateOverlay}
          minCardHeight={"7rem"}
        />
      ) : null}

      <AddPresetCard
        disabled={disabled}
        transaction_type={cell?.transaction_type}
        preset_type="CategoryRecurringPlanning"
        due_date={cell?.date}
        categoryId={categoryId}
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        minCardHeight={"7rem"}
      />
      <AddPresetCard
        disabled={disabled}
        transaction_type={cell?.transaction_type}
        preset_type="RevenueRecurringPlanning"
        due_date={cell?.date}
        categoryId={categoryId}
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        minCardHeight={"7rem"}
      />
      {cell?.transaction_type !== "expense" ? (
        <AddPresetCard
          disabled={disabled}
          transaction_type={cell?.transaction_type}
          preset_type="SubscriptionPlanning"
          due_date={cell?.date}
          categoryId={categoryId}
          popup_state={popup_state}
          updateOverlay={updateOverlay}
          minCardHeight={"7rem"}
        />
      ) : null}
    </Box>
  );
};

const Header = ({
  headerHeight,
  transactionList,
  onClickReconcile,
  selected_scenario_uuid,
  tab = false,
  updateOverlay,
  searchText,
  setSearchText,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  //redux
  const state = useSelector((state) => state.globalSlice.state);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];

  const cell = popupStatus2?.payload?.cell;

  const [total, setTotal] = useState(0);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    let value = transactionList
      ?.filter(
        (item) =>
          item.highlight &&
          (cell?.calculateAllState
            ? true
            : !initialData.calculationExcludeStates2.includes(item.state))
      )
      ?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.gross_value ?? 0),
        0
      );

    setTotal(value);
  }, [cell?.calculateAllState, transactionList]);

  const onClickFilter = () => {
    let category_id = cell?.rowId;

    if (
      cell?.rowId === "uncategorized" ||
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
    ) {
      category_id = null;
    }
    let _path = "bulk";
    let _states = [];

    state.forEach((item) => {
      if (tab === "Booked" && initialData.bookedState.includes(item?.title)) {
        _states.push(item?.uuid);
      }
      if (tab === "Planned" && initialData.plannedState.includes(item?.title)) {
        _states.push(item?.uuid);
      }
      if (
        tab === "Open" &&
        !initialData.bookedState.includes(item?.title) &&
        !initialData.plannedState.includes(item?.title)
      ) {
        _states.push(item?.uuid);
      }
    });
    const defaultFilter = getDefaultFilters();
    let obj = {
      ...defaultFilter?.list,
    };
    if (cell?.date) {
      obj.start_date = format(startOfMonth(new Date(cell?.date)), "yyyy-MM-dd");
      obj.end_date = format(endOfMonth(new Date(cell?.date)), "yyyy-MM-dd");
    }
    if (cell?.end_date) {
      obj.end_date = format(new Date(cell?.end_date), "yyyy-MM-dd");
    }
    if (cell?.start_date) {
      obj.start_date = format(new Date(cell?.start_date), "yyyy-MM-dd");
    }
    if (cell?.multiState?.length > 0) {
      obj.selectedStates = cell?.multiState;
    } else {
      obj.selectedStates = [];
    }
    if (cell?.multiScenario?.length > 0) {
      obj.selectedStates = cell?.multiScenario;
    } else {
      obj.selectedScenarios = [];
    }

    if (category_id) {
      obj.selectedCategory = [category_id];
      obj.selectedScenarios = selected_scenario_uuid;
      obj.selectedStates = _states;
    } else {
      // obj.bulk_list_states = _states;
    }
    if (cell?.multiCategory?.length > 0) {
      obj.selectedCategory = cell?.multiCategory;
    } else {
      obj.selectedCategory = [];
    }
    if (tab === "Booked") {
      _path = "bank";
    } else if (tab === "Open") {
      _path = "open";
      obj.selectedOpenStates = cell?.multiState;
    } else if (tab === "Planned") {
      _path = "budget";
    } else if (tab === "category") {
      if (cell?.listViewPath === "open") {
        obj.selectedOpenStates = cell?.multiState;
      }
      _path = cell?.listViewPath || "bulk";
    }
    dispatch(
      updateFilters({
        parent_key: "list",
        obj: obj,
      })
    );
    dispatch(setPopupStatus2(null));
    setTimeout(() => {
      navigate(`/${initialData.path.organization}/${dataset}/list/${_path}`);
    }, 0);
  };

  const onClickToCategorized = () => {
    dispatch(setPopupStatus2(null));
    navigate(`/${initialData.path.organization}/${dataset}/list/bulk`);
  };

  const onClickClear = () => {
    setSearchText("");
    onClickSearch();
  };

  const onChangeSearch = (e) => {
    if (e.target.value === "") {
      onClickSearch();
    }
    setSearchText(e.target.value);
  };

  const onClickSearch = () => {
    setShowSearch((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: headerHeight,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        backgroundColor: getTailwindColor(_scenario?.color, 100),
        py: "1rem",
        mb: "-0.5rem",
        px: "1.5rem",
        fontSize: "1.6rem",
        color: theme.palette.color.slate[700],
        fontWeight: 600,
      }}
    >
      <div
        style={{
          fontFamily: Fonts.Text,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
        }}
      >
        {formatAmount({
          amount: isNaN(total) || !total ? 0 : total ?? 0,
        })}
        {tab ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "68%",
              flexWrap: "wrap",
              position: "absolute",
              right: "0.5rem",
              top: "0.25rem",
              justifyContent: "flex-end",
              color: theme.palette.color.slate[700],
            }}
          >
            {showSearch ? (
              <SearchFilterView
                active={true}
                value={searchText}
                color={"slate"}
                onClickClear={onClickClear}
                onChange={onChangeSearch}
                sx={{ mr: "0.5rem" }}
                width={"18rem"}
              />
            ) : null}
            {!showSearch ? (
              <ButtonView
                onClick={onClickSearch}
                label={"Search"}
                icon={<SearchIcon sx={{ fontSize: "1rem" }} />}
                sx={{ mb: "0.5rem", ml: "0.5rem" }}
              />
            ) : null}
            {!showSearch && cell?.showToCategorizeViewFilter ? (
              <ButtonView
                onClick={onClickToCategorized}
                label={"cell_list_view_please_categorize"}
                icon={<BsFillTagsFill />}
                sx={{ ml: "0.5rem", mb: "0.5rem" }}
                hideOutline
              />
            ) : null}
            {!showSearch &&
            tab !== "Planned" &&
            popupStatus2?.payload?.overlayTab !== "category" ? (
              <ButtonView
                onClick={onClickReconcile}
                label={"Reconcile"}
                icon={<MdCompareArrows />}
                sx={{ mb: "0.5rem", ml: "0.5rem" }}
              />
            ) : null}
            {showSearch ||
            popupStatus2?.payload?.hideAccessInListViewFilter ? null : (
              <ButtonView
                onClick={onClickFilter}
                label={"Access in Listview"}
                icon={<TbList />}
                sx={{ ml: "0.5rem", mb: "0.5rem" }}
              />
            )}
            {!showSearch && popupStatus2?.payload?.overlayTab !== "category" ? (
              <AddOverlay
                disabled={popupStatus2?.payload?.cell?.hasChildren}
                updateOverlay={updateOverlay}
                tab={tab}
                backgroundColor={getTailwindColor(_scenario?.color, 100)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {popupStatus2?.payload?.hideScenarioChain ? null : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0.5rem",
          }}
        >
          <Tooltip arrow title={t("Scenario reference")} placement="top">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8rem",
                marginTop: "1rem",
                width: "fit-content",
              }}
            >
              {selected_scenario_uuid?.map((item, index) => {
                const _scenario_ = scenarioById?.[item]?.[0];
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={_scenario_?.uuid}
                  >
                    <ScenarioChip
                      title={_scenario_?.title}
                      hideBorder
                      shade="200"
                      width="fit-content"
                      height="1.4rem"
                      fontSize="0.8rem"
                    />
                    {selected_scenario_uuid?.length - 1 !== index ? (
                      <Icon
                        icon={<IoUnlink />}
                        fontSize={"1.1rem"}
                        color={
                          theme.palette.color?.[
                            _scenario?.color || "slate"
                          ]?.[600]
                        }
                        style={{ marginInline: "0.5rem" }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

const BudgetPlanStatistics = forwardRef(
  ({ transactions, tab, footerHeight }, ref) => {
    const { t } = useTranslation();
    const popupStatus2 = useSelector(
      (state) => state.datasetSlice?.popupStatus2
    );
    const cell = popupStatus2?.payload?.cell;

    const [value, setValue] = useState({
      booked: 0,
      open: 0,
      late: 0,
      total: 0,
      budget: 0,
      change: 0,
      isGreater: false,
      color: undefined,
      isChip: "",
    });

    useDebounce(
      () => {
        if (tab && transactions) {
          let booked = 0;
          let open = 0;
          let late = 0;
          let budget = 0;
          let isChip = "";

          if (cell?.isCurrentMonth) {
            let lateData = [];
            let openData = [];
            transactions?.Open?.forEach((item) => {
              if (item?.highlight && item?.gross_value) {
                if (new Date(item?.due_date) < startOfMonth(new Date())) {
                  lateData.push(item);
                } else {
                  openData.push(item);
                }
              }
            });
            late = lateData?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.gross_value ?? 0),
              0
            );
            open = openData?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.gross_value ?? 0),
              0
            );
          }
          if (cell?.isFutureMonth) {
            open = transactions?.Open?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(item?.highlight ? item?.gross_value ?? 0 : 0),
              0
            );
          } else {
            booked = transactions?.Booked?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(item?.highlight ? item?.gross_value ?? 0 : 0),
              0
            );
          }

          budget = transactions?.Planned?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(item?.highlight ? item?.gross_value ?? 0 : 0),
            0
          );
          let total = Number(booked) + Number(open);
          let isGreater = Math.abs(total) > Math.abs(budget);
          let change = null;
          let diff = null;
          if (
            transactions?.Planned?.length > 0 &&
            cell.transaction_type === "income"
          ) {
            change = calculatePercentageChange(budget, total);
            if (budget === 0 && total !== 0) {
              change = 100;
            }
          }
          if (
            transactions?.Planned?.length > 0 &&
            cell.transaction_type === "expense"
          ) {
            change = calculatePercentageChange(budget, total);
            if (budget === 0 && total !== 0) {
              change = -100;
            }
          }

          if (cell?.isPastMonth) {
            isChip = "booked";
          }
          if (cell?.isCurrentMonth) {
            isChip = isGreater ? "booked-open" : "budget";
            if (!isGreater) {
              diff = (budget || 0) - (booked || 0) + Number(late);
              budget = (budget || 0) + Number(late);
            } else {
              diff = (open || 0) + Number(late);
              total = (total || 0) + Number(late);
            }
          }
          if (cell?.isFutureMonth) {
            isChip = isGreater ? "open" : "budget";
          }
          setValue({
            booked: booked?.toFixed(0),
            open: open?.toFixed(0),
            late: late?.toFixed(0),
            budget: budget?.toFixed(0),
            total: total?.toFixed(0),
            isChip,
            change,
            isGreater,
            diff,
          });
        }
      },
      200,
      [tab],
      true
    );
    return (
      <Box
        ref={ref}
        sx={{
          backgroundColor: Color.tailwind.slate[50],
          width: "100%",
          height: footerHeight,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          px: "2rem",
          py: "1rem",
          "& b": {
            display: "contents",
          },
        }}
      >
        {" "}
        <CardItem
          label={"Booked"}
          label2={
            cell?.isPastMonth
              ? "budget_footer_booked_past_label"
              : cell?.isCurrentMonth
                ? "budget_footer_booked_current_label"
                : "budget_footer_booked_future_label"
          }
          // label2={<Translate i18nkey={"Paid this month"} />}
          value={value?.booked}
          change={value?.change}
          isGreater={value?.isGreater}
          showUnderLine={tab === "Booked"}
          disabled={cell?.isFutureMonth}
          isChip={value.isChip === "booked"}
          cell={cell}
        />
        <b>+</b>
        <CardItem
          label={"Open"}
          late={cell?.isCurrentMonth ? value?.late : null}
          label2={
            cell?.isPastMonth
              ? "budget_footer_open_past_label"
              : cell?.isCurrentMonth
                ? "budget_footer_open_current_label"
                : "budget_footer_open_future_label"
          }
          // label2={<Translate i18nkey={"Due this month"} />}
          value={value?.open}
          change={value?.change}
          isGreater={value?.isGreater}
          showUnderLine={tab === "Open"}
          isChip={value.isChip === "open"}
          cell={cell}
        />
        <b>=</b>
        <CardItem
          label={
            cell?.isCurrentMonth
              ? `${t("Booked")} + ${t("Open")}`
              : cell?.isPastMonth
                ? "Booked"
                : "Open"
          }
          label2={
            cell?.isFutureMonth
              ? value?.isGreater > 0
                ? "budget_footer_open_booked_future_higher_label"
                : "budget_footer_open_booked_future_lower_label"
              : ""
          }
          tooltip={
            cell?.isCurrentMonth
              ? "budget_footer_open_booked_current_tooltip_label"
              : ""
          }
          value={value?.total}
          diff={value?.diff}
          change={value?.change}
          isGreater={value?.isGreater}
          isChip={value.isChip === "booked-open"}
          cell={cell}
          valueObj={value}
        />
        <b>{value?.isGreater ? ">" : "<"}</b>
        <CardItem
          label={"Budget"}
          label2={
            cell?.isPastMonth
              ? "budget_footer_budget_past_label"
              : cell?.isCurrentMonth
                ? "budget_footer_budget_current_label"
                : value?.isGreater > 0
                  ? "budget_footer_budget_future_lower_label"
                  : "budget_footer_budget_future_higher_label"
          }
          lateLabel={
            cell?.isCurrentMonth &&
            !value?.isGreater &&
            value?.late &&
            value?.late !== 0
          }
          tooltip={
            cell?.isCurrentMonth
              ? "budget_footer_budget_current_tooltip_label"
              : ""
          }
          value={value?.budget}
          change={value?.change}
          diff={value?.diff}
          isGreater={value?.isGreater}
          isChip={value.isChip === "budget"}
          cell={cell}
          showUnderLine={tab === "Planned"}
          valueObj={value}
        />
      </Box>
    );
  }
);

const CardItem = ({
  label,
  label2,
  tooltip,
  late,
  value,
  change,
  isGreater,
  color = "grey",
  showUnderLine = false,
  isChip = false,
  cell,
  disabled,
  lateLabel,
  valueObj,
}) => {
  const isBigger = isChip & cell?.isCurrentMonth;
  const formattedValue = formatAmount({
    amount: value,
    hidePrefix: true,
  });

  const _late = formatAmount({
    amount: late,
    hidePrefix: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (tooltip && isBigger) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getFormateValue = (value) => {
    return formatAmount({
      amount: value,
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginInline: "0.5rem",
        width: "21%",
        position: "relative",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <div
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderRadius: "4px",
          paddingBlock: "0.5rem",
          paddingRight: "0.5rem",
          backgroundColor: getTailwindColor(color, 100),
          border: `2px solid ${
            isChip ? getTailwindColor(color, 600) : "transparent"
          }`,
        }}
      >
        <div
          style={{
            display: "flow-root",
            flexDirection: "row",
            fontFamily: Fonts.Text,
            fontSize: "0.75rem",
            paddingLeft: "0.25rem",
            lineHeight: "0.7rem",
            textAlign: "right",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: getTailwindColor(color, 600),
            fontWeight: isChip ? 700 : 500,
          }}
        >
          {formattedValue}

          {late && late !== 0 ? (
            <span
              style={{
                display: "inline-flex",
                fontFamily: Fonts.Text,
                fontSize: "0.75rem",
                lineHeight: "0.7rem",
                textAlign: "right",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                marginLeft: "0.25rem",
                color: getTailwindColor("red", 600),
                fontWeight: 500,
              }}
            >{`(${_late})`}</span>
          ) : (
            ""
          )}
        </div>
        {isChip && change?.toString() ? (
          <span>
            <PercentageChangeChip
              cell={cell}
              percentageChange={change}
              isGreater={isGreater}
              isBigger={isBigger}
            />
          </span>
        ) : null}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 1.5,
              boxShadow: Constant.OverlayBoxShadow,
            },
          },
        }}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: "1rem",
            width: "20rem",
            fontSize: "0.9rem",
            fontFamily: Fonts.Text,
            color: getTailwindColor("purple", 600),
          }}
        >
          {tooltip ? (
            <Translate
              i18nkey={tooltip}
              values={{
                actual_budget_total: getFormateValue(
                  (valueObj?.budget || 0) - (valueObj?.late || 0)
                ),
                final_budget_total: getFormateValue(valueObj?.budget || 0),
                late: getFormateValue(valueObj?.late || 0),
                calculated_budget_total: getFormateValue(
                  (valueObj?.diff || 0) - (valueObj?.late || 0)
                ),
                final_calculated_budget_total: getFormateValue(
                  valueObj?.diff || 0
                ),

                actual_total: getFormateValue(
                  (valueObj?.total || 0) - (valueObj?.late || 0)
                ),
                final_total: getFormateValue(valueObj?.total || 0),
                calculated_total: getFormateValue(
                  (valueObj?.diff || 0) - (valueObj?.late || 0)
                ),
                final_calculated_total: getFormateValue(valueObj?.diff || 0),
              }}
            />
          ) : null}
        </Typography>
      </Popover>

      <LabelView
        label={label}
        label2={label2}
        color={color}
        late={late}
        tooltip={tooltip}
        showUnderLine={showUnderLine}
        isBigger={isBigger}
        valueObj={valueObj}
        lateLabel={lateLabel}
      />
    </div>
  );
};

const LabelView = ({
  label,
  label2,
  late,
  tooltip,
  isBigger,
  color = "grey",
  showUnderLine,
  lateLabel,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <span
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          whiteSpace: "break-spaces",
          marginTop: "0.25rem",
          fontFamily: Fonts.Text,
          fontSize: "1rem",
          fontWeight: 700,
          textDecoration: showUnderLine ? "underline" : undefined,
          color: getTailwindColor(color, 600),
        }}
      >
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          {label ? <Translate i18nkey={label} /> : null}{" "}
          {/* {isBigger ? (
            <LabelPopOver tooltip={tooltip} valueObj={valueObj} />
          ) : null} */}
        </span>
      </span>

      <Tooltip title={label2 ? <Translate i18nkey={label2} /> : ""} arrow>
        <span
          style={{
            width: "100%",
            borderRadius: "0.3rem",
            paddingBlock: "0.1rem",
            display: "inline-flex",
            fontFamily: Fonts.Text,
            fontSize: "0.6rem",
            lineHeight: "0.6rem",
            fontWeight: 500,
            color: getTailwindColor(color, 600),
          }}
        >
          {label2 ? <Translate i18nkey={label2} /> : null}
          {(late && late !== 0) || lateLabel ? (
            <b
              style={{
                color: getTailwindColor("red", 600),
              }}
            >
              {lateLabel ? ` + ${t("Late")}` : ` (${t("Late")})`}
            </b>
          ) : null}
        </span>
      </Tooltip>
    </>
  );
};

const PercentageChangeChip = ({
  percentageChange,
  cell,
  isGreater,
  isBigger,
}) => {
  let color = "slate";
  let bgShade = 100;
  let colorShade = 500;
  let change =
    !isNaN(percentageChange) && percentageChange ? Number(percentageChange) : 0;
  if (cell.transaction_type === "income") {
    if (isGreater) {
      color = "emerald";
      bgShade = 100;
      colorShade = 600;
    } else {
      if (cell.isPastMonth) {
        color = "orange";
        bgShade = 100;
        colorShade = 600;
      } else {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      }
    }
  }
  if (cell.transaction_type === "expense") {
    if (isGreater) {
      color = "red";
      bgShade = 100;
      colorShade = 600;
    } else {
      if (cell.isPastMonth) {
        color = "lime";
        bgShade = 100;
        colorShade = 600;
      } else {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      }
    }
  }

  if (percentageChange === 0) {
    color = "slate";
    bgShade = 100;
    colorShade = 500;
  }

  const chipStyle = {
    borderRadius: 4,
    backgroundColor: getTailwindColor(color, bgShade),
    color: getTailwindColor(color, colorShade),
    width: "fit-content",
    height: "100%",
    fontSize: change?.toString()?.length > 3 || isBigger ? "0.7rem" : "0.9rem",
    fontFamily: Fonts.Text,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingInline: "6px",
    marginLeft: "0.5rem",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return <div style={chipStyle}>{change}%</div>;
};
