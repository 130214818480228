import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import _ from "underscore";

import {
  setIsAccountSelectOverlayOpen,
  setIsFirstLoginOverlayOpen,
  setIsUpdatesOverlayOpen,
  setTransactionSystem,
  setIsDsUuidLoading,
  setRecurring_rules,
  setAppliedFilters,
  setOnBoardingList,
  setJoyRideStatus,
  setDataSource,
  setAdvanceVat,
  setCostUnits,
  setScenario,
  setAccounts,
  setContacts,
  setFilters,
  setStates,
  setBanks,
  setVats,
} from "../store/slices/global";
import {
  setHighlightedScenarios,
  setDataSetData,
  setdatasetList,
} from "../store/slices/board";
import ReconcileListViewModal from "../Pages/Datasets/Transactions/Components/ReconcileListViewModal";
import AccountsSelectOverlay from "../Pages/Settings/DataSource/AccountsSelectOverlay";
import AddTransactionModal from "../Pages/Datasets/Kanban/Overlay/AddTransactionModal";
import GroupSettingsModal from "../Pages/Datasets/Kanban/Overlay/GroupSettingsModal";
import DrawerModalEditForm from "../components/TableEditModal/DrawerModalEditForm";
import { setStageLoadingText, setFirstLoading } from "../store/slices/appmain";
import { getAllTransactionsByParams, updateLanguage } from "../Helper/data";
import FinApiOverlay from "../Pages/Datasets/Kanban/Overlay/FinApiOverlay";
import CategoryTreeSelect from "../components/Overlay/CategoryTreeSelect";
import RecurOverlay from "../Pages/Datasets/Kanban/Overlay/RecurOverlay";
import useUpdateEffect from "../hooks/4-useUpdateEffect/useUpdateEffect";
import ConfirmationModal from "../components/Model/ConfirmationModal";
import DrawerModal from "../components/TableEditModal/DrawerModal";
import registerServiceWorker from "./../serviceWorkerRegistration";
import { setIsAllHeaderApiFetched } from "../store/slices/common";
import { setAddFrom, setProfile } from "../store/slices/settings";
import SettingForm from "../Pages/Datasets/Kanban/SettingForm";
import PlanExpiredAlert from "../components/PlanExpiredAlert";
import useDebounce from "../hooks/3-useDebounce/useDebounce";
import { setCategories } from "../store/slices/category";
import GlobalDSFetchSystem from "./GlobalDSFetchSystem";
import MuiSnackbar from "../components/MuiSnackbar";
import StageLoader from "../components/StageLoader";
import SpinLoader from "../components/Spinner";
import EndPoints from "../APICall/EndPoints";
import authSlice from "../store/slices/auth";
import packageJson from "../../package.json";
import Loader from "../components/Loader";
import { queryClient } from "../App";
import APICall from "../APICall";

const defaultJoyrideOptions = [
  {
    data_type: "transaction",
    title: "joyride",
    filters: {
      header: false,
      dataSetList: false,
      dataSource: false,
      dashboard_overview: false,
      list: false,
      kanban: false,
    },
  },
];

const CommonView = () => {
  return (
    <>
      <GlobalFunctions />
      <GlobalDSFetchSystem />
      <StageLoader />
      <ChatWidget />
      <GlobalSpinner />
      <MuiSnackbar />
      <PlanExpiredAlert />
      <AccountsSelectOverlay />
      <FinApiOverlay />
      <PopupOverlay />
      <DrawerModalListView />
      <DrawerModalEditFormView />
    </>
  );
};
export default CommonView;

//transactions form
const DrawerModalListView = () => {
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);

  return (
    <>
      {popupStatus2?.open && popupStatus2?.overlay_type === "drawer_modal" && (
        <DrawerModal />
      )}
    </>
  );
};

const DrawerModalEditFormView = () => {
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  return (
    <>
      {transactionsOverlayStatus?.open &&
        transactionsOverlayStatus?.overlay_type ===
          "drawer_modal_edit_form" && <DrawerModalEditForm />}
    </>
  );
};

//global finban support chat widget
const ChatWidget = () => {
  const location = useLocation();
  const scriptRef = useRef(null);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const showHeader =
    profile?.email &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");

  // const REACT_APP_CHAT_BOT_URL = useMemo(() => {
  //   return showHeader && process.env.REACT_APP_CHAT_BOT_URL
  //     ? (function (d, w, c) {
  //         w.BrevoConversationsID = process.env.REACT_APP_CHAT_BOT_ID;
  //         w[c] =
  //           w[c] ||
  //           function () {
  //             (w[c].q = w[c].q || []).push(arguments);
  //           };
  //         var s = d.createElement("script");
  //         s.async = true;
  //         s.type = "text/javascript";
  //         s.src = process.env.REACT_APP_CHAT_BOT_URL;
  //         if (d.head) {
  //           if (showHeader) {
  //             scriptRef.current = s;
  //             d.head.appendChild(s);
  //           } else {
  //             if (scriptRef.current) {
  //               d.head.removeChild(scriptRef.current);
  //               scriptRef.current = null;
  //             }
  //           }
  //         }
  //       })(document, window, "BrevoConversations")
  //     : null;
  // }, [showHeader]);

  // if (!showHeader) {
  //   const chatScript = document.getElementById("brevo-conversations");
  //   if (chatScript) {
  //     chatScript.remove();
  //   }
  // }
  if (process.env.REACT_APP_INTERCOM_APP_ID && showHeader) {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: profile?.email,
      name: profile?.first_name + " " + profile?.last_name,
      email: profile?.email,
      created_at: Math.floor(
        new Date(profile?.subscription?.start_date).getTime() / 1000
      ),
    });
  }
  // return <script>{REACT_APP_CHAT_BOT_URL}</script>;
  return null;
};

//global spinner
const GlobalSpinner = () => {
  const loading = useSelector((state) => state.appSlice.loading);
  const isFetching = useSelector((state) => state.appSlice?.isFetching);
  const isLoading = useSelector((state) => state.appSlice?.isLoading);
  const isFirstLoading = useSelector((state) => state.appSlice?.isFirstLoading);
  const token = useSelector((state) => state.auth?.token);

  return (
    <>
      <SpinLoader
        loading={
          loading || isFetching || isLoading || (isFirstLoading && token)
        }
        name={isFirstLoading && token ? "ball-clip-rotate-multiple" : undefined}
      />
      <Loader loading={loading || isFetching || isLoading} />
    </>
  );
};

//popup overlay
const PopupOverlay = () => {
  //redux
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);
  const popupStatus4 = useSelector((state) => state.datasetSlice?.popupStatus4);
  return (
    <>
      {popupStatus?.from === "column_add_Transaction" && popupStatus?.open && (
        <AddTransactionModal />
      )}
      {popupStatus?.from === "column_add_recurrence" && popupStatus?.open && (
        <RecurOverlay />
      )}
      {popupStatus?.from === "column_reconcile" && popupStatus?.open && (
        <ReconcileListViewModal />
      )}
      {/* <ReconcileModal /> */}
      {popupStatus2?.from === "card_setting" && <GroupSettingsModal />}
      {/*for delete, warning ,for ask confirmation */}
      {popupStatus3?.open && <ConfirmationModal />}{" "}
      {/*for category selection ,form for state,scenario,cost unit, due date ,invoice date, date ,recurring date range*/}
      {popupStatus4?.open && popupStatus4?.overlay_type === "category" && (
        <CategoryTreeSelect
          anchorEl={popupStatus4?.anchorEl}
          onClickCategoryTitle={popupStatus4?.payload?.onClickCategoryTitle}
          handleClosePopOver={popupStatus4?.payload?.handleClosePopOver}
          allowParentCategory={popupStatus4?.payload?.allowParentCategory}
          hiddenCategory={popupStatus4?.payload?.hiddenCategory}
          ruleTitle={popupStatus4?.payload?.ruleTitle}
          type={popupStatus4?.payload?.type}
          fromSequence={popupStatus4?.payload?.fromSequence}
        />
      )}
      {popupStatus4?.open && popupStatus4?.overlay_type === "form" && (
        <SettingForm
          open={popupStatus4.open}
          onSave={popupStatus4?.payload?.onSave}
          type={popupStatus4?.payload?.type}
          groupedCard={popupStatus4?.payload?.groupedCard}
          fromSequence={popupStatus4?.payload?.fromSequence}
        />
      )}
    </>
  );
};

//global functions
const GlobalFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const last_sync_date = useRef(null);
  const versionCheckInterval = useRef(null);
  const alreadyStoredFilters = useRef(null);
  const isFirstFetch = useRef({
    joyride: true,
    dataset: true,
    filter: true,
  });

  //redux
  const auth = useSelector((state) => state.auth);
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const filters = useSelector((state) => state.globalSlice?.filters);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const appliedFilter = useSelector((state) => state.globalSlice.appliedFilter);
  const refreshBalance = useSelector((state) => state.appSlice?.refreshBalance);

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  //const
  let showHeader =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");

  //api
  const getDataSourceById = async (DSID) => {
    dispatch(setStageLoadingText("Updating Consents .."));
    await APICall("get", EndPoints.integrations + `${DSID}/`).then(
      (response) => {
        if (response.status === 200 && response.data) {
          // if (
          //   last_sync_date.current?.toString() ===
          //   response?.data?.last_sync_date?.toString()
          // ) {
          //   setTimeout(() => {
          //     getDataSourceById(DSID);
          //   }, 3000);
          // } else {
          queryClient.resetQueries({
            queryKey: [
              "integrations",
              {
                data_source: addFrom?.integration_id,
                apiType: "data_source_finapi_connections",
              },
            ],
          });
          setTimeout(() => {
            dispatch(setIsAccountSelectOverlayOpen(null));
            dispatch(setAddFrom(null));
            dispatch(setIsAllHeaderApiFetched(true));
            dispatch(setStageLoadingText(null));
          });
          enqueueSnackbar(t("Consents Updated Successfully"), {
            variant: "success",
            autoHideDuration: 5000,
          });
          // }
        }
      }
    );
  };

  const updateApiKey = async (integration_id, obj) => {
    await APICall(
      "patch",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response) {
        setTimeout(() => {
          dispatch(setIsDsUuidLoading(integration_id));
          dispatch(setAddFrom(null));
          queryClient.resetQueries({
            queryKey: [
              "integrations",
              {
                data_source: integration_id,
                apiType: "data_source_external_connections",
              },
            ],
          });
        }, 500);
      }
    });
  };

  const getFinApiDataSourceConnection = async (DSID) => {
    let status = null;

    await APICall(
      "get",
      EndPoints.integrations + `${DSID}/finapi/connections/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        status = response?.data?.[0]?.status;
        if (status === "IN_PROGRESS") {
          setTimeout(() => {
            getFinApiDataSourceConnection(DSID);
          }, 2000);
        }
        if (status === "COMPLETED") {
          dispatch(setStageLoadingText(null));
          dispatch(
            setIsAccountSelectOverlayOpen({
              open: true,
              bankType: "finapi",
            })
          );
        }
      }
    });
  };

  const updateFilters = async (id, obj) => {
    await APICall("patch", EndPoints.filters + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...filters];
          let index = filters?.findIndex(
            (o1) => o1?.uuid === response.data.uuid
          );
          data[index] = response.data;
          dispatch(setFilters(data));
        }
      }
    );
  };

  const updateToken = async () => {
    let obj = {
      refresh: refreshToken,
    };
    await APICall("post", EndPoints.refreshToken, obj).then((response) => {
      if (response.status === 200 && response.data) {
        let accessToken = response.data.access;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        dispatch(
          authSlice.actions.setAuthTokens({
            token: accessToken,
            refreshToken: refreshToken,
          })
        );
      }
    });
  };

  const updateDataSetByID = async (
    id,
    obj,
    array,
    skipUpdateDataset = false
  ) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          alreadyStoredFilters.current = response.data?.filters;
          let data = [...array];
          let index = array.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
          }
          data.sort((a, b) => (a.position > b.position ? 1 : -1));
          dispatch(setdatasetList(data));
          if (!skipUpdateDataset) {
            dispatch(setDataSetData(response.data));
          }
        }
      }
    );
  };

  const getRecurringRules = async () => {
    const results = await getAllTransactionsByParams({
      endpoint: "recurring_rules",
    });
    if (results) {
      dispatch(setRecurring_rules(results));
    }
  };

  const getFilters = async () => {
    await APICall("get", EndPoints.filters).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        if (data.length === 0) {
          defaultJoyrideOptions.forEach((element, i) => {
            addFilters(element, i === defaultJoyrideOptions.length - 1);
          });
        } else {
          let filterData = data?.find((o1) => {
            return o1?.title === "joyride";
          });
          if (filterData) {
            dispatch(
              setJoyRideStatus({ ...filterData, data_type: "transaction" })
            );
          }
          let filterListData = data?.find((o1) => {
            return o1?.title === "list";
          });
          if (filterListData) {
            dispatch(
              setAppliedFilters({ ...filterListData, data_type: "transaction" })
            );
          }
          dispatch(setFilters(data));
        }
      }
    });
  };

  const addFilters = async (obj, isAllow = false) => {
    await APICall("post", EndPoints.filters, obj).then((response) => {
      if (response.status === 201 && response.data) {
        if (isAllow) {
          getFilters();
        }
      }
    });
  };

  const getMasterData = async () => {
    await APICall("get", EndPoints.master_data).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;

        if (!data?.profile?.first_login) {
          updateLanguage(data?.profile?.locale);
        }
        const base_id = data?.scenarios?.results?.find(
          (o1) => o1.title === "Base"
        )?.uuid;
        dispatch(setHighlightedScenarios([base_id]));
        dispatch(setBanks(data?.banks?.results));
        dispatch(setStates(data?.states?.results));
        dispatch(setScenario(data?.scenarios?.results));
        dispatch(setProfile(data?.profile));
        dispatch(setCostUnits(data?.costunits?.results));
        dispatch(
          setCategories({ data: data?.categories?.results, type: "all" })
        );
        dispatch(setVats(data?.taxes?.results));
        dispatch(setAdvanceVat(data?.tax_configuration));
        dispatch(setAccounts(data?.accounts?.results));
        dispatch(setContacts(data?.contacts?.results));
        dispatch(setDataSource(data?.data_sources?.results));
        dispatch(
          setOnBoardingList(
            data?.todos?.results.filter(
              (o1) => o1.category?.toLowerCase() === "onboarding"
            )
          )
        );
        dispatch(
          setdatasetList(
            data?.datasets?.results.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          )
        );
      }
    });
  };

  const getTransactionSystemUuid = async () => {
    await APICall("get", EndPoints.transaction_systems).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setTransactionSystem(response.data.results));
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  //lifecycle method

  //registerServiceWorker
  useEffect(() => {
    registerServiceWorker();
    // unregisterServiceWorker();
    clearInterval(versionCheckInterval.current);
    versionCheckInterval.current = setInterval(() => {
      checkVersionUpdate();
    }, 60000);

    return () => {
      clearInterval(versionCheckInterval.current);
    };
  }, []);

  //first load
  useEffect(() => {
    const tokenExpire = setInterval(
      () => {
        updateToken();
      },
      29 * 60 * 1000
    );
    global.allowFetch = { Inflow: [], Outflow: [] };
    global.isFirstFetchUuid = [];

    setTimeout(async () => {
      if (refreshToken) {
        queryClient.clear();
        await updateToken();
        await getMasterData();
        await getTransactionSystemUuid();
        await getFilters();
        await getRecurringRules();
        setTimeout(() => {
          dispatch(setIsAllHeaderApiFetched(true));
          dispatch(setFirstLoading(false));
        }, 0);
      } else {
        navigate("/login");
      }
    }, 0);

    return () => {
      clearInterval(tokenExpire);
    };
  }, []);

  //check is user is valid
  useEffect(() => {
    if (showHeader) {
      checkIsUserValid();
    } else {
      updateLanguage();
    }
  }, [showHeader]);

  //check is user is valid
  useDebounce(
    () => {
      window.addEventListener("focus", checkIsUserValid);

      checkIsUserValid();

      return () => {
        window.removeEventListener("focus", checkIsUserValid);
      };
    },
    500,
    [showHeader, location.pathname],
    true
  );

  //check navigation/callback for integrations
  useEffect(() => {
    if (isAllHeaderApiFetched) {
      checkNavigation();
    }
  }, [isAllHeaderApiFetched]);

  //update joyride filters
  useDebounce(
    () => {
      if (
        joyRideStatus?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        if (!isFirstFetch.current?.joyride) {
          updateFilters(joyRideStatus?.uuid, joyRideStatus);
        } else {
          isFirstFetch.current.joyride = false;
        }
      }
    },
    2000,
    [joyRideStatus]
  );

  //update filters
  useDebounce(
    () => {
      if (
        appliedFilter?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        if (!isFirstFetch.current?.filter) {
          updateFilters(appliedFilter?.uuid, appliedFilter);
        } else {
          isFirstFetch.current.filter = false;
        }
      }
    },
    2000,
    [appliedFilter]
  );

  //update dataset filters
  useDebounce(
    () => {
      if (
        dataSetData?.uuid &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !isFirstLoginOverlayOpen &&
        appliedFilterlist &&
        (!alreadyStoredFilters.current ||
          (alreadyStoredFilters.current &&
            !_.isEqual(alreadyStoredFilters.current, appliedFilterlist)))
      ) {
        if (!isFirstFetch.current?.dataset) {
          updateDataSetByID(
            dataSetData?.uuid,
            { filters: appliedFilterlist },
            dataSetList,
            true
          );
        } else {
          isFirstFetch.current.dataset = false;
        }
      }
    },
    2000,
    [appliedFilterlist]
  );

  //refresh balance
  useUpdateEffect(() => {
    getAccountsApi();
  }, [refreshBalance]);

  //check finban version update
  useEffect(() => {
    if (isAllHeaderApiFetched) {
      const version_key = `FINBAN_APP_VERSION_${profile?.email}`;
      let FINBAN_APP_VERSION = localStorage.getItem(version_key);
      if (profile?.first_login) {
        localStorage.setItem(version_key, packageJson?.version);
        checkUserLanguage();
        dispatch(setIsFirstLoginOverlayOpen(true));
      } else {
        const UserVersionArray = FINBAN_APP_VERSION?.split(".");
        const UserMajorVersion1 = UserVersionArray?.[0];
        const UserMajorVersion2 = UserVersionArray?.[1];
        const FinbanVersionArray = packageJson?.version?.split(".");
        const FinbanMajorVersion1 = FinbanVersionArray?.[0];
        const FinbanMajorVersion2 = FinbanVersionArray?.[1];
        let isMajorRelease = false;

        if (
          !FINBAN_APP_VERSION ||
          UserMajorVersion1 !== FinbanMajorVersion1 ||
          UserMajorVersion2 !== FinbanMajorVersion2
        ) {
          isMajorRelease = true;
        }
        if (isMajorRelease) {
          requestAnimationFrame(() => {
            localStorage.setItem(version_key, packageJson?.version);
            dispatch(setIsUpdatesOverlayOpen(true));
          });
        }
      }
    }
  }, [profile?.first_login, isAllHeaderApiFetched]);

  //functions
  const checkUserLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage;
    updateLanguage();
    let splittedLang = userLanguage?.split("-");
    const updatedLang = splittedLang === "de" ? "de_DE" : "en_GB";
    dispatch(
      setProfile({
        ...profile,
        locale: updatedLang,
      })
    );
    const obj = {
      locale: updatedLang,
    };
    updateProfile(obj);
  };

  const checkNavigation = async () => {
    let pathname = window.location.href;

    let defaultPathname = "/settings/Integrations";

    //lexoffice
    if (pathname.includes("client_id") && pathname.includes("state")) {
      if (pathname.includes("error")) {
        if (addFrom?.targetUrl) {
          navigate(addFrom?.targetUrl);
        } else {
          navigate(defaultPathname);
        }
        enqueueSnackbar(t("lexoffice_error_msg"), {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch(setAddFrom(null));
        return;
      }
      if (addFrom) {
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }

    //finapi
    if (pathname.includes("code") && pathname.includes("state")) {
      if (addFrom) {
        let url = new URL(pathname);
        let searchParams = url.searchParams;
        let authorization_code = searchParams.get("code");
        if (addFrom?.integration_id) {
          updateApiKey(addFrom?.integration_id, { authorization_code });
        }
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }
    if (pathname.includes("isFinAPi")) {
      navigate(addFrom?.targetUrl || defaultPathname);

      if (addFrom?.type === "Consents") {
        last_sync_date.current = addFrom?.last_sync_date;
        getDataSourceById(addFrom?.integration_id);
      } else {
        dispatch(setStageLoadingText(t("checking_finApi_account_connection")));
        await getFinApiDataSourceConnection(addFrom?.integration_id);
      }
    }
  };

  const checkIsUserValid = () => {
    if (showHeader) {
      const EMAIL = localStorage.getItem("LoggedInUser");
      if (!profile?.email) {
        window.location.href = "/login";
        return;
      }
      if (!EMAIL) {
        localStorage.setItem("LoggedInUser", profile?.email);
        return;
      }
      if (EMAIL && EMAIL !== profile?.email) {
        localStorage.setItem("LoggedInUser", profile?.email);
        window.location.href = "/dashboard";
        emptyCacheStorage(true, 0);
      }
    }
  };

  const checkVersionUpdate = () => {
    if (
      "serviceWorker" in navigator &&
      process.env.REACT_APP_MODE !== "development"
    ) {
      navigator?.serviceWorker?.ready?.then(async (registration) => {
        try {
          const onLine =
            navigator && "connection" in navigator ? navigator.onLine : true;
          const response = onLine
            ? await fetch("/sw.js", {
                cache: "no-store",
                "cache-control": "no-cache",
              })
            : undefined;
          response && response.status === 200 && (await registration?.update());
        } catch (e) {
          console.log("cannot ping/update sw.js", e);
        }
      });
    }
  };

  const emptyCacheStorage = async (update = false, count = 2000) => {
    if ("caches" in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
        })
      );
      queryClient.clear();
      if (update) {
        setTimeout(() => {
          window.location.replace(window.location.href);
        }, count);
      }
    }
  };
};
