import { Box, Dialog, Fade } from "@mui/material";

import AvailableView from "../../../Pages/Settings/DataSource/AvailableView";
import { thinScrollbarStyle } from "../../../Helper/data";
import { Color } from "../../../Helper";

const IntegrationsDialogView = ({
  open,
  handleClickClose,
  type,
  isDSChanged,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          maxWidth: "none",
          maxHeight: "none",
          paddingInline: "0.2rem",
          width: "auto",
          overflowY: "hidden",
        },
      }}
      sx={{
        zIndex: 1303,
      }}
    >
      <Box
        sx={{
          p: "1rem",
          px: "3rem",
          // maxWidth: "100rem",
          height: "70rem",
          maxHeight: "80vh",
          overflowY: "auto",
          backgroundColor: Color.appThemeBg,
          ...thinScrollbarStyle,
        }}
      >
        <AvailableView
          clickable
          closeAvailableOverlay={handleClickClose}
          type={type}
          defaultSection={type === "assign" ? 10 : 0}
          isDSChanged={isDSChanged}
        />
      </Box>
    </Dialog>
  );
};

export default IntegrationsDialogView;
