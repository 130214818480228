import { alpha, Stack, Tooltip } from "@mui/material";
import { PiArrowElbowDownLeft } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import React from "react";

import TailwindButton from "./TailwindButton";
import theme from "../../theme";

const OverlayFooter = ({
  modalType,
  loading,
  onCancel,
  onClickAction,
  textAdd,
  textCancel,
  disable,
  tooltipAdd,
  addIcon = <EnterIcon />,
  cancelIcon = <EscapeIcon />,
  sx,
  addButtonSx,
  cancelButtonSx,
  hideAddAction,
}) => {
  const { t } = useTranslation();
  const keyEnter = React.useCallback(
    (event) => {
      if (!disable && !loading && event.key === "Enter") {
        event.preventDefault();
        onClickAction(event);
      }
    },
    [disable, loading, onClickAction]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", keyEnter);

    return () => {
      document.removeEventListener("keydown", keyEnter);
    };
  }, [keyEnter]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"flex-end"}
      sx={sx}
    >
      {onCancel ? (
        <TailwindButton
          disableElevation
          onClick={onCancel}
          text={textCancel ? t(textCancel) : t("Cancel")}
          variant="contained"
          type="edit"
          icon={cancelIcon}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            ml: "0rem",
            py: "0.35rem",
            px: "0.75rem",
            border: `1px solid ${theme.palette.color.slate[300]}`,
            ...cancelButtonSx,
          }}
        />
      ) : null}
      {!hideAddAction ? (
        <Tooltip title={t(tooltipAdd)} arrow followCursor placement="top">
          <span>
            <TailwindButton
              disableElevation
              disable={disable}
              onClick={onClickAction}
              text={
                textAdd
                  ? t(textAdd)
                  : modalType === "add"
                    ? t("Add")
                    : t("Update")
              }
              variant="contained"
              loading={loading}
              icon={loading ? undefined : addIcon}
              sx={{
                py: loading ? "0.8rem" : "0.35rem",
                px: "0.75rem",
                display: "flex",
                flexDirection: "row-reverse",
                ...addButtonSx,
              }}
            />
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  );
};
export default OverlayFooter;

const EscapeIcon = () => (
  <span
    style={{
      marginLeft: "0.5rem",
      fontSize: "0.55rem",
      lineHeight: "0.5rem",
      width: "28px",
      height: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.borderRadius.main,
      border: `1px solid ${theme.palette.color.slate[300]}`,
    }}
  >
    {"ESC"}
  </span>
);

const EnterIcon = () => (
  <span
    style={{
      marginLeft: "0.5rem",
      width: "22px",
      height: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.borderRadius.main,
      border: `1px solid ${alpha(theme.palette.color.white, 0.3)}`,
    }}
  >
    <PiArrowElbowDownLeft strokeWidth={10} />
  </span>
);
