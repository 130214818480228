/* eslint-disable import/no-webpack-loader-syntax */
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { useDeferredValue, useEffect, useMemo, useState } from "react";
import Worker from "worker-loader!../../../../workers/worker.js";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { unstable_runWithPriority } from "scheduler";
import Spinner from "react-spinkit";

import {
  setAccountBalanceUpdateDate,
  setMonthlyChartData,
  setDailyChartData,
  setChartLineKeys,
} from "../../../../store/slices/board";
import {
  setIsDailyChartLoading,
  setIsMonthChartLoading,
} from "../../../../store/slices/appmain";
import { getStatisticsDataWithParams } from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { updateKanbanFilters } from "../../../../store/slices/global";
import useStatusHook from "../../../../hooks/useStatusHook";
import { Color, Constant } from "../../../../Helper";
import initialData from "./../../../../Helper/data";
import theme from "../../../../theme";

const CommonView = () => {
  const dispatch = useDispatch();

  //redux
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const state = useSelector((state) => state.globalSlice?.state);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const chartData = useSelector((state) => state.boardSlice?.chartData);
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );
  const isFooterExpanded = useSelector(
    (state) => state.chartSlice?.isFooterExpanded
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );

  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const selectedScenarios = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedScenarios
  );
  const precisionValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.precisionValue
  );
  const scenarioValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.scenarioValue
  );
  const selectedStates = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedStates
  );
  const chart_keys = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.chart_keys
  );

  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isDailyChartLoading = useSelector(
    (state) => state.appSlice.isDailyChartLoading
  );
  const isMonthChartLoading = useSelector(
    (state) => state.appSlice.isMonthChartLoading
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  const updateChart = useSelector((state) => state.boardSlice.updateChart);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === uuid
    );
  }, [_selectionCategories, uuid, use_global_categories]);
  const appliedFilterlist = {
    kanban: {
      scenarioValue: scenarioValue,
      precisionValue: precisionValue,
      selectedScenarios: selectedScenarios,
      selectedStates: selectedStates,
      chart_keys: chart_keys,
    },
  };

  const dataSetData = {
    uuid: uuid,
    start_date: start_date,
    end_date: end_date,
    use_global_categories: use_global_categories,
  };

  const isKanbanGroupFetching = useIsFetching({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "monthly",
        start_date: null,
        end_date: null,
      },
    ],
    exect: true,
  });

  //state
  const [webWorkerMonthly, setWebWorker] = useState(null);
  const [webWorkerDaily, setWebWorkerDaily] = useState(null);
  const allowMonthFetch = isFooterExpanded || isChartExpanded;
  const _to_payment_date = endOfMonth(
    addMonths(new Date(dataSetData?.start_date), Constant.column_count)
  );
  let _start_date = new Date(dataSetData?.start_date);

  const _last_month_date = new Date(startOfMonth(subMonths(new Date(), 1)));

  if (_start_date > _last_month_date) {
    _start_date = _last_month_date;
  }
  const from_payment_date = useDeferredValue(_start_date);
  const to_payment_date = useDeferredValue(_to_payment_date);
  const dataset = useDeferredValue(dataSetData?.uuid);
  let vat_pay_months = 0;
  if (advanceVat && advanceVat?.enabled) {
    if (
      String(advanceVat?.frequency) === "1" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 1;
    }

    if (
      String(advanceVat?.frequency) === "1" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 2;
    }

    if (
      String(advanceVat?.frequency) === "2" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 3;
    }
    if (
      String(advanceVat?.frequency) === "2" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 4;
    }
  } else {
    vat_pay_months = 1;
  }

  //query
  const transaction_monthly_chart = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "transaction_monthly_chart",
        from_date: from_payment_date,
        to_date: to_payment_date,
        subMonthNumber: vat_pay_months,
      },
    ],
    queryFn: () => {
      let param = {
        type: "transaction_monthly_chart",
        dataset: dataset,
        from_date: from_payment_date,
        to_date: to_payment_date,
        subMonthNumber: vat_pay_months,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    priority: 1,
    enabled:
      !!allowMonthFetch &&
      !!dataset &&
      !!from_payment_date &&
      !!to_payment_date &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen,
  });
  let fetching_payment_monthly_statistic =
    transaction_monthly_chart.isFetching ||
    transaction_monthly_chart.isLoading ||
    !transaction_monthly_chart.isSuccess;

  const transaction_daily_chart = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "transaction_daily_chart",
        from_payment_date,
        to_payment_date,
      },
    ],
    queryFn: () => {
      let param = {
        type: "transaction_daily_chart",
        dataset: dataset,
        from_payment_date,
        to_payment_date,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },

    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    backgroundFetch: true,
    priority: 2,
    enabled:
      isChartExpanded &&
      appliedFilterlist?.kanban?.precisionValue === 2 &&
      !fetching_payment_monthly_statistic &&
      !!dataset &&
      !!from_payment_date &&
      !!to_payment_date &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen,
  });

  let fetching_payment_daily_statistic =
    transaction_daily_chart.isFetching ||
    transaction_daily_chart.isLoading ||
    !transaction_daily_chart.isSuccess;

  const isChartInProgress = useMemo(() => {
    return (
      fetching_payment_monthly_statistic ||
      (appliedFilterlist?.kanban?.precisionValue === 2 &&
        fetching_payment_daily_statistic) ||
      isMonthChartLoading ||
      isDailyChartLoading
    );
  }, [
    appliedFilterlist?.kanban?.precisionValue,
    fetching_payment_daily_statistic,
    fetching_payment_monthly_statistic,
    isDailyChartLoading,
    isMonthChartLoading,
  ]);

  const Transaction_monthly_statistic = useStatusHook(
    "Transaction_monthly_statistic"
  );

  const Limit = useMemo(() => {
    const array = datasetAccountList?.filter(
      (o1) =>
        o1.account?.show_limit &&
        o1.account?.datasets?.includes(dataSetData?.uuid) &&
        o1.active
    );
    return array?.map((o1) => o1.account);
  }, [datasetAccountList, dataSetData?.uuid]);

  //life cycle method
  useEffect(() => {
    if (!webWorkerMonthly) setWebWorker(new Worker());
    if (!webWorkerDaily) setWebWorkerDaily(new Worker());
    return () => {
      webWorkerMonthly?.terminate();
      webWorkerDaily?.terminate();
    };
  }, []);

  //updateComposedMonthlyChart
  useDebounce(
    () => {
      if (
        allowMonthFetch &&
        webWorkerMonthly &&
        !isFirstLoginOverlayOpen &&
        !isKanbanGroupFetching &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !fetching_payment_monthly_statistic &&
        !Transaction_monthly_statistic?.isFetching &&
        transaction_monthly_chart?.data &&
        dataSetData?.uuid
      ) {
        unstable_runWithPriority(4, () => {
          updateComposedMonthlyChart();
        });
      }
    },
    500,
    [
      isKanbanGroupFetching,
      isAccountSelectOverlayOpen,
      fetching_payment_monthly_statistic,
      isAllHeaderApiFetched,
      transaction_monthly_chart?.data,
      Transaction_monthly_statistic?.isFetching,
      Transaction_monthly_statistic?.data,
      appliedFilterlist?.kanban?.selectedScenarios,
      appliedFilterlist?.kanban?.scenarioValue,
      Limit,
      allowMonthFetch,
      webWorkerMonthly,
      isFooterExpanded,
      isChartExpanded,
      selectionCategories,
      Constant.column_count,
      dataSetData?.start_date,
    ],
    true
  );

  //updateComposedDailyChart
  useDebounce(
    () => {
      if (
        isChartExpanded &&
        webWorkerMonthly &&
        webWorkerDaily &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        !isKanbanGroupFetching &&
        isAllHeaderApiFetched &&
        !fetching_payment_monthly_statistic &&
        !fetching_payment_daily_statistic &&
        !Transaction_monthly_statistic?.isFetching &&
        subscription?.kanban_daily_chart &&
        appliedFilterlist?.kanban?.precisionValue === 2 &&
        transaction_daily_chart?.data &&
        transaction_monthly_chart?.data
      ) {
        unstable_runWithPriority(5, () => {
          updateComposedDailyChart();
        });
      }
    },
    500,
    [
      isChartExpanded,
      webWorkerMonthly,
      isAccountSelectOverlayOpen,
      webWorkerDaily,
      isKanbanGroupFetching,
      isAllHeaderApiFetched,
      fetching_payment_daily_statistic,
      appliedFilterlist?.kanban?.precisionValue,
      appliedFilterlist?.kanban?.scenarioValue,
      appliedFilterlist?.kanban?.selectedScenarios,
      transaction_monthly_chart?.data,
      Transaction_monthly_statistic?.data,
      Transaction_monthly_statistic?.isFetching,
      transaction_daily_chart?.data,
      subscription?.kanban_daily_chart,
      Limit,
      selectionCategories,
      Constant.column_count,
      dataSetData?.start_date,
    ],
    true
  );

  useDebounce(
    () => {
      if (
        isAllHeaderApiFetched &&
        !isMonthChartLoading &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        appliedFilterlist
      ) {
        dispatch(setChartLineKeys(getChartKeys()));
      }
    },
    200,
    [
      isAccountSelectOverlayOpen,
      isFirstLoginOverlayOpen,
      isMonthChartLoading,
      isAllHeaderApiFetched,
      scenario,
      state,
      Limit,
      chartData,
      updateChart,
      // appliedFilterlist?.kanban?.chart_keys,
    ]
  );

  //function
  // const generateMonthlyChart = (props) => {
  //   let data = JSON.parse(props.data);
  //   let {
  //     Limit,
  //     advanceVat,
  //     selectionCategoriesByID,
  //     scenario,
  //     transaction_monthly_chart,
  //     start_date,
  //     end_date,
  //     booked_balances,
  //     Transaction_monthly_statistic,
  //     includedScenarios,
  //     includedStates,
  //     scenarioByTitle,
  //     selectionCategories,
  //     isDataset,
  //   } = data;

  //   let dummyChartData = [];
  //   let dataSetData = {
  //     start_date: new Date(start_date),
  //     end_date: addMonths(new Date(start_date), Constant.column_count + 1),
  //   };

  //   if (transaction_monthly_chart && selectionCategoriesByID && start_date) {
  //     const groupedMonthlyTransactions = _.groupBy(
  //       Transaction_monthly_statistic,
  //       (item) => item?.month
  //     );
  //     const groupByMonthData = _.groupBy(
  //       transaction_monthly_chart,
  //       (item) => item?.month
  //     );
  //     const groupByMonthBookedBalances = _.groupBy(
  //       booked_balances,
  //       ({ month }) => month
  //     );

  //     const filteredScenarios = scenario?.filter((o1) =>
  //       includedScenarios?.includes(o1.title)
  //     );
  //     const currentMonth = format(new Date(), "yyyy-MM");
  //     const currentDate = format(new Date(), "yyyy-MM-dd");
  //     const vatDueDate = advanceVat?.enabled
  //       ? format(
  //           setDate(new Date(), String(advanceVat?.due_day ?? 1)),
  //           "yyyy-MM-dd"
  //         )
  //       : null;
  //     const getConnectedScenariosUuid = ({ _scenario }) => {
  //       let array = [];
  //       array.push(_scenario?.uuid);
  //       function checkScenario(ref_scenario) {
  //         if (ref_scenario) {
  //           let _ref_scenario = scenarioByTitle?.[ref_scenario]?.[0];
  //           array.push(_ref_scenario?.uuid);
  //           checkScenario(_ref_scenario?.reference);
  //         }
  //       }
  //       checkScenario(_scenario?.reference);
  //       return array;
  //     };
  //     const getConnectedScenarios = ({ _scenario }) => {
  //       let array = [];
  //       array.push(_scenario?.title);
  //       function checkScenario(ref_scenario) {
  //         if (ref_scenario) {
  //           array.push(ref_scenario);
  //           let _ref_scenario = scenarioByTitle?.[ref_scenario]?.[0];
  //           checkScenario(_ref_scenario?.reference);
  //         }
  //       }
  //       checkScenario(_scenario?.reference);
  //       return array;
  //     };

  //     const result = eachMonthOfInterval({
  //       start: new Date(isDataset ? dataSetData?.start_date : start_date),
  //       end: new Date(isDataset ? dataSetData?.end_date : end_date),
  //     });

  //     let LiquidityTotal = {};
  //     let vat_total = {};
  //     let scenario_list_uuid = {};
  //     let scenario_list_title = {};
  //     let netChange = {};
  //     let prev_booked_balance = 0;
  //     let booked_balance = 0;

  //     filteredScenarios?.forEach((scenarioElement) => {
  //       const key = scenarioElement?.title;
  //       let scenarioArrayUuid = getConnectedScenariosUuid({
  //         _scenario: scenarioElement,
  //       });
  //       let scenarioArrayTitle = getConnectedScenarios({
  //         _scenario: scenarioElement,
  //       });
  //       scenario_list_uuid[key] = scenarioArrayUuid;
  //       scenario_list_title[key] = scenarioArrayTitle;
  //     });
  //     result?.forEach((date, index) => {
  //       let monthDate = format(date, "yyyy-MM");
  //       let prevMonthDate = format(subMonths(date, 1), "yyyy-MM");
  //       let monthData = groupByMonthData?.[monthDate]?.[0];

  //       let isFuture = monthDate >= currentMonth;
  //       const isPastMonth = monthDate < currentMonth;
  //       const isCurrentMonth = monthDate === currentMonth;
  //       const isFutureMonth = monthDate > currentMonth;

  //       let subtractVat =
  //         advanceVat?.enabled &&
  //         (monthDate > currentMonth ||
  //           (monthDate === currentMonth && currentDate <= vatDueDate));

  //       prev_booked_balance =
  //         groupByMonthBookedBalances?.[
  //           prevMonthDate > currentMonth ? currentMonth : prevMonthDate
  //         ]?.[0]?.booked_balance || prev_booked_balance;
  //       booked_balance =
  //         groupByMonthBookedBalances?.[
  //           isFutureMonth ? currentDate : monthDate
  //         ]?.[0]?.booked_balance || booked_balance;

  //       let vatData = [];
  //       let bars_data = {};
  //       if (isFuture) {
  //         bars_data = {
  //           income_type_open_bar:
  //             (monthData?.["income_type_open"] || 0) +
  //             (monthData?.["income_type_open_late"] || 0),
  //           expense_type_open_bar:
  //             (monthData?.["expense_type_open"] || 0) +
  //             (monthData?.["expense_type_open_late"] || 0),
  //         };
  //       }

  //       if (monthData) {
  //         bars_data = {
  //           ...bars_data,
  //           ...(monthData
  //             ? Object.keys(monthData)
  //                 .filter((key) =>
  //                   ["-scenario-", "_type_"].some((o1) => key.includes(o1))
  //                 )
  //                 .reduce((result, key) => {
  //                   result[key] = (isPastMonth ? key.includes("booked") : true)
  //                     ? monthData[key]
  //                     : 0;
  //                   return result;
  //                 }, {})
  //             : {}),
  //         };
  //       }
  //       let startVatDate = null;
  //       let endVatDate = null;
  //       let isVatPayMentMonth = false;
  //       let vat_pay_date = 0;

  //       if (advanceVat && advanceVat?.enabled) {
  //         const isMonthly =
  //           String(advanceVat?.frequency) === "1" &&
  //           !advanceVat?.permanent_extension;
  //         const isMonthlyExtended =
  //           String(advanceVat?.frequency) === "1" &&
  //           advanceVat?.permanent_extension;
  //         const isQuarterly =
  //           String(advanceVat?.frequency) === "2" &&
  //           !advanceVat?.permanent_extension;
  //         const isQuarterlyExtended =
  //           String(advanceVat?.frequency) === "2" &&
  //           advanceVat?.permanent_extension;
  //         if (isMonthly) {
  //           const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 1);

  //           startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
  //           endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
  //           vat_pay_date = format(
  //             addMonths(startOfMonth(new Date(endVatDate)), 1),
  //             "yyyy-MM"
  //           );
  //         }
  //         if (isMonthlyExtended) {
  //           const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 2);

  //           startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
  //           endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
  //           vat_pay_date = format(
  //             addMonths(startOfMonth(new Date(endVatDate)), 2),
  //             "yyyy-MM"
  //           );
  //         }
  //         if (isQuarterly) {
  //           const prevQuarter = subQuarters(
  //             startOfMonth(new Date(monthDate)),
  //             1
  //           );
  //           startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
  //           endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
  //           vat_pay_date = format(
  //             addMonths(startOfMonth(new Date(endVatDate)), 1),
  //             "yyyy-MM"
  //           );
  //         }
  //         if (isQuarterlyExtended) {
  //           const prevQuarter = subQuarters(
  //             startOfMonth(new Date(monthDate)),
  //             1
  //           );
  //           startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
  //           endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
  //           vat_pay_date = format(
  //             addMonths(startOfMonth(new Date(endVatDate)), 2),
  //             "yyyy-MM"
  //           );
  //         }

  //         isVatPayMentMonth = isSameMonth(
  //           new Date(monthDate),
  //           new Date(vat_pay_date)
  //         );
  //         if (isVatPayMentMonth) {
  //           vatData = transaction_monthly_chart?.filter(
  //             (item) =>
  //               item?.month >= startVatDate &&
  //               item?.month <= endVatDate &&
  //               !Constant?.calculationExcludeStates2.includes(item?.state)
  //           );
  //           vatData?.forEach((item) => {
  //             filteredScenarios?.forEach((scenarioElement) => {
  //               const key = scenarioElement?.title;
  //               if (item[`vat_type_booked_${key}`]) {
  //                 item[`vat_type_${key}`] = item[`vat_type_booked_${key}`] || 0;
  //               }
  //               if (!item[`vat_type_${key}`]) {
  //                 scenario_list_title[key].forEach((_scenario, i) => {
  //                   if (item[`vat_type_${_scenario}`]) {
  //                     item[`vat_type_${key}`] =
  //                       item[`vat_type_${_scenario}`] || 0;
  //                   }
  //                 });
  //               }
  //             });
  //           });
  //         }
  //       }
  //       if (index === 0) {
  //         filteredScenarios?.forEach((scenarioElement) => {
  //           const key = scenarioElement?.title;

  //           let startValue = getStartingLiquidityValue({
  //             monthlyTransactions: Transaction_monthly_statistic,
  //             selected_scenario_uuid: scenario_list_uuid?.[key],
  //             date: monthDate,
  //             selectionCategories,
  //             groupedMonthlyTransactions,
  //             advanceVat,
  //             // isChart: key === "Base",
  //           });

  //           LiquidityTotal[key] = startValue?.LiquidityTotal || 0;
  //           vat_total[key] = startValue?.VatChargeTotal || 0;
  //         });
  //         // console.log(
  //         //   "🚀 / chart / LiquidityTotal:",
  //         //   monthDate,
  //         //   // vatDate,
  //         //   prev_booked_balance,
  //         //   LiquidityTotal,
  //         //   vat_total,
  //         //   Transaction_monthly_statistic,
  //         //   groupedMonthlyTransactions
  //         // );
  //       }

  //       let updatedKeyData = {
  //         ...monthData,
  //       };
  //       let monthEndTotal = {};
  //       let monthEndVatTotal = {};
  //       let monthEndVatChargeTotal = {};

  //       filteredScenarios?.forEach((scenarioElement) => {
  //         const key = scenarioElement?.title;

  //         bars_data = {
  //           ...bars_data,
  //           [`liquidity_start_${key}`]:
  //             Number(LiquidityTotal?.[key] || 0) +
  //             (prev_booked_balance ?? 0) -
  //             Number(vat_total?.[key] || 0),
  //         };
  //         if (monthData?.[`vat_type_booked_${key}`]) {
  //           updatedKeyData[`vat_type_${key}`] =
  //             updatedKeyData?.[`vat_type_booked_${key}`] || 0;
  //         }
  //         if (monthData?.[`type_booked_${key}`]) {
  //           updatedKeyData[`type_${key}`] =
  //             updatedKeyData?.[`type_booked_${key}`] || 0;
  //         }
  //         if (!updatedKeyData[`type_${key}`]) {
  //           scenario_list_title[key].forEach((_scenario, i) => {
  //             if (updatedKeyData[`type_${_scenario}`]) {
  //               updatedKeyData[`type_${key}`] =
  //                 updatedKeyData[`type_${_scenario}`] || 0;
  //             }
  //           });
  //         }

  //         if (isPastMonth) {
  //           monthEndVatTotal[key] =
  //             Number(monthEndVatTotal?.[key] || 0) +
  //             Number(updatedKeyData?.[`vat_type_${key}`] || 0);
  //         }
  //         if (isCurrentMonth) {
  //           monthEndVatTotal[key] =
  //             Number(monthEndVatTotal?.[key] || 0) +
  //             Number(updatedKeyData?.[`vat_type_${key}`] || 0);

  //           monthEndTotal[key] =
  //             Number(monthEndTotal?.[key] || 0) +
  //             Number(updatedKeyData?.[`type_${key}`] || 0);
  //         }
  //         if (isFutureMonth) {
  //           monthEndTotal[key] =
  //             Number(monthEndTotal?.[key] || 0) +
  //             Number(updatedKeyData?.[`type_${key}`] || 0);
  //           monthEndVatTotal[key] =
  //             Number(monthEndVatTotal?.[key] || 0) +
  //             Number(updatedKeyData?.[`vat_type_${key}`] || 0);
  //         }
  //         vatData?.forEach((item) => {
  //           monthEndVatChargeTotal[key] =
  //             Number(monthEndVatChargeTotal?.[key] || 0) +
  //             Number(item?.[`vat_type_${key}`] || 0);
  //         });
  //       });

  //       let VAT = monthEndVatTotal?.["Base"] || 0;
  //       let scenario_vat = monthEndVatChargeTotal?.["Base"] || 0;

  //       let obj = {
  //         name: date,
  //         payload: [],
  //       };
  //       let position = 2;
  //       if (advanceVat && advanceVat?.enabled) {
  //         obj["VAT"] = -VAT;
  //         obj["VAT_Imputation"] = subtractVat ? -scenario_vat : 0;
  //         position++;
  //         obj.payload.push({
  //           name: "VAT",
  //           key: "VAT",
  //           amount: -VAT,
  //           stroke: Color.pink,
  //           position: position,
  //         });
  //         position++;
  //         obj.payload.push({
  //           name: "VAT_Imputation",
  //           key: "VAT",
  //           amount: subtractVat ? -scenario_vat : 0,
  //           stroke: Color.pink,
  //           position: position,
  //         });
  //       }
  //       // if (index < 1) {
  //       //   console.log(
  //       //     "month===>",
  //       //     monthDate,
  //       //     `booked_balance==>`,
  //       //     booked_balance,
  //       //     `LiquidityTotal==>`,
  //       //     { ...LiquidityTotal },
  //       //     `vat_total==>`,
  //       //     { ...vat_total },
  //       //     `monthEndTotal==>`,
  //       //     { ...monthEndTotal },
  //       //     `monthEndVatChargeTotal==>`,
  //       //     { ...monthEndVatChargeTotal },
  //       //     `netChange==>`,
  //       //     { ...netChange }
  //       //     // vatDate,
  //       //     // subtractVat,
  //       //     // updatedKeyData,
  //       //     // vatData,
  //       //     // { ...monthEndVatTotal },
  //       //   );
  //       // }

  //       filteredScenarios?.forEach((scenarioElement) => {
  //         const key = scenarioElement?.title;
  //         let startValue =
  //           Number(LiquidityTotal?.[key] || 0) + (prev_booked_balance || 0);

  //         LiquidityTotal[key] =
  //           Number(LiquidityTotal?.[key] || 0) +
  //           Number(monthEndTotal?.[key] || 0);
  //         let endValue =
  //           Number(LiquidityTotal?.[key] || 0) + (booked_balance || 0);

  //         vat_total[key] =
  //           Number(vat_total?.[key] || 0) +
  //           (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);

  //         if (isPastMonth) {
  //           netChange[key] = endValue - startValue;
  //         }
  //         if (isCurrentMonth) {
  //           netChange[key] =
  //             (updatedKeyData?.[`type_${key}`] || 0) +
  //             (updatedKeyData?.[`income_type_booked`] || 0) +
  //             (updatedKeyData?.[`expense_type_booked`] || 0) -
  //             (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);
  //         }
  //         if (isFutureMonth) {
  //           netChange[key] =
  //             (updatedKeyData?.[`income_type_open`] || 0) +
  //             (updatedKeyData?.[`income_type_remaining`] || 0) +
  //             ((updatedKeyData?.[`expense_type_open`] || 0) +
  //               (updatedKeyData?.[`expense_type_remaining`] || 0)) -
  //             (subtractVat ? Number(monthEndVatChargeTotal?.[key] || 0) : 0);
  //         }

  //         let total = 0;
  //         position++;
  //         total = bars_data[`liquidity_start_${key}`] + netChange[key];
  //         // if (key === "Base") {
  //         //   console.log(
  //         //     "🚀 /total:",
  //         //     monthDate,
  //         //     startValue,
  //         //     netChange[key],
  //         //     total
  //         //   );
  //         // }

  //         obj[`VAT_${key}`] = -monthEndVatTotal?.[key] || 0;
  //         obj[`VAT_Imputation_${key}`] = subtractVat
  //           ? -monthEndVatChargeTotal?.[key] || 0
  //           : 0;
  //         if (
  //           new Date(monthDate) >=
  //           addMonths(new Date(start_date), Constant.column_count)
  //         ) {
  //           obj[`VAT_${key}`] = 0;
  //           obj[`VAT_Imputation_${key}`] = 0;
  //         }
  //         obj[key] = total;

  //         bars_data = {
  //           ...bars_data,
  //           [`liquidity_end_${key}`]: obj[key] || 0,
  //           [`liquidity_net_change_${key}`]: netChange[key],
  //         };
  //         if (key === "Base") {
  //           if (monthDate <= currentMonth) {
  //             obj["Base_solid"] = bars_data?.[`liquidity_start_${key}`] || 0;
  //           }
  //         }
  //         let monthly_amount = 0;
  //         if (monthData && monthData[`type_booked_${key}`]) {
  //           updatedKeyData[`type_${key}`] =
  //             monthData[`type_booked_${key}`] || 0;
  //         }

  //         monthly_amount = updatedKeyData
  //           ? updatedKeyData[`type_${key}`] || 0
  //           : 0;
  //         obj.payload.push({
  //           name: key,
  //           key: key,
  //           amount: obj[key],
  //           monthly_amount: monthly_amount,
  //           [`liquidity_start_${key}`]: bars_data[`liquidity_start_${key}`],
  //           [`liquidity_end_${key}`]: bars_data[`liquidity_end_${key}`],
  //           stroke: scenarioElement.color,
  //           position: position,
  //         });
  //         // if (
  //         //   new Date(monthDate) >=
  //         //   addMonths(new Date(start_date), Constant.column_count)
  //         // ) {
  //         //   bars_data = {};
  //         // }
  //       });

  //       obj = {
  //         ...obj,
  //         ...bars_data,
  //       };

  //       if (Limit?.length > 0) {
  //         let totalOF = 0;
  //         Limit.forEach((element) => {
  //           totalOF = parseInt(element?.limit ?? 0) + parseInt(totalOF);
  //           position++;
  //         });
  //         let title = "Limit";
  //         obj[title] = totalOF > 0 ? -totalOF : totalOF;
  //         obj.payload.push({
  //           name: "Limit",
  //           key: title,
  //           amount: totalOF,
  //           stroke: Color.tailwind.red[500],
  //           position: position,
  //         });
  //       }
  //       dummyChartData.push(obj);
  //     });
  //   }
  //   return JSON.stringify({
  //     statesKey: includedStates,
  //     scenariosKey: includedScenarios,
  //     data: [...dummyChartData],
  //   });
  // };

  const updateComposedMonthlyChart = () => {
    let includedScenarios = scenario?.map((o1) => o1.title);
    let includedStates = state?.map((o1) => o1.title);
    let obj = JSON.stringify({
      Limit,
      advanceVat,
      selectionCategoriesByID,
      scenario,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
      booked_balances: transaction_monthly_chart?.data?.booked_balances,
      Transaction_monthly_statistic:
        Transaction_monthly_statistic?.data?.results?.filter(
          (item) =>
            !initialData?.calculationExcludeStates2?.includes(item.state)
        ),
      transaction_monthly_chart: transaction_monthly_chart?.data?.results,
      includedScenarios,
      includedStates,
      scenarioByTitle,
      selectionCategories,
      isDataset: true,
    });
    dispatch(setAccountBalanceUpdateDate());
    dispatch(setIsMonthChartLoading(true));

    ////
    // const data = generateMonthlyChart({ data: obj });
    // const result = JSON.parse(data);
    //   dispatch(setMonthlyChartData(result));
    //   dispatch(setIsMonthChartLoading(false));
    ////

    webWorkerMonthly?.postMessage({
      type: "updateComposedMonthlyChart",
      data: obj,
    });

    webWorkerMonthly.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updateComposedMonthlyChart") {
          let result = JSON.parse(data);
          // console.log("🚀 ~  monthly ", result);
          dispatch(setMonthlyChartData(result));
          dispatch(setIsMonthChartLoading(false));
          if (appliedFilterlist?.kanban?.precisionValue === 1) {
            // const chart1 = performance.now();
            // console.log(`<<-- Chart Process took --> ${chart1 - chart0} ms.`);
          }
        }
      } else {
        console.log("worker client Error");
        dispatch(setIsMonthChartLoading(false));
      }
    };
  };

  const updateComposedDailyChart = () => {
    let includedScenarios = scenario?.map((o1) => o1.title);
    let includedStates = state?.map((o1) => o1.title);
    let obj = JSON.stringify({
      booked_balances: transaction_daily_chart?.data?.booked_balances,
      transaction_daily_chart: transaction_daily_chart?.data?.results,
      transaction_monthly_chart: transaction_monthly_chart?.data?.results,
      Transaction_monthly_statistic:
        Transaction_monthly_statistic?.data?.results?.filter(
          (item) =>
            !initialData?.calculationExcludeStates2?.includes(item.state)
        ),
      selectionCategoriesByID,
      scenario,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
      Limit,
      advanceVat,
      includedScenarios,
      includedStates,
      scenarioByTitle,
      selectionCategories,
      isDataset: true,
    });
    dispatch(setIsDailyChartLoading(true));
    webWorkerDaily?.postMessage({
      type: "updateComposedDailyChart",
      data: obj,
    });
    webWorkerDaily.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updateComposedDailyChart") {
          let result = JSON.parse(data);
          // const chart1 = performance.now();
          // console.log(`<<-- Chart Process took --> ${chart1 - chart0} ms.`);
          dispatch(setDailyChartData(result));
          dispatch(setIsDailyChartLoading(false));
        }
      } else {
        console.log("worker client Error");
        dispatch(setIsDailyChartLoading(false));
      }
    };
  };

  const getChartKeys = () => {
    if (
      appliedFilterlist?.kanban &&
      chartData &&
      chartData?.scenariosKey &&
      chartData?.statesKey
    ) {
      let chart_keys = appliedFilterlist?.kanban?.chart_keys;
      let scenarioData = scenario?.filter((o1) =>
        chartData?.scenariosKey?.includes(o1.title)
      );

      let stateData = state?.filter((o1) =>
        chartData?.statesKey?.includes(o1.title)
      );

      let vat_array = [];
      if (advanceVat?.enabled) {
        vat_array = [
          {
            uuid: "VAT",
            name: "VAT",
            title: "VAT",
            color: Color.theme.pink["A400"],
            fill: Color.theme.pink["A400"],
            stroke: Color.theme.pink["A400"],
            isSelected:
              !chart_keys || !chart_keys?.hasOwnProperty("VAT")
                ? true
                : chart_keys["VAT"],
            position: 2,
          },
        ];
      }
      let array = [
        ...vat_array,
        Limit && {
          uuid: "Limit",
          name: "Limit",
          stroke: Color.theme.red[500],
          color: Color.theme.red[500],
          fill: Color.theme.red[500],
          title: "Limit",
          can_be_deleted: true,
          isSelected:
            !chart_keys || !chart_keys?.hasOwnProperty("Limit")
              ? true
              : chart_keys?.["Limit"],
          position: 5,
          type: "account",
        },
        ...scenarioData?.map((o1, index) => {
          // let isSelected = false;
          // if (
          //   !chart_keys?.isFirstLogin &&
          //   !chart_keys?.hasOwnProperty(o1.uuid)
          // ) {
          //   isSelected = true;
          // }
          // if (o1.title === "Base" && chart_keys?.isFirstLogin) {
          //   isSelected = true;
          // }

          return {
            uuid: o1.uuid,
            name: o1.title,
            stroke: o1.color,
            color: o1.color,
            title: o1.title,
            style: o1.style,
            can_be_deleted: o1.can_be_deleted,
            fill: o1.color,
            isSelected: o1.title === "Base" || chart_keys?.[o1.uuid],
            position: 5 + o1.position,
            isScenario: true,
            isLast: index === scenarioData.length - 1,
          };
        }),
        ...stateData?.map((o1) => {
          return {
            uuid: o1.uuid,
            name: o1.title,
            stroke: o1.color,
            color: o1.color,
            title: o1.title,
            can_be_deleted: o1.can_be_deleted,
            fill: o1.color,
            position: 5 + scenarioData?.length + o1.position,
            isState: true,
          };
        }),
      ];

      let keys = array
        .filter((o1) => !o1.isState && o1?.uuid)
        .map((o1) => o1?.uuid);
      let update_chart_keys = {};
      if (chart_keys && keys?.length !== Object.keys(chart_keys)?.length) {
        Object.keys(chart_keys)?.forEach((key) => {
          if (keys?.includes(key)) {
            update_chart_keys[key] = chart_keys[key];
          }
        });
        if (Boolean(JSON.stringify(update_chart_keys) !== "{}")) {
          updateFilter("chart_keys", update_chart_keys);
        }
      }
      return array
        ?.map((o1) => o1)
        ?.sort((a, b) => (a?.position > b?.position ? 1 : -1));
    }
  };

  const updateFilter = (key, value) => {
    dispatch(updateKanbanFilters({ [key]: value }));
  };

  return (
    <>
      {isChartInProgress && isChartExpanded ? (
        <span
          style={{
            position: "absolute",
            top: "15%",
            zIndex: 4,
            left: "45%",
            fontSize: "1.5rem",
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          <Spinner name="line-scale" color={theme.palette.primary.main} />
        </span>
      ) : null}
    </>
  );
};
export default CommonView;
