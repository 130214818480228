import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line,
  Bar,
} from "recharts";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material";
import React from "react";

import CustomizedAxisTick from "./CustomizedAxisTick";
import CategoryTooltip from "./CategoryTooltip";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import { Color } from "../../Helper";

const StackedCharts = React.memo(
  ({ data, withLine = false, selectedItem }) => {
    const currentWidth = useWidth();
    const selectionCategories = useSelector(
      (state) => state.categorySlice?.selectionCategories
    );
    const aggregates = useSelector((state) => state.chartSlice?.aggregates);
    const newCat = [
      {
        value: null,
        label: "Uncategorized",
        color: Color?.unCategorizedCardBg,
      },
      ...selectionCategories,
    ];
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={remToPx(currentWidth, 31.25)}
          height={remToPx(currentWidth, 18.75)}
          data={data}
          margin={{
            top: remToPx(currentWidth, 1.25),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
          barSize={remToPx(currentWidth, 2.1875)}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey="due_date"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                aggregates={aggregates}
              />
            }
            tickCount={12}
            minTickGap={2}
            type="category"
            allowDataOverflow={false}
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1)}
                formatted
              />
            }
            type="number"
            tickCount={12}
            interval={"preserveStartEnd"}
          />
          <Legend wrapperStyle={{ display: "none" }} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={
              <CategoryTooltip
                data={
                  selectedItem === 0
                    ? newCat
                    : newCat?.filter(
                        (o1) => !o1.type || o1.type === selectedItem
                      )
                }
                type="stacked"
                aggregates={aggregates}
                selectedItem={selectedItem === 0 ? 1 || 2 : selectedItem}
              />
            }
          />
          {newCat?.map((entry, index) => {
            if (
              !entry?.type ||
              (entry.type &&
                entry?.type === (selectedItem === 0 ? 1 || 2 : selectedItem))
            ) {
              return (
                <Bar
                  key={`cell-${index}`}
                  dataKey={`${entry?.value ? entry?.value : entry?.label}`}
                  stackId="a"
                  fill={entry?.color ?? alpha(Color?.themeColor2, 0.7)}
                />
              );
            }
            return null;
          })}
          {withLine && (
            <Line type="monotone" dataKey="gross_value" stroke="#ff7300" />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return (
      prev.data === next.data &&
      prev.withLine === next.withLine &&
      prev.aggregates === next.aggregates &&
      prev.selectedItem === next.selectedItem
    );
  }
);
export default StackedCharts;
