import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { alpha, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Color } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const width = "12rem";

export default React.forwardRef(function CustomerScenarioToggle(
  {
    onUpdateState,
    value,
    active,
    wrapperStyle,
    onOpen,
    onClose,
    ...otherProps
  },
  ref
) {
  const { t } = useTranslation();
  //redux
  const scenario = useSelector((state) => state.globalSlice.scenario);

  //state
  const [stateValue, setStateValue] = useState(value || []);

  React.useEffect(() => {
    setStateValue(value);
  }, [value]);

  //function
  const onChange = (event) => {
    const {
      target: { value },
    } = event;
    let finalValue = typeof value === "string" ? value?.split(",") : value;
    setStateValue(finalValue);
    onUpdateState(finalValue);
  };

  //render function
  return (
    <Box
      {...otherProps}
      ref={ref}
      sx={{
        display: "flex",
        flexWrap: "wrap",

        "& .MuiOutlinedInput-root": {
          borderRadius: 6,
          p: "0.4rem",
          backgroundColor: active
            ? alpha(Color.themeColor, 0.1)
            : "transaparent",
          pl: "0.5rem",
          fontSize: "0.95rem",
          color: alpha(Color.themeColor, active ? 1 : 0.6),
          "&:hover": {
            backgroundColor: alpha(Color.themeColor, 0.1),
          },
          "& .MuiSvgIcon-root": {
            color: alpha(Color.themeColor, active ? 1 : 0.6),
          },
        },
        ".MuiInputBase-root": {
          height: "2rem",
          fontSize: "0.8rem",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "0 !important",
          backgroundColor: Color.hederEleBg,
          borderRadius: 6,
          height: "2.33rem",
        },
        ...wrapperStyle,
      }}
    >
      <FormControl sx={{ width: width }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={stateValue}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected || selected?.length === 0) {
              return <em>{t("Scenario")}</em>;
            }
            const translatedSelected = selected.map((item) =>
              item === "Base" ? t(item) : item
            );
            return translatedSelected.join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {scenario?.map((item) => (
            <MenuItem
              key={item?.uuid}
              value={item?.title}
              sx={{
                width: "14rem",
                backgroundColor: getTailwindColor(item?.color, 200),
                color: getTailwindColor(item?.color, 900),
                p: 0,
                "&:hover": {
                  backgroundColor: getTailwindColor(item?.color, 300),
                },
                ".Mui-selected": {
                  backgroundColor: getTailwindColor(item?.color, 300),
                },
              }}
            >
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { width: "0.9em", height: "0.9em" },
                }}
                checked={stateValue?.indexOf(item?.title) > -1}
              />
              <ListItemText
                primary={item?.title === "Base" ? t(item?.title) : item?.title}
                sx={{ "& span": { fontSize: "0.9rem" } }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
