import {
  useMediaQuery,
  Typography,
  Alert,
  alpha,
  Paper,
  Box,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import React, { memo, useCallback, useMemo } from "react";
import startOfMonth from "date-fns/startOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import { useTranslation } from "react-i18next";
import endOfMonth from "date-fns/endOfMonth";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import Popper from "@mui/material/Popper";
import subYears from "date-fns/subYears";
import { styled } from "@mui/styles";
import format from "date-fns/format";
import { subMonths } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";

import {
  getPercentageChange,
  isPlanExpired,
  formatAmount,
  remToPx,
} from "../../../Helper/data";
import DashBoardSucessReportLineChart from "../../../components/Charts/DashBoardSucessReportLineChart";
import Placeholder from "../../../components/Placeholder";
import { Color, Fonts, Images } from "../../../Helper";
import WarnView from "../../../components/WarnView";
import initialData from "./../../../Helper/data";
import useWidth from "../../../hooks/useWidth";

const PREFIX = "Employess";
const classes = {
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
};
const Root = styled("div")(({ width, height }) => ({
  display: "flex",
  justifyContent: "center",
  [`& .${classes.paper}`]: {
    "& .css-mcts47-MuiPaper-root": {
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    // width: width,
    borderRadius: "5px",
    backgroundColor: Color.white,
    overflowY: "auto",
    height: height,
    position: "relative",
    "& MuiDataGrid-virtualScroller": {
      width: width,
    },
    "& .super-app-theme--cell": {
      backgroundColor: alpha(Color.headerIconLight, 0.3),
    },
  },
  [`& .${classes.table}`]: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
}));

const SuccessReport = () => {
  const currentWidth = useWidth();
  const s1745 = useMediaQuery("(min-width:1745px)");
  let width = remToPx(currentWidth, s1745 ? 125 : 115);
  let height = remToPx(currentWidth, 43.75);
  let currentDate = useMemo(() => new Date(), []);
  const { t } = useTranslation();
  //redux
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const monthlyTransactions = useSelector(
    (state) => state.datasetSlice?.monthlyTransactions
  );

  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isFetching = useSelector((state) => state.appSlice.isFetching);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const planExpired = useMemo(() => {
    return isPlanExpired(subscription);
  }, [subscription]);

  //states
  const [showInfo, setShowInfo] = useState(false);
  const [warnFor, setWarnfor] = useState(null);
  const [rows, setRows] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { variableCategoriesId, fixCategoriesId, privateExpenseCategoryIds } =
    useMemo(() => {
      let variableCategoriesId = [];
      let fixCategoriesId = [];
      let privateExpenseCategoryIds = [];

      selectionCategories.forEach((o1) => {
        if (o1.type === 2 && o1.type_of_cost === "variable") {
          variableCategoriesId.push(o1.value);
        }
        if (o1.type === 2 && o1.type_of_cost === "fix") {
          fixCategoriesId.push(o1.value);
        }
        if (o1.type === 2 && o1.type_of_cost === "private") {
          privateExpenseCategoryIds.push(o1.value);
        }
      });
      return {
        variableCategoriesId,
        fixCategoriesId,
        privateExpenseCategoryIds,
      };
    }, [selectionCategories]);

  const othersCostIds = useMemo(
    () => [...variableCategoriesId, ...fixCategoriesId],
    [fixCategoriesId, variableCategoriesId]
  );

  //data
  let data = useMemo(() => {
    let earliestMonth = monthlyTransactions?.[0]?.month
      ? new Date(monthlyTransactions[0]?.month)
      : null;
    let icon = false;
    setWarnfor(null);
    let start_date = appliedFilterlist?.reports?.start_date;
    let prev_start_date = start_date ? subYears(new Date(start_date), 1) : null;

    if (start_date && earliestMonth && prev_start_date) {
      if (
        format(new Date(earliestMonth), "yyyy-MM") >
          format(new Date(start_date), "yyyy-MM") &&
        format(new Date(earliestMonth), "yyyy") ===
          format(new Date(start_date), "yyyy")
      ) {
        icon = true;
        setWarnfor("currentyear");
      }
      if (
        format(new Date(earliestMonth), "yyyy-MM") ===
        format(new Date(start_date), "yyyy-MM")
      ) {
        icon = true;
        setWarnfor("currentyear");
      }
      if (
        format(new Date(earliestMonth), "yyyy-MM") <
          format(new Date(start_date), "yyyy-MM") &&
        format(new Date(earliestMonth), "yyyy-MM") >=
          format(new Date(prev_start_date), "yyyy-MM")
      ) {
        icon = true;
        setWarnfor("lastyear");
      }
    }
    return [
      {
        id: 1,
        title: "Revenue",
        key: "Revenue",
        total: "Total",
        description: t("SucessReport_Revenue_description"),
      },
      {
        id: 2,
        title: "Other Revenue",
        key: "OtherRevenue",
        total: "Total",
        icon: icon,
        description: t("SucessReport_OtherRevenue_description"),
      },
      {
        id: 3,
        title: "Variable Cost",
        key: "VariableCost",
        total: "Total",
        description: t("SucessReport_VariableCost_description"),
      },
      {
        id: 4,
        title: "Gross Profit",
        key: "GrossProfit",
        total: "Total",
        description: t("SucessReport_GrossProfit_description"),
      },
      {
        id: 5,
        title: "Fix Costs",
        key: "FixCosts",
        total: "Total",
        description: t("SucessReport_FixCosts_description"),
      },
      {
        id: 6,
        title: "Other Costs",
        key: "OtherCosts",
        total: "Total",
        description: t("SucessReport_OtherCost_description"),
        info: true,
      },
      {
        id: 7,
        title: "Operating Income",
        key: "OperatingIncome",
        total: "Total",
        description: t("SucessReport_OperatingIncome_description"),
      },
    ];
  }, [appliedFilterlist?.reports?.start_date, monthlyTransactions, t]);
  let key = ["currentYear", "preYear", "longGrowth", "preMonth", "shortGrowth"];

  const Column = useMemo(
    () => [
      {
        field: "title",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.175,
        height: 200,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  color: Color.theme.blueGrey[900],
                  fontWeight: 500,
                  fontFamily: Fonts?.Text,
                  fontSize: "1rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {params?.value ? params?.value : ""}{" "}
                {params?.row?.icon ? (
                  <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                    <ErrorOutlineIcon
                      sx={{
                        fontSize: "1.1rem",
                        cursor: "pointer",
                        color: Color.theme.orange[500],
                        mt: "0.3rem",
                        ml: "0.3rem",
                      }}
                    />
                  </div>
                ) : null}
                {params?.row?.info && showInfo ? (
                  <WarnView
                    showInfo={showInfo}
                    warnKey={"success_report_warning_uncategorized"}
                    infoKey={"success_report_warn_no_enough_data"}
                    iconStyle={{ mt: "0.4rem", ml: "0.5rem" }}
                  />
                ) : null}
              </span>
            </div>
          );
        },
      },

      {
        field: "currentYear",
        height: 300,
        headerName: "Financial year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.14,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? params?.value : ""}
            </span>
          );
        },
      },
      {
        field: "preYear",
        height: 400,
        headerName: "Previous year",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.12,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? params?.value : ""}
            </span>
          );
        },
      },
      {
        field: "longGrowth",
        headerName: "Growth in the long term",
        subTitle: "Current year compared to previous year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.135,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;

          return (
            <span
              style={{
                color:
                  number >= 0 ? Color.theme.green[500] : Color.theme.red[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
      {
        field: "preMonth",
        headerName: "Previous Month",
        subTitle: "01.01.year - 31.12.year",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.13,
        editable: false,
        sortable: false,
        type: "text",
        renderHeader: (params) => headerCell(params?.colDef),
        renderCell: (params) => {
          return (
            <span
              style={{
                color: Color.theme.blueGrey[900],
                fontWeight: 500,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? params?.value : ""}
            </span>
          );
        },
      },
      {
        field: "shortGrowth",
        headerName: "Growth short-term",
        subTitle: "3-month average compared with prior-year period",
        cellClassName: "super-app-theme--cell",
        width: width * 0.7 * 0.24,
        editable: false,
        sortable: false,
        renderHeader: (params) => headerCell(params?.colDef),
        valueGetter: ({ value }) => parseFloat(value),
        renderCell: (params) => {
          let number = parseFloat(params?.value).toFixed(1);
          let value = number > 0 ? `+${number} %` : `${number} %`;
          return (
            <span
              style={{
                color:
                  number >= 0 ? Color.theme.green[500] : Color.theme.red[500],
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {params?.value || params?.value === 0 ? value : ""}
            </span>
          );
        },
      },
    ],
    [
      width,
      showInfo,
      appliedFilterlist?.reports?.start_date,
      appliedFilterlist?.reports?.end_date,
    ]
  );

  useEffect(() => {
    if (
      subscription?.success_report_view &&
      isAllHeaderApiFetched &&
      !isFetching &&
      !planExpired &&
      variableCategoriesId &&
      privateExpenseCategoryIds &&
      fixCategoriesId &&
      appliedFilterlist?.reports?.start_date &&
      appliedFilterlist?.reports?.end_date
    ) {
      let yearGrowthObj = null;
      key.forEach((element, index) => {
        let obj = null;
        if (index === 0) {
          obj = getCurrentYear();
          yearGrowthObj = {
            ...yearGrowthObj,
            current: obj,
          };
        }
        if (index === 1) {
          obj = getPreYear();
          yearGrowthObj = {
            ...yearGrowthObj,
            prev: obj,
          };
        }
        if (index === 2) {
          obj = getYearDifference(yearGrowthObj);
        }
        if (index === 3) {
          obj = getPrevMonthData();
        }
        if (index === 4) {
          obj = get3MonthShare();
        }

        if (obj) {
          data.forEach((item) => {
            item[element] = obj[item?.key];
          });
        }
      });
      getChartData();
    }
    setRows(data);
  }, [
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    appliedFilterlist?.reports?.end_date,
    appliedFilterlist?.reports?.start_date,
    privateExpenseCategoryIds?.length,
    planExpired,
    isAllHeaderApiFetched,
    isFetching,
    subscription?.success_report_view,
  ]);

  //function
  const getGrowth = (prevPer, current) => {
    let Growth = current >= 0 ? 100 : -100;
    if (prevPer !== 0) {
      Growth = getPercentageChange(prevPer, current);
    }
    if (prevPer === 0 && current === 0) {
      Growth = 0;
    }
    return Growth;
  };

  const getCurrentYear = useCallback(() => {
    let { selectedStates, selectedScenarios, start_date, end_date } =
      appliedFilterlist?.reports;
    const currentYearStartDate = start_date;
    const currentYearEndDate = end_date;

    let CurrentYearData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(currentYearStartDate) &&
        new Date(o1.month) <= new Date(currentYearEndDate)
    );
    let variableData = CurrentYearData?.filter((o1) =>
      variableCategoriesId.some((o2) => o2 === o1.category)
    );
    let fixData = CurrentYearData?.filter((o1) =>
      fixCategoriesId.some((o2) => o2 === o1.category)
    );
    let Revenue = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          item?.category &&
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
            selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
              "revenue"
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );
    let OtherRevenue = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !item?.category ||
            (item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category !==
                "revenue")
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );
    let Expense = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !privateExpenseCategoryIds?.includes(item?.category)
            ? Math.abs(item?.outflow ?? 0)
            : 0
        ),
      0
    );
    let VariableCost = variableData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let FixCosts = fixData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let OtherCosts = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Math.abs(
            !othersCostIds.includes(item?.category) ? item?.outflow ?? 0 : 0
          )
        ),
      0
    );
    let GrossProfit = Revenue - VariableCost;
    let OperatingIncome = Revenue - Expense;
    let obj = {
      Revenue_orignal: Revenue,
      OtherRevenue_orignal: OtherRevenue,
      VariableCost_orignal: VariableCost,
      FixCosts_orignal: FixCosts,
      GrossProfit_orignal: GrossProfit,
      OperatingIncome_orignal: OperatingIncome,
      OtherCosts_orignal: OtherCosts,
      OtherCosts: formatAmount({
        amount: String(parseFloat(OtherCosts).toFixed(1)),
      }),
      Revenue: formatAmount({
        amount: String(parseFloat(Revenue).toFixed(1)),
      }),
      OtherRevenue: formatAmount({
        amount: String(parseFloat(OtherRevenue).toFixed(1)),
      }),

      VariableCost: formatAmount({
        amount: String(parseFloat(VariableCost).toFixed(1)),
      }),
      FixCosts: formatAmount({
        amount: String(parseFloat(FixCosts).toFixed(1)),
      }),
      GrossProfit: formatAmount({
        amount: String(parseFloat(GrossProfit).toFixed(1)),
      }),
      OperatingIncome: formatAmount({
        amount: String(parseFloat(OperatingIncome).toFixed(1)),
      }),
    };
    return obj;
  }, [
    appliedFilterlist?.reports,
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    selectionCategoriesByID,
    privateExpenseCategoryIds,
    othersCostIds,
  ]);

  const getPreYear = useCallback(() => {
    let { selectedStates, selectedScenarios, start_date, end_date } =
      appliedFilterlist?.reports;
    const preYearStartDate = subYears(new Date(start_date), 1);
    const preYearEndDate = subYears(new Date(end_date), 1);
    let CurrentYearData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(preYearStartDate) &&
        new Date(o1.month) <= new Date(preYearEndDate)
    );
    let variableData = CurrentYearData?.filter((o1) =>
      variableCategoriesId.some((o2) => o2 === o1.category)
    );
    let fixData = CurrentYearData?.filter((o1) =>
      fixCategoriesId.some((o2) => o2 === o1.category)
    );
    let Revenue = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          item?.category &&
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
            selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
              "revenue"
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );
    let OtherRevenue = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !item?.category ||
            (item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category !==
                "revenue")
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );
    let OtherCosts = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Math.abs(
            !othersCostIds.includes(item?.category) ? item?.outflow ?? 0 : 0
          )
        ),
      0
    );
    let Expense = CurrentYearData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !privateExpenseCategoryIds?.includes(item?.category)
            ? Math.abs(item?.outflow ?? 0)
            : 0
        ),
      0
    );
    let VariableCost = variableData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let FixCosts = fixData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let GrossProfit = Revenue - VariableCost;
    let OperatingIncome = Revenue - Expense;
    let obj = {
      Revenue_orignal: Revenue,
      OtherRevenue_orignal: OtherRevenue,
      VariableCost_orignal: VariableCost,
      FixCosts_orignal: FixCosts,
      GrossProfit_orignal: GrossProfit,
      OperatingIncome_orignal: OperatingIncome,
      OtherCosts_orignal: OtherCosts,
      OtherCosts: formatAmount({
        amount: String(parseFloat(OtherCosts).toFixed(1)),
      }),
      Revenue: formatAmount({
        amount: String(parseFloat(Revenue).toFixed(1)),
      }),
      OtherRevenue: formatAmount({
        amount: String(parseFloat(OtherRevenue).toFixed(1)),
      }),
      VariableCost: formatAmount({
        amount: String(parseFloat(VariableCost).toFixed(1)),
      }),
      FixCosts: formatAmount({
        amount: String(parseFloat(FixCosts).toFixed(1)),
      }),
      GrossProfit: formatAmount({
        amount: String(parseFloat(GrossProfit).toFixed(1)),
      }),
      OperatingIncome: formatAmount({
        amount: String(parseFloat(OperatingIncome).toFixed(1)),
      }),
    };
    return obj;
  }, [
    appliedFilterlist?.reports,
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    selectionCategoriesByID,
    othersCostIds,
    privateExpenseCategoryIds,
  ]);

  const getYearDifference = ({ current, prev }) => {
    let Revenue = getGrowth(
      Number(prev?.Revenue_orignal) / 12,
      Number(current?.Revenue_orignal) / 12
    );
    let VariableCost = getGrowth(
      Number(prev?.VariableCost_orignal) / 12,
      Number(current?.VariableCost_orignal) / 12
    );
    let FixCosts = getGrowth(
      Number(prev?.FixCosts_orignal) / 12,
      Number(current?.FixCosts_orignal) / 12
    );
    let GrossProfit = getGrowth(
      Number(prev?.GrossProfit_orignal) / 12,
      Number(current?.GrossProfit_orignal) / 12
    );
    let OperatingIncome = getGrowth(
      Number(prev?.OperatingIncome_orignal) / 12,
      Number(current?.OperatingIncome_orignal) / 12
    );
    let OtherRevenue = getGrowth(
      Number(prev?.OtherRevenue_orignal) / 12,
      Number(current?.OtherRevenue_orignal) / 12
    );
    let OtherCosts = getGrowth(
      Number(prev?.OtherCosts_orignal) / 12,
      Number(current?.OtherCosts_orignal) / 12
    );

    let obj = {
      Revenue,
      OtherRevenue,
      OtherCosts,
      VariableCost,
      FixCosts,
      GrossProfit,
      OperatingIncome,
    };

    return obj;
  };

  const getPrevMonthData = useCallback(() => {
    const result = subMonths(currentDate, 1);
    const prevMonthStartDate = startOfMonth(result);
    const prevMonthEndDate = endOfMonth(result);
    let { selectedStates, selectedScenarios } = appliedFilterlist?.reports;
    let prevMonthData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(prevMonthStartDate) &&
        new Date(o1.month) <= new Date(prevMonthEndDate)
    );
    let variableData = prevMonthData?.filter((o1) =>
      variableCategoriesId.some((o2) => o2 === o1.category)
    );
    let fixData = prevMonthData?.filter((o1) =>
      fixCategoriesId.some((o2) => o2 === o1.category)
    );
    let Revenue = prevMonthData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          item?.category &&
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
            selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
              "revenue"
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );
    let OtherRevenue = prevMonthData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !item?.category ||
            (item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category !==
                "revenue")
            ? Math.abs(item?.inflow ?? 0)
            : 0
        ),
      0
    );

    let Expense = prevMonthData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          !privateExpenseCategoryIds?.includes(item?.category)
            ? Math.abs(item?.outflow ?? 0)
            : 0
        ),
      0
    );
    let VariableCost = variableData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let FixCosts = fixData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
      0
    );
    let GrossProfit = Revenue - VariableCost;
    let OperatingIncome = Revenue - Expense;
    let OtherCosts = prevMonthData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Math.abs(
            !othersCostIds.includes(item?.category) ? item?.outflow ?? 0 : 0
          )
        ),
      0
    );
    let obj = {
      OtherCosts: formatAmount({
        amount: String(parseFloat(OtherCosts).toFixed(1)),
      }),
      Revenue: formatAmount({
        amount: String(parseFloat(Revenue).toFixed(1)),
      }),
      OtherRevenue: formatAmount({
        amount: String(parseFloat(OtherRevenue).toFixed(1)),
      }),
      VariableCost: formatAmount({
        amount: String(parseFloat(VariableCost).toFixed(1)),
      }),
      FixCosts: formatAmount({
        amount: String(parseFloat(FixCosts).toFixed(1)),
      }),
      GrossProfit: formatAmount({
        amount: String(parseFloat(GrossProfit).toFixed(1)),
      }),
      OperatingIncome: formatAmount({
        amount: String(parseFloat(OperatingIncome).toFixed(1)),
      }),
    };

    return obj;
  }, [
    currentDate,
    appliedFilterlist?.reports,
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    selectionCategoriesByID,
    privateExpenseCategoryIds,
    othersCostIds,
  ]);

  const get3MonthShare = useCallback(() => {
    let { selectedStates, selectedScenarios } = appliedFilterlist?.reports;
    const result = subMonths(currentDate, 3);
    const prev3MonthStartDate = startOfMonth(result);
    const prevPer3MonthStartDate = subYears(prev3MonthStartDate, 1);
    const endDate = endOfMonth(currentDate);
    const pervPerEndDate = subYears(endDate, 1);

    let current3MonthData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(prev3MonthStartDate) &&
        new Date(o1.month) <= new Date(endDate)
    );
    let prevPer3MonthData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= new Date(prevPer3MonthStartDate) &&
        new Date(o1.month) <= new Date(pervPerEndDate)
    );
    let currentVariableData = current3MonthData?.filter((o1) =>
      variableCategoriesId.some((o2) => o2 === o1.category)
    );
    let currentFixData = current3MonthData?.filter((o1) =>
      fixCategoriesId.some((o2) => o2 === o1.category)
    );
    let prePerVariableData = prevPer3MonthData?.filter((o1) =>
      variableCategoriesId.some((o2) => o2 === o1.category)
    );
    let prePerFixData = prevPer3MonthData?.filter((o1) =>
      fixCategoriesId.some((o2) => o2 === o1.category)
    );
    let currentRevenue =
      current3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
                "revenue"
              ? Math.abs(item?.inflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let currentOtherRevenue =
      current3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category !==
                "revenue"
              ? Math.abs(item?.inflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let currentOtherCost =
      current3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !othersCostIds.includes(item?.category) ? item?.outflow ?? 0 : 0
          ),
        0
      ) / 3;
    let prevPerRevenue =
      prevPer3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category ===
                "revenue"
              ? Math.abs(item?.inflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let prevPerOtherRevenue =
      prevPer3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.category &&
              selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.cost_category !==
                "revenue"
              ? Math.abs(item?.inflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let prevPerOtherCost =
      prevPer3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !othersCostIds.includes(item?.category) ? item?.outflow ?? 0 : 0
          ),
        0
      ) / 3;
    let currentExpense =
      current3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !privateExpenseCategoryIds?.includes(item?.category)
              ? Math.abs(item?.outflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let prevPerExpense =
      prevPer3MonthData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !privateExpenseCategoryIds?.includes(item?.category)
              ? Math.abs(item?.outflow ?? 0)
              : 0
          ),
        0
      ) / 3;
    let currentVariableCost =
      currentVariableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      ) / 3;
    let currentFixCosts =
      currentFixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      ) / 3;
    let prePerVariableCost =
      prePerVariableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      ) / 3;
    let prePerFixCosts =
      prePerFixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      ) / 3;

    let Revenue = getGrowth(prevPerRevenue, currentRevenue);
    let OtherRevenue = getGrowth(prevPerOtherRevenue, currentOtherRevenue);
    let OtherCosts = getGrowth(prevPerOtherCost, currentOtherCost);
    let VariableCost = getGrowth(prePerVariableCost, currentVariableCost);
    let FixCosts = getGrowth(prePerFixCosts, currentFixCosts);
    let currentGrossProfit = currentRevenue - currentVariableCost;
    let prePerGrossProfit = prevPerRevenue - prePerVariableCost;
    let GrossProfit = getGrowth(prePerGrossProfit, currentGrossProfit);
    let currentOperatingIncome = currentRevenue - currentExpense;
    let prePerOperatingIncome = prevPerRevenue - prevPerExpense;
    let OperatingIncome = getGrowth(
      prePerOperatingIncome,
      currentOperatingIncome
    );
    let obj = {
      Revenue,
      OtherRevenue,
      OtherCosts,
      VariableCost,
      FixCosts,
      GrossProfit,
      OperatingIncome,
    };
    return obj;
  }, [
    appliedFilterlist?.reports,
    currentDate,
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    privateExpenseCategoryIds,
    selectionCategoriesByID,
  ]);

  const getChartData = useCallback(() => {
    let { selectedStates, selectedScenarios, start_date, end_date } =
      appliedFilterlist?.reports;
    let last12M_start_date = new Date(start_date);
    let last12M_end_date = new Date(end_date);
    let preYear_start_date = new Date(subYears(last12M_start_date, 1));
    let filterData = monthlyTransactions?.filter(
      (o1) =>
        o1.month &&
        !initialData?.calculationExcludeStates2?.includes(o1.state) &&
        selectedStates?.some((o2) => o2 === o1.state) &&
        selectedScenarios?.some((o2) => o2 === o1.scenario) &&
        new Date(o1.month) >= preYear_start_date &&
        new Date(o1.month) <= last12M_end_date
    );
    const result = eachMonthOfInterval({
      start: last12M_start_date,
      end: last12M_end_date,
    });
    let dummy = [];
    result.forEach((element, index) => {
      let due_date_prev = subYears(element, 1);
      let currentData = filterData?.filter((o1) =>
        isSameMonth(new Date(o1.month), element)
      );
      let prevPreData = filterData?.filter((o1) =>
        isSameMonth(new Date(o1.month), due_date_prev)
      );
      let currentVariableData = currentData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let currentFixData = currentData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );
      let prePerVariableData = prevPreData?.filter((o1) =>
        variableCategoriesId.some((o2) => o2 === o1.category)
      );
      let prePerFixData = prevPreData?.filter((o1) =>
        fixCategoriesId.some((o2) => o2 === o1.category)
      );

      let currentRevenue = currentData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let prevPerRevenue = prevPreData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.inflow ?? 0)),
        0
      );
      let currentExpense = currentData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !privateExpenseCategoryIds?.includes(item?.category)
              ? Math.abs(item?.outflow ?? 0)
              : 0
          ),
        0
      );
      let prevPerExpense = prevPreData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            !privateExpenseCategoryIds?.includes(item?.category)
              ? Math.abs(item?.outflow ?? 0)
              : 0
          ),
        0
      );
      let currentVariableCost = currentVariableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let currentFixCosts = currentFixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let prePerVariableCost = prePerVariableData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let prePerFixCosts = prePerFixData?.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(Math.abs(item?.outflow ?? 0)),
        0
      );
      let currentGrossProfit = currentRevenue - currentVariableCost;
      let prePerGrossProfit = prevPerRevenue - prePerVariableCost;
      let currentOperatingIncome = currentRevenue - currentExpense;
      let prePerOperatingIncome = prevPerRevenue - prevPerExpense;

      dummy[index] = {
        ...dummy[index],
        id: index,
        due_date_12M: format(element, "MM-yyyy"),
        due_date_prev: format(due_date_prev, "MM-yyyy"),
        [`Revenue_12M`]: currentRevenue,
        [`VariableCost_12M`]: currentVariableCost,
        [`FixCosts_12M`]: currentFixCosts,
        [`GrossProfit_12M`]: currentGrossProfit,
        [`OperatingIncome_12M`]: currentOperatingIncome,
        [`Revenue_prev`]: prevPerRevenue,
        [`VariableCost_prev`]: prePerVariableCost,
        [`FixCosts_prev`]: prePerFixCosts,
        [`GrossProfit_prev`]: prePerGrossProfit,
        [`OperatingIncome_prev`]: prePerOperatingIncome,
      };
    });
    let fix = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          fixCategoriesId?.includes(item.category)
            ? item?.outflow_count ?? 0
            : 0
        ),
      0
    );
    let variable = filterData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          variableCategoriesId?.includes(item.category)
            ? item?.outflow_count ?? 0
            : 0
        ),
      0
    );
    let revenue = filterData?.reduce(
      (total, item) =>
        parseFloat(total) + parseFloat(Math.abs(item?.inflow_count ?? 0)),
      0
    );

    let uncategorized = monthlyTransactions
      ?.filter(
        (o1) =>
          !o1.category &&
          !initialData?.calculationExcludeStates2?.includes(o1.state)
      )
      ?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(item?.inflow_count ?? 0) +
          parseFloat(item?.outflow_count ?? 0),
        0
      );
    if (uncategorized > 10) {
      setShowInfo("warning");
    }
    if (
      monthlyTransactions?.length !== 0 &&
      uncategorized < 10 &&
      fix !== 0 &&
      variable !== 0 &&
      revenue !== 0
    ) {
      setShowInfo(false);
    }

    setChartData(dummy);
  }, [
    appliedFilterlist?.reports,
    monthlyTransactions,
    variableCategoriesId,
    fixCategoriesId,
    selectionCategoriesByID,
    privateExpenseCategoryIds,
  ]);

  const onMouseEnter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  if (
    !appliedFilterlist?.reports?.start_date ||
    !appliedFilterlist?.reports?.end_date
  ) {
    return null;
  }

  //RenderFunction
  const headerCell = (props) => {
    const { headerName: title, subTitle, field } = props;
    let value = subTitle;

    if (field === "preMonth") {
      const result = subMonths(currentDate, 1);
      const prevMonthStartDate = format(startOfMonth(result), "dd.MM.yyyy");
      const prevMonthEndDate = format(endOfMonth(result), "dd.MM.yyyy");
      value = `${prevMonthStartDate} - ${prevMonthEndDate}`;
    }
    if (field === "currentYear") {
      const currentYearStartDate = format(
        new Date(appliedFilterlist?.reports?.start_date),
        "dd.MM.yyyy"
      );
      const currentYearEndDate = format(
        new Date(appliedFilterlist?.reports?.end_date),
        "dd.MM.yyyy"
      );
      value = `${currentYearStartDate} - ${currentYearEndDate}`;
    }
    if (field === "preYear") {
      const preYearStartDate = format(
        subYears(new Date(appliedFilterlist?.reports?.start_date), 1),
        "dd.MM.yyyy"
      );
      const preYearEndDate = format(
        subYears(new Date(appliedFilterlist?.reports?.end_date), 1),
        "dd.MM.yyyy"
      );
      value = `${preYearStartDate} - ${preYearEndDate}`;
    }
    return (
      <Typography
        component="div"
        sx={{
          width: "15%",
          color: Color?.blueGrey800,
          fontFamily: Fonts?.Text,
          fontSize: "0.9rem",
          fontweight: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {title}
        <Typography
          component="span"
          variant="span"
          sx={{
            color: Color?.grey500,
            fontFamily: Fonts?.Text,
            fontSize: ".7rem",
            fontweight: 400,
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  const ChartIndicator = ({ title, color = Color.black, type = "solid" }) => {
    return (
      <Typography sx={{ display: "flex", flexDirection: "column", mx: "1rem" }}>
        {title}
        <span
          style={{
            height: "0.15625rem",
            width: "100%",
            borderBottom: `3px ${type} ${color}`,
          }}
        />
      </Typography>
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <Root width={width} height={height}>
      <Popper
        key={id}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
          },
        ]}
      >
        <Alert severity="warning">
          {warnFor === "lastyear"
            ? "The earliest transaction is within the last year - the calculation may be inaccurate."
            : warnFor === "currentyear"
              ? "The earliest transaction is within this year - the calculation may be inaccurate."
              : ""}
        </Alert>
      </Popper>
      <Box sx={{ width: width }}>
        {!subscription?.success_report_view ? (
          <Placeholder
            image={Images.success_report_placeholder}
            insightText={"Success Report"}
            // description={
            //   subscription?.success_report_view && showInfo ? (
            //     <span>
            //       {" "}
            //       We cannot show any interesting here - we need more data.
            //       Please follow our
            //       <Link
            //         color="inherit"
            //         href={
            //           "https://finban.io/documentation/reports/setup-succes"
            //         }
            //         target="_blank"
            //         sx={{
            //           fontFamily: Fonts.Text,
            //           color: Color.themeColor2,
            //           ml: "0.5rem",
            //         }}
            //       >
            //         instructions.
            //       </Link>
            //     </span>
            //   ) : null
            // }
            height={height + remToPx(currentWidth, 6.25)}
            width={width}
            style={{
              mt: "1.5rem",
            }}
          />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                width: width,
                justifyContent: "space-between",
              }}
            >
              <Paper
                className={classes.paper}
                sx={{
                  width: "70%",
                }}
              >
                <DataGrid
                  className={classes.table}
                  rows={rows}
                  columns={Column}
                  // rowHeight={remToPx(currentWidth, 7.885)}
                  getRowHeight={(data) => {
                    if ([1, 2, 5, 6].includes(data.id)) {
                      return remToPx(currentWidth, 3.9425);
                    } else {
                      return remToPx(currentWidth, 7.885);
                    }
                  }}
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                />
              </Paper>

              <Box
                sx={{
                  flex: 1,
                  width: "30%",
                  height: height,
                  border: `2px solid ${Color.theme.grey[200]}`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    height: 55,
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <ChartIndicator
                    title={"Financial Year"}
                    color={alpha(Color?.themeColor2, 0.9)}
                  />
                  <ChartIndicator
                    title={"Previous year "}
                    color={Color.theme.blueGrey[600]}
                    type={"dotted"}
                  />
                </Box>
                {data
                  ?.filter(
                    (o1) => !["OtherRevenue", "OtherCosts"].includes(o1.key)
                  )
                  ?.map((o1, index) => {
                    return (
                      <Box
                        key={o1.key}
                        sx={{
                          flex: 1,
                          height: (height - 50) / 5 - 2 * 5,
                          borderBlock:
                            (index === 1 || index === 3) &&
                            `2px solid ${Color.theme.grey[200]}`,
                        }}
                      >
                        <DashBoardSucessReportLineChart
                          data={chartData}
                          type={o1?.key}
                          title={o1?.title}
                          height={height}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Root>
  );
};

export default memo(SuccessReport);
