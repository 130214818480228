import { forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { setIsOnBoardingOverlayOpen } from "../../store/slices/common";
import { setOnBoardingList } from "../../store/slices/global";
import EndPoints from "../../APICall/EndPoints";
import initialData from "../../Helper/data";
import APICall from "../../APICall";

const OnBoardingFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBoardingList = useSelector((state) => {
    return state?.globalSlice?.onBoardingList;
  });
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);

  //api
  const updateTodoById = async (uuid, payload) => {
    await APICall("patch", `${EndPoints.todos}${uuid}/`, payload).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  //functions
  const updateCheckedProperty = async (uuid, payload) => {
    let parentUpdatedObj = null;
    const traverseAndUpdate = (nodes) => {
      return nodes?.map((node) => {
        if (node?.uuid === uuid) {
          return { ...node, ...payload };
        }
        if (node?.children?.length > 0) {
          const children = traverseAndUpdate(node?.children);
          const isAllChecked = children?.every((child) => child?.checked);
          if (isAllChecked && !node?.checked) {
            parentUpdatedObj = {
              uuid: node?.uuid,
              payload: { checked: isAllChecked },
            };
          }
          if (!isAllChecked && node?.checked) {
            parentUpdatedObj = {
              uuid: node?.uuid,
              payload: { checked: isAllChecked },
            };
          }
          return { ...node, checked: isAllChecked, children: children };
        }
        return node;
      });
    };

    const updatedData = traverseAndUpdate(onBoardingList);
    dispatch(setOnBoardingList(updatedData));
    await updateTodoById(uuid, payload);
    if (parentUpdatedObj) {
      await updateTodoById(parentUpdatedObj?.uuid, parentUpdatedObj?.payload);
    }
  };

  const findObjectByTitle = (data, title) => {
    if (data?.length > 0) {
      for (const child of data) {
        if (child.title === title) {
          return child;
        }
        const result = findObjectByTitle(child?.children, title);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const updateOnboardingList = async (obj) => {
    if (obj?.uuid) {
      await updateCheckedProperty(obj?.uuid, obj?.payload || { checked: true });
    }
    if (obj?.isAccount) {
      const titleToFind = "Connect your Bank Account";
      const foundObject = findObjectByTitle(onBoardingList, titleToFind);
      if (foundObject) {
        updateCheckedProperty(
          foundObject?.uuid,
          obj?.payload || { checked: true }
        );
      }
    }
  };

  const onClickAction = async (item) => {
    updateOnboardingList(item);
    dispatch(setIsOnBoardingOverlayOpen(false));

    const dataset = dataSetData?.uuid || dataSetList?.[0]?.uuid;
    if (item?.button_id === "access-staff-view") {
      navigate(`/${initialData?.path?.organization}/${dataset}/employees`);
    }
    if (item?.button_id === "access-list-view-to-cat") {
      navigate(`/${initialData?.path?.organization}/${dataset}/list/bulk`);
    }
    if (item?.button_id === "access-plan-view") {
      navigate(`/${initialData?.path?.organization}/${dataset}/table`);
    }
    if (item?.button_id === "access-category-view ") {
      navigate(`/${initialData?.path?.organization}/${dataset}/categories`);
    }
    if (item?.button_id === "access-reports-view") {
      navigate(
        `/${initialData?.path?.organization}/${dataset}/reports/overview`
      );
    }
    if (item?.button_id === "access-rules-view") {
      navigate(`/settings/rules`);
    }
    if (item?.button_id === "access-org-settings") {
      navigate(`/settings/organizations`);
    }
    if (item?.button_id === "access-vat-settings-advanced") {
      navigate(`/settings/vats`, {
        state: {
          fromCategories: true,
        },
      });
    }
    if (item?.button_id === "open-add-integration-overlay") {
      props?.setIsOpen("assign");
    }
  };

  useImperativeHandle(_ref, () => ({
    updateOnboardingList,
    onClickAction,
  }));

  return null;
});

export default OnBoardingFunctions;
