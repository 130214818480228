import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

import CustomModal from "../../../components/Model/CustomModal";
import HeaderMappingView from "./HeaderMappingView";
import { cloneDeep } from "../../../Helper/data";
import { Constant } from "../../../Helper";

// ----------------------------------------------------------------------

const requiredFields = ["id", "title", "gross_value", "due_date"];

const GoogleSheetHeaderMappingDialogView = ({
  open,
  onClose,
  updateGSheetsHeaders,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [errors, setErrors] = useState(null);
  const [dropdownValues, setDropdownValues] = useState(Constant.FinbanMappingFields);

  useEffect(() => {
    if (open?.headersSetByUser) {
      setDropdownValues(open?.headersSetByUser);
    } else if (open?.headersFromSheet) {
      const initialDropdownStateClone = cloneDeep(Constant.FinbanMappingFields);
      open?.headersFromSheet.forEach((header) => {
        const key = header.replace(/\s+/g, "_").toLowerCase();
        if (initialDropdownStateClone.hasOwnProperty(key)) {
          initialDropdownStateClone[key] = header;
        }
      });
      setDropdownValues(initialDropdownStateClone);
    }
  }, [open?.headersSetByUser, open?.headersFromSheet]);

  const handleClickAdd = () => {
    const newErrors = {};
    requiredFields.forEach((key) => {
      if (!dropdownValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const headersObj = Object.keys(dropdownValues).reduce((acc, key) => {
        const value = dropdownValues[key];
        if (value) {
          acc[value] = key;
        }
        return acc;
      }, {});
      updateGSheetsHeaders(headersObj);
    }
  };

  return (
    <CustomModal
      textAdd={t("save")}
      heading={t("gsheet_header_mapping_overlay_title")}
      onClose={onClose}
      onAdd={handleClickAdd}
      open={open}
      dialogContentStyle={{
        ...theme.thinScrollBar,
      }}
    >
      <HeaderMappingView
        errors={errors}
        setErrors={setErrors}
        dropdownValues={dropdownValues}
        setDropdownValues={setDropdownValues}
        headersFromSheet={open?.headersFromSheet}
        headersSetByUser={open?.headersSetByUser}
      />
    </CustomModal>
  );
};

export default GoogleSheetHeaderMappingDialogView;
