import {
  Typography,
  useTheme,
  Tooltip,
  Button,
  Radio,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  setIsAccountSelectOverlayOpen,
  setDataSource,
  setAccounts,
} from "../../../store/slices/global";
import {
  setIsAllHeaderApiFetched,
  setIsOpenSnackbar,
} from "../../../store/slices/common";
import OnBoardingFunctions from "../../../components/Stepper/OnBoardingFunctions";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import { setStageLoadingText } from "../../../store/slices/appmain";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomModal from "../../../components/Model/CustomModal";
import { setAddFrom } from "../../../store/slices/settings";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";

const AccountsSelectOverlay = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const onbRef = useRef(null);

  //redux
  const dataSourceById = useSelector(
    (state) => state.globalSlice?.dataSourceById
  );
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  //state
  const [dsLoading, setDsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [disableADD, setDisableADD] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const type = "finapi";
  const open =
    isAccountSelectOverlayOpen?.open && !isAccountSelectOverlayOpen?.isBank;
  const DS = dataSourceById?.[addFrom?.integration_id]?.[0];
  const isAccountNotFound = useDeferredTimeout({
    value: !dsLoading && accountList?.length === 0,
    timeout: 2000,
  });

  //life cycle method
  useEffect(() => {
    if (open && addFrom?.integration_id) {
      setLoader(false);
      setDisableADD(true);
      getAccountList(addFrom?.integration_id);
    }
  }, [addFrom?.integration_id, open]);

  if (!open) {
    return null;
  }

  //api
  const addAccount = async (obj, integration_id) => {
    setLoader(true);
    await APICall(
      "post",
      EndPoints.integrations + integration_id + `/${type}/accounts/`,
      obj
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        if (response?.data?.[0]?.uuid) {
          const account = await getAccountByID(response.data[0]?.uuid);
          if (account?.bank_details?.title) {
            await updateDataSourceByID(integration_id, {
              title: account?.bank_details?.title,
            });
          }
        }
        onbRef.current?.updateOnboardingList({
          isAccount: true,
          payload: {
            checked: true,
          },
        });
        enqueueSnackbar(t("New_Account_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        setTimeout(() => {
          getAccountsApi(false);
          getDataSource(true); //necessary
        }, 0);
      } else {
        setLoader(false);
      }
    });
  };

  const getAccountByID = async (id, obj) => {
    let data = null;
    await APICall("get", EndPoints.accounts + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });
    return data;
  };

  const updateDataSourceByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const getDataSource = async (allowClose = false) => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSource([...response.data.results]));
        dispatch(setIsAllHeaderApiFetched(true));
        if (allowClose) {
          onClose(true);
        }
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const getAccountList = async (integration_id) => {
    setDsLoading(true);
    setDisableADD(true);
    await APICall(
      "get",
      EndPoints.integrations + integration_id + `/${type}/accounts/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        const data = initSelect(response.data);
        setAccountList(data);
        setDsLoading(false);
      } else {
        dispatch(setIsOpenSnackbar(false));
      }
    });
  };

  //function
  const initSelect = (data, id = null) => {
    return data.map((item) => ({
      ...item,
      isSelected: id ? (id === item.id ? !item?.isSelected : false) : false,
    }));
  };

  const onAddAccount = () => {
    const selectedAccount = accountList?.find((o1) => o1.isSelected);
    const obj = {
      id: selectedAccount?.id,
    };
    if (selectedAccount) {
      addAccount(obj, addFrom?.integration_id);
    } else {
      enqueueSnackbar(t("please_select_bank_account"), {
        variant: "info",
        autoHideDuration: 4000,
      });
    }
  };

  const onClickList = (item) => {
    let updatedItemList = accountList.map((element) => {
      if (element.id === item.id) {
        setDisableADD(false);
        return { ...element, isSelected: true };
      } else {
        return { ...element, isSelected: false };
      }
    });
    setAccountList(updatedItemList);
  };

  const handleClose = () => {
    clearAddFrom();
    onClose();
  };

  const onClose = (doReset = false) => {
    dispatch(setIsAccountSelectOverlayOpen(null));
    if (doReset) {
      resetKanban();
    }
  };

  const resetKanban = async () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
  };

  const clearAddFrom = () => {
    dispatch(setAddFrom(null));
    dispatch(setStageLoadingText(null));
    setLoader(false);
  };

  //render function
  return (
    <CustomModal
      modalType="add"
      heading={t("Select_Account")}
      open={open}
      backdropClose={false}
      loadingAdd={loader}
      disabled={disableADD}
      onAdd={onAddAccount}
      onClose={handleClose}
      backdropStyle={{
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
      }}
    >
      <OnBoardingFunctions ref={onbRef} />
      <Box
        sx={{
          backgroundColor: Color.BodyBG,
          borderBottomLeftRadius: theme.borderRadius.main,
          borderBottomRightRadius: theme.borderRadius.main,
          px: "2rem",
          py: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          width: "60rem",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              width: "35%",
              alignItems: "center",
              pl: "3.25rem",
            }}
          >
            {t("Name")}
          </Typography>

          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              width: "20%",
              alignItems: "center",
              pl: "0.5rem",
            }}
          >
            {t("Type")}
          </Typography>
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              width: "20%",
              alignItems: "center",
              pl: "0.75rem",
            }}
          >
            {t("Currency")}
          </Typography>
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightBold"}
            sx={{
              minWidth: "30%",
              alignItems: "center",
              pl: "1rem",
            }}
          >
            {t("IBAN")}
          </Typography>
        </Box>
        {dsLoading || isAccountNotFound ? (
          <Box
            sx={{
              flex: 1,
              pr: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ComponentLoader
              loading={dsLoading}
              key1="bank_account_list_no_data_text_01"
              key2="bank_account_list_no_data_text_02"
              sx={{
                width: "80%",
              }}
            />
          </Box>
        ) : (
          accountList?.map((item) => {
            return (
              <AccountsViewList
                key={item?.id}
                item={item}
                onClickList={() => onClickList(item)}
                theme={theme}
                disabled={item?.currency !== DS?.currency}
              />
            );
          })
        )}
        {DS?.currency && !dsLoading && !isAccountNotFound ? (
          <Tooltip
            arrow
            followCursor
            title={t("bank_account_list_currency_tooltip")}
            placement="top"
          >
            <Typography
              variant="subtitle2"
              fontWeight={"fontWeightBold"}
              sx={{
                position: "absolute",
                left: "1.5rem",
                bottom: "1.5rem",
                textAlign: "left",
                ml: "2rem",
              }}
            >
              {DS?.currency}
            </Typography>
          </Tooltip>
        ) : null}
      </Box>
    </CustomModal>
  );
};

export default AccountsSelectOverlay;

const AccountsViewList = ({ item, theme, onClickList, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClickList}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        my: "0.5rem",
        p: 0,
        borderRadius: theme.borderRadius.main,
        backgroundColor: item?.isSelected
          ? theme.palette.color.slate[100]
          : null,
        opacity: disabled ? "0.5" : "1",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[50],
        },
      }}
    >
      <Radio
        edge="start"
        checked={item?.isSelected}
        tabIndex={-1}
        disableRipple
        sx={{ pl: "2rem" }}
      />

      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "30%",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {item?.name}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "20%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.type}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "20%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.currency}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMedium"}
        color={"color.slate.500"}
        sx={{
          width: "30%",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        {item?.iban}
      </Typography>
    </Button>
  );
};
