import {
  ClickAwayListener,
  Typography,
  useTheme,
  Tooltip,
  Dialog,
  Button,
  Popper,
  Stack,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as React from "react";

import { truncate, updateBatchTransactions } from "../../../Helper/data";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setStageLoadingText } from "../../../store/slices/appmain";
import { setProfile } from "../../../store/slices/settings";
import CategoryChip from "../../../components/CategoryChip";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import RuleModel from "./RuleModel";

const AttentionOverlay = ({ open, setOpen, title, message, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const itemRow = React.useRef();
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const excluded_transaction_titles = useSelector(
    (state) => state.settingsSlice?.profile?.excluded_transaction_titles
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  // const _categories = useSelector((state) => state.categorySlice?.categories);
  const [openRule, setOpenRule] = React.useState(false);
  const [foundedRule, setFoundedRule] = React.useState(null);

  const rule_type = data?.current?.rule_type;

  // const categories = React.useMemo(() => {
  //   return _categories?.filter((o1) =>
  //     use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
  //   );
  // }, [_categories, dataSetData?.uuid, use_global_categories]);

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const updateCardsBatch = async (array, action) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    await updateBatchTransactions(array);
    reset(action);
    onClose();
    dispatch(setStageLoadingText(null));
  };

  const deleteRulesByIdApi = async (id) => {
    await APICall("delete", EndPoints.transactions_rules + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === "rules",
          });
        }
      }
    );
  };

  const getRulesByIdApi = async (id) => {
    await APICall("get", EndPoints.transactions_rules + `${id}/`).then(
      (response) => {
        if (response.status === 200 && response) {
          setFoundedRule(response?.data);
        }
      }
    );
  };

  const updateRulesByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions_rules + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setStageLoadingText(null));
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === "rules",
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (!!open) {
      const transaction_rule = data?.current?.transaction_rule;
      getRulesByIdApi(transaction_rule);
    }
  }, [data, open]);

  const isCategoryValid = use_global_categories
    ? !selectionCategoriesByID?.[foundedRule?.category]?.[0]?.dataset
    : selectionCategoriesByID?.[foundedRule?.category]?.[0]?.dataset ===
      dataSetData?.uuid;
  // console.log("isCategoryValid:", isCategoryValid);

  const onClickHelp = () => {
    window.open("https://finban.io/documentation/rules/", "_blank");
  };

  const onClickAssignAndUpdate = async () => {
    await updateRulesByID(data?.current?.transaction_rule, {
      category: data.current?.category,
    });
    if (data.current?.payload) {
      await updateCardsBatch(data.current?.payload);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
    }
    onClose();
  };

  const onClickDelete = async () => {
    await deleteRulesByIdApi(data?.current?.transaction_rule);
    if (data.current?.payload) {
      await updateCardsBatch(data.current?.payload);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
    }
    onClose();
  };

  const onClickOverwrite = () => {
    let newArray = [];
    const action = {
      overwrite_transaction_rule: true,
    };
    if (data.current?.payload) {
      newArray = data.current?.payload?.map((item) => {
        return {
          ...item,
          ...action,
        };
      });
      data.current.newObj = data.current?.oldObj;
      updateCardsBatch(newArray, action);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
  };

  const onClickNoOverwrite = () => {
    let newArray = [];
    const action = {
      skip_transaction_rule: true,
    };
    if (data.current?.payload) {
      newArray = data.current?.payload?.map((item) => {
        return {
          ...item,
          ...action,
        };
      });
      data.current.newObj = data.current?.oldObj;
      updateCardsBatch(newArray, action);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
  };

  const onClickExclude = async () => {
    await deleteRulesByIdApi(data?.current?.transaction_rule);
    const isAlreadyAdded = excluded_transaction_titles?.find(
      (i) => i === data.current?.title
    );
    if (!isAlreadyAdded) {
      const _data = [
        ...(excluded_transaction_titles || []),
        data.current?.title,
      ];
      dispatch(
        setProfile({
          key: "excluded_transaction_titles",
          value: _data,
        })
      );
      await updateProfile({ excluded_transaction_titles: _data });
    }
    let newArray = [];
    const action = {
      overwrite_transaction_rule: true,
    };
    if (data.current?.payload) {
      data.current?.payload?.forEach((item) => {
        newArray.push({
          ...item,
          ...action,
        });
      });
      updateCardsBatch(newArray, action);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj, action);
      }
      onClose();
    }
    data.current.newObj = data.current?.oldObj;
  };

  const onClickManualRule = () => {
    itemRow.current = {
      category: data.current?.category,
      transaction_type: data.current?.transaction_type,
      title: data.current?.title,
      onClickExclude,
    };
    setOpenRule(true);
  };

  const onClickKeepRule = async () => {
    if (foundedRule && data.current?.payload) {
      itemRow.current = {
        category: foundedRule?.category,
        categoryTitle:
          selectionCategoriesByID?.[foundedRule?.category]?.[0]?.title,
      };
      let newArray = data.current?.payload?.map((item) => {
        return {
          ...item,
          category: foundedRule?.category,
        };
      });
      data.current.newObj = {
        ...data.current?.oldObj,
        category: foundedRule?.category,
      };
      updateCardsBatch(newArray);
    } else {
      if (data.current?.callback) {
        data.current?.callback(data.current?.newObj);
      }
      onClose();
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const reset = async (action) => {
    if (data.current?.resetBulkStates) {
      data.current?.resetBulkStates();
    }
    if (data.current?.updateList) {
      data.current?.updateList();
    }
    if (data.current?.callback) {
      data.current?.callback(data.current?.newObj, action);
    }
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      refetchType: "active",
      predicate: (query) =>
        (query.queryKey[0] === "transactions" ||
          query.queryKey[0] === "List") &&
        query.queryKey[1]?.dataset === dataSetData?.uuid,
    };
    queryClient.invalidateQueries(options);
  };

  const onClickSearchRule = () => {
    navigate("/settings/rules", {
      state: {
        searchText: foundedRule?.transaction_title,
      },
    });
  };

  const onClickManage = () => {
    navigate("/settings/rules");
  };

  return (
    <div>
      {openRule && (
        <RuleModel
          setOpen={setOpenRule}
          open={openRule}
          transactionsRow={itemRow.current}
          notEditable
        />
      )}

      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 1303 }}
        PaperProps={{
          style: {
            minWidth: "85rem",
            height: "25rem",
            padding: "2rem",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: 8,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="div"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              lineHeight: "1.5rem",
              fontWeight: "600",
              color: "color.slate.500",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "color.slate.500",
                mr: "1rem",
              }}
            />

            {t(title)}
          </Typography>

          <Stack direction="row" alignItems={"center"}>
            <Button
              onClick={onClickHelp}
              disableElevation
              disableFocusRipple
              sx={{
                ml: "auto",
                p: 0,
                textTransform: "initial",
                fontSize: "0.875rem",
                fontWeight: "500",
                color: "color.purple.700",
                "&:hover": {
                  color: "color.purple.800",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              {t("Help")}
            </Button>
            <Button
              onClick={onClickManage}
              disableElevation
              disableFocusRipple
              sx={{
                ml: "auto",
                p: 0,
                textTransform: "initial",
                fontSize: "0.875rem",
                fontWeight: "500",
                color: "color.purple.700",
                "&:hover": {
                  color: "color.purple.800",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              {t("Manage Rules")}
            </Button>
          </Stack>
        </Stack>

        <Typography
          my="1.5rem"
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "color.description",
          }}
        >
          <Translate i18nkey={message} />
        </Typography>

        <Box
          display={"flex"}
          alignItems={"center"}
          width={"fit-content"}
          p="0.75rem"
          borderRadius={1.5}
        >
          <Typography
            variant="body1"
            fontWeight={"fontWeightMediumBold"}
            mr="0.5rem"
            width={"8rem"}
          >
            {t("Recent action")}:
          </Typography>{" "}
          {data.current?.category ? (
            <CategoryChip categoryId={data.current?.category} width="8rem" />
          ) : null}
          <span
            style={{
              marginInline: "0.5rem",
              lineHeight: "1rem",
              marginBottom: "1px",
            }}
          >
            <Icon
              icon={<IoIosArrowRoundForward />}
              fontSize={"1.8rem"}
              color="color.slate.600"
            ></Icon>
          </span>
          <Stack
            direction={"row"}
            backgroundColor={"color.slate.50"}
            p={3.5}
            alignItems={"center"}
            borderRadius={theme.borderRadius.main}
            width={"25rem"}
          >
            <Tooltip
              title={foundedRule?.transaction_title}
              arrow
              placement="top"
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  color: "color.slate.600",
                }}
              >
                {truncate(foundedRule?.transaction_title, 42)}
              </Typography>
            </Tooltip>
            {foundedRule?.note ? (
              <Tooltip title={foundedRule?.note} arrow placement="top">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    color: "color.slate.600",
                    ml: "0.5rem",
                  }}
                >
                  {` / ${truncate(foundedRule?.note, 42)}`}
                </Typography>
              </Tooltip>
            ) : null}
          </Stack>
          {rule_type === "manual" ? (
            <TailwindButton
              text={t("category_rule_already_available_assign_and_keep_rule")}
              tooltip={
                "category_rule_already_available_assign_and_keep_rule_tooltip"
              }
              onClick={onClickNoOverwrite}
              backgroundColor={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                "&:hover": {
                  backgroundColor: "color.green.700",
                  borderColor: "color.green.700",
                },
              }}
            />
          ) : (
            <TailwindButton
              variant="outlined"
              text={t("category_rule_already_available_assign_and_keep_rule")}
              tooltip={
                "category_rule_already_available_assign_and_keep_rule_tooltip"
              }
              onClick={onClickNoOverwrite}
              color={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                backgroundColor: "color.white",
                "&:hover": {
                  backgroundColor: "color.green.700",
                  borderColor: "color.green.700",
                  color: "color.white",
                },
              }}
            />
          )}
          {rule_type === "manual" ? null : (
            <TailwindButton
              tooltip={"category_rule_assign_category_and_update_rule_tooltip"}
              text={t("category_rule_assign_category_and_update_rule")}
              onClick={onClickAssignAndUpdate}
              backgroundColor={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                "&:hover": {
                  backgroundColor: "color.green.700",
                  borderColor: "color.green.700",
                },
              }}
            />
          )}
        </Box>

        {rule_type === "manual" ? (
          <Stack direction={"row"} alignItems={"center"} sx={{ ml: "0.75rem" }}>
            <Typography
              variant="h6"
              color="color.mainTitle"
              sx={{ width: "44.5rem" }}
            >
              {t("manual_rule_available")}
            </Typography>
            <TailwindButton
              variant="outlined"
              text={t("Search Rules")}
              onClick={onClickSearchRule}
              color={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                backgroundColor: "color.white",
                "&:hover": {
                  backgroundColor: "color.green.100",
                  borderColor: "color.green.100",
                },
              }}
            />
          </Stack>
        ) : (
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"fit-content"}
            p="0.75rem"
            borderRadius={1.5}
          >
            <Typography
              variant="body1"
              fontWeight={"fontWeightMediumBold"}
              mr="0.5rem"
              width={"8rem"}
            >
              {t("Saved Rule")}:
            </Typography>{" "}
            {foundedRule?.category ? (
              <CategoryChip categoryId={foundedRule?.category} width="8rem" />
            ) : null}
            <span
              style={{
                marginInline: "0.5rem",
                lineHeight: "1rem",
                marginBottom: "1px",
              }}
            >
              <Icon
                icon={<IoIosArrowRoundForward />}
                fontSize={"1.8rem"}
                color="color.slate.600"
              ></Icon>
            </span>
            <Stack
              direction={"row"}
              backgroundColor={"color.slate.50"}
              p={3.5}
              alignItems={"center"}
              borderRadius={theme.borderRadius.main}
              width={"25rem"}
            >
              <Tooltip
                title={foundedRule?.transaction_title}
                arrow
                placement="top"
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    color: "color.slate.600",
                  }}
                >
                  {truncate(foundedRule?.transaction_title, 42)}
                </Typography>
              </Tooltip>
            </Stack>
            <TailwindButton
              tooltip={"category_rule_already_available_no_overwrite_tooltip"}
              text={t("category_rule_already_available_no_overwrite")}
              onClick={onClickKeepRule}
              backgroundColor={theme.palette.color.green[500]}
              sx={{
                borderRadius: 50,
                "&:hover": {
                  backgroundColor: "color.green.700",
                  borderColor: "color.green.700",
                },
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            bottom: "1.5rem",
            position: "absolute",
            mt: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TailwindButton
              type="cancel"
              text={t("Cancel")}
              onClick={onClose}
              backgroundColorCancel={theme.palette.color.slate[200]}
              sx={{
                ml: 0,
                borderRadius: 50,
              }}
            />

            <MoreView
              onClickManualRule={onClickManualRule}
              onClickExclude={onClickExclude}
              onClickOverwrite={onClickOverwrite}
              onClickDelete={onClickDelete}
            />
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default AttentionOverlay;
const MoreView = ({
  onClickManualRule,
  onClickExclude,
  onClickOverwrite,
  onClickDelete,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const overlayRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //functions
  const onClickMore = () => {
    if (!anchorEl) {
      setAnchorEl(overlayRef.current);
    } else {
      onClickAway();
    }
  };

  const onClickAway = () => setAnchorEl(null);

  return (
    <Box
      ref={overlayRef}
      sx={{
        position: "relative",
        alignSelf: "flex-end",
      }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top"}
        transition
        disablePortal
        sx={{
          zIndex: 1303,
          inset: "auto !important",
          transform: "none !important",
          top: "-600% !important",
          right: "-12% !important",
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  minWidth: "22rem",
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  border: `1px solid ${theme.palette.color.slate[400]}`,
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                {" "}
                <TailwindButton
                  variant="outlined"
                  text={t("category_rule_already_available_add_manual")}
                  tooltip={"category_rule_already_available_add_manual_tooltip"}
                  onClick={onClickManualRule}
                  color={theme.palette.color.slate[500]}
                  sx={{
                    borderRadius: 50,
                    width: "max-content",
                    "&:hover": {
                      backgroundColor: "color.slate.200",
                      color: "color.slate.700",
                      borderColor: "color.slate.700",
                    },
                  }}
                />
                <TailwindButton
                  variant="outlined"
                  tooltip={"category_rule_already_available_exclude_tooltip"}
                  text={t("category_rule_already_available_exclude")}
                  onClick={onClickExclude}
                  color={theme.palette.color.red[500]}
                  sx={{
                    my: "1rem",
                    width: "max-content",
                    borderRadius: 50,
                    "&:hover": {
                      backgroundColor: "color.red.200",
                      color: "color.red.700",
                      borderColor: "color.red.700",
                    },
                  }}
                />
                <TailwindButton
                  tooltip={"category_rule_already_available_overwrite_tooltip"}
                  text={t("category_rule_already_available_overwrite")}
                  onClick={onClickOverwrite}
                  backgroundColor={theme.palette.color.red[500]}
                  sx={{
                    width: "max-content",
                    borderRadius: 50,
                    mb: "1rem",
                    "&:hover": {
                      backgroundColor: "color.red.700",
                    },
                  }}
                />
                <TailwindButton
                  tooltip={"category_rule_delete_rule_tooltip"}
                  text={t("category_rule_delete_rule")}
                  onClick={onClickDelete}
                  backgroundColor={theme.palette.color.red[500]}
                  sx={{
                    width: "max-content",
                    borderRadius: 50,
                    mb: "1rem",
                    "&:hover": {
                      backgroundColor: "color.red.700",
                    },
                  }}
                />
                <TailwindButton
                  variant="outlined"
                  text={t("Close")}
                  onClick={onClickMore}
                  color={theme.palette.color.white}
                  sx={{
                    width: "8rem",
                    borderRadius: 50,
                    backgroundColor: "color.slate.600",
                    borderColor: "color.slate.700",
                    "&:hover": {
                      backgroundColor: "color.slate.700",
                    },
                  }}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <TailwindButton
        variant="outlined"
        text={t("More actions")}
        onClick={onClickMore}
        color={theme.palette.color.slate[500]}
        sx={{
          minWidth: "8rem",
          borderRadius: 50,
          "&:hover": {
            backgroundColor: theme.palette.color.slate[200],
            color: theme.palette.color.slate[700],
            borderColor: theme.palette.color.slate[700],
          },
        }}
      />
    </Box>
  );
};
