import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
  useTheme,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import TailwindButton from "../Overlay/TailwindButton";
import { setPopupStatus3 } from "../../store/slices/datasets";
import { updateKanbanFilters } from "../../store/slices/global";

export default function ConfirmationModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  //redux
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);
  const action_confirm = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban[popupStatus3?.from]
  );
  const [rememberChecked, setRememberChecked] = React.useState(
    popupStatus3?.from ? action_confirm : popupStatus3?.rememberChecked
  );
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  //functions
  const onClose = () => {
    dispatch(setPopupStatus3(null));
    if (popupStatus3?.onClose) popupStatus3?.onClose();
  };

  const onCloseViaBackdrop = () => {
    if (!popupStatus3?.disableBackdropClick) onClose();
  };

  const onCloseViaButton = () => {
    onClose();
  };

  const handleRememberChange = (event) => {
    setRememberChecked(event.target.checked);
    popupStatus3?.onCheckMarkChange(event.target.checked);
    if (popupStatus3?.from) {
      dispatch(
        updateKanbanFilters({ [popupStatus3?.from]: event.target.checked })
      );
    }
  };

  const handleCheckChange = (event) => {
    setIsConfirmed(event.target.checked);
  };

  return (
    <Dialog
      open={popupStatus3?.open}
      onClose={onCloseViaBackdrop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 1320 }}
      PaperProps={{
        style: {
          minWidth: "45rem",
          maxWidth: "50rem",
          padding: "2rem",
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: 8,
          // backgroundColor:
          //   popupStatus3?.type === "info"
          //     ? Color.tailwind.blue[50]
          //     : popupStatus3?.type === "warn"
          //     ? Color.tailwind.red[50]
          //     : Color.white,
        },
      }}
    >
      <Typography
        variant="h5"
        fontWeight={"fontWeightBold"}
        sx={{
          display: "flex",
          alignItems: "center",
          color:
            popupStatus3?.type === "info"
              ? theme.palette.color.blue[500]
              : popupStatus3?.type === "warn"
                ? theme.palette.color.red[500]
                : theme.palette.color.black,
        }}
      >
        {" "}
        {popupStatus3?.type === "info" && popupStatus3?.showIcon ? (
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.color.blue[500],
              mr: "1rem",
            }}
          />
        ) : null}
        {popupStatus3?.type === "warn" && popupStatus3?.showIcon ? (
          <WarningAmberOutlinedIcon
            sx={{
              color: theme.palette.color.red[500],
              mr: "1rem",
            }}
          />
        ) : null}
        {popupStatus3?.payload?.title}
      </Typography>
      <Box mt="1.5rem" mb="0.5rem">
        <Typography
          variant="body1"
          sx={{
            backgroundColor:
              popupStatus3?.type === "info"
                ? theme.palette.color.blue[50]
                : theme.palette.color.red[50],
            color:
              popupStatus3?.type === "info"
                ? theme.palette.color.blue[500]
                : theme.palette.color.red[500],
            p: "1rem",
            borderRadius: theme.borderRadius.main,
          }}
        >
          {/* <span
            style={{
              fontWeight: theme.typography.fontWeightMediumBold,
            }}
          >
            {popupStatus3?.type === "info" ? t("Info") : t("Warning")}
            {`: `}
          </span> */}
          {popupStatus3?.payload?.message}
        </Typography>
      </Box>
      {popupStatus3?.showConfirmCheckBox ? (
        <FormControlLabel
          sx={{
            mb: "0.5rem",
            ml: "-9px",
          }}
          control={
            <Checkbox
              variant="outlined"
              value="remember"
              color="primary"
              checked={isConfirmed || false}
              onChange={handleCheckChange}
            />
          }
          label={t(popupStatus3?.confirmCheckBoxLabel)}
        />
      ) : null}
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div>
          {!popupStatus3?.payload?.hideCancel && (
            <TailwindButton
              type="cancel"
              text={popupStatus3?.payload?.cancelText || t("Cancel")}
              onClick={onCloseViaButton}
              backgroundColorCancel={
                popupStatus3?.type
                  ? theme.palette.color.slate[200]
                  : theme.palette.color.slate[100]
              }
              sx={{
                ml: 0,
              }}
            />
          )}
          <TailwindButton
            text={
              popupStatus3?.loading
                ? undefined
                : popupStatus3?.payload?.confirmText || t("Confirm")
            }
            loading={popupStatus3?.loading}
            disable={popupStatus3?.showConfirmCheckBox && !isConfirmed}
            onClick={
              popupStatus3?.onConfirm
                ? () =>
                    popupStatus3?.onConfirm({
                      payload: popupStatus3?.payload,
                      fromSequence: popupStatus3?.payload?.fromSequence,
                    })
                : undefined
            }
            backgroundColor={
              popupStatus3?.overlay_type === "delete"
                ? theme.palette.color.red[500]
                : theme.palette.color.green[500]
            }
            sx={{
              py: popupStatus3?.loading ? "1.2rem" : "0.75rem",
              "&.MuiLoadingButton-root.Mui-disabled": {
                backgroundColor:
                  popupStatus3?.overlay_type === "delete"
                    ? `${theme.palette.color.red[200]} !important`
                    : `${theme.palette.color.green[200]} !important`,
                color: `${theme.palette.color.white} !important`,
                fontWeight: theme.typography.fontWeightMediumBold,
              },
            }}
          />
        </div>

        {popupStatus3?.showCheckBox ? (
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "0.9rem",
              },
            }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberChecked || false}
                onChange={handleRememberChange}
              />
            }
            label={t("don't show this checkbox again")}
          />
        ) : null}
      </Box>
    </Dialog>
  );
}
