import { Box, Paper, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { useEffect } from "react";
import _ from "underscore";

import {
  getTransactionByUrl,
  formatAmount,
  remToPx,
} from "../../../Helper/data";
import { Color, Fonts } from "../../../Helper";
import useWidth from "../../../hooks/useWidth";

const GridView = React.memo(
  ({ title, subTitle, columnData = null, selectedItem = 1, width = "47%" }) => {
    const currentWidth = useWidth();
    //redux
    const aggregates = useSelector((state) => state.chartSlice?.aggregates);
    const dateRange = useSelector((state) => state.chartSlice?.dateRange);

    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const contacts = useSelector((state) => state.globalSlice?.contacts);
    const groupedContacts = useMemo(() => {
      return _.groupBy(contacts, ({ uuid }) => uuid);
    }, [contacts]);
    const [gridData, setGridData] = useState(null);
    const columns = useMemo(
      () => [
        {
          field: "title",
          headerName: "Title",
          width: remToPx(currentWidth, 7.3),
        },
        {
          field: "due_date",
          headerName: "Date",
          width: remToPx(currentWidth, 7.3),
          valueFormatter: ({ value }) => {
            return aggregates.value === "year"
              ? format(new Date(value), "yyyy")
              : aggregates.value === "month"
                ? format(new Date(value), "MMM yyyy")
                : aggregates.value === "daily"
                  ? format(new Date(value), "MMM dd")
                  : null;
          },
        },
        {
          field: "gross_value",
          headerName: "Amount",
          width: remToPx(currentWidth, 7.3),
          valueFormatter: ({ value }) => {
            return formatAmount({
              amount: String(value),
            });
          },
        },
        {
          field: "Contact",
          headerName: selectedItem === 1 ? "Customers" : "Suppliers",
          width: remToPx(currentWidth, 7.3),
          valueFormatter: ({ value }) => {
            return value ? groupedContacts[value][0]?.name ?? "" : "";
          },
        },
      ],
      [currentWidth, selectedItem, aggregates.value, groupedContacts]
    );
    useEffect(() => {
      if (dateRange) {
        fetchData();
      }
    }, [dataSetData?.uuid, dateRange, selectedItem]);

    //functions
    async function fetchData() {
      let url = `&missing_date=false&from_date=${format(
        dateRange[0],
        "yyyy-MM-dd"
      )}&to_date=${format(dateRange[1], "yyyy-MM-dd")}`;
      if (selectedItem === 1) {
        url = url + "&min_gross_value=500";
      } else {
        url = url + "&max_gross_value=-500";
      }
      const response = await getTransactionByUrl(dataSetData?.uuid, url);
      setGridData(response);
    }

    //RenderFunction
    return (
      <Paper
        elevation={0}
        sx={{
          boxShadow:
            "4px -3px 1px -14px rgb(0 0 0 / 20%), 2px 4px 5px -3px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          borderRadius: 1,
          width: width,
          height: "30rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "1rem",
            mb: "1.75rem",
          }}
        >
          <Typography
            sx={{
              mx: "1rem",
              fontSize: "1.2rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.black,
              display: "inline-flex",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            px: "1rem",
          }}
        >
          {" "}
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.greyText,
              opacity: 0.9,
            }}
          >
            By contribution to
          </Typography>
          <Typography
            sx={{
              mx: "0.5rem",
              fontSize: "1.05rem",
              fontWeight: 700,
              fontFamily: Fonts.Text,
              color: Color.black,
              opacity: 0.9,
            }}
          >
            {subTitle}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.greyText,
              opacity: 0.9,
            }}
          >
            this period
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "24.13rem",
            pt: "2.8rem",
          }}
        >
          {gridData?.length > 0 ? (
            <DataGrid
              disableColumnMenu
              density="compact"
              rows={gridData}
              columns={columnData ? columnData : columns}
              pageSize={5}
              rowHeight={remToPx(currentWidth, 4)}
              getRowId={(row) => row.uuid}
            />
          ) : (
            <Typography
              sx={{
                height: "100%",
                fontSize: "1.2rem",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                color: Color.black,
                opacity: 0.9,
                alignSelf: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              There is no data to show yet
            </Typography>
          )}
        </Box>
      </Paper>
    );
  },
  (prev, next) =>
    prev.data === next.data &&
    prev.title === next.title &&
    prev.aggregates === next.aggregates &&
    prev.subTitle === next.subTitle
);

export default GridView;
