import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staffData: [],
  employeeType: [],
  employeeDepartment: [],
  employeeScenarios: [],
  staffSearchText: "",
  employeeStateTab: "Planned",
  contractData: [],
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setEmployeeStateTab: (state, action) => {
      state.employeeStateTab = action.payload;
    },
    setEmployeeScenarios: (state, action) => {
      state.employeeScenarios = action.payload;
    },
    setEmployeeType: (state, action) => {
      state.employeeType = action.payload;
    },
    setEmployeeDepartment: (state, action) => {
      state.employeeDepartment = action.payload;
    },
    setStaffSearchText: (state, action) => {
      state.staffSearchText = action.payload;
    },
    setContractData: (state, action) => {
      state.contractData = action.payload;
    },
  },
});
export const {
  setStaffData,
  setEmployeeStateTab,
  setEmployeeScenarios,
  setEmployeeType,
  setEmployeeDepartment,
  setStaffSearchText,
  setContractData,
} = staffSlice.actions;
export default staffSlice;
