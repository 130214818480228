import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TbError404 } from "react-icons/tb";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";

import { setPageNotFound } from "../store/slices/common";
import Icon from "../components/Icon";
import theme from "../theme";

const NotFoundPage = ({ isLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogin) {
      dispatch(setPageNotFound(true));
    }
  }, [dispatch, isLogin]);

  const onClickHome = () => {
    navigate("/", { replace: true });
    dispatch(setPageNotFound(false));
  };

  if (!isLogin) {
    navigate("/login", { replace: true });
  }

  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "background.paper",
      }}
    >
      <Icon
        icon={<TbError404 />}
        fontSize="8rem"
        color={theme.palette.color.slate[600]}
        style={{ mb: 3 }}
      />
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        color={theme.palette.color.slate[600]}
      >
        Page Not Found
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="color.description"
        sx={{ maxWidth: 400, mb: 4 }}
      >
        Sorry, the page you are looking for does not exist. You can return to
        the home page.
      </Typography>
      <Button variant="contained" color="primary" onClick={onClickHome}>
        Go to Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
