import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Color, Fonts } from "../../../Helper";
import set from "date-fns/set";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import subWeeks from "date-fns/subWeeks";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import getYear from "date-fns/getYear";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { useDispatch, useSelector } from "react-redux";
// import "react-datetime/css/react-datetime.css";
// import Datetime from "react-datetime";
import {
  setAggregates,
  setDateRange,
  setPeriodDateRange,
  setPeriodTranData,
  setRefPeriodTranData,
} from "../../../store/slices/chart";
import {
  getStatisticsData,
  getStatisticsDataWithParams,
} from "../../../Helper/data";
import { useQuery } from "@tanstack/react-query";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { queryClient } from "../../../App";
import { useTranslation } from "react-i18next";
import DropDown from "../../../components/Overlay/DropDown";

// const aggregatesOptions = [
//   // { value: "daily", label: "Daily aggregates" },
//   { value: t("month"), label: t("Monthly aggregates") },
//   { value: t("year"), label: t("Yearly aggregates") },
// ];
// const dailyPeriodOptions = [
//   { value: t("period"), label: `${t("Previous")} ${t("period")}` },
//   { value: t("day"), label: `${t("Previous")} ${t("day")}` },
//   { value: "1", label: `1 ${t("week")} ${t("before")}` },
//   { value: "2", label: `2 ${t("weeks")} ${t("before")}` },
//   { value: "3", label: `3 ${t("weeks")} ${t("before")}` },
//   { value: "4", label: `4 ${t("weeks")} ${t("before")}` },
// ];
// const monthPeriodOptions = [
//   { value: t("period"), label: `${t("Previous")} ${t("period")}` },
//   { value: t("month"), label: `${t("Previous")} ${t("month")}` },
//   { value: "3", label: `3 ${t("months")} ${t("before")}` },
//   { value: "6", label: `6 ${t("months")} ${t("before")}` },
//   { value: "9", label: `9 ${t("months")} ${t("before")}` },
//   { value: "12", label: `12 ${t("months")} ${t("before")}` },
// ];
// const yearPeriodOptions = [
//   { value: t("period"), label: `${t("Previous")} ${t("period")}` },
//   { value: t("year"), label: `${t("Previous")} ${t("year")}` },
//   { value: "2", label: `2 ${t("years")} ${t("before")}` },
//   { value: "3", label: `3 ${t("years")} ${t("before")}` },
// ];

const HeaderView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const aggregatesOptions = [
    // { value: "daily", label: "Daily aggregates" },
    { value: "month", label: "Monthly aggregates" },
    { value: "year", label: "Yearly aggregates" },
  ];
  const dailyPeriodOptions = [
    { value: "period", label: "Previous period" },
    { value: "day", label: "Previous day" },
    { value: "1", label: "1 week before" },
    { value: "2", label: "2 weeks before" },
    { value: "3", label: "3 weeks before" },
    { value: "4", label: "4 weeks before" },
  ];
  const monthPeriodOptions = [
    { value: "period", label: "Previous period", count: "" },
    { value: "month", label: "Previous month", count: "" },
    { value: "3", label: "count months before", count: "3" },
    { value: "6", label: "count months before", count: "6" },
    { value: "9", label: "count months before", count: "9" },
    { value: "12", label: "count months before", count: "12" },
  ];
  const yearPeriodOptions = [
    { value: "period", label: "Previous period" },
    { value: "year", label: "Previous year" },
    { value: "2", label: "2 years before" },
    { value: "3", label: "3 years before" },
  ];

  //redux state
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const aggregates = useSelector((state) => state.chartSlice?.aggregates);
  const dateRange = useSelector((state) => state.chartSlice?.dateRange);
  const periodDateRange = useSelector(
    (state) => state.chartSlice?.periodDateRange
  );

  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  let preWeekFromDay = [
    new Date(dataSetData?.start_date),
    new Date(dataSetData?.end_date),
  ];

  //state
  const [period, setPeriod] = useState(monthPeriodOptions[0]);
  const [periodOptions, setPeriodOptions] = useState(monthPeriodOptions);
  const [hoverRange, setHoverRange] = useState("month");
  const [startYear, setStartYear] = useState(new Date());
  const [endYear, setEndYear] = useState(new Date());

  const period_start_date =
    dateRange?.[0] && dateRange?.[0] !== "Invalid Data"
      ? format(new Date(dateRange[0]), "yyyy-MM-dd")
      : null;
  const period_end_date =
    dateRange?.[1] && dateRange?.[1] !== "Invalid Data"
      ? format(new Date(dateRange[1]), "yyyy-MM-dd")
      : "";
  const prev_period_start_date =
    periodDateRange?.[0] && periodDateRange?.[0] !== "Invalid Data"
      ? format(new Date(periodDateRange[0]), "yyyy-MM-dd")
      : null;
  const prev_period_end_date =
    periodDateRange?.[1] && periodDateRange?.[1] !== "Invalid Data"
      ? format(new Date(periodDateRange[1]), "yyyy-MM-dd")
      : "";

  const period_data = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
      },
    ],
    queryFn: () => {
      const result = getStatisticsDataWithParams({
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
        multiStatesIds: stateByTitle?.["Booked"]?.[0]?.uuid,
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    select: (response) => {
      dispatch(setPeriodTranData(response?.results || []));
      return response;
    },

    priority: 1,
    enabled:
      !!dataSetData?.uuid &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen &&
      !!stateByTitle?.["Booked"]?.[0]?.uuid &&
      !!period_end_date &&
      !!period_start_date,
  });
  const prev_period_data = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "prev_period_data",
        start_date: prev_period_start_date,
        end_date: prev_period_end_date,
      },
    ],
    queryFn: () => {
      const result = getStatisticsDataWithParams({
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: prev_period_start_date,
        to_payment_date: prev_period_end_date,
        multiStatesIds: stateByTitle?.["Booked"]?.[0]?.uuid,
      });

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    select: (response) => {
      // console.log("🚀 /prev_period_data:", response?.results);
      dispatch(setRefPeriodTranData(response?.results || []));
      return response;
    },
    priority: 1,
    enabled:
      !!dataSetData?.uuid &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen &&
      !!stateByTitle?.["Booked"]?.[0]?.uuid &&
      !!period_end_date &&
      !!period_start_date,
  });

  //lifeCycle method
  useEffect(() => {
    getDatePeriod();
  }, [dateRange, period]);

  useDebounce(
    () => {
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "period_data",
        type: "active",
      };
      queryClient.invalidateQueries(options, { cancelRefetch: true });
    },
    500,
    [dateRange]
  );

  useLayoutEffect(() => {
    if (dataSetData?.start_date && dataSetData?.end_date) {
      dispatch(
        setDateRange([
          new Date(dataSetData?.start_date),
          new Date(dataSetData?.end_date),
        ])
      );
    }
  }, [dataSetData?.start_date, dataSetData?.end_date, dispatch]);

  //function
  const onChangeAggregates = (e, values) => {
    if (values) {
      dispatch(setAggregates(values));

      const startDate = startOfWeek(new Date());
      const endDate = endOfWeek(new Date());
      let yearChange = [
        set(new Date(), {
          month: 0,
          date: 1,
          year: getYear(new Date()) - 1,
        }),
        set(new Date(), {
          month: 11,
          date: 31,
          year: getYear(new Date()) + 1,
        }),
      ];
      setStartYear(yearChange[0]);
      setEndYear(yearChange[1]);
      dispatch(
        setDateRange(
          values.value === "daily"
            ? [startDate, endDate]
            : values.value === "month"
            ? preWeekFromDay
            : values.value === "year"
            ? yearChange
            : null
        )
      );
      setPeriod(
        values.value === "daily"
          ? dailyPeriodOptions[0]
          : values.value === "month"
          ? monthPeriodOptions[0]
          : values.value === "year"
          ? yearPeriodOptions[0]
          : null
      );
      setPeriodOptions(
        values.value === "daily"
          ? dailyPeriodOptions
          : values.value === "month"
          ? monthPeriodOptions
          : values.value === "year"
          ? yearPeriodOptions
          : dailyPeriodOptions
      );
      if (values.value === "daily" || values.value === "year") {
        setHoverRange(null);
        return;
      }
      setHoverRange(values.value);
    }
  };

  const onChangePeriods = (e, values) => {
    if (values) setPeriod(values);
  };

  const onChangeRangeDate = (values) => {
    if (values) dispatch(setDateRange(values));
  };

  const onChangeStartYear = (change) => {
    if (change?._isAMomentObject) {
      setStartYear(change._d);
      setEndYear(change._d);
    }
  };

  const onChangEndYear = (change) => {
    if (change?._isAMomentObject) {
      setEndYear(change._d);
      let date = [
        set(new Date(startYear), { month: 0, date: 1 }),
        set(new Date(change._d), { month: 11, date: 31 }),
      ];
      dispatch(setDateRange(date));
    }
  };

  const getDatePeriod = useCallback(() => {
    if (
      dateRange &&
      dateRange[0] !== "Invalid Date" &&
      dateRange[1] !== "Invalid Date"
    ) {
      let startDate = new Date(dateRange[0]);
      let endDate = new Date(dateRange[1]);
      let newStartDate = null;
      let differnceDay = differenceInCalendarDays(startDate, endDate);
      let newEndDate = null;
      let key = ["period", "day", "month", "year"];
      if (period.value === "period") {
        newStartDate = addDays(startDate, differnceDay - 1);
        newEndDate = subDays(startDate, 1);
      }
      if (period.value === "day") {
        newStartDate = subDays(startDate, 1);
        newEndDate = subDays(endDate, 1);
      }
      if (period.value === "month") {
        newStartDate = subMonths(startDate, 1);
        newEndDate = subMonths(endDate, 1);
      }
      if (period.value === "year") {
        newStartDate = subYears(startDate, 1);
        newEndDate = subYears(endDate, 1);
      }
      if (aggregates.value === "daily" && !key.includes(period.value)) {
        newStartDate = subWeeks(startDate, period.value);
        newEndDate = subWeeks(endDate, period.value);
      }
      if (aggregates.value === "month" && !key.includes(period.value)) {
        newStartDate = subMonths(startDate, period.value);
        newEndDate = subMonths(endDate, period.value);
      }
      if (aggregates.value === "year" && !key.includes(period.value)) {
        newStartDate = subYears(startDate, period.value);
        newEndDate = subYears(endDate, period.value);
      }
      dispatch(setPeriodDateRange([newStartDate, newEndDate]));
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "prev_period_data",
        type: "active",
      };
      queryClient.invalidateQueries(options, { cancelRefetch: true });
    }
  }, [dateRange, period.value, aggregates.value]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        mt: "4rem",
        mb: "2.5rem",
        position: "relative",
        color: Color.greyText,
        "&.MuiOutlinedInput-root": {
          color: Color.greyText,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderRadius: 2,
          borderColor: `${Color.autoCompleteBorder} !important`,
        },
        ".MuiAutocomplete-option": {
          color: Color.greyText,
        },
        ".rs-picker-toggle-value": {
          color: `${Color.greyText} !important`,
          fontSize: "1rem",
        },
        ".rs-picker-toggle:hover": {
          borderColor: `${Color.autoCompleteBorder} !important`,
        },
        ".rs-picker-toggle": {
          borderColor: `${Color.autoCompleteBorder} !important`,
        },
      }}
    >
      <DropDown
        hideTitle
        mb="0"
        id="controllable-states-demo"
        size="small"
        disableClearable
        sx={{
          width: "fit-content",
          minWidth: "14.75rem",
          backgroundColor: Color.white,
        }}
        onChange={onChangeAggregates}
        value={aggregates ?? null}
        options={aggregatesOptions}
        isOptionEqualToValue={(option) => option.value}
        renderOption={(option) => t(option.label)}
        getOptionLabel={(option) => t(option.label)}
      />
      <Typography sx={{ mx: "1.5rem" }}>from</Typography>
      {aggregates.value === "year" ? (
        <Box
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            "& input": {
              width: "8.125rem",
              height: "2.5rem",
              pl: "0.5rem",
              color: Color.greyText,
              fontSize: "0.9rem",
              borderRadius: 2,
              borderColor: `${Color.autoCompleteBorder} !important`,
            },

            "& .rdt": {
              mx: "0.5rem",
              "& :focus-visible": {
                outline: "none",
              },
            },
          }}
        >
          {/* <Datetime
            dateFormat="YYYY"
            timeFormat={false}
            onChange={onChangeStartYear}
            value={startYear}
            closeOnSelect
          /> */}
          <Typography>~</Typography>
          {/* <Datetime
            dateFormat="YYYY"
            timeFormat={false}
            onChange={onChangEndYear}
            closeOnSelect
            value={endYear}
          /> */}
        </Box>
      ) : (
        {/* dateRange?.length > 0 &&
        dateRange[0] !== "Invalid Date" &&
        dateRange[1] !== "Invalid Date" &&
        typeof dateRange[0] !== "string" && (
          <DateRangePicker
            hoverRange={hoverRange}
            ranges={[]}
            value={dateRange}
            className="dateRange"
            renderValue={(value) => {
              if (value && value?.length > 0 && value[0] !== "Invalid Date") {
                return (
                  format(new Date(value[0]), "MMM dd,yyyy") +
                  " ~ " +
                  format(new Date(value[1]), "MMM dd,yyyy")
                );
              }
            }}
            showWeekNumbers
            onChange={onChangeRangeDate}
            placeholder="Select date range"
          />
        ) */}
      )}

      <Typography sx={{ mx: "0.5rem" }}>vs</Typography>

      <DropDown
        hideTitle
        mb="0"
        id="controllable-states-demo"
        size="small"
        disableClearable
        sx={{
          width: "fit-content",
          minWidth: "14.75rem",
          backgroundColor: Color.white,
        }}
        onChange={onChangePeriods}
        value={period ?? null}
        options={periodOptions}
        isOptionEqualToValue={(option) => option?.value}
        renderOption={(option) => t(option.label, { count: option.count })}
        getOptionLabel={(option) => t(option.label, { count: option.count })}
      />
      {periodDateRange?.length > 0 &&
        periodDateRange?.[0] !== "Invalid Date" && (
          <Typography
            sx={{
              mx: "1.25rem",
              position: "absolute",
              top: "3.5rem",
              fontSize: "0.8rem",
              fontFamily: Fonts.Text,
              fontWeight: 500,
            }}
          >
            {format(new Date(periodDateRange[0]), "MMM dd,yyyy") +
              " ~ " +
              format(new Date(periodDateRange[1]), "MMM dd,yyyy")}
          </Typography>
        )}
    </Box>
  );
};
export default HeaderView;
