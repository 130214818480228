import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import React from "react";

import { formatAmount } from "../../../Helper/data";
import { Color, Fonts } from "../../../Helper";

const WidgetInnerView = React.memo(
  ({ value = 0, date = null, percentValue = "0%", high, type }) => {
    const { t } = useTranslation();

    //redux
  

    let valueNumber = formatAmount({
      amount: String(parseFloat(value ?? 0).toFixed(2)),
    });
    let formattedDate = date && format(new Date(date), "MMM dd");

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          position: "relative",
          mt: "0.25rem",
        }}
      >
        <Typography
          sx={{
            width: "36%",
            maxWidth: "100px",
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "pre",
            overflow: "hidden",
            alignItems: "center",
            fontSize: "0.9rem",
            fontWeight: 800,
            fontFamily: Fonts.Text,
            color: Color.black,
            textAlign: "left",
            opacity: 0.78,
            mr: "0.25rem",
          }}
        >
          {valueNumber}
        </Typography>
        <Typography
          sx={{
            width: "28%",
            display: "flow-root",
            alignItems: "center",
            fontSize: "0.65rem",
            fontWeight: 600,
            fontFamily: Fonts.Text,
            color: Color.greyText,
            alignSelf: "end",
            pb: "0.1rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textAlign:"left"
          }}
        >
          {date ? `${t("at")} ${formattedDate}` : t("last month")}
        </Typography>
        <Tooltip title={percentValue}>
          <Chip
            label={percentValue}
            size="small"
            icon={
              high ? (
                type === "2" ? (
                  <ArrowUpwardIcon
                    sx={{
                      color: `${Color.expenseBarBg} !important`,
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    sx={{
                      color: `${Color.expenseBarBg} !important`,
                    }}
                  />
                )
              ) : type === "2" ? (
                <ArrowDownwardIcon
                  sx={{
                    color: `${Color.green800} !important`,
                  }}
                />
              ) : (
                <ArrowUpwardIcon
                  sx={{
                    color: `${Color.green800} !important`,
                  }}
                />
              )
            }
            sx={{
              width: "32%",
              position: "absolute",
              right: 0,
              ml: "0.5rem",
              pt: "0.15rem",
              height: "1.25rem",
              fontWeight: 800,
              fontFamily: Fonts.Text,
              fontSize: "0.6rem",
              color: high ? Color.expenseBarBg : Color.green800,
              backgroundColor: high ? Color.red100 : Color.greenA100,
              border: `1px solid ${high ? Color.expenseBarBg : Color.green}`,
              "& .MuiSvgIcon-root": {
                fontSize: "1rem",
                mr: "-0.375rem",
              },
            }}
          />
        </Tooltip>
      </Box>
    );
  }
);
export default WidgetInnerView;
