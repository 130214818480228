import { format, endOfMonth, subMonths, startOfMonth } from "date-fns";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { IoTrendingDownSharp } from "react-icons/io5";
import { IoTrendingUpSharp } from "react-icons/io5";
import { BiDollarCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FiUsers } from "react-icons/fi";
import { Outlet } from "react-router";
import { Box } from "@mui/material";

import { getTransactionByUrl } from "../../../Helper/data";
import MainChartView from "../Reports/MainChratView";
import AccountsGridView from "./AccountsGridView";
import HeaderView from "../Reports/HeaderView";
import OverviewWidget from "./OverviewWidget";
import { Color } from "../../../Helper";

const Dashboard = () => {
  const { t } = useTranslation();

  //function
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        overflowY: "auto",
      }}
    >
      <Box
        width={{ lg: "76rem", mid: "75.5rem" }}
        sx={{
          height: "fit-content",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          pt: "4rem",
        }}
      >
        <WidgetsView />
        {/* <Box
          sx={{
            pt: "5rem",
            width: "100%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <AccountsGridView
            title={t("Latest Transactions")}
            subTitle={"Revenue"}
            type="latest"
          />
        </Box> */}
        <HeaderView />
        <Box
          sx={{
            mb: "1.5rem",
            width: "100%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "47%",
              height: "25rem",
              display: "flex",
            }}
          >
            {" "}
            <MainChartView
              title={"Profit"}
              selectedItem={0}
              chartId={"2"}
              chartName={"Line"}
              type={"Overview"}
            />
          </Box>
          <Box
            sx={{
              width: "47%",
              height: "25rem",
              display: "flex",
            }}
          >
            {" "}
            <MainChartView
              title={"Revenue"}
              selectedItem={1}
              type={"Revenue"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            mb: "18rem",
            width: "100%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "47%",
              height: "25rem",
              display: "flex",
            }}
          >
            {" "}
            <MainChartView
              title={"Expenses"}
              selectedItem={2}
              type={"Expenses"}
            />
          </Box>
          <Box
            sx={{
              width: "47%",
              height: "25rem",
              display: "flex",
            }}
          >
            {" "}
            <MainChartView
              title={"Revenue"}
              selectedItem={1}
              type={"Revenue"}
            />
          </Box>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default Dashboard;

const WidgetsView = () => {
  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);

  //state
  const [filteredTransactionData, setFilteredTransactionData] = useState([]);
  const [loader, setLoader] = useState(false);

  //life cycle method
  useEffect(() => {
    if (dataset) {
      getTransaction();
    }
  }, [ dataset]);

  //api
  const getTransaction = async () => {
    let bookedId = stateByTitle?.["Booked"]?.[0]?.uuid;
    if (bookedId) {
      setLoader(true);
      let currentMonthEnd = format(endOfMonth(new Date()), "yyyy-MM-dd");
      let preMonthStart = format(
        startOfMonth(subMonths(new Date(), 1)),
        "yyyy-MM-dd"
      );
      let url = `&from_date=${preMonthStart}&to_date=${currentMonthEnd}&state=${bookedId}`;
      const result = await getTransactionByUrl(dataset, url);
      if (result) {
        setFilteredTransactionData(result);
        setLoader(false);
      }
    }
  };

  return (
    <Box
      sx={{
        mt: "1.5rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <OverviewWidget
        filteredTransactionData={filteredTransactionData}
        icon={<BiDollarCircle />}
        title="Profit"
        type="0"
        loader={loader}
      />
      <OverviewWidget
        filteredTransactionData={filteredTransactionData}
        icon={<IoTrendingUpSharp />}
        title="Revenue"
        type="1"
        loader={loader}
      />
      <OverviewWidget
        filteredTransactionData={filteredTransactionData}
        icon={<IoTrendingDownSharp />}
        title="Expenses"
        type="2"
        loader={loader}
      />
      <OverviewWidget
        filteredTransactionData={filteredTransactionData}
        icon={<FiUsers />}
        title="Customers"
        type="3"
        loader={loader}
      />
    </Box>
  );
};
