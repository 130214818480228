import {
  Typography,
  useTheme,
  Popover,
  Stack,
  Alert,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
  setPopupStatus4,
} from "../../../store/slices/datasets";
import SearchFilterView from "../ThemeFilter/Component/SearchFilterView";
import TreeView from "../../../Pages/Settings/Categories/TreeView";
import { Color, Constant } from "../../../Helper";
import initialData from "../../../Helper/data";
import ThemeTabs from "../../ThemeTabs";
import AddButton from "../../AddButton";
import store from "../../../store";

const CategoryTreeSelect = ({
  type = 3,
  anchorEl = null,
  onClickCategoryTitle,
  isDataSet = false,
  isGlobal = false,
  fromSequence = null,
  isSelect = false,
  handleClosePopOver,
  hiddenCategory = [],
  allowParentCategory = false,
  ruleTitle = false,
  hideUncategorize = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const treeRef = useRef(null);

  let categoryRef = useRef(null);
  const preventClose = useRef(false);

  //state
  const [searchText, setSearchText] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState(type === 3 ? 1 : type);

  useEffect(() => {
    if (type !== 3) {
      if (type !== value) {
        setValue(type);
      }
    }
  }, [type]);

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onClickClearSearch = () => {
    setSearchText("");
  };

  const onClickAddNewCategory = (e) => {
    e.stopPropagation();
    const categoryData = treeRef.current?.categoryData;
    if (categoryData) {
      categoryRef?.current?.onClickAddNewCategory(e, {
        value,
        setValue,
        categoryData,
      });
    }
  };

  const onClose = (e) => {
    e.stopPropagation();

    if (!preventClose?.current) {
      if (handleClosePopOver) {
        handleClosePopOver(e);
      } else {
        dispatch(setPopupStatus4(null));
      }
    }
  };

  const onClickLink = () => {
    dispatch(setTransactionsOverlayStatus(null));
    dispatch(setPopupStatus2(null));
    dispatch(setPopupStatus4(null));
    let dataset = store?.getState()?.boardSlice?.dataSetData?.uuid;
    const dataSetList = store?.getState()?.boardSlice?.dataSetList;

    if (isDataSet) {
      dataset = isDataSet;
    } else if (dataset) {
    } else {
      dataset = dataSetList?.[0]?.uuid;
    }

    navigate(`/${initialData?.path?.organization}/${dataset}/categories`);
  };

  return (
    <>
      <Popover
        id={Boolean(anchorEl) ? "simple-popover-1" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={8}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{
          paper: {
            sx: {
              border: 0,
              borderRadius: 1.5,
              boxShadow: Constant.OverlayBoxShadow,
            },
          },
        }}
        sx={{
          zIndex: 1303,
        }}
      >
        <Box
          sx={{
            border: 0,
            width: "45rem",
            height: "42rem",
            display: "flex",
            alignItems: "start",
            py: "2rem",
            flexDirection: "column",
            backgroundColor: Color.white,
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", px: "2rem", mb: "1rem" }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ gap: "1rem" }}
            >
              <Typography
                variant="h6"
                component={"h6"}
                sx={{
                  fontWeight: 800,
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {value === 1 ? t("Inflow Category") : t("Outflow Category")}
              </Typography>
              {isSaving ? (
                <Alert
                  icon={<UpdateIcon />}
                  severity="success"
                  sx={{
                    boxShadow: "none !important",
                    fontSize: "0.8rem",
                    padding: "0.35rem 0.75rem",
                    backgroundColor: theme.palette.color.slate[100],
                    display: "inline-flex",
                    alignItems: "center",
                    alignSelf: "center",
                    "& .MuiAlert-icon": {
                      color: theme.palette.color.slate[700],
                      fontSize: "1rem",
                      py: "0rem",
                      alignItems: "center",
                    },
                    "& .MuiAlert-message": {
                      color: theme.palette.color.slate[700],
                      textAlign: "left",
                      py: "0rem",
                      display: "inline-flex",
                    },
                  }}
                >
                  {t("Saving")}
                </Alert>
              ) : null}
            </Stack>

            <AddButton
              onClick={onClickAddNewCategory}
              label={t("Add_New_Category")}
              isNKey
              sx={{
                width: "fit-content",
              }}
            />
          </Stack>

          <SearchFilterView
            active={false}
            value={searchText}
            color={"slate"}
            onClickClear={onClickClearSearch}
            onChange={onChangeSearch}
            width="16rem"
            sx={{ width: "fit-content", mx: "2rem", mb: "1rem" }}
          />

          {type === 3 ? (
            <ThemeTabs
              tab={value}
              setTab={setValue}
              options={[
                { label: t("Inflow"), value: 1 },
                { label: t("Outflow"), value: 2 },
              ]}
              sx={{
                px: "2rem",
              }}
            />
          ) : null}

          <TreeView
            ref={treeRef}
            categoryRef={categoryRef}
            hideUncategorize={hideUncategorize}
            isOverlay
            setIsSaving={setIsSaving}
            isDataSet={isDataSet}
            isGlobal={isGlobal}
            searchText={searchText}
            hiddenCategory={hiddenCategory}
            allowParentCategory={allowParentCategory}
            ruleTitle={ruleTitle}
            onClickCategoryTitle={onClickCategoryTitle}
            fromSequence={fromSequence}
            isSelect={isSelect}
            preventClose={preventClose}
            value={value}
            LoaderSx={{
              sx: { px: "2rem" },
            }}
            sx={{
              mb: "2rem",
            }}
          />

          <Typography
            onClick={onClickLink}
            sx={{
              position: "absolute",
              bottom: "1rem",
              right: "2rem",
              display: "inline-flex",
              fontSize: "0.8rem !important",
              fontWeight: 600,
              cursor: "pointer",
              color: theme.palette.primary.main,
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Manage categories")}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default CategoryTreeSelect;
