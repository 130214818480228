import {
  eachMonthOfInterval,
  getDaysInMonth,
  startOfQuarter,
  isSameQuarter,
  lastDayOfYear,
  endOfQuarter,
  startOfMonth,
  isSameMonth,
  subQuarters,
  endOfMonth,
  addMonths,
  subMonths,
  isValid,
  setDate,
  format,
} from "date-fns";
import _ from "underscore";

import { Constant } from "./Constant";

export const getStartingLiquidityValue = ({
  monthlyTransactions,
  groupedMonthlyTransactions,
  selected_scenario_uuid,
  date,
  selectionCategories,
  advanceVat,
  isChart,
  isTable,
}) => {
  let LiquidityTotal = 0;
  let VatChargeTotal = 0;
  let currentMonth = format(new Date(), "yyyy-MM");
  if (date > currentMonth) {
    let dateArray = eachMonthOfInterval({
      start: subMonths(startOfMonth(new Date()), 6),
      end: subMonths(startOfMonth(new Date(date)), 1),
    });

    if (dateArray?.length && selectionCategories) {
      dateArray?.forEach((columnElement) => {
        let monthDate = format(new Date(columnElement), "yyyy-MM");

        let statsData = groupedMonthlyTransactions[monthDate] ?? [];
        let monthData = statsData?.filter(
          (o1) =>
            !Constant?.calculationExcludeStates2.includes(o1?.state) &&
            selected_scenario_uuid?.includes(o1?.scenario_uuid)
        );

        let monthEndTotal = 0;
        let monthEndVatChargeTotal = 0;
        let startVatDate = null;
        let endVatDate = null;
        let isVatPayMentMonth = false;
        let vat_pay_date = 0;
        let vatData = [];

        const isPastMonth = monthDate < currentMonth;
        const isCurrentMonth = monthDate === currentMonth;
        const isFutureMonth = monthDate > currentMonth;

        let isVatPast = false;
        let isVatCurrent = false;
        let isVatFuture = false;
        if (advanceVat && advanceVat?.enabled) {
          const isMonthly =
            String(advanceVat?.frequency) === "1" &&
            !advanceVat?.permanent_extension;
          const isMonthlyExtended =
            String(advanceVat?.frequency) === "1" &&
            advanceVat?.permanent_extension;
          const isQuarterly =
            String(advanceVat?.frequency) === "2" &&
            !advanceVat?.permanent_extension;
          const isQuarterlyExtended =
            String(advanceVat?.frequency) === "2" &&
            advanceVat?.permanent_extension;

          if (isMonthly) {
            const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 1);
            const currentStartDate = format(
              startOfMonth(new Date()),
              "yyyy-MM"
            );
            const currentEndDate = format(endOfMonth(new Date()), "yyyy-MM");
            startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
            endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 1),
              "yyyy-MM"
            );
            isVatPast = endVatDate < currentStartDate;
            isVatFuture = startVatDate > currentEndDate;
            isVatCurrent = isSameMonth(new Date(startVatDate), new Date());
          }
          if (isMonthlyExtended) {
            const prevMonth = subMonths(startOfMonth(new Date(monthDate)), 2);
            const currentStartDate = format(
              startOfMonth(new Date()),
              "yyyy-MM"
            );
            const currentEndDate = format(endOfMonth(new Date()), "yyyy-MM");
            startVatDate = format(startOfMonth(prevMonth), "yyyy-MM");
            endVatDate = format(endOfMonth(prevMonth), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 2),
              "yyyy-MM"
            );

            isVatPast = endVatDate < currentStartDate;
            isVatFuture = startVatDate > currentEndDate;
            isVatCurrent = isSameMonth(new Date(startVatDate), new Date());
          }
          if (isQuarterly) {
            const prevQuarter = subQuarters(
              startOfMonth(new Date(monthDate)),
              1
            );
            const currentStartDate = format(
              startOfQuarter(new Date()),
              "yyyy-MM"
            );
            const currentEndDate = format(endOfQuarter(new Date()), "yyyy-MM");
            startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
            endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 1),
              "yyyy-MM"
            );
            isVatPast = endVatDate < currentStartDate;
            isVatFuture = startVatDate > currentEndDate;
            isVatCurrent = isSameQuarter(new Date(startVatDate), new Date());
          }
          if (isQuarterlyExtended) {
            const prevQuarter = subQuarters(
              startOfMonth(new Date(monthDate)),
              1
            );
            const currentStartDate = format(
              startOfQuarter(new Date()),
              "yyyy-MM"
            );
            const currentEndDate = format(endOfQuarter(new Date()), "yyyy-MM");
            startVatDate = format(startOfQuarter(prevQuarter), "yyyy-MM");
            endVatDate = format(endOfQuarter(prevQuarter), "yyyy-MM");
            vat_pay_date = format(
              addMonths(startOfMonth(new Date(endVatDate)), 2),
              "yyyy-MM"
            );
            isVatPast = endVatDate < currentStartDate;
            isVatFuture = startVatDate > currentEndDate;
            isVatCurrent = isSameQuarter(new Date(startVatDate), new Date());
          }

          isVatPayMentMonth = isSameMonth(
            new Date(monthDate),
            new Date(vat_pay_date)
          );
          if (isVatPayMentMonth) {
            vatData = monthlyTransactions?.filter(
              (item) =>
                item?.month >= startVatDate &&
                item?.month <= endVatDate &&
                !Constant?.calculationExcludeStates2.includes(item?.state) &&
                selected_scenario_uuid?.includes(item.scenario_uuid)
            );
          }
        }
        // if (isPastMonth) {
        //   let future_booked_data = monthData?.filter((item) =>
        //     Constant?.bookedState?.includes(item.state)
        //   );
        //   let future_booked_total = future_booked_data?.reduce(
        //     (total, item) =>
        //       parseFloat(total) +
        //       parseFloat(item?.inflow ?? 0) +
        //       parseFloat(item?.outflow ?? 0),
        //     0
        //   );
        //   monthEndTotal = future_booked_total;
        // }
        if (isCurrentMonth) {
          let allData = monthlyTransactions?.filter(
            (item) =>
              item.month < currentMonth &&
              !Constant.bookedState.includes(item.state) &&
              !Constant.plannedState.includes(item.state) &&
              !Constant?.calculationExcludeStates.includes(item?.state) &&
              selected_scenario_uuid?.includes(item.scenario_uuid)
          );

          let InflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 1,
            time: "current",
            allData,
            isChart,
            isTable,
          });
          let OutflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 2,
            time: "current",
            allData,
          });
          // if (isChart || isTable) {
          //   console.log(
          //     "🚀 current:",
          //     isChart,
          //     isTable,
          //     statsData,
          //     InflowMonthTotal,
          //     OutflowMonthTotal
          //   );
          // }

          monthEndTotal =
            (InflowMonthTotal?.total || 0) -
            (InflowMonthTotal?.currentBookedTotal || 0) +
            (OutflowMonthTotal?.total || 0) -
            (OutflowMonthTotal?.currentBookedTotal || 0);
        }
        if (isFutureMonth) {
          let InflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 1,
            time: "future",
          });
          let OutflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 2,
            time: "future",
          });
          // console.log("🚀  future:", InflowMonthTotal, OutflowMonthTotal);

          monthEndTotal =
            (InflowMonthTotal?.total || 0) + (OutflowMonthTotal?.total || 0);
        }
        if (!isPastMonth && isVatPast && isVatPayMentMonth) {
          let Vdata = vatData?.filter((item) => item.state === "Booked");
          monthEndVatChargeTotal = Vdata?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(item?.vat_outflow ?? 0) +
              parseFloat(item?.vat_inflow ?? 0),
            0
          );
        }
        if (isVatCurrent && isVatPayMentMonth) {
          let allData = monthlyTransactions?.filter(
            (item) =>
              item?.month < startVatDate &&
              selected_scenario_uuid?.includes(item.scenario_uuid) &&
              !Constant.bookedState.includes(item.state) &&
              !Constant.plannedState.includes(item.state) &&
              !Constant?.calculationExcludeStates.includes(item?.state)
          );
          let currentInflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 1,
            time: "current",
            calculation_type: "vat",
            allData,
          });

          let currentOutflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 2,
            time: "current",
            calculation_type: "vat",
            allData,
          });
          // if (isChart || isTable) {
          //   console.log(
          //     "🚀 current:",
          //     isChart,
          //     isTable,
          //     statsData,
          //     currentInflowVat,
          //     currentOutflowVat
          //   );
          // }
          // console.log("🚀 current vat :", currentInflowVat, currentOutflowVat);
          monthEndVatChargeTotal =
            (currentInflowVat?.vat || 0) + (currentOutflowVat?.vat || 0);
        }
        if (isVatFuture && isVatPayMentMonth) {
          let futureInflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 1,
            time: "future",
            calculation_type: "vat",
          });
          let futureOutflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 2,
            time: "future",
            calculation_type: "vat",
          });
          // console.log(
          //   "🚀  futureInflowVat:",
          //   futureInflowVat,
          //   futureOutflowVat
          // );

          monthEndVatChargeTotal =
            (futureInflowVat?.vat || 0) + (futureOutflowVat?.vat || 0);
        }
        // if (isChart || isTable) {
        //   console.log(
        //     "🚀 / month:",
        //     // isChart,
        //     // isTable,
        //     monthDate,
        //     // isPastMonth,
        //     isCurrentMonth,
        //     isFutureMonth,

        //     startVatDate,
        //     vat_pay_date,
        //     "vat",
        //     // isVatPayMentMonth,
        //     isVatPast,
        //     isVatCurrent,
        //     isVatFuture,
        //     monthEndTotal,
        //     monthEndVatChargeTotal
        //   );
        // }

        LiquidityTotal = (LiquidityTotal || 0) + (monthEndTotal || 0);
        VatChargeTotal = (VatChargeTotal || 0) + (monthEndVatChargeTotal || 0);
      });
    }
  }

  return {
    LiquidityTotal,
    VatChargeTotal,
  };
};

export const getMonthlyValue = ({
  selectionCategories,
  data,
  type = 1,
  time = "current",
  calculation_type = "amount",
  allData,
  isCheck,
  isChart,
  isTable,
}) => {
  let currentMonth = format(new Date(), "yyyy-MM");
  let bookedTotal = 0;
  let finalTotal = 0;
  let finalVatTotal = 0;
  let pastData = null;
  let pastTotalSum = 0;
  let pastVatSum = 0;
  let categoryWisePastTotal = {};
  let pastStartMonth = format(
    subMonths(new Date(), Constant.Past_Month),
    "yyyy-MM"
  );

  let unCategorizedData = null;
  if (calculation_type === "amount") {
    unCategorizedData = data?.filter(
      (item) =>
        (time === "current"
          ? item.month === currentMonth
          : item.month > currentMonth) && !item?.category
    );
  } else {
    unCategorizedData = data?.filter((item) => !item?.category);
  }
  if (time === "current") {
    if (calculation_type === "amount") {
      pastData = allData?.filter(
        (item) => item.month >= pastStartMonth && item.month < currentMonth
      );
    } else {
      pastData = allData?.filter((item) => item.month >= pastStartMonth);
    }
  }
  const unCategorizedValue = getTotal({
    data: unCategorizedData,
    pastData: pastData?.filter((item) => !item?.category),
    time,
    type,
    currentMonth,
    uuid: "unCategorized",
    title: "unCategorized",
    calculation_type,
  });
  // if(isChart){
  //   console.log("isChart ~", "categoryWisePastTotal", unCategorizedData);
  // }
  // if(isTable){
  //   console.log("isTable ~", "categoryWisePastTotal", unCategorizedData);
  // }
  if (time === "current") {
    categoryWisePastTotal[unCategorizedValue?.uuid] =
      unCategorizedValue?.past?.total;
    // if (isChart && unCategorizedValue?.total > 0) {
    //   console.log("isChart ~", "unCategorized", unCategorizedValue);
    // }
    // if (isTable && unCategorizedValue?.total > 0) {
    //   console.log("isTable ~", "unCategorized", unCategorizedValue);
    // }
    bookedTotal = bookedTotal + (unCategorizedValue?.currentBookedTotal || 0);
  }
  pastTotalSum = pastTotalSum + (unCategorizedValue?.past?.total || 0);
  pastVatSum = pastVatSum + (unCategorizedValue?.past?.vat || 0);
  finalTotal = finalTotal + (unCategorizedValue?.total || 0);
  finalVatTotal = finalVatTotal + (unCategorizedValue?.vatTotal || 0);

  selectionCategories?.forEach((category) => {
    if (category?.type === type && category?.visible) {
      let categorizedData = null;
      if (calculation_type === "amount") {
        categorizedData = data?.filter(
          (item) =>
            (time === "current"
              ? item.month === currentMonth
              : item.month > currentMonth) && item?.category === category?.uuid
        );
      } else {
        categorizedData = data?.filter(
          (item) => item?.category === category?.uuid
        );
      }
      // pastData: pastData?.filter((item) =>
      //     selectionsCategoryUuidCollections?.[category?.uuid]?.includes(
      //       item?.category
      //     )
      //   ),
      const categorizedValue = getTotal({
        data: categorizedData,
        pastData: pastData?.filter((item) => item?.category === category?.uuid),
        time,
        type,
        currentMonth,
        uuid: category?.uuid,
        title: category?.title,
        calculation_type,
        isCheck,
      });

      if (time === "current") {
        categoryWisePastTotal[categorizedValue?.uuid] =
          categorizedValue?.past?.total;
        // if (isChart && categorizedValue?.total > 0) {
        //   console.log("isChart ~", category?.title, categorizedValue);
        // }
        // if (isTable && categorizedValue?.total > 0) {
        //   console.log("isTable ~", category?.title, categorizedValue);
        // }
        bookedTotal = bookedTotal + (categorizedValue?.currentBookedTotal || 0);
      }
      pastTotalSum = pastTotalSum + (categorizedValue?.past?.total || 0);
      pastVatSum = pastVatSum + (categorizedValue?.past?.vat || 0);
      finalTotal = finalTotal + (categorizedValue?.total || 0);
      finalVatTotal = finalVatTotal + (categorizedValue?.vatTotal || 0);
    }
  });

  return {
    currentBookedTotal: bookedTotal,
    total: finalTotal,
    vat: finalVatTotal,
    past: { total: pastTotalSum, vat: pastVatSum },
    categoryWisePastTotal,
  };
};

const getTotal = ({
  data,
  pastData,
  time,
  type,
  uuid,
  title,
  currentMonth,
  calculation_type,
  isCheck,
}) => {
  if (time === "current") {
    let pastTotal = 0;
    let pastVatTotal = 0;
    let currentBookedTotal = 0;
    let currentTotal = 0;
    let currentVatTotal = 0;
    const filterData =
      calculation_type === "amount"
        ? data?.filter((item) => item.month === currentMonth)
        : data;

    if (pastData) {
      pastTotal = pastData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
      pastVatTotal = pastData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );
    }

    if (calculation_type === "amount") {
      const filterBookedData = filterData?.filter(
        (item) => !Constant.plannedState.includes(item.state)
      );
      const filterPlannedData = filterData?.filter((item) =>
        Constant.plannedState.includes(item.state)
      );

      let currentBookedOpenTotal = filterBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
      let currentPlannedTotal = filterPlannedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );

      const isBooked =
        Math.abs(currentBookedOpenTotal) >= Math.abs(currentPlannedTotal);
      const filterRelBookedData = filterData?.filter(
        (item) => item.state === "Booked"
      );
      currentBookedTotal = filterRelBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );

      if (isBooked) {
        const currentBookedOpenVatTotal = filterBookedData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
            ),
          0
        );

        currentTotal = currentBookedOpenTotal + pastTotal;
        currentVatTotal = currentBookedOpenVatTotal + pastVatTotal;
      } else {
        let currentPlannedVatTotal = filterPlannedData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
            ),
          0
        );

        currentTotal = currentPlannedTotal + pastTotal;
        currentVatTotal = currentPlannedVatTotal + pastVatTotal;
      }
    } else {
      const dates = _.groupBy(filterData, ({ month }) => month);

      Object.keys(dates).forEach((_date) => {
        const isPastMonth = _date < currentMonth;
        const isCurrentMonth = _date === currentMonth;
        const isFutureMonth = _date > currentMonth;
        const monthData = dates?.[_date] || [];

        if (isPastMonth) {
          const pastBookedData = monthData?.filter(
            (item) => item.state === "Booked"
          );
          const pastBookedVatTotal = pastBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(
                type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
              ),
            0
          );
          currentVatTotal = (currentVatTotal || 0) + pastBookedVatTotal;
          // currentVatTotal = currentPlannedVatTotal;
        }
        if (isCurrentMonth) {
          const filterBookedData = monthData?.filter(
            (item) => !Constant.plannedState.includes(item.state)
          );
          const filterPlannedData = monthData?.filter((item) =>
            Constant.plannedState.includes(item.state)
          );

          let currentBookedOpenTotal = filterBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
          let currentPlannedTotal = filterPlannedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
          const isBooked =
            Math.abs(currentBookedOpenTotal) >= Math.abs(currentPlannedTotal);
          const filterRelBookedData = monthData?.filter(
            (item) => item.state === "Booked"
          );
          currentBookedTotal = filterRelBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

          if (isBooked) {
            let currentBookedOpenVatTotal = filterBookedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + currentBookedOpenTotal + pastTotal;
            currentVatTotal =
              currentVatTotal + currentBookedOpenVatTotal + pastVatTotal;
          } else {
            let currentPlannedVatTotal = filterPlannedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + currentPlannedTotal + pastTotal;
            currentVatTotal =
              currentVatTotal + currentPlannedVatTotal + pastVatTotal;
          }
        }
        if (isFutureMonth) {
          const filterOpenData = monthData?.filter(
            (item) =>
              !Constant.bookedState.includes(item.state) &&
              !Constant.plannedState.includes(item.state)
          );
          const filterPlannedData = monthData?.filter((item) =>
            Constant.plannedState.includes(item.state)
          );

          let futureOpenTotal = filterOpenData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
          let futurePlannedTotal = filterPlannedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

          if (Math.abs(futureOpenTotal) >= Math.abs(futurePlannedTotal)) {
            let futureOpenVatTotal = filterOpenData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + futureOpenTotal;
            currentVatTotal = currentVatTotal + futureOpenVatTotal;
          } else {
            let futurePlannedVatTotal = filterPlannedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );
            currentTotal = currentTotal + futurePlannedTotal;
            currentVatTotal = currentVatTotal + futurePlannedVatTotal;
          }
        }
      });
    }

    return {
      currentBookedTotal: currentBookedTotal,
      total: currentTotal,
      vatTotal: currentVatTotal,
      past: {
        total: pastTotal,
        vat: pastVatTotal,
      },
      uuid: uuid,
    };
  }
  if (time === "future") {
    let futureTotal = 0;
    let futureVatTotal = 0;
    const filterData =
      calculation_type === "amount"
        ? data?.filter((item) => item.month > currentMonth)
        : data;

    const filterOpenData = filterData?.filter(
      (item) =>
        !Constant.bookedState.includes(item.state) &&
        !Constant.plannedState.includes(item.state)
    );
    const filterPlannedData = filterData?.filter((item) =>
      Constant.plannedState.includes(item.state)
    );

    let futureOpenTotal = filterOpenData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
      0
    );
    let futurePlannedTotal = filterPlannedData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
      0
    );

    if (Math.abs(futureOpenTotal) >= Math.abs(futurePlannedTotal)) {
      let futureOpenVatTotal = filterOpenData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );

      futureTotal = futureOpenTotal;
      futureVatTotal = futureOpenVatTotal;
    } else {
      let futurePlannedVatTotal = filterPlannedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );
      futureTotal = futurePlannedTotal;
      futureVatTotal = futurePlannedVatTotal;
    }
    return {
      total: futureTotal,
      vatTotal: futureVatTotal,
      uuid: uuid,
    };
  }
};

export function collectUUIDs(node, uuids) {
  if (!node) return;

  if (node.uuid) {
    uuids.push(node.uuid);
  }

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => collectUUIDs(child, uuids));
  }
}

const getLastDate = (id, date) => {
  let result = null;
  if (id === -1) {
    result = lastDayOfYear(new Date(date));
  } else {
    result = endOfMonth(addMonths(new Date(date), id));
  }
  return result;
};

export function calculateEndDate({
  months = 0,
  start_date = null,
  extraMonth = 0,
}) {
  if (!months || Number(months) === 0) return null;
  if (!start_date || !isValid(new Date(start_date))) return null;
  const end_date = format(
    new Date(
      getLastDate(Math.abs(Number(months) + Number(extraMonth)) - 1, start_date)
    ),
    "yyyy-MM-dd"
  );

  return end_date;
}

export function calculateLoanTenure({
  principal = 0,
  downPayment = 0,
  emi = 0,
  annualInterestRate = 0,
  VariableRatesItemList = [],
  start_date,
}) {
  if (!principal || Number(principal) === 0) return 0;
  if (!emi || Number(emi) === 0) return 0;
  const _principal = principal - downPayment;
  if (VariableRatesItemList?.length > 0 && start_date) {
    let remainingPrincipal = _principal;
    let tenureInMonths = 0;
    let previousDate = start_date;

    for (let i = 0; i < VariableRatesItemList.length; i++) {
      const { interest_rate, start_date: vstart_date } =
        VariableRatesItemList[i];
      const monthlyInterestRate = interest_rate / (12 * 100);

      const duration = Math.round(
        (vstart_date - previousDate) / (1000 * 60 * 60 * 24 * 30)
      );

      // Calculate the number of months to repay the loan with the current interest rate and EMI
      for (let j = 0; j < duration; j++) {
        const interestForMonth = remainingPrincipal * monthlyInterestRate;
        const principalRepayment = emi - interestForMonth;
        remainingPrincipal -= principalRepayment;
        tenureInMonths++;

        if (remainingPrincipal <= 0) {
          return tenureInMonths;
        }
      }

      previousDate = vstart_date;
    }

    // Handle the remaining principal after the last rate change period
    while (remainingPrincipal > 0) {
      const { interest_rate } =
        VariableRatesItemList[VariableRatesItemList.length - 1];
      const monthlyInterestRate = interest_rate / (12 * 100);
      const interestForMonth = remainingPrincipal * monthlyInterestRate;
      const principalRepayment = emi - interestForMonth;
      remainingPrincipal -= principalRepayment;
      tenureInMonths++;
    }

    return tenureInMonths;
  } else {
    // Convert annual interest rate to monthly and decimal
    const monthlyInterestRate =
      annualInterestRate && annualInterestRate !== 0
        ? annualInterestRate / (12 * 100)
        : 0;

    // Calculate loan tenure
    const tenureInMonths = Math.ceil(
      Number(monthlyInterestRate) === 0
        ? _principal / emi
        : Math.log(emi / (emi - _principal * monthlyInterestRate)) /
            Math.log(1 + monthlyInterestRate)
    );
    return tenureInMonths;
  }
}

export function calculateEMIForPeriod({
  principal = 0,
  downPayment = 0,
  annualInterestRate = 0,
  loanTenureInMonths = 0,
}) {
  if (!principal || Number(principal) === 0) return 0;
  if (!loanTenureInMonths || Number(loanTenureInMonths) === 0) return 0;
  const _principal = principal - downPayment;

  // Convert annual interest rate to monthly and decimal
  const monthlyInterestRate =
    annualInterestRate && Number(annualInterestRate) !== 0
      ? annualInterestRate / (12 * 100)
      : 0;

  // Calculate EMI
  const emi = parseFloat(
    Number(monthlyInterestRate) === 0
      ? _principal / loanTenureInMonths
      : (_principal *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, loanTenureInMonths)) /
          (Math.pow(1 + monthlyInterestRate, loanTenureInMonths) - 1)
  ).toFixed(1);

  return emi;
}

export function calculateEMI({
  principal = 0,
  downPayment = 0,
  loanTenureInMonths = 0,
  annualInterestRate = 0,
  VariableRatesItemList = [],
  start_date,
}) {
  if (!principal || Number(principal) === 0)
    return { emi: 0, totalEMI: 0, totalInterestPaid: 0 };
  if (!loanTenureInMonths || Number(loanTenureInMonths) === 0)
    return { emi: 0, totalEMI: 0, totalInterestPaid: 0 };

  let _principal = principal - downPayment;
  let _annualInterestRate = annualInterestRate || 0;
  if (VariableRatesItemList?.length > 0 && start_date) {
    let remainingTermMonths = loanTenureInMonths;
    let nextDate = null;

    let finalInterestRate = 0;
    for (let i = 0; i < VariableRatesItemList.length; i++) {
      const { interest_rate, start_date } = VariableRatesItemList[i];
      nextDate = VariableRatesItemList?.[i + 1]?.start_date
        ? new Date(VariableRatesItemList?.[i + 1]?.start_date)
        : null;
      const durationMonths = nextDate
        ? Math.round(
            (nextDate - new Date(start_date)) / (1000 * 60 * 60 * 24 * 30)
          )
        : remainingTermMonths;
      const totalRate = durationMonths * interest_rate;
      finalInterestRate += totalRate;
      remainingTermMonths -= durationMonths;
      nextDate = new Date(start_date);
    }
    _annualInterestRate = parseFloat(
      finalInterestRate / loanTenureInMonths
    )?.toFixed(0);
  }
  const emi = calculateEMIForPeriod({
    principal,
    downPayment,
    annualInterestRate: _annualInterestRate,
    loanTenureInMonths,
  });
  const totalInterestPaid = emi * loanTenureInMonths - _principal;
  const totalEMI = emi * loanTenureInMonths;

  return {
    emi: parseFloat(emi)?.toFixed(2),
    totalEMI: parseFloat(totalEMI).toFixed(2),
    totalInterestPaid: parseFloat(totalInterestPaid).toFixed(2),
  };
}

export const getValidDateInMonth = (date, day) => {
  const daysInMonth = getDaysInMonth(date);
  const validDay = Math.min(day, daysInMonth);
  return setDate(date, validDay);
};

export const calculateDueDate = (start_date, value) => {
  if (!start_date) {
    return null;
  }

  const startDate = new Date(start_date);
  const day = startDate.getDate();
  const dateValue = new Date(value);

  const dueDate = getValidDateInMonth(dateValue, day);

  return format(dueDate, "yyyy-MM-dd");
};

export const addMonthWithForecast = (existingData, forecastMonth) => {
  const dampingFactor = 0.001; // Adjust the damping factor as needed

  const forecastData = { due_date: forecastMonth };

  existingData.forEach((obj) => {
    for (const key in obj) {
      if (key !== "due_date" && key !== "month" && key !== "total_key") {
        if (!forecastData[key]) {
          forecastData[key] = obj[key];
        } else {
          forecastData[key] =
            obj[key] * dampingFactor + forecastData[key] * (1 - dampingFactor);
        }
      }
    }
  });
  return forecastData;
};

export const hexToArgb = (hex) => {
  // Ensure hex color code starts with '#' and has the correct length
  if (hex.startsWith("#") && (hex.length === 7 || hex.length === 4)) {
    // Remove '#' and pad short hex codes
    hex = hex.substring(1);
    if (hex.length === 4) {
      hex = hex
        .split("")
        .map((x) => x + x)
        .join("");
    }
    return `FF${hex}`.toUpperCase(); // Add alpha (opacity) and convert to ARGB
  } else {
    return "FFFFFF"; // Return white if invalid hex code
  }
};
export const hexToRgb = (hex) => {
  let bigint = parseInt(hex.replace("#", ""), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
};
