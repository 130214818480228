import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

import PieCharts from "../../../components/Charts/PieCharts";
import { formatAmount, remToPx } from "../../../Helper/data";
import { Color, Fonts } from "../../../Helper";
import useWidth from "../../../hooks/useWidth";

const PieChratView = ({ data, title, subTitle }) => {
  const currentWidth = useWidth();

  //redux

  const option = [
    { value: "chart", label: "Pie Chart" },
    { value: "table", label: "Table" },
  ];
  const columns = [
    {
      field: "categoryName",
      headerName: "Category",
      width: remToPx(currentWidth, 12.5),
    },
    {
      field: "gross_value",
      headerName: "Amount",
      width: remToPx(currentWidth, 8.75),
      valueFormatter: ({ value }) => {
        return formatAmount({
          amount: value,
        });
      },
    },
    { field: "share", headerName: "Share", width: remToPx(currentWidth, 8.75) },
  ];

  //state
  const [type, setType] = useState(option[0]);

  //function
  const onChangeOption = (e, values) => {
    if (values) setType(values);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow:
          "4px -3px 1px -14px rgb(0 0 0 / 20%), 2px 4px 5px -3px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        borderRadius: 1,
        width: "47%",
        height: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ".css-112aias-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: `${Color.autoCompleteBorder} !important`,
          },
        ".MuiOutlinedInput-root": {
          color: Color.greyText,
          borderRadius: 2,

          ":hover .MuiOutlinedInput-notchedOutline": {
            borderColor: Color.autoCompleteBorder,
          },
        },
        ".MuiAutocomplete-option": {
          color: Color.greyText,
        },
        ".rs-picker-toggle-value": {
          color: `${Color.greyText} !important`,
        },
        ".rs-picker-toggle:hover": {
          borderColor: `${Color.autoCompleteBorder} !important`,
        },
        ".rs-picker-toggle": {
          borderColor: `${Color.autoCompleteBorder} !important`,
        },
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: "1rem",
        }}
      >
        <Typography
          sx={{
            mx: "1rem",
            fontSize: "1.2rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            color: Color.black,
            display: "inline-flex",
          }}
        >
          {title}
        </Typography>

        <Autocomplete
          id="controllable-states-demo"
          size="small"
          disableClearable
          sx={{
            backgroundColor: Color.white,
            width: "fit-content",
            minWidth: "10.625rem",
            mr: "1rem",
          }}
          onChange={onChangeOption}
          value={type ?? null}
          options={option}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          px: "1rem",
        }}
      >
        {" "}
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            color: Color.greyText,
            opacity: 0.9,
          }}
        >
          By contribution to
        </Typography>
        <Typography
          sx={{
            mx: 1,
            fontSize: "1.05rem",
            fontWeight: 700,
            fontFamily: Fonts.Text,
            color: Color.black,
            opacity: 0.9,
          }}
        >
          {subTitle}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            color: Color.greyText,
            opacity: 0.9,
          }}
        >
          this period
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "24.13rem",
          pt: type.value === "chart" ? 0 : "2.55rem",
        }}
      >
        {data?.length > 0 ? (
          <>
            {type.value === "chart" ? (
              <PieCharts data={data} />
            ) : (
              <DataGrid
                disableColumnMenu
                density="compact"
                rows={data}
                columns={columns}
                pageSize={5}
                rowHeight={remToPx(currentWidth, 4)}
              />
            )}
          </>
        ) : (
          <Typography
            sx={{
              height: "100%",
              fontSize: "1.2rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.black,
              opacity: 0.9,
              alignSelf: "center",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            There is no data to show yet
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default PieChratView;
