import {
  Typography,
  Checkbox,
  ListItem,
  Divider,
  Button,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Radio from "@mui/material/Radio";
import { Outlet } from "react-router";

import { setPopupStatus2, setPopupStatus4, setTransactionsOverlayStatus } from "../../../store/slices/datasets";
import {
  setAdvanceVat,
  addNewVat,
  setVats,
} from "../../../store/slices/global";
import DeleteConfirmationModal from "../../../components/Model/DeleteConfirmationModal";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import useSubscriptions from "../../../hooks/useSubscriptions";
import DropDown from "../../../components/Overlay/DropDown";
import { Card } from "../../../Styles/Pages/sheets.style";
import ThemeTabs from "../../../components/ThemeTabs";
import AddButton from "../../../components/AddButton";
import EndPoints from "../../../APICall/EndPoints";
import { Fonts, Color } from "../../../Helper";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";

const tabOptions = [
  {
    value: "1",
    label: "Setup_VAT",
  },
  {
    value: "2",
    label: "Advanced_VAT_Settings",
  },
];
const Vats = () => {
  let itemId = useRef(null);
  let due_day = useRef([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();

  //redux state
  const vat = useSelector((state) => state.globalSlice.vat);

  //state
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [datasetItem, setdatasetItem] = useState();
  const [tab, setTab] = useState("1");
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //life cycle method
  useEffect(() => {
    getTaxApi();
  }, []);

  useEffect(() => {
    if (location?.state?.fromCategories) {
      dispatch(setTransactionsOverlayStatus(false));
      dispatch(setPopupStatus4(null));
      dispatch(setPopupStatus2(null));
      setTab("2");
    }
  }, [location]);

  //Api
  const deleteTaxByIdApi = async (id) => {
    await APICall("delete", EndPoints.taxes + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        getTaxApi(false);
        enqueueSnackbar(t("Vat_Deleted_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const updateTaxByID = async (id, obj) => {
    await APICall("patch", EndPoints.taxes + `${id}/`, obj).then((response) => {
      if (response.status === 200 && response.data) {
        getTaxApi(false);
      }
    });
  };

  const addVatApi = async (obj) => {
    await APICall("post", EndPoints.taxes, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar(t("New_Vat_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        dispatch(addNewVat(response.data));
      }
    });
  };

  const getTaxApi = async () => {
    setLoading(true);
    await APICall("get", EndPoints.taxes).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setVats(data));
        setLoading(false);
      }
    });
  };

  //function
  const onClickAddNewdataset = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      let obj = {
        title: `Vat${vat.length + 1}`,
        value: 0.0,
        // dataset: dataSetData.uuid,
      };
      addVatApi(obj);
    }
  };

  const onClickDeleteDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item.uuid;
    setIsDeleteOpen(true);
  };

  const onOk = () => {
    setIsDeleteOpen(false);
    deleteTaxByIdApi(itemId.current);
  };

  const onClickEditDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item.uuid;
    setdatasetItem(item);
    setOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;
    let errorText = null;
    if (name === "title" && value.trim() === "") {
      errorText = "Title can not be empty";
    }
    if (name === "value") {
      finalValue = value ? parseFloat(value).toFixed(2) : "0.00";
    }
    setdatasetItem({ ...datasetItem, [name]: finalValue });
    setError({ ...error, [name]: errorText });
    if (errorText) {
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = () => {
    setOpen(false);
    updateTaxByID(itemId.current, {
      title: datasetItem?.title,
      value: datasetItem?.value,
    });
  };

  const onCloseDelete = () => {
    setIsDeleteOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  //Render Function

  const editModal = () => {
    return (
      <CustomModal
        textAdd="Save"
        heading={t("VAT")}
        onClose={handleClose}
        onAdd={onSave}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: Color.BodyBG,
            flexDirection: "column",
            width: "40rem",
            height: "fit-content",
            p: "2.5rem",
          }}
        >
          <TitleInput
            value={datasetItem?.title || ""}
            name="title"
            onChange={handleEditChange}
            helperText={error?.title}
            error={Boolean(error?.title)}
            hideTitle
            label={t("Title")}
            likeGoogle
            variant="filled"
          />
          <TitleInput
            type="number"
            value={datasetItem?.value || ""}
            name="value"
            label={t("VAT_in_percent")}
            onChange={handleEditChange}
            helperText={error?.value}
            error={Boolean(error?.value)}
            hideTitle
            likeGoogle
            variant="filled"
            sx={{ mt: "2rem" }}
          />
        </Box>
      </CustomModal>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.appThemeBg,
        ml: "90px",
        position: "relative",
      }}
    >
      <DeleteConfirmationModal
        open={isDeleteOpen}
        onClose={onCloseDelete}
        onOk={onOk}
        type="delete"
        message={`${t("Are_you_sure_delete_this")} ${t("vat")}?`}
      />
      <Stack
        sx={{
          width: "80%",
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
          sx={{
            mt: "4rem",
          }}
        >
          {t("Vats")}
        </Typography>
        <Typography
          variant="subtitle2"
          color="color.description"
          fontWeight={"fontWeightMedium"}
          sx={{
            my: "0.5rem",
            mb: "1.5rem",
          }}
        >
          {t("vat_list_description")}
        </Typography>
        <Divider
          sx={{
            mb: "1rem",
          }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          width: "80%",
        }}
      >
        <ThemeTabs options={tabOptions} tab={tab} onChange={handleChangeTab} />
        {tab === "1" ? (
          <AddButton
            tooltipLabel={t("Add_Vat")}
            label={t("Add_Vat")}
            onClick={onClickAddNewdataset}
            hideKey
          />
        ) : null}
      </Stack>

      {tab === "1" ? (
        <Stack sx={{ width: "80%" }}>
          <Grid
            container
            elevation={1}
            sx={{
              display: "inline",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                mt: "2rem",
                mb: "1rem",
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  px: "1rem",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    height: "fit-content",
                  }}
                  variant="h6"
                  component="div"
                >
                  {t("Title")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  px: "1rem",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    height: "fit-content",
                  }}
                  variant="h6"
                  component="div"
                >
                  {t("vat")}
                </Typography>
              </Grid>
            </Box>
            {!isLoading && vat?.length > 0 ? (
              <Card
                elevation={1}
                sx={{
                  width: "100%",
                  maxHeight: "70%",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                    background: "transparent" /* make scrollbar transparent */,
                  },
                }}
              >
                {vat?.map((item, index) => {
                  return (
                    <ListItem
                      key={item.uuid}
                      divider
                      sx={{
                        px: 0,
                        py: "1rem",
                        backgroundColor: Color.white,
                        height: "3rem",
                        "& .actionBtn": {
                          backgroundColor: Color.white,
                          borderRadius: 1,
                          width: "1.8rem",
                          height: "1.8rem",
                          marginLeft: "0.5rem",
                          minWidth: "1.8rem",
                          display: "none",
                          border: `1px solid ${Color.tailwind.slate[300]}`,
                          "&: hover": {
                            backgroundColor: Color.tailwind.slate[100],
                          },
                          "& .actionBtnIcon": {
                            backgroundColor: "transparent",
                            color: Color.black,
                            fontSize: "1.1rem",
                            margin: 0,
                          },
                        },

                        "&: hover": {
                          "& .actionBtn": {
                            display: "flex",
                          },
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={3}
                        sx={{
                          px: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            height: "fit-content",
                          }}
                          variant="h6"
                          component="div"
                        >
                          {item?.title}
                        </Typography>
                        <Button
                          onClick={(e) => onClickEditDataSet(e, item)}
                          className={"actionBtn"}
                        >
                          <ModeEditRoundedIcon className={"actionBtnIcon"} />
                        </Button>
                        <Button
                          className={"actionBtn"}
                          onClick={(e) => onClickDeleteDataSet(e, item, index)}
                        >
                          <DeleteIcon className={"actionBtnIcon"} />
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          px: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            height: "fit-content",
                          }}
                          variant="h6"
                          component="div"
                        >
                          {item?.value + " %"}
                        </Typography>
                      </Grid>
                    </ListItem>
                  );
                })}
              </Card>
            ) : (
              <ComponentLoader
                loading={isLoading}
                placeHolderWidth={"35%"}
                key1={`setting_vat_no_data_01`}
                key2={`setting_vat_no_data_02`}
                sx={{ width: "70%", mx: "auto" }}
              />
            )}
          </Grid>
          {editModal()}
        </Stack>
      ) : (
        <Setting due_day={due_day} />
      )}
      <Outlet />
    </Box>
  );
};
export default Vats;

const Setting = ({ due_day }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //redux
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);

  //state
  const [selectedDueDate, setSelectedDueDate] = useState(null);

  if (due_day.current?.length > 0 && !selectedDueDate && advanceVat) {
    let filterDueDay = due_day.current?.find(
      (o1) => o1.value === advanceVat.due_day
    );
    if (filterDueDay) setSelectedDueDate(filterDueDay);
  }

  //life cycle method
  useEffect(() => {
    getAdvanceTaxOption();
  }, []);

  //api
  const updateAdvanceTaxByID = async (obj) => {
    await APICall("patch", EndPoints.advanceTax, obj).then((response) => {
      if (response.status === 200 && response.data) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === "transactions" &&
            (query.queryKey[1]?.apiType === "daily" ||
              query.queryKey[1]?.apiType === "monthly"),
        });
      }
    });
  };

  const getAdvanceTaxOption = async () => {
    await APICall("OPTIONS", EndPoints.advanceTax).then((response) => {
      if (response.status === 200 && response.data) {
        due_day.current = response.data.actions.PUT.due_day.choices;
        getAdvanceTaxApi();
      }
    });
  };

  const getAdvanceTaxApi = async () => {
    await APICall("get", EndPoints.advanceTax).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        dispatch(setAdvanceVat(data));
        const filterDueDay = due_day.current?.find(
          (o1) => o1.value === data.due_day
        );
        if (filterDueDay) setSelectedDueDate(filterDueDay);
      }
    });
  };

  //functions
  const handleChangeRadio = (event) => {
    let value = event.target.value;
    dispatch(setAdvanceVat({ ...advanceVat, frequency: value }));
    updateAdvanceTaxByID({ frequency: value });
  };

  const handleChangeCheck = (event) => {
    let value = event.target.checked;
    dispatch(setAdvanceVat({ ...advanceVat, permanent_extension: value }));
    updateAdvanceTaxByID({ permanent_extension: value });
  };

  const handleChangeEnable = (event) => {
    let value = event.target.checked;
    dispatch(setAdvanceVat({ ...advanceVat, enabled: value }));
    updateAdvanceTaxByID({
      enabled: value,
      due_day: advanceVat?.due_day ? advanceVat?.due_day : 15,
    });
  };

  const handleChangeDueDate = (event, value) => {
    setSelectedDueDate(value);
    dispatch(setAdvanceVat({ ...advanceVat, due_day: value?.value }));
    updateAdvanceTaxByID({ due_day: value?.value });
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        borderRadius: 2,
        p: "2rem",
        justifyContent: "flex-start",
        backgroundColor: Color.white,
      }}
    >
      <Box flexDirection={"row"} display="flex">
        <Checkbox
          checked={advanceVat?.enabled || false}
          onChange={handleChangeEnable}
          inputProps={{ "aria-label": "controlled" }}
          sx={{ pl: 0 }}
        />
        <Typography
          sx={{
            display: "inline-flex",
            fontSize: "1rem",
            fontWeight: 800,
            fontFamily: Fonts.Text,
            alignSelf: "center",
          }}
        >
          {t("Subject_to_sales_tax")}
        </Typography>
      </Box>
      <Divider sx={{ my: "2rem" }} />
      <Typography
        sx={{
          display: "inline-flex",
          color: Color.black,
          fontSize: "1rem",
          fontWeight: 800,
          fontFamily: Fonts.Text,
        }}
      >
        {t("Frequency_of_sales_tax_payment")}
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={advanceVat?.frequency || null}
          onChange={handleChangeRadio}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={t("Monthly")}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={t("Quarterly")}
          />
        </RadioGroup>
      </FormControl>
      <Divider sx={{ my: "2rem" }} />
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ width: "24%", ml: "-1rem" }}>
          <DropDown
            mb={0}
            hideTitle
            likeGoogle
            variant="filled"
            label={t("Due_date_of_the_sales_tax")}
            options={due_day.current || []}
            value={selectedDueDate || null}
            onChange={(e, value) => handleChangeDueDate(e, value)}
            getOptionLabel={(option) => option?.display_name ?? ""}
            renderOption={(option) => option?.display_name ?? ""}
          />
        </Box>
        <Checkbox
          checked={advanceVat?.permanent_extension || false}
          onChange={handleChangeCheck}
          inputProps={{ "aria-label": "controlled" }}
          sx={{ ml: "1rem" }}
        />
        <Typography
          sx={{
            display: "inline-flex",
            color: Color.theme.grey[500],
            fontSize: "1rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            alignSelf: "center",
          }}
        >
          {t("Permanent_deadline_extension")}
        </Typography>
      </Box>
    </Card>
  );
};
