import { Typography, Box, Stack, Alert, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdateIcon from "@mui/icons-material/Update";
import { Outlet, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setDataSetData, setDataSetList } from "../../../store/slices/board";
import CategoryLevelAttentionOverlay from "./CategoryLevelAttentionOverlay";
import ThemeToggle from "../../../components/ToggleGroupeView/ThemeToggle";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import ActionButton from "../../../components/ActionButton";
import AddButton from "../../../components/AddButton";
import ThemeTabs from "../../../components/ThemeTabs";
import EndPoints from "../../../APICall/EndPoints";
import { Color } from "../../../Helper/Color";
import { Constant } from "../../../Helper";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import store from "../../../store";
import TreeView from "./TreeView";

const tabOptions = [
  {
    value: 1,
    label: "Tab_Inflow",
  },
  {
    value: 2,
    label: "Tab_Outflow",
  },
];

const Categories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const treeRef = useRef(null);
  const categoryRef = useRef(null);
  const theme = useTheme();

  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  //state
  const [value, setValue] = useState(1);
  const [isSaving, setIsSaving] = useState(false);

  //function
  const onClickAddNewCategory = (e) => {
    e?.stopPropagation();
    const categoryData = treeRef.current?.categoryData;
    if (categoryData) {
      categoryRef?.current?.onClickAddNewCategory(e, {
        value,
        setValue,
        categoryData,
      });
    }
  };

  const onClickLink = () => {
    navigate("/settings/rules");
  };

  if (!dataset || !isAllHeaderApiFetched) {
    return null;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        pt: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          width: { xs: "95%", s1280: "80%" },
          alignItems: "center",
          overflow: "auto",
          paddingBottom: "3rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TabsView value={value} setValue={setValue} />
            {isSaving ? (
              <Alert
                icon={<UpdateIcon />}
                severity="success"
                sx={{
                  boxShadow: "none !important",
                  fontSize: "0.8rem",
                  padding: "0.35rem 0.75rem",
                  display: "inline-flex",
                  alignItems: "center",
                  backgroundColor: theme.palette.color.slate[100],
                  alignSelf: "center",
                  "& .MuiAlert-icon": {
                    color: theme.palette.color.slate[700],
                    fontSize: "1rem",
                    py: "0rem",
                    alignItems: "center",
                  },
                  "& .MuiAlert-message": {
                    color: theme.palette.color.slate[700],
                    textAlign: "left",
                    py: "0rem",
                    display: "inline-flex",
                  },
                }}
              >
                {t("Saving")}
              </Alert>
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {Constant.isStag || Constant.isTest ? <CategoryTypeSwitch /> : null}

            {/* <Stack sx={{ position: "relative" }}> */}
            {/* <SmartRulesStatus /> */}
            <ActionButton
              onClick={onClickLink}
              label={"Manage Rules"}
              buttontype="manage2"
              sx={{ mr: "1rem" }}
            />
            {/* </Stack> */}

            <Stack
              direction="row"
              alignItems={"center"}
              sx={{ position: "relative" }}
            >
              <AddButton
                tooltipLabel={t("Add_New_Category")}
                label={t("Add_New_Category")}
                onClick={onClickAddNewCategory}
                isNKey
                sx={{
                  mr: "1rem",
                }}
              />
              <FinbanInfoBulb url="https://finban.io/documentation/categories/" />
            </Stack>
          </div>
        </Box>

        <Box
          sx={{
            display: "flex",
            mt: "0.5rem",
            width: "100%",
          }}
        >
          <span style={{ width: "42.75rem" }}></span>
          <Typography
            sx={{
              mb: "0.5rem",
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
              minWidth: "12rem",
            }}
            variant="h6"
            component="div"
          >
            {t("vat")}
          </Typography>
          <Typography
            sx={{
              mb: "0.5rem",
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              height: "fit-content",
              minWidth: "1rem",
            }}
            variant="h6"
            component="div"
          >
            {t("category_type")}
          </Typography>
        </Box>

        <TreeView
          ref={treeRef}
          categoryRef={categoryRef}
          value={value}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Box>
      <Outlet />
    </Box>
  );
};
export default Categories;

// const SmartRulesStatus = () => {
//   const { t } = useTranslation();
//   const auto_categorization = useSelector(
//     (state) => state.settingsSlice?.profile?.auto_categorization
//   );
//   return (
//     <Typography
//       fontWeight={"fontWeightMedium"}
//       color="color.slate.600"
//       variant="caption"
//       sx={{
//         position: "absolute",
//         bottom: "-1.25rem",
//         right: "1.25rem",
//         fontSize: "0.65rem",
//       }}
//     >
//       {t("Smart_rules_status")}:{" "}
//       <b>
//         {Number(auto_categorization) === 0
//           ? t("rules_Disabled")
//           : t("rules_Enabled")}
//       </b>
//     </Typography>
//   );
// };

const CategoryTypeSwitch = () => {
  const dispatch = useDispatch();
  const payload = useRef(null);

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  //state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  //Api
  const updateDataSetByID = async (id, obj) => {
    const dataSetList = store.getState().boardSlice?.dataSetList;

    await APICall("patch", EndPoints.datasets + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        let data = [...dataSetList];
        const index = data.findIndex((o1) => o1.uuid === id);
        if (index > -1) {
          data[index] = response.data;
        }
        data.sort((a, b) => (a.position > b.position ? 1 : -1));
        dispatch(
          setDataSetData({
            partial: {
              use_global_categories: !dataSetData?.use_global_categories,
            },
          })
        );
        dispatch(setDataSetList(data));
        setOpen(false);
        setLoading(false);
        queryClient.resetQueries({
          predicate: (query) => query.queryKey[0] === "transactions",
        });
      } else {
        setLoading(false);
      }

      if (response.status === 400 && response.data?.use_global_categories) {
        const unCategorizeMessage =
          "This dataset has categorized transactions. Please uncategorize them first.";

        const isUnCategorize =
          response?.data?.use_global_categories?.[0] === unCategorizeMessage;

        payload.current = {
          doUnCategorize: isUnCategorize,
          message: response?.data?.use_global_categories?.[0],
          addText: isUnCategorize ? "confirm_category_level_change" : "Ok",
        };
        setOpen(true);
      }
    });
  };

  const unCategorizedDataSetByID = async (id) => {
    await APICall(
      "post",
      EndPoints.datasets + `${id}/uncategorize_transactions/`,
      null,
      {
        doNotCatchRespond: true,
      }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        updateDataSetByID(dataSetData?.uuid, {
          use_global_categories: !dataSetData?.use_global_categories,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const onChangeType = (e, value) => {
    if (
      dataSetData?.uuid &&
      dataSetData?.use_global_categories?.toString() !== value?.toString()
    ) {
      updateDataSetByID(dataSetData?.uuid, {
        use_global_categories: !dataSetData?.use_global_categories,
      });
    }
  };

  const onChangeCategoryLevel = () => {
    if (payload.current?.doUnCategorize) {
      setLoading(true);
      unCategorizedDataSetByID(dataSetData?.uuid);
    } else {
      setOpen(false);
    }
  };

  if (Constant?.isProd || !beta_enabled) {
    return null;
  }
  return (
    <>
      <CategoryLevelAttentionOverlay
        open={open}
        setOpen={setOpen}
        loading={loading}
        onAdd={onChangeCategoryLevel}
        title="category_level_attention_title"
        message={
          payload.current?.message || "category_level_attention_description"
        }
        addText={payload.current?.addText || "confirm_category_level_change"}
        // message="category_level_attention_description"
        // addText={"confirm_category_level_change"}
      />
      <ThemeToggle
        value={dataSetData?.use_global_categories}
        onChange={onChangeType}
        options={[
          {
            value: false,
            label: "category_type_label_dataset",
            tooltip: "category_type_label_dataset_tooltip",
          },
          {
            value: true,
            label: "category_type_label_general",
            tooltip: "category_type_label_general_tooltip",
          },
        ]}
        sx={{ mr: "3rem" }}
      />
    </>
  );
};

const TabsView = ({ value, setValue }) => {
  const location = useLocation();

  //life cycle method
  useEffect(() => {
    if (location?.state?.fromCategories) {
      setValue(location?.state?.isOutflow ? 2 : 1);
    }
  }, [location]);

  return <ThemeTabs options={tabOptions} tab={value} setTab={setValue} />;
};
