import { Box, Chip, Paper, Skeleton, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import eachYearOfInterval from "date-fns/eachYearOfInterval";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { format } from "date-fns";
import _ from "underscore";

import StackedCharts from "../../../components/Charts/StackedCharts";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import LineCharts from "../../../components/Charts/LineCharts";
import BarCharts from "../../../components/Charts/BarCharts";
import CustomPopover from "../../../components/PopOver";
import { formatAmount } from "../../../Helper/data";
import { Color, Fonts } from "../../../Helper";

const MainChartView = ({
  title,
  type,
  selectedItem,
  loading,
  isBig = false,
  chartId = "1",
  chartName = "Bars",
}) => {
  const { t } = useTranslation();
  const option1 = [
    { id: "1", title: "Bars" },
    { id: "2", title: "Line" },
  ];
  const option2 = [
    { id: "3", title: "Bars" },
    { id: "4", title: "Sum" },
  ];
  const option3 = [
    { id: "5", title: "Stacked" },
    { id: "6", title: "Stacked(percent)" },
  ];
  const option4 = [
    { id: "7", title: "Stacked(total)" },
    { id: "8", title: "Lines" },
  ];

  //redux
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const testLoading = useSelector((state) => state.appSlice.testLoading);
  
  const periodTranData = useSelector(
    (state) => state.chartSlice?.periodTranData
  );
  const refPeriodTranData = useSelector(
    (state) => state.chartSlice?.refPeriodTranData
  );
  const aggregates = useSelector((state) => state.chartSlice?.aggregates);
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const dateRange = useSelector((state) => state.chartSlice?.dateRange);
  const periodDateRange = useSelector(
    (state) => state.chartSlice?.periodDateRange
  );

  //state
  const [chartTypeTitle, setChartTypeTitle] = useState(chartName);
  const [selectedIName, setSelectedName] = useState(chartId);
  const [item, setItem] = useState(null);
  const [loader, setLoader] = useState(true);
  const [chartData, setChartData] = useState([]);

  useDebounce(
    () => {
      setLoader(true);
      let filterData = [];
      if (
        periodDateRange?.length > 0 &&
        periodDateRange[0] !== "Invalid Date"
      ) {
        // if (selectedItem === 0) {
        //   filterChartData.current = periodTranData
        //     ?.filter((o1) => o1.category && o1.due_date)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        //   filterData = refPeriodTranData
        //     .filter((o1) => o1.category && o1.due_date)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        // }
        // if (selectedItem === 1) {
        //   filterChartData.current = periodTranData
        //     ?.filter((o1) => o1.category && o1.due_date && o1.gross_value >= 0)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        //   filterData = refPeriodTranData
        //     .filter((o1) => o1.category && o1.due_date && o1.gross_value > 0)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        // }
        // if (selectedItem === 2) {
        //   filterChartData.current = periodTranData
        //     ?.filter((o1) => o1.category && o1.due_date && o1.gross_value < 0)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        //   filterData = refPeriodTranData
        //     .filter((o1) => o1.category && o1.due_date && o1.gross_value < 0)
        //     .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        // }
        let mainTotal = 0;
        let refTotal = 0;
        let dummyData = [];
        if (selectedItem === 0) {
          mainTotal = periodTranData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(item?.inflow ?? 0) +
              parseFloat(item?.outflow ?? 0),
            0
          );
          refTotal = refPeriodTranData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(item?.inflow ?? 0) +
              parseFloat(item?.outflow ?? 0),
            0
          );
        }
        if (selectedItem === 1) {
          mainTotal = periodTranData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
            0
          );
          refTotal = refPeriodTranData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
            0
          );
        }
        if (selectedItem === 2) {
          mainTotal = periodTranData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
            0
          );
          refTotal = refPeriodTranData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
            0
          );
        }
        let change = getPercentageChange(refTotal, mainTotal);
        // if (aggregates.value === "daily") {
        //   const resultMain = eachDayOfInterval({
        //     start: new Date(dateRange[0]),
        //     end: new Date(dateRange[1]),
        //   });
        //   const resultRef = eachDayOfInterval({
        //     start: new Date(periodDateRange[0]),
        //     end: new Date(periodDateRange[1]),
        //   });
        //   resultMain?.forEach((element, index) => {
        //     let date = moment(element).format("YYYY-MM-DD");
        //     let date_ref = moment(resultRef[index]).format("YYYY-MM-DD");
        //     const item = filterChartData.current?.filter(
        //       (item) => item.due_date === date
        //     );
        //     const item_ref = filterData?.filter(
        //       (item) => item.due_date === date_ref
        //     );
        //     const gross_value = item?.reduce(
        //       (total, entry) => parseFloat(total) + parseFloat(entry.gross_value ?? 0),
        //       0
        //     );
        //     const gross_value_ref = item_ref?.reduce(
        //       (total, entry) => parseFloat(total) + parseFloat(entry.gross_value ?? 0),
        //       0
        //     );
        //     if (selectedIName === "5") {
        //       let obj = { due_date: date };
        //       item.forEach((element) => {
        //         selectionCategories?.forEach((entry, index) => {
        //           if (entry.value === element.category && !entry.parent) {
        //             obj = {
        //               ...obj,
        //               total:
        //                 parseFloat(obj?.total ?? 0) +
        //                 parseFloat(element.gross_value ?? 0),
        //               [entry.value]: element.gross_value ?? 0,
        //             };
        //           }
        //         });
        //       });
        //       dummyData.push(obj);
        //     } else {
        //       dummyData.push({
        //         due_date: date,
        //         due_date_ref: date_ref,
        //         gross_value_ref: gross_value_ref ?? 0,
        //         gross_value: gross_value ?? 0,
        //       });
        //     }
        //   });
        // }
        let formateString =
          aggregates.value === "year"
            ? "yyyy"
            : aggregates.value === "month"
              ? "MMM-yyyy"
              : "yyyy-MM-dd";
        let resultMain = [];
        let resultRef = [];
        if (aggregates.value === "month") {
          resultMain = eachMonthOfInterval({
            start: new Date(dateRange[0]),
            end: new Date(dateRange[1]),
          });
          resultRef = eachMonthOfInterval({
            start: new Date(periodDateRange[0]),
            end: new Date(periodDateRange[1]),
          });
        }
        if (aggregates.value === "year") {
          resultMain = eachYearOfInterval({
            start: new Date(dateRange[0]),
            end: new Date(dateRange[1]),
          });
          resultRef = eachYearOfInterval({
            start: new Date(periodDateRange[0]),
            end: new Date(periodDateRange[1]),
          });
        }
        let gross_value = 0;
        let gross_value_ref = 0;

        let periodData = _.groupBy(periodTranData, ({ month }) =>
          format(new Date(month), formateString)
        );
        let refPeriodData = _.groupBy(refPeriodTranData, ({ month }) =>
          format(new Date(month), formateString)
        );
        resultMain?.forEach((element, index) => {
          let date = format(new Date(element), formateString);
          let date_ref = resultRef?.[index]
            ? format(new Date(resultRef?.[index]), formateString)
            : null;

          const item = periodData?.[date] ?? [];

          const item_ref = refPeriodData?.[date_ref] ?? [];

          if (selectedItem === 0) {
            gross_value = item?.reduce(
              (total, entry) =>
                parseFloat(total) +
                parseFloat(entry?.outflow ?? 0) +
                parseFloat(entry?.inflow ?? 0),
              0
            );
            gross_value_ref = item_ref?.reduce(
              (total, entry) =>
                parseFloat(total) +
                parseFloat(entry?.outflow ?? 0) +
                parseFloat(entry?.inflow ?? 0),
              0
            );
          }
          if (selectedItem === 1) {
            gross_value = item?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.inflow ?? 0),
              0
            );
            gross_value_ref = item_ref?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.inflow ?? 0),
              0
            );
          }
          if (selectedItem === 2) {
            gross_value = item?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.outflow ?? 0),
              0
            );
            gross_value_ref = item_ref?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.outflow ?? 0),
              0
            );
          }
          let newCat = [
            {
              value: null,
              label: "Uncategorized",
              color: Color?.unCategorizedCardBg,
            },
            ...selectionCategories,
          ];
          if (selectedIName === "5") {
            let obj = { due_date: date };
            let gross_valueTotal = 0;
            item.forEach((element) => {
              newCat?.forEach((entry) => {
                if (entry.value === element.category) {
                  if (selectedItem === 0) {
                    gross_valueTotal =
                      parseFloat(element?.outflow ?? 0) +
                      parseFloat(element?.inflow ?? 0);
                  }
                  if (selectedItem === 1) {
                    gross_valueTotal = parseFloat(element?.inflow ?? 0);
                  }
                  if (selectedItem === 2) {
                    gross_valueTotal = parseFloat(element?.outflow ?? 0);
                  }
                  obj = {
                    ...obj,
                    total:
                      parseFloat(obj?.total ?? 0) +
                      parseFloat(gross_valueTotal ?? 0),

                    [entry.value ? entry.value : entry.label]:
                      parseFloat(
                        obj[entry.value ? entry.value : entry.label] ?? 0
                      ) + parseFloat(gross_valueTotal ?? 0),
                  };
                }
              });
            });

            dummyData.push(obj);
          } else {
            dummyData.push({
              due_date: date,
              due_date_ref: date_ref,
              gross_value_ref: gross_value_ref ?? 0,
              gross_value: gross_value ?? 0,
            });
          }
        });

        setTimeout(() => {
          setChartData([...dummyData]);
          setItem({
            high:
              type === "Expenses"
                ? change <= 0
                  ? false
                  : true
                : change >= 0
                  ? false
                  : true,
            value: `${change}%`,
            refTotal: refTotal,
            total: mainTotal,
          });
          setLoader(false);
        }, 500);
      }
      return () => {};
    },
    300,
    [
      periodTranData,
      refPeriodTranData,
      selectedItem,
      type,
      selectionCategories,
      dateRange,
      periodDateRange,
      selectedIName,
    ]
  );

  //function
  const getPercentageChange = (oldNumber, newNumber) => {
    var decreaseValue = newNumber - oldNumber;
    let divide = oldNumber === 0.0 ? (type === "Expenses" ? -1 : 1) : oldNumber;
    return ((decreaseValue / divide) * 100.0).toFixed(1);
  };

  const onClickItem = (item) => {
    setSelectedName(item.id);
    setChartTypeTitle(item.title);
  };

  //render function
  const CommonView = ({ title, data }) => {
    return (
      <Box sx={{ mb: "1.5rem" }}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: 400,
            fontSize: "1rem",
            fontFamily: Fonts.Text,
            textAlign: "left",
            color: Color.greyText,
            mb: "1rem",
            pb: title ? 0 : "1rem",
          }}
        >
          {title ?? ""}
        </Typography>
        {data?.map((item) => {
          return (
            <Typography
              key={item.id}
              onClick={() => onClickItem(item)}
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                fontFamily: Fonts.Text,
                textAlign: "left",
                color:
                  selectedIName === item.id
                    ? Color.deepPurple400
                    : Color.greyText,
                mb: "1rem",
                cursor: "pointer",
                "&:hover": {
                  color: Color.deepPurple400,
                },
              }}
            >
              - {item.title}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const GroupPopOver = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", mr: "1rem" }}>
        <CustomPopover
          title={chartTypeTitle}
          width={"12.5rem"}
          justifyContent={"space-between"}
        >
          {" "}
          <Box
            sx={{
              width: "31.25rem",
              height: "fit-content",
              minHeight: "12.5rem",
              fontSize: "2.125rem",
              fontWeight: 600,
              pl: "1.5rem",
              pr: "3rem",
              pt: "1.5rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <CommonView title={t("Analysis Period")} data={option1} />
              <CommonView title={t("Transaction Type")} data={option3} />
            </Box>
            <Box>
              <CommonView title={t("Analysis vs. Reference")} data={option2} />
              <CommonView title={null} data={option4} />
            </Box>
          </Box>
        </CustomPopover>
      </Box>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        borderRadius: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: "1rem",
        }}
      >
        <Typography
          sx={{
            mx: "1rem",
            fontSize: "1.2rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            color: Color.black,
            display: "inline-flex",
          }}
        >
          {t(title) ?? ""}
        </Typography>
        <GroupPopOver />
      </Box>
      {!loading ? (
        <>
          {chartData?.length > 0 ? (
            <>
              {!isAllHeaderApiFetched || loader || testLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={"20rem"}
                  height={"2rem"}
                  sx={{ my: "0.3rem", ml: "1rem" }}
                />
              ) : (
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  {" "}
                  <Typography
                    sx={{
                      ml: "1rem",
                      mr: "0.5rem",
                      fontSize: "1.4rem",
                      fontWeight: 800,
                      fontFamily: Fonts.Text,
                      color: Color.deepPurple400,
                    }}
                  >
                    {item &&
                      formatAmount({
                        amount: String(parseFloat(item?.total).toFixed(2)),
                      })}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: Fonts.Text,
                      color: Color.greyText,
                      display: "inline-flex",
                      alignItems: "end",
                      pt: "0.25rem",
                      opacity: 0.9,
                    }}
                  >
                    {t("this period")}
                  </Typography>
                </Box>
              )}
              {!isAllHeaderApiFetched || loader || testLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={"20rem"}
                  height={"1.5rem"}
                  sx={{ my: "0.3rem", ml: "1rem" }}
                />
              ) : (
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    mt: "0.5rem",
                    mb: "1rem",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      ml: "1rem",
                      mr: "0.5rem",
                      fontSize: "1.05rem",
                      fontWeight: 700,
                      fontFamily: Fonts.Text,
                      color: Color.black,
                      opacity: 0.8,
                    }}
                  >
                    {item &&
                      formatAmount({
                        amount: String(parseFloat(item?.refTotal).toFixed(2)),
                      })}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: Fonts.Text,
                      color: Color.greyText,
                      opacity: 0.9,
                    }}
                  >
                    {t("reference period")}
                  </Typography>
                  <Chip
                    label={item?.value}
                    size="small"
                    icon={
                      item?.high ? (
                        type === "Expenses" ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: `${Color.expenseBarBg} !important`,
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: `${Color.expenseBarBg} !important`,
                            }}
                          />
                        )
                      ) : type === "Expenses" ? (
                        <ArrowDownwardIcon
                          sx={{
                            color: `${Color.green800} !important`,
                          }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          sx={{
                            color: `${Color.green800} !important`,
                          }}
                        />
                      )
                    }
                    sx={{
                      minWidth: "4.6875rem",
                      ml: "0.5rem",
                      pt: "0.15rem",
                      fontWeight: 800,
                      fontFamily: Fonts.Text,
                      fontSize: "0.8rem",
                      color: item?.high ? Color.expenseBarBg : Color.green800,
                      backgroundColor: item?.high
                        ? Color.red100
                        : Color.greenA100,
                      border: `1px solid ${
                        item?.high ? Color.expenseBarBg : Color.green
                      }`,
                    }}
                  />
                </Box>
              )}
              {!isAllHeaderApiFetched || loader || testLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={isBig ? "28.125rem" : "15.125rem"}
                  sx={{ my: "1rem", mx: "1rem" }}
                />
              ) : (
                <Box sx={{ height: isBig ? "28.125rem" : "15.125rem" }}>
                  {selectedIName === "1" ||
                  selectedIName === "3" ||
                  selectedIName === "4" ? (
                    <BarCharts
                      data={chartData}
                      type={type}
                      twoBar={
                        selectedIName === "3" || selectedIName === "4"
                          ? true
                          : false
                      }
                    />
                  ) : null}
                  {selectedIName === "5" ||
                  selectedIName === "6" ||
                  selectedIName === "7" ? (
                    <StackedCharts
                      data={chartData}
                      type={type}
                      withLine={selectedIName === "7"}
                      selectedItem={selectedItem}
                    />
                  ) : null}
                  {selectedIName === "2" || selectedIName === "8" ? (
                    <LineCharts
                      data={chartData}
                      type={type}
                      selectedItem={selectedItem}
                      multilines={selectedIName === "8"}
                    />
                  ) : null}
                </Box>
              )}
            </>
          ) : (
            <Typography
              sx={{
                height: "100%",
                fontSize: "1.2rem",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                color: Color.black,
                opacity: 0.9,
                alignSelf: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              {t("No_Data_Found")}
            </Typography>
          )}
        </>
      ) : (
        <Typography
          sx={{
            height: "100%",
            fontSize: "1.2rem",
            fontWeight: 500,
            fontFamily: Fonts.Text,
            color: Color.black,
            opacity: 0.9,
            alignSelf: "center",
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          {t("Loading")}..
        </Typography>
      )}
    </Paper>
  );
};

export default MainChartView;
