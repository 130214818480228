import { Box, Paper, Typography, Skeleton } from "@mui/material";
import { formatValue } from "react-currency-input-field";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import format from "date-fns/format";

import { getTransactionByParams, remToPx } from "../../../Helper/data";
import CategoryChip from "../../../components/CategoryChip";
import { Color, Fonts } from "../../../Helper";
import useWidth from "../../../hooks/useWidth";

const AccountsGridView = React.memo(
  ({ title }) => {
    const currentWidth = useWidth();
    const { t } = useTranslation();
    let width = remToPx(currentWidth, 93.75);
    //redux
    const currencyFormate = useSelector(
      (state) => state.settingsSlice.currencyFormate
    );
    const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
    const testLoading = useSelector((state) => state.appSlice.testLoading);
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const dataSource = useSelector((state) => state.globalSlice?.dataSource);
    const isAllHeaderApiFetched = useSelector(
      (state) => state.commonSlice.isAllHeaderApiFetched
    );
    const isFirstLoginOverlayOpen = useSelector(
      (state) => state.globalSlice.isFirstLoginOverlayOpen
    );

    //state
    const columns = useMemo(
      () => [
        {
          field: "title",
          headerName: t("Title"),
          width: width * 0.17,
        },
        {
          field: "due_date",
          headerName: t("Date"),
          width: width * 0.17,
          valueFormatter: ({ value }) => {
            return format(new Date(value), "dd MMM yyyy");
          },
        },
        {
          field: "gross_value",
          headerName: t("Amount"),
          width: width * 0.17,
          valueFormatter: ({ value }) => {
            return formatValue({
              value: String(value),
              groupSeparator: currencyFormate.groupSeparator,
              decimalSeparator: currencyFormate.decimalSeparator,
              prefix: currencyFormate.prefix,
            });
          },
        },
        {
          width: width * 0.17,
          field: "category",
          headerName: t("category"),
          editable: false,
          renderCell: (params) => {
            return (
              <strong>
                <CategoryChip
                  categoryId={params.value}
                  noCategoryLabel={"Uncategorized"}
                />
              </strong>
            );
          },
        },
      ],
      [currencyFormate, width, t]
    );

    const Latest_Transactions = useQuery({
      queryKey: [
        "transactions",
        {
          page: 1,
          page_size: 6,
          apiType: "Latest_Transactions",
          source: 2,
        },
      ],
      queryFn: ({ signal }) => {
        let params = {
          config: {
            signal: signal,
          },
          dataset: dataSetData?.uuid,
          page: 1,
          page_size: 6,
          order_by: "-due_date",
          is_reconciled: false,
          source: 2,
          state: [stateByTitle?.["Booked"]?.[0]?.uuid],
        };

        const result = getTransactionByParams(params);
        if (result) {
          return result;
        }
      },
      cacheTime: 60000 * 5,
      backgroundFetch: true,
      enabled:
        isAllHeaderApiFetched &&
        !isFirstLoginOverlayOpen &&
        !!dataSetData?.uuid &&
        !!dataSource,
      priority: 2,
    });

    //RenderFunction
    return (
      <Paper
        elevation={0}
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          borderRadius: 1,
          width: width,
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 52,
          }}
        >
          {Latest_Transactions?.isFetching || testLoading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"20rem"}
              height={"1.5rem"}
              sx={{ my: "0.3rem", ml: "1rem" }}
            />
          ) : (
            <Typography
              sx={{
                mx: "1rem",
                fontSize: "1.2rem",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                color: Color.black,
                display: "inline-flex",
              }}
            >
              {title}
            </Typography>
          )}
        </Box>
        {Latest_Transactions?.isFetching || testLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "90%",
              display: "flex",
              flexDirection: "Column",
              alignItems: "center",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"2rem"}
              sx={{ my: "0.3rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem", mt: "1rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"98%"}
              height={"1rem"}
              sx={{ my: "0.6rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"15rem"}
              height={"1.5rem"}
              sx={{ my: "1.1rem", mr: "0.9rem", alignSelf: "end" }}
            />
          </Box>
        ) : Latest_Transactions?.data?.results?.length > 0 ? (
          <DataGrid
            disableColumnMenu
            density="compact"
            autoHeight
            rows={Latest_Transactions?.data?.results}
            rowHeight={remToPx(currentWidth, 3)}
            columns={columns}
            pageSize={6}
            getRowId={(row) => row.uuid}
          />
        ) : (
          <Typography
            sx={{
              height: "100%",
              fontSize: "1.2rem",
              fontWeight: 500,
              fontFamily: Fonts.Text,
              color: Color.black,
              opacity: 0.9,
              alignSelf: "center",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            {t("No_Data_Found")}
          </Typography>
        )}
      </Paper>
    );
  },
  (prev, next) =>
    prev.data === next.data &&
    prev.title === next.title &&
    prev.aggregates === next.aggregates &&
    prev.subTitle === next.subTitle
);

export default AccountsGridView;
