import React, { useEffect, useState } from "react";
import { alpha, Box } from "@mui/material";
import { useSelector } from "react-redux";

import MainChartView from "./MainChratView";
import PieChratView from "./PieChratView";
import { Color } from "../../../Helper";
import HeaderView from "./HeaderView";
import GridView from "./GridView";

const Chart = ({ selectedItem, type }) => {
  //redux state
  const periodTranData = useSelector(
    (state) => state.chartSlice?.periodTranData
  );
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  

  let item = {};
  if (selectedItem === 1) {
    item.title = "Revenue";
    item.pieChartTitle = "Transaction types";
    item.pieChartSubTitle = "Revenue";
    item.gridTitle = "Top transactions";
    item.gridSubTitle = "Revenue";
  }
  if (selectedItem === 2) {
    item.title = "Expenses";
    item.pieChartTitle = "Top transactions";
    item.pieChartSubTitle = "Expenses";
    item.gridTitle = "Top transactions";
    item.gridSubTitle = "Expenses";
  }
  if (selectedItem === 3) {
    item.title = "Revenue";
    item.pieChartTitle = "Customer types";
    item.pieChartSubTitle = "Revenue";
    item.gridTitle = "Top customers";
    item.gridSubTitle = "Revenue";
  }
  //state
  const [pieChartData, setPieChartData] = useState(null);

  //lifeCycle method
  useEffect(() => {
    // setLoading(true);
    if (periodTranData?.length > 0) {
      let array = [];
      let total = [];
      if (selectedItem === 1) {
        total = periodTranData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.inflow ?? 0),
          0
        );
      }
      if (selectedItem === 2) {
        total = periodTranData?.reduce(
          (total, item) => parseFloat(total) + parseFloat(item?.outflow ?? 0),
          0
        );
      }
      let newCat = [
        {
          value: null,
          label: "Uncategorized",
          color: Color?.unCategorizedCardBg,
        },
        ...selectionCategories,
      ];
      newCat.forEach((element, index) => {
        let categoryData = periodTranData.filter(
          (o1) => o1.category === element.value
        );
        if (categoryData?.length > 0) {
          let gross_value = 0;
          if (selectedItem === 1) {
            gross_value = categoryData?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.inflow ?? 0),
              0
            );
          }
          if (selectedItem === 2) {
            gross_value = categoryData?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.outflow ?? 0),
              0
            );
          }

          const share = ((gross_value * 100.0) / total).toFixed(1);

          array.push({
            id: index,
            categoryName: element.label,
            gross_value: Math.abs(gross_value),
            color: element.color ?? alpha(Color?.themeColor2, 0.7),
            share: `${share}%`,
          });
          setPieChartData(array);
        }
      });
    } else {
      setPieChartData([]);
    }
    // setLoading(false);
  }, [selectedItem, selectionCategories, periodTranData]);

  //function
  return (
    <Box
      sx={{
        width: "70%",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <HeaderView selectedItem={selectedItem} />
      <Box
        sx={{
          width: "100%",
          height: "37.5rem",
          display: "flex",
        }}
      >
        <MainChartView
          title={item?.title}
          selectedItem={selectedItem}
          type={type}
          isBig={true}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "37.5rem",
          display: "flex",
          justifyContent: "space-between",
          my: "2.5rem",
        }}
      >
        <PieChratView
          data={pieChartData}
          title={item?.pieChartTitle}
          subTitle={item?.pieChartSubTitle}
        />
        <GridView
          title={item?.gridTitle}
          subTitle={item?.gridSubTitle}
          selectedItem={selectedItem}
        />
      </Box>
    </Box>
  );
};
export default React.memo(Chart, (prev, next) => {
  return prev.selectedItem === next.selectedItem && prev.type === next.type;
});
