import {
  lightGreen,
  deepPurple,
  deepOrange,
  lightBlue,
  blueGrey,
  yellow,
  orange,
  indigo,
  purple,
  amber,
  brown,
  green,
  pink,
  lime,
  grey,
  cyan,
  teal,
  blue,
  red,
} from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

export const Color = {
  categoryTheme: [
    red[500],
    "#6101D1", //primary 500
    pink[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    lightBlue[500],
    cyan[500],
    teal[500],
    green[500],
    lightGreen[500],
    lime[500],
    yellow[500],
    amber[500],
    orange[500],
    deepOrange[500],
    brown[500],
    grey[500],
    blueGrey[500],
  ],
  stateTheme: [
    red[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    lightBlue[500],
    teal[500],
    yellow[500],
    orange[500],
    amber[500],
    deepOrange[500],
    brown[500],
    blueGrey[500],
    lightGreen[500],
    green[500],
    pink[500],
    lime[500],
    grey[500],
    cyan[500],
    // "#86cbd3",
    // "#9ecf62",
    // "#ca5d44",
    // "#8b8b8b",
    // "#b33665",
    // "#4e9ae8",
    // "#e2ca70",
    // "#5d418c",
    // "#924ba3",
    // "#ac4e73",
  ],
  scenarioTheme: [
    // "#64748b",
    // "#6b7280",
    // "#525252",
    "#03a9f4",
    "#ff7c0f",
    "#ef4444",
    "#d69e2e",
    "#38a169",
    "#3b82f6",
    "#6366f1",
    "#f59e0b",
    "#84CC16",
    "#10B981",
    "#14B8A6",
    "#06B6D4",
    "#0EA5E9",
    "#8B5CF6",
    "#A855F7",
    "#D946EF",
    "#EC4899",
    "#FB7185",
  ],
  scenario_hase_to_name: {
    // "#64748b": "slate",
    // "#525252": "stone",
    // "#6b7280": "grey",
    "#03a9f4": "lightBlue",
    "#ff7c0f": "orange",
    "#ef4444": "red",
    "#d69e2e": "yellow",
    "#38a169": "green",
    "#3b82f6": "blue",
    "#6366f1": "indigo",
    "#f59e0b": "amber",
    "#84cc16": "lime",
    "#10b981": "emerald",
    "#14b8a6": "teal",
    "#06b6d4": "cyan",
    "#0ea5e9": "sky",
    "#8b5cf6": "violet",
    "#a855f7": "purple",
    "#d946ef": "fuchsia",
    "#ec4899": "pink",
    "#fb7185": "rose",
  },
  scenario_name_to_hase: {
    // slate: "#64748b",
    // stone: "#525252",
    // grey: "#6b7280",
    lightBlue: "#03a9f4",
    orange: "#ff7c0f",
    red: "#ef4444",
    yellow: "#d69e2e",
    green: "#38a169",
    blue: "#3b82f6",
    indigo: "#6366f1",
    amber: "#f59e0b",
    lime: "#84cc16",
    emerald: "#10b981",
    teal: "#14b8a6",
    cyan: "#06b6d4",
    sky: "#0ea5e9",
    violet: "#8b5cf6",
    purple: "#a855f7",
    fuchsia: "#d946ef",
    pink: "#ec4899",
    rose: "#fb7185",
  },
  theme: {
    blue: {
      50: "#e3f2fd",
      100: "#bbdefb",
      200: "#90caf9",
      300: "#64b5f6",
      400: "#42a5f5",
      500: "#2196f3",
      600: "#1e88e5",
      700: "#1976d2",
      800: "#1565c0",
      900: "#0d47a1",
      A100: "#82b1ff",
      A200: "#448aff",
      A400: "#2979ff",
      A700: "#2962ff",
    },
    amber: {
      50: "#fff8e1",
      100: "#ffecb3",
      200: "#ffe082",
      300: "#ffd54f",
      400: "#ffca28",
      500: "#ffc107",
      600: "#ffb300",
      700: "#ffa000",
      800: "#ff8f00",
      900: "#ff6f00",
      A100: "#ffe57f",
      A200: "#ffd740",
      A400: "#ffc400",
      A700: "#ffab00",
    },
    blueGrey: {
      50: "#eceff1",
      100: "#cfd8dc",
      200: "#b0bec5",
      300: "#90a4ae",
      400: "#78909c",
      500: "#607d8b",
      600: "#546e7a",
      700: "#455a64",
      800: "#37474f",
      900: "#263238",
      A100: "#cfd8dc",
      A200: "#b0bec5",
      A400: "#78909c",
      A700: "#455a64",
    },
    brown: {
      50: "#efebe9",
      100: "#d7ccc8",
      200: "#bcaaa4",
      300: "#a1887f",
      400: "#8d6e63",
      500: "#795548",
      600: "#6d4c41",
      700: "#5d4037",
      800: "#4e342e",
      900: "#3e2723",
      A100: "#d7ccc8",
      A200: "#bcaaa4",
      A400: "#8d6e63",
      A700: "#5d4037",
    },
    cyan: {
      50: "#e0f7fa",
      100: "#b2ebf2",
      200: "#80deea",
      300: "#4dd0e1",
      400: "#26c6da",
      500: "#00bcd4",
      600: "#00acc1",
      700: "#0097a7",
      800: "#00838f",
      900: "#006064",
      A100: "#84ffff",
      A200: "#18ffff",
      A400: "#00e5ff",
      A700: "#00b8d4",
    },
    deepOrange: {
      50: "#fbe9e7",
      100: "#ffccbc",
      200: "#ffab91",
      300: "#ff8a65",
      400: "#ff7043",
      500: "#ff5722",
      600: "#f4511e",
      700: "#e64a19",
      800: "#d84315",
      900: "#bf360c",
      A100: "#ff9e80",
      A200: "#ff6e40",
      A400: "#ff3d00",
      A700: "#dd2c00",
    },
    deepPurple: {
      50: "#ede7f6",
      100: "#d1c4e9",
      200: "#b39ddb",
      300: "#9575cd",
      400: "#7e57c2",
      500: "#673ab7",
      600: "#5e35b1",
      700: "#512da8",
      800: "#4527a0",
      900: "#311b92",
      A100: "#b388ff",
      A200: "#7c4dff",
      A400: "#651fff",
      A700: "#6200ea",
    },
    green: {
      50: "#e8f5e9",
      100: "#c8e6c9",
      200: "#a5d6a7",
      300: "#81c784",
      400: "#66bb6a",
      500: "#4caf50",
      600: "#43a047",
      700: "#388e3c",
      800: "#2e7d32",
      900: "#1b5e20",
      A100: "#b9f6ca",
      A200: "#69f0ae",
      A400: "#00e676",
      A700: "#00c853",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    indigo: {
      50: "#e8eaf6",
      100: "#c5cae9",
      200: "#9fa8da",
      300: "#7986cb",
      400: "#5c6bc0",
      500: "#3f51b5",
      600: "#3949ab",
      700: "#303f9f",
      800: "#283593",
      900: "#1a237e",
      A100: "#8c9eff",
      A200: "#536dfe",
      A400: "#3d5afe",
      A700: "#304ffe",
    },
    lightBlue: {
      50: "#e1f5fe",
      100: "#b3e5fc",
      200: "#81d4fa",
      300: "#4fc3f7",
      400: "#29b6f6",
      500: "#03a9f4",
      600: "#039be5",
      700: "#0288d1",
      800: "#0277bd",
      900: "#01579b",
      A100: "#80d8ff",
      A200: "#40c4ff",
      A400: "#00b0ff",
      A700: "#0091ea",
    },
    lightGreen: {
      50: "#f1f8e9",
      100: "#dcedc8",
      200: "#c5e1a5",
      300: "#aed581",
      400: "#9ccc65",
      500: "#8bc34a",
      600: "#7cb342",
      700: "#689f38",
      800: "#558b2f",
      900: "#33691e",
      A100: "#ccff90",
      A200: "#b2ff59",
      A400: "#76ff03",
      A700: "#64dd17",
    },
    lime: {
      50: "#f9fbe7",
      100: "#f0f4c3",
      200: "#e6ee9c",
      300: "#dce775",
      400: "#d4e157",
      500: "#cddc39",
      600: "#c0ca33",
      700: "#afb42b",
      800: "#9e9d24",
      900: "#827717",
      A100: "#f4ff81",
      A200: "#eeff41",
      A400: "#c6ff00",
      A700: "#aeea00",
    },
    orange: {
      50: "#fff3e0",
      100: "#ffe0b2",
      200: "#ffcc80",
      300: "#ffb74d",
      400: "#ffa726",
      500: "#ff9800",
      600: "#fb8c00",
      700: "#f57c00",
      800: "#ef6c00",
      900: "#e65100",
      A100: "#ffd180",
      A200: "#ffab40",
      A400: "#ff9100",
      A700: "#ff6d00",
    },
    pink: {
      50: "#fce4ec",
      100: "#f8bbd0",
      200: "#f48fb1",
      300: "#f06292",
      400: "#ec407a",
      500: "#e91e63",
      600: "#d81b60",
      700: "#c2185b",
      800: "#ad1457",
      900: "#880e4f",
      A100: "#ff80ab",
      A200: "#ff4081",
      A400: "#f50057",
      A700: "#c51162",
    },
    purple: {
      50: "#f3e5f5",
      100: "#e1bee7",
      200: "#ce93d8",
      300: "#ba68c8",
      400: "#ab47bc",
      500: "#9c27b0",
      600: "#8e24aa",
      700: "#7b1fa2",
      800: "#6a1b9a",
      900: "#4a148c",
      A100: "#ea80fc",
      A200: "#e040fb",
      A400: "#d500f9",
      A700: "#aa00ff",
    },
    red: {
      50: "#ffebee",
      100: "#ffcdd2",
      200: "#ef9a9a",
      300: "#e57373",
      400: "#ef5350",
      500: "#f44336",
      600: "#e53935",
      700: "#d32f2f",
      800: "#c62828",
      900: "#b71c1c",
      A100: "#ff8a80",
      A200: "#ff5252",
      A400: "#ff1744",
      A700: "#d50000",
    },
    teal: {
      50: "#e0f2f1",
      100: "#b2dfdb",
      200: "#80cbc4",
      300: "#4db6ac",
      400: "#26a69a",
      500: "#009688",
      600: "#00897b",
      700: "#00796b",
      800: "#00695c",
      900: "#004d40",
      A100: "#a7ffeb",
      A200: "#64ffda",
      A400: "#1de9b6",
      A700: "#00bfa5",
    },
    yellow: {
      50: "#fffde7",
      100: "#fff9c4",
      200: "#fff59d",
      300: "#fff176",
      400: "#ffee58",
      500: "#ffeb3b",
      600: "#fdd835",
      700: "#fbc02d",
      800: "#f9a825",
      900: "#f57f17",
      A100: "#ffff8d",
      A200: "#ffff00",
      A400: "#ffea00",
      A700: "#ffd600",
    },
  },
  tailwind: {
    lightBlue: {
      25: "##e1f5fe80",
      50: "#e1f5fe",
      100: "#b3e5fc",
      200: "#81d4fa",
      300: "#4fc3f7",
      400: "#29b6f6",
      500: "#03a9f4",
      600: "#039be5",
      700: "#0288d1",
      800: "#0277bd",
      900: "#01579b",
      A100: "#80d8ff",
      A200: "#40c4ff",
      A400: "#00b0ff",
      A700: "#0091ea",
    },

    slate: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
      950: "#171b23",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#ffc27f",
      400: "#ff9a3c",
      500: "#ff7c0f",
      600: "#db6b08",
      700: "#bd580c",
      800: "#8c3d10",
      900: "#77340d",
      950: "#572508",
    },
    zinc: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
      950: "#171b23",
    },
    neutral: {
      50: "#f9fafb",
      100: "#f4f5f7",
      200: "#e5e7eb",
      300: "#d2d6dc",
      400: "#9fa6b2",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#252f3f",
      900: "#161e2e",
      950: "#0e131f",
    },
    stone: {
      50: "#f5f5f5",
      100: "#ebebeb",
      200: "#d4d4d4",
      300: "#a3a3a3",
      400: "#757575",
      500: "#525252",
      600: "#404040",
      700: "#2c2c2c",
      800: "#1f1f1f",
      900: "#141414",
      950: "#000000",
    },
    grey: {
      50: "#f9fafb",
      100: "#f4f5f7",
      200: "#e5e7eb",
      300: "#d2d6dc",
      400: "#9fa6b2",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#252f3f",
      900: "#161e2e",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffff0",
      100: "#fefcbf",
      200: "#faf089",
      300: "#f6e05e",
      400: "#ecc94b",
      500: "#d69e2e",
      600: "#b7791f",
      700: "#975a16",
      800: "#744210",
      900: "#5f370e",
    },
    green: {
      50: "#f0fdf4",
      100: "#c6f6d5",
      200: "#9ae6b4",
      300: "#68d391",
      400: "#48bb78",
      500: "#38a169",
      600: "#2f855a",
      700: "#276749",
      800: "#22543d",
      900: "#1c4532",
    },
    lightGreen: {
      50: "#f1f8e9",
      100: "#dcedc8",
      200: "#c5e1a5",
      300: "#aed581",
      400: "#9ccc65",
      500: "#8bc34a",
      600: "#7cb342",
      700: "#689f38",
      800: "#558b2f",
      900: "#33691e",
      A100: "#ccff90",
      A200: "#b2ff59",
      A400: "#76ff03",
      A700: "#64dd17",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    amber: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
      950: "#451a03",
    },
    lime: {
      50: "#F7FEE7",
      100: "#ECFCCB",
      200: "#D9F99D",
      300: "#BEF264",
      400: "#A3E635",
      500: "#84CC16",
      600: "#65A30D",
      700: "#4D7C0F",
      800: "#3F6212",
      900: "#365314",
      950: "#1A2E05",
    },
    emerald: {
      50: "#ECFDF5",
      100: "#D1FAE5",
      200: "#A7F3D0",
      300: "#6EE7B7",
      400: "#34D399",
      500: "#10B981",
      600: "#059669",
      700: "#047857",
      800: "#065F46",
      900: "#064E3B",
      950: "#022C22",
    },
    teal: {
      50: "#F0FDFA",
      100: "#CCFBF1",
      200: "#99F6E4",
      300: "#5EEAD4",
      400: "#2DD4BF",
      500: "#14B8A6",
      600: "#0D9488",
      700: "#0F766E",
      800: "#115E59",
      900: "#134E4A",
      950: "#042F2E",
    },
    cyan: {
      50: "#ECFEFF",
      100: "#CFFAFE",
      200: "#A5F3FC",
      300: "#67E8F9",
      400: "#22D3EE",
      500: "#06B6D4",
      600: "#0891B2",
      700: "#0E7490",
      800: "#155E75",
      900: "#164E63",
      950: "#083344",
    },
    sky: {
      50: "#F0F9FF",
      100: "#E0F2FE",
      200: "#BAE6FD",
      300: "#7DD3FC",
      400: "#38BDF8",
      500: "#0EA5E9",
      600: "#0284C7",
      700: "#0369A1",
      800: "#075985",
      900: "#0C4A6E",
      950: "#082F49",
    },
    violet: {
      50: "#F5F3FF",
      100: "#EDE9FE",
      200: "#DDD6FE",
      300: "#C4B5FD",
      400: "#A78BFA",
      500: "#8B5CF6",
      600: "#7C3AED",
      700: "#6D28D9",
      800: "#5B21B6",
      900: "#4C1D95",
      950: "#2E1065",
    },
    purple: {
      50: "#FAF5FF",
      100: "#F3E8FF",
      200: "#E9D5FF",
      300: "#D8B4FE",
      400: "#C084FC",
      500: "#A855F7",
      600: "#9333EA",
      700: "#7E22CE",
      800: "#6B21A8",
      900: "#581C87",
      950: "#3B0764",
    },
    fuchsia: {
      50: "#FDF4FF",
      100: "#FAE8FF",
      200: "#F5D0FE",
      300: "#F0ABFC",
      400: "#E879F9",
      500: "#D946EF",
      600: "#C026D3",
      700: "#A21CAF",
      800: "#86198F",
      900: "#701A75",
      950: "#4A044E",
    },
    pink: {
      50: "#FDF2F8",
      100: "#FCE7F3",
      200: "#FBCFE8",
      300: "#F9A8D4",
      400: "#F472B6",
      500: "#EC4899",
      600: "#DB2777",
      700: "#BE185D",
      800: "#9D174D",
      900: "#831843",
      950: "#500724",
    },
    rose: {
      50: "#FFF1F2",
      100: "#FFE4E6",
      200: "#FECDD3",
      300: "#FDA4AF",
      400: "#FB7185",
      500: "#F43F5E",
      600: "#E11D48",
      700: "#BE123C",
      800: "#9F1239",
      900: "#881337",
      950: "#4C0519",
    },
    blueGrey: {
      50: "#eceff1",
      100: "#cfd8dc",
      200: "#b0bec5",
      300: "#90a4ae",
      400: "#78909c",
      500: "#607d8b",
      600: "#546e7a",
      700: "#455a64",
      800: "#37474f",
      900: "#263238",
      A100: "#cfd8dc",
      A200: "#b0bec5",
      A400: "#78909c",
      A700: "#455a64",
    },
    brown: {
      50: "#efebe9",
      100: "#d7ccc8",
      200: "#bcaaa4",
      300: "#a1887f",
      400: "#8d6e63",
      500: "#795548",
      600: "#6d4c41",
      700: "#5d4037",
      800: "#4e342e",
      900: "#3e2723",
      A100: "#d7ccc8",
      A200: "#bcaaa4",
      A400: "#8d6e63",
      A700: "#5d4037",
    },
    deepOrange: {
      50: "#fbe9e7",
      100: "#ffccbc",
      200: "#ffab91",
      300: "#ff8a65",
      400: "#ff7043",
      500: "#ff5722",
      600: "#f4511e",
      700: "#e64a19",
      800: "#d84315",
      900: "#bf360c",
      A100: "#ff9e80",
      A200: "#ff6e40",
      A400: "#ff3d00",
      A700: "#dd2c00",
    },
    deepPurple: {
      50: "#ede7f6",
      100: "#d1c4e9",
      200: "#b39ddb",
      300: "#9575cd",
      400: "#7e57c2",
      500: "#673ab7",
      600: "#5e35b1",
      700: "#512da8",
      800: "#4527a0",
      900: "#311b92",
      A100: "#b388ff",
      A200: "#7c4dff",
      A400: "#651fff",
      A700: "#6200ea",
    },
  },
  ThemeBackground1: "#f0f6ff",
  ThemeBackground2: "#FAFAFA",
  datasetWidgetBG: "#f8fafc",

  upgradeCardBg: amber[50],
  premiumBtnBg: amber[200],
  indigo50: indigo[50],
  cyan50: cyan[50],
  pink50: pink[50],
  indigo500: indigo[500],
  error: "#d03535",
  red: red[500],
  pink: pink[500],
  purple: purple[500],
  deepPurple: deepPurple[500],
  indigo: indigo[500],
  blue: blue[500],
  lightBlue: lightBlue[500],
  cyan: cyan[500],
  teal: teal[500],
  green: green[500],
  green900: green[900],
  lightGreen: lightGreen[500],
  lime: lime[500],
  yellow: yellow[500],
  amber: amber[500],
  orange: orange[500],
  deepOrange: deepOrange[500],
  brown: brown[500],
  grey: grey[500],
  blueGrey: blueGrey[500],
  pink100: pink[100],
  cyan100: cyan[100],
  indigo100: indigo[100],
  red50: red[50],
  red100: red[100],
  red200: red[200],
  red400: red[400],
  red500: red[500],
  red600: red[600],
  red700: red[700],
  red800: red[800],
  red900: red[900],
  deepOrange400: deepOrange[400],
  pinkText: pink[300],
  blueGreyA100: blueGrey["A100"],
  blueGrey100: blueGrey[100],
  blueGrey200: blueGrey[200],
  blueGrey400: blueGrey[400],
  blueGrey500: blueGrey[500],
  blueGrey600: blueGrey[600],
  blueGrey700: blueGrey[700],
  blueGrey800: blueGrey[800],
  blueGrey900: blueGrey[900],
  blue50: blue[50],
  blue100: blue[100],
  blue200: blue[200],
  blue500: blue[500],
  blue700: blue[700],
  deepPurple50: deepPurple[50],
  deepPurple100: deepPurple[100],
  deepPurple200: deepPurple[200],
  deepPurple300: deepPurple[300],
  deepPurple400: deepPurple[400],
  deepPurple600: deepPurple[600],
  deepPurple700: deepPurple[700],

  purple50: purple[50],
  purple100: purple[100],
  purple200: purple[200],
  purple400: purple[400],
  purple500: purple[500],
  purple800: purple[800],
  purple900: purple[900],
  tableBorder: "#EDE7F6",
  tableBorderHighLight: "#E0D6EF",
  blueGrey50: blueGrey[50],
  grey50: grey[50],
  grey100: grey[100],
  grey200: grey[200],
  grey300: grey[300],
  grey400: grey[400],
  grey500: grey[500],
  grey600: grey[600],
  green50: green[50],
  green100: green[100],
  greenA100: green["A100"],
  green200: green[200],
  green300: green[300],
  green400: green[400],
  green500: green[500],
  green800: green[800],
  yellow100: yellow[100],
  yellow500: yellow[500],
  orange500: orange[500],
  themeBackground: "#ededed",
  themeButtonText: "#7f35a2",
  activeSheetBg: "#F2F5FF",
  catSearchTitle: "#c5b3e3 ",
  inActiveSheetBg: " #F9FAFE",
  boardBackground: "#f1f6ff",
  themeColor: "#530c84",
  themeColor2: "#551680",
  linkHighlight: "#1675e0",
  textFelidBg: "rgba(0,0,0,0.1)",
  tableLightBg: "#fbf9fc",
  white: "#fff",
  violated: "palevioletred",
  black: alpha("#000", 0.9),
  success: "#14c781",
  cardBottomBg: "#f8f8f8",
  Yellow: "#FFECA9",
  greyText: grey[700],
  autoCompleteBorder: "rgba(0, 0, 0, 0.23)",
  columnTitle: "#878787",
  headerTitle: "#b9c5db",
  headerIcon: "#859dc4",
  headerIconLight: "#d4e0f3",
  unAssign: "#e3ecf8",
  greyButtonBorder: "#e4e4e4",

  switchUnChecked: "#ccdaef",
  switchChecked: "#859dc4",
  appThemeBg: "#f8fafc",
  navMainHoverBg: "#41016d",
  profitBg: "#c5d6f1",
  footerBg: "#ECF1FC",
  unCategorizedCardBg: blueGrey[200],
  lightGrey: grey[400],
  unCategorizedBg: "#fff",
  allCatBG: blueGrey[50],
  unCategorizedTitle: red[500],
  categoryBg: "#f3f3f3",
  categoryHoverBg: "#f0f0f0",

  expenseBarBg: "#e65a65",
  barBg: "#fec35c",
  refBarBg: "#a0aec0",
  dashedDivider: "#e3e5e6",
  todoTopBorder: "#eee",
  oInputBorderBg: purple[50],
  HeaderTitle: blueGrey[700],
  BodyBG: "#fff",
  HeaderBG: "#f1f5f9",
  FooterBG: "#f1f5f9",
  transparent: "transparent",
  info: "#03a9f4",
  employeeColor: deepPurple[500],
  freelancerColor: blue[800],
  hederEleBg: "#9c27b006",
  inputBG: "rgb(0 0 0 / 5%)",
  bulkActionBG: "#a21caf",
};
