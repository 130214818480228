import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { formatValue } from "react-currency-input-field";
import React, { useEffect, useState } from "react";
import startOfMonth from "date-fns/startOfMonth";
import { useTranslation } from "react-i18next";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import { useSelector } from "react-redux";

import WidgetInnerView from "../Reports/WidgetInnerView";
import { Color, Fonts } from "../../../Helper";
import Icon from "../../../components/Icon";

const OverviewWidget = React.memo(
  ({ icon, title, type, filteredTransactionData, loader = false }) => {
    const { t } = useTranslation();

    //redux
    const currencyFormate = useSelector(
      (state) => state.settingsSlice.currencyFormate
    );
    const isAllHeaderApiFetched = useSelector(
      (state) => state.commonSlice.isAllHeaderApiFetched
    );
    const testLoading = useSelector((state) => state.appSlice.testLoading);

    //state
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(null);

    useEffect(() => {
      setLoading(true);
      let currentMonthStart = new Date(startOfMonth(new Date()));
      let currentMonthEnd = new Date(endOfMonth(new Date()));
      const prePeriodCurrentDate = subMonths(new Date(), 1);
      let preMonthStart = new Date(
        startOfMonth(new Date(prePeriodCurrentDate))
      );
      let preMonthEnd = new Date(endOfMonth(new Date(prePeriodCurrentDate)));

      let currentMonthData = filteredTransactionData?.filter(
        (o1) =>
          (type === "1"
            ? o1.gross_value >= 0
            : type === "2"
            ? o1.gross_value < 0
            : true) &&
          (type === "3" ? o1.contact : true) &&
          new Date(o1.due_date) >= currentMonthStart &&
          new Date(o1.due_date) <= currentMonthEnd
      );
      let preMonthData = filteredTransactionData?.filter(
        (o1) =>
          (type === "1"
            ? o1.gross_value >= 0
            : type === "2"
            ? o1.gross_value < 0
            : true) &&
          (type === "3" ? o1.contact : true) &&
          new Date(o1.due_date) >= preMonthStart &&
          new Date(o1.due_date) <= preMonthEnd
      );
      let prePeriodData = filteredTransactionData?.filter(
        (o1) =>
          (type === "1"
            ? o1.gross_value >= 0
            : type === "2"
            ? o1.gross_value < 0
            : true) &&
          (type === "3" ? o1.contact : true) &&
          new Date(o1.due_date) >= preMonthStart &&
          new Date(o1.due_date) <= new Date(prePeriodCurrentDate)
      );

      let currentMonthTotal = currentMonthData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );
      let prePeriodMonthTotal = prePeriodData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );
      let preMonthTotal = preMonthData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.gross_value ?? 0),
        0
      );

      let changeMonth = getPercentageChange(preMonthTotal, currentMonthTotal);
      let change = getPercentageChange(prePeriodMonthTotal, currentMonthTotal);
      let high1 =
        type === "2"
          ? changeMonth <= 0
            ? false
            : true
          : changeMonth >= 0
          ? false
          : true;
      let high2 =
        type === "2"
          ? change <= 0
            ? false
            : true
          : change >= 0
          ? false
          : true;
      let obj = {
        currentMonthTotal,
        preMonthTotal,
        prePeriodMonthTotal,
        prePeriodCurrentDate,
        changeMonth,
        change,
        high1,
        high2,
      };
      setItem(obj);
      setLoading(false);
    }, [filteredTransactionData]);

    //Function
    const getPercentageChange = (oldNumber, newNumber) => {
      if (newNumber === 0 && oldNumber === 0) {
        return 0;
      }
      var decreaseValue = newNumber - oldNumber;
      let divide = oldNumber === 0.0 ? (type === "2" ? -1 : 1) : oldNumber;
      return ((decreaseValue / divide) * 100.0).toFixed(1);
    };

    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0.75rem",
          height: "fit-content",
          width: "15rem",
          borderRadius: 2,
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          {loader ||
          !isAllHeaderApiFetched ||
          !title ||
          loading ||
          testLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="circular"
                width={"1.25rem"}
                height={"1.25rem"}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width={"10rem"}
                height={"1.25rem"}
                sx={{ my: "0.3rem", ml: "0.3rem" }}
              />
            </>
          ) : (
            <>
              <Icon icon={icon} fontSize={"1.25rem"} color={Color.black}></Icon>
              <Typography
                sx={{
                  width: "70%",
                  ml: "0.5rem",
                  mr: "1.5rem",
                  flexShrink: 0,
                  flexGrow: 0,
                  fontWeight: 500,
                  fontSize: "0.8rem",
                  fontFamily: Fonts.Text,
                  display: "inline-flex",
                }}
              >
                {t(title)}
              </Typography>
            </>
          )}
        </Box>
        {loader ||
        !isAllHeaderApiFetched ||
        !title ||
        loading ||
        testLoading ? (
          <>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={"1.1rem"}
              sx={{ my: "1rem" }}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              my: "1rem",
            }}
          >
            <Typography
              sx={{
                maxWidth: "7.625rem",
                display: "block",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                overflow: "hidden",
                alignItems: "center",
                fontSize: "1.2rem",
                fontWeight: 800,
                fontFamily: Fonts.Text,
                color: Color.deepPurple300,
                textAlign: "left",
                mr: "0.5rem",
              }}
            >
              {formatValue({
                value: String(
                  parseFloat(item?.currentMonthTotal ?? 0).toFixed(2)
                ),
                groupSeparator: currencyFormate.groupSeparator,
                decimalSeparator: currencyFormate.decimalSeparator,
                prefix: currencyFormate.prefix,
              })}
            </Typography>
            <Typography
              sx={{
                width: "fit-content",
                display: "inline-flex",
                alignItems: "center",
                fontSize: "01rem",
                fontWeight: 500,
                fontFamily: Fonts.Text,
                color: Color.greyText,
                textAlign: "right",
                alignSelf: "end",
                pb: "0.1rem",
              }}
            >
              {t("this month")}
            </Typography>
          </Box>
        )}
        {loader ||
        !isAllHeaderApiFetched ||
        !title ||
        loading ||
        testLoading ? (
          <>
            <Skeleton
              animation="wave"
              variant="text"
              width={"100%"}
              height={"0.9rem"}
              sx={{ my: "0.5rem" }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width={"100%"}
              height={"0.9rem"}
            />
          </>
        ) : (
          <>
            <WidgetInnerView
              value={item?.prePeriodMonthTotal}
              date={item?.prePeriodCurrentDate}
              percentValue={`${item?.change}%`}
              high={item?.high1}
            />
            <WidgetInnerView
              value={item?.preMonthTotal}
              percentValue={`${item?.changeMonth}%`}
              high={item?.high2}
            />
          </>
        )}
      </Paper>
    );
  }
);
export default OverviewWidget;
