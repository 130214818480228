import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
} from "recharts";
import { alpha, Paper, Typography } from "@mui/material";
import { formatValue } from "react-currency-input-field";
import { useSelector } from "react-redux";
import React from "react";

import CustomizedAxisTick from "./CustomizedAxisTick";
import { Color, Fonts } from "../../Helper";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";

const TooltipItemView = ({ title, value, color }) => {
  return (
    <Typography
      sx={{
        display: "inline-flex",
        fontSize: "0.7rem",
        alignItems: "center",
        fontWeight: 400,
      }}
    >
      <span
        style={{
          width: "0.5rem",
          height: "0.5rem",
          borderRadius: 5,
          backgroundColor: color,
          marginRight: "0.5rem",
        }}
      />
      {title}:<b style={{ position: "absolute", right: "0.5rem" }}>{value}</b>
    </Typography>
  );
};

const CustomTooltipScatter = (props) => {
  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );
  let { active, payload, type, title } = props;
  if (active && payload && payload.length) {
    let due_date_12M = payload[0]?.payload?.due_date_12M;
    let due_date_prev = payload[0]?.payload?.due_date_prev;
    let current = payload[0]?.payload[`${type}_12M`];
    let previous = payload[0]?.payload[`${type}_prev`];
    return (
      <Paper
        sx={{
          width: "14.375rem",
          height: "fix-content",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          p: "0.5rem",
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
        }}
      >
        <Typography
          sx={{
            display: "inline-flex",
            fontSize: "0.9rem",
            fontFamily: Fonts.Text,
            fontWeight: 600,
            textOverflow: "ellipsis",
            whiteSpace: "pre",
            overflow: "hidden",
          }}
        >
          {title}
        </Typography>
        <TooltipItemView
          title={due_date_prev}
          color={alpha(Color.themeColor2, 0.3)}
          value={formatValue({
            value: String(parseFloat(previous ?? 0).toFixed(2)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
        <TooltipItemView
          title={due_date_12M}
          color={Color.themeColor2}
          value={formatValue({
            value: String(parseFloat(current ?? 0).toFixed(2)),
            groupSeparator: currencyFormate.groupSeparator,
            decimalSeparator: currencyFormate.decimalSeparator,
            prefix: currencyFormate.prefix,
          })}
        />
      </Paper>
    );
  }

  return null;
};

const DashBoardSucessReportLineChart = React.memo(
  ({ data, type = "Revenue", title = "Revenue" }) => {
    const gradientOffset = (key = "") => {
      const dataMax = Math.max(...data?.map((i) => i[key]));
      const dataMin = Math.min(...data?.map((i) => i[key]));
      if (dataMin > 0 || dataMax < 0) {
        return 0;
      } else if (dataMin === 0 || dataMax === 0) {
        return 1;
      } else {
        let number = dataMax / (dataMax - dataMin);
        if (!isNaN(number)) {
          return number;
        }
      }
    };

    const isPositive = (key = "") => {
      const dataMax = Math.max(...data?.map((i) => i[key]));
      const dataMin = Math.min(...data?.map((i) => i[key]));
      if (dataMin === 0) {
        return true;
      }
      if (dataMax === 0) {
        return false;
      }
    };

    const currentWidth = useWidth();
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: remToPx(currentWidth, 0.3125),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="due_date_12M"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                formatted={false}
              />
            }
            type="category"
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.25)}
                dx={-remToPx(currentWidth, 1.625)}
                formatted
                type={type}
              />
            }
            type="number"
          />
          <Tooltip
            cursor={{ fill: "transparent", strokeDasharray: "3 3" }}
            content={<CustomTooltipScatter type={type} title={title} />}
          />
          <defs>
            <linearGradient
              id={`${type}_12M`}
              x1="0"
              y1={isPositive(`${type}_12M`) ? "0" : "0.99"}
              x2="0"
              y2={isPositive(`${type}_12M`) ? "0.99" : "0.03"}
            >
              <stop
                // offset="0"
                offset={gradientOffset(`${type}_12M`)}
                stopColor={alpha(Color.themeColor2, 0.9)}
                stopOpacity={1}
              />
              <stop
                // offset="1"
                offset={gradientOffset(`${type}_12M`)}
                stopColor={alpha(Color.themeColor2, 0.2)}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient
              id={`${type}_prev`}
              x1="0"
              y1={isPositive(`${type}_prev`) ? "0" : "0.99"}
              x2="0"
              y2={isPositive(`${type}_prev`) ? "0.99" : "0.03"}
            >
              <stop
                offset={gradientOffset(`${type}_prev`)}
                stopColor={Color.theme.blueGrey[600]}
                stopOpacity={1}
              />
              <stop
                offset={gradientOffset(`${type}_prev`)}
                stopColor={Color.theme.blueGrey[100]}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>

          <Line
            dataKey={`${type}_12M`}
            // stroke={alpha(Color.themeColor2, 0.75)}
            strokeWidth={3}
            dot={false}
            stroke={`url(#${type}_12M)`}
          />
          <Line
            dataKey={`${type}_prev`}
            // stroke={Color.theme.blueGrey[400]}
            stroke={`url(#${type}_prev)`}
            strokeDasharray="5 5"
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return prev.data === next.data && prev.height === next.height;
  }
);
export default DashBoardSucessReportLineChart;
