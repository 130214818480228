import {
  ClickAwayListener,
  Typography,
  Collapse,
  useTheme,
  Tooltip,
  Button,
  Stack,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import {
  PiMathOperationsBold,
  PiUsersThreeBold,
  PiHandshakeFill,
} from "react-icons/pi";
import { format, addYears, endOfYear, startOfYear, subYears } from "date-fns";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsCalendar2Event, BsCalendar2Week } from "react-icons/bs";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { HiArrowPathRoundedSquare } from "react-icons/hi2";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CategoryIcon from "@mui/icons-material/Category";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { TbBuildingBank } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GrMoney } from "react-icons/gr";
import { useSnackbar } from "notistack";

import {
  CurrencyPrefixIcon,
  getFormattedDate,
  getStaffQueryKey,
  cloneDeep,
  isExpire,
} from "../../Helper/data";
import {
  setTransactionsOverlayStatus,
  setPopupStatus3,
  setPopupStatus4,
} from "../../store/slices/datasets";
import {
  CostAddOverlay,
  CostTotalView,
  RadioInput,
} from "./Component/StaffCostsView";
import LoanSpecialPaymentsView from "./Component/LoanSpecialPaymentsView";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import PauseDurationsView from "./Component/PauseDurationsView";
import VariableRatesView from "./Component/VariableRatesView";
import ThemeToggle from "../ToggleGroupeView/ThemeToggle";
import { calculateEndDate } from "../../Helper/functions";
import DateInputPicker from "../Overlay/DateInputPicker";
import { Color, Constant, Fonts } from "../../Helper";
import getExpectedList from "./Component/functions";
import EndPoints from "../../APICall/EndPoints";
import TitleInput from "../Overlay/TitleInput";
import AmountView from "../Overlay/AmountView";
import initialData from "./../../Helper/data";
import StaffInputView from "./StaffInputView";
import CategoryChip from "../CategoryChip";
import DropDown from "../Overlay/DropDown";
import MenuView from "../Overlay/MenuView";
import ToggleButton from "./ToggleButton";
import Preview from "./Component/Preview";
import FormulrForm from "./FormulrForm";
import { queryClient } from "../../App";
import CustomPopover from "../PopOver";
import APICall from "../../APICall";
import store from "../../store";
import Icon from "../Icon";

const menuOptions = [
  {
    value: 1,
    label: "Edit name",
    icon: <ModeEditIcon />,
  },

  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];

const tabs = initialData?.recurringTypeTabs;
const RecurringFormView = ({
  PausedDuration,
  PausedDurationItemList,
  VariableRatesItemList,
  SpecialPaymentsItemList,
  tab,
  setTab,
  cardItem,
  setError,
  error,
  isRecurrenceUpdated,
  setIsRecurrenceUpdated,
  showError,
  disabled,
  recurring_Obj,
  setRecurring_Obj,
  setCardItem,
  setShowError,
  updateRecurrenceFlag,
  updateFlag,
  oldRecurId,
  oldTransactionId,
  isLoanSpecialPayments,
  updatedObj,
}) => {
  // console.log("🚀 / cardItem:", cardItem)
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const changedTab = useRef(null);
  const itemId = useRef(null);
  const advanceSeqData = useRef({});
  const recurDateRef = useRef(null);
  const pickerRef = useRef(null);
  const totalPreviewCount = useRef(0);
  const hoverInput = useRef(null);
  const formData = useRef({ type: "", defaultData: {}, formType: "Add" });
  const menuItem = useRef({});

  //redux
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const vat = useSelector((state) => state.globalSlice.vat);

  const [option, setOption] = useState({
    average: [],
    period: [],
    repetition: [],
    installments: [],
    source: [],
    loan_calculation_method: [],
  });

  const [anchorEl, setAnchorEl] = useState(false);
  const [expectedList, setExpectedList] = useState([]);
  const [isExpandedDate, setIsExpandedDate] = useState(false);
  const [isOpenFormuler, setIsOpenFormuler] = useState(false);
  const [showTaxDropDown, setShowTaxDropDown] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const isLoan = tab === "loan";
  const isLeasing = tab === "leasing";

  const transactionsItem = transactionsOverlayStatus?.payload?.item;
  const modalType = transactionsOverlayStatus?.payload?.modalType;
  const transaction_type = transactionsOverlayStatus?.payload?.transaction_type;
  const hideEditFormTabBar =
    transactionsOverlayStatus?.payload?.hideEditFormTabBar ||
    isLoanSpecialPayments ||
    (modalType === "edit" ? isLoan || isLeasing : false);
  const isStaffView = transactionsOverlayStatus?.payload?.isStaffView;
  //api
  const getOptions = async () => {
    await APICall("OPTIONS", EndPoints.recurring_rules).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.actions.POST;
        setOption({
          average: data["average"]?.choices?.map((o1) => {
            return { value: o1.value, display_name: t(o1.display_name) };
          }),
          period: data["period"]?.choices?.map((o1) => {
            return { value: o1.value, display_name: t(o1.display_name) };
          }),
          repetition: data["repetition"]?.choices?.map((o1) => {
            return { value: o1.value, display_name: t(o1.display_name) };
          }),
          source: data["source"]?.choices?.map((o1) => {
            return { value: o1.value, display_name: t(o1.display_name) };
          }),
          installments: [
            {
              value: 1,
              display_name: t("Monthly"),
            },
            {
              value: 3,
              display_name: t("Quarterly"),
            },
          ],
          loan_calculation_method: data[
            "loan_calculation_method"
          ]?.choices?.map((o1) => {
            return { value: o1.value, display_name: t(o1.display_name) };
          }),
        });
      }
    });
  };

  const deleteItemToValueSet = async (
    recurring_uuid,
    value_set_uuid,
    itemUuid,
    type
  ) => {
    let data = null;
    await APICall(
      "delete",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/${itemUuid}`,
      3
    ).then((response) => {
      if (response.status === 204) {
        removeItem(type, itemUuid);
      }
    });

    return data;
  };

  const removeItem = (type, itemUuid) => {
    updateRecurrenceFlag();
    const ongoing_cost = recurring_Obj?.value_sets?.[0];
    const one_off_cost = recurring_Obj?.value_sets?.[1];
    if (type === "ongoing_cost") {
      let items = (recurring_Obj?.value_sets?.[0]?.items || [])?.filter(
        (o1) => o1.uuid !== itemUuid
      );

      setRecurring_Obj({
        ...recurring_Obj,
        value_sets: [{ ...ongoing_cost, items }, one_off_cost],
      });
    } else {
      let items = (recurring_Obj?.value_sets?.[1]?.items || [])?.filter(
        (o1) => o1.uuid !== itemUuid
      );
      setRecurring_Obj({
        ...recurring_Obj,
        value_sets: [ongoing_cost, { ...one_off_cost, items }],
      });
    }
  };

  const batchAddValueSets = async (recurring_uuid, value_sets) => {
    let newValueSet = value_sets;
    await APICall(
      "post",
      EndPoints.recurring_rules + `${recurring_uuid}/value_sets/batch_add/`,
      value_sets
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        newValueSet = response.data;
      }
    });
    return newValueSet;
  };

  //functions
  const onOkWarnSingle = () => {
    oldRecurId.current = cardItem.recurring_rule;
    setTab("single");
    const obj = {
      contact: null,
      recurring_rule: null,
    };
    setCardItem({
      ...cardItem,
      ...obj,
    });
    setShowError(false);
    setIsRecurrenceUpdated(false);
    onCloseWarn();
  };

  const onOkWarn = async () => {
    if (tab === "single" && changedTab.current !== "single") {
      oldTransactionId.current = cardItem?.uuid;
    }
    if (changedTab.current !== "single" && oldRecurId.current) {
      let obj = {
        recurring_rule: oldRecurId.current,
        contact: null,
      };
      let cardObj = { ...cardItem, ...obj };
      if (changedTab.current === "employee") {
        if (!Constant.staffState?.includes(cardItem?.state)) {
          cardObj.state = "planned";
        }
        // obj.tax = null;
      }
      setCardItem(cardObj);
      oldRecurId.current = null;
    }

    let recurObj = {
      ...recurring_Obj,
      category: cardItem?.category,
      recurring_type: changedTab.current,
      contact: null,
    };

    if (changedTab.current === "employee") {
      // recurObj.tax = null;
      recurObj.name = "";
      recurObj.salary_type = 1;
    }
    if (
      changedTab.current === "employee" &&
      (!recurObj?.value_sets || recurObj?.value_sets?.length === 0)
    ) {
      const newValueSet = await batchAddValueSets(recurObj?.uuid, [
        {
          base_value: 0,
          base_value_name: "ongoing_cost",
          title: "ongoing_cost",
          items: [],
        },
        {
          base_value: 0,
          base_value_name: "one_off_cost",
          title: "one_off_cost",
          items: [],
        },
      ]);
      if (newValueSet) {
        recurObj.value_sets = newValueSet;
        setRecurring_Obj(recurObj);
      }
    } else {
      setRecurring_Obj(recurObj);
    }
    setTab(changedTab.current);
    updateRecurrenceFlag();
    onCloseWarn();
  };

  const onCloseWarn = () => {
    dispatch(setPopupStatus3(null));
  };

  const handleChange = (e, value) => {
    if (modalType === "edit") {
      if (value === "single") {
        changedTab.current = value;
        dispatch(
          setPopupStatus3({
            id: "simple-popper",
            overlay_type: "warn_overlay",
            open: true,
            onConfirm: onOkWarnSingle,
            onClose: onCloseWarn,
            payload: {
              title: t("Attention"),
              message: t("recur_tab_single_change_warning_msg"),
              confirmText: t("Ok"),
            },
          })
        );
      } else {
        changedTab.current = value;
        dispatch(
          setPopupStatus3({
            id: "simple-popper",
            overlay_type: "warn_overlay",
            open: true,
            onConfirm: onOkWarn,
            onClose: onCloseWarn,
            payload: {
              title: t("Attention"),
              message: t("recur_tab_change_warning_msg"),
              confirmText: t("Ok"),
            },
          })
        );
        if (tab === "single" && value !== "single") {
          let recurObj = {
            ...recurring_Obj,
            value: cardItem?.gross_value,
            tax: cardItem?.tax,
            recurring_type: value,
            category: cardItem?.category,
            contact: null,
          };

          setRecurring_Obj(recurObj);
        }
      }
    } else {
      setTab(value);

      if (value === "single") {
        let cardObj = {
          ...cardItem,
          gross_value: recurring_Obj.value,
          tax: recurring_Obj.tax,
          recurring_rule: null,
          contact: null,
        };

        setShowError(false);
        setCardItem(cardObj);
      } else {
        updateRecurrenceFlag();
        let cardObj = { ...cardItem, contact: null };
        let recurObj = {
          ...recurring_Obj,
          value: cardItem?.gross_value,
          category: cardItem?.category,
          tax: cardItem.tax,
          recurring_type: value,
          contact: null,
        };

        if (value === "employee") {
          if (!Constant.staffState?.includes(cardItem?.state)) {
            cardObj.state = "planned";
          }
          // cardObj.tax = null;
          // recurObj.tax = null;
          recurObj.employee_type = null;
          recurObj.salary_type = 1;
          recurObj.name = "";
        }
        if (value === "loan") {
          recurObj.employee_type = null;
        }
        setRecurring_Obj(recurObj);
        setCardItem(cardObj);
      }
      if (value === "single" || value === "simple" || value === "employee") {
        focusAmount(value);
      }
    }
  };

  const onChangeDropDown = (e, value, name) => {
    let obj = { [name]: value?.value || null };
    if (name === "period") {
      const start_date = recurring_Obj?.start_date
        ? recurring_Obj?.start_date
        : cardItem?.due_date;

      const end_date = calculateEndDate({
        months: value?.value,
        start_date,
      });
      obj.start_date = start_date;
      obj.end_date = end_date;
    }
    updateRecurrenceFlag();
    setRecurring_Obj((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onChangeRangeDate = (value, name, locale) => {
    if (value?.toString() !== "Invalid Date") {
      const obj = {
        period: -2,
        [name]: format(new Date(value), "yyyy-MM-dd"),
      };
      updateRecurrenceFlag();
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onBlurRangeDate = (e, name, locale) => {
    const _date = e.target.value;
    const formateString = locale === "de_DE" ? "dd.MM.yyyy" : "dd-MM-yyyy";
    let obj = { period: -2 };
    let date = getFormattedDate(_date, formateString);
    if (date) {
      const year = new Date(date).getFullYear();
      if (year < 1950) {
        date = null;
      }
    }

    if (name === "start_date") {
      obj[name] = date || format(new Date(), "yyyy-MM-dd");
      obj.end_date =
        recurring_Obj.end_date &&
        date &&
        new Date(recurring_Obj.end_date) <= new Date(date)
          ? null
          : recurring_Obj.end_date;
    } else {
      obj[name] = date || null;
    }
    updateRecurrenceFlag();
    setRecurring_Obj((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onChangeDate = (value, name, locale) => {
    if (value?.toString() !== "Invalid Date") {
      const date = value ? format(new Date(value), "yyyy-MM-dd") : "";
      setError({ ...error, due_date: "" });
      let obj = {
        [name]: date || null,
      };
      if (name === "due_date") {
        obj.invoice_date = null;
      }
      if (name === "due_date" && !date) {
        setError({ ...error, due_date: "due date can not be empty" });
        return;
      }

      updateFlag(obj);
    }
  };

  const onBlurDate = (e, name, locale) => {
    let _date = e.target.value;
    const formateString = locale === "de_DE" ? "dd.MM.yyyy" : "dd-MM-yyyy";
    let date = getFormattedDate(_date, formateString);
    if (date) {
      const year = new Date(date).getFullYear();
      if (year < 1950) {
        date = null;
      }
    }
    setError({ ...error, due_date: "" });
    let obj = {
      [name]: date || null,
    };
    if (name === "due_date") {
      obj.invoice_date = null;
    }
    if (name === "due_date" && !date) {
      setError({ ...error, due_date: "due date can not be empty" });
      return;
    }
    updateFlag(obj);
  };

  const handleRadioChange = (event, type) => {
    updateRecurrenceFlag();
    const ongoing_cost = recurring_Obj?.value_sets?.[0];
    const one_off_cost = recurring_Obj?.value_sets?.[1];
    if (type === "ongoing_cost") {
      let items = cloneDeep(recurring_Obj?.value_sets?.[0]?.items || []);
      const index = items?.findIndex(
        (item) => item?.title === event.target.name
      );
      if (index > -1) {
        items[index] = {
          ...items?.[index],
          type: event.target.value,
        };

        setRecurring_Obj({
          ...recurring_Obj,
          value_sets: [
            { ...ongoing_cost, items: cloneDeep(items) },
            one_off_cost,
          ],
        });
      }
    } else {
      let items = cloneDeep(recurring_Obj?.value_sets?.[1]?.items || []);
      const index = items?.findIndex(
        (item) => item?.title === event.target.name
      );
      if (index > -1) {
        items[index] = {
          ...items?.[index],
          type: event.target.value,
        };

        setRecurring_Obj({
          ...recurring_Obj,
          value_sets: [
            ongoing_cost,
            { ...one_off_cost, items: cloneDeep(items) },
          ],
        });
      }
    }
  };

  const onChangeValue = (e, type) => {
    updateRecurrenceFlag();
    const { name, value } = e.target;
    let obj = {};
    if (type === "ongoing_cost") {
      const ongoing_cost = recurring_Obj?.value_sets?.[0];
      const one_off_cost = recurring_Obj?.value_sets?.[1];
      let items = cloneDeep(recurring_Obj?.value_sets?.[0]?.items || []);
      const index = items?.findIndex((item) => item?.title === name);
      if (index > -1) {
        items[index] = {
          ...items?.[index],
          value: value,
        };
        obj.value_sets = [{ ...ongoing_cost, items }, one_off_cost];
      }
    } else if (type === "one_off_cost") {
      const ongoing_cost = recurring_Obj?.value_sets?.[0];
      const one_off_cost = recurring_Obj?.value_sets?.[1];
      let items = cloneDeep(recurring_Obj?.value_sets?.[1]?.items || []);
      const index = items?.findIndex((item) => item?.title === name);
      if (index > -1) {
        items[index] = {
          ...items?.[index],
          value: value,
        };
        obj.value_sets = [ongoing_cost, { ...one_off_cost, items }];
      }
    } else {
      obj[name] = value;
    }
    setRecurring_Obj((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onBlurAmount = (originalValue, name, type) => {
    updateRecurrenceFlag();
    if (
      (originalValue?.trim("") === "" ||
        originalValue?.trim("") === "-" ||
        Number(originalValue) === 0) &&
      transaction_type === "expense" &&
      modalType === "edit"
    ) {
      enqueueSnackbar(t("expense_amount_zero_value_warn"), {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return;
    }
    if (transaction_type === "income" || isLoan || isLeasing) {
      originalValue = Math.abs(originalValue);
    } else {
      originalValue = -Math.abs(originalValue);
    }
    let obj = {
      value: String(originalValue),
    };

    if (obj.value && obj.value?.trim() !== "-" && obj.value?.trim() !== "") {
      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onBlurValue = (originalValue, name, type) => {
    updateRecurrenceFlag();
    if (
      (originalValue?.trim("") === "" ||
        originalValue?.trim("") === "-" ||
        Number(originalValue) === 0) &&
      transaction_type === "expense" &&
      modalType === "edit"
    ) {
      enqueueSnackbar(t("expense_amount_zero_value_warn"), {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return;
    }
    if (transaction_type === "income" || isLoan) {
      originalValue = Math.abs(originalValue);
    } else {
      originalValue = -Math.abs(originalValue);
    }
    const obj = {
      value: String(originalValue),
    };

    if (obj.value && obj.value?.trim() !== "-" && obj.value?.trim() !== "") {
      const ongoing_cost = recurring_Obj?.value_sets?.[0];
      const one_off_cost = recurring_Obj?.value_sets?.[1];
      if (type === "ongoing_cost") {
        let items = cloneDeep(recurring_Obj?.value_sets?.[0]?.items || []);
        const index = items?.findIndex((item) => item?.title === name);
        if (index > -1) {
          items[index] = {
            ...items?.[index],
            ...obj,
          };

          setRecurring_Obj({
            ...recurring_Obj,
            value_sets: [{ ...ongoing_cost, items }, one_off_cost],
          });
        }
      } else if (type === "one_off_cost") {
        let items = cloneDeep(recurring_Obj?.value_sets?.[1]?.items || []);
        const index = items?.findIndex((item) => item?.title === name);
        if (index > -1) {
          items[index] = {
            ...items?.[index],
            ...obj,
          };

          setRecurring_Obj({
            ...recurring_Obj,
            value_sets: [ongoing_cost, { ...one_off_cost, items }],
          });
        }
      } else {
        setRecurring_Obj({
          ...recurring_Obj,
          ...obj,
        });
      }
    }
  };

  const onBlurTimeToPay = (e) => {
    const { name, value } = e.target;
    const obj = {
      [name]: Number(value || 0),
    };
    updateFlag(obj);
  };

  const onChangeTimeToPay = (e) => {
    const { name, value } = e.target;
    setCardItem({ ...cardItem, [name]: Number(value || 0) });
  };

  const onClickCategoryRecurTitle = (e, item) => {
    updateRecurrenceFlag();
    setRecurring_Obj({
      ...recurring_Obj,
      category: item?.uuid,
    });
    dispatch(setPopupStatus4(null));
  };

  const onClickAdvanceCategory = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          type: tab === "employee" ? 2 : 3,
          allowParentCategory: true,
          onClickCategoryTitle: onClickCategoryRecurTitle,
        },
      })
    );
  };

  const onDeleteRecurringCategory = () => {
    updateRecurrenceFlag();
    setRecurring_Obj({
      ...recurring_Obj,
      category: null,
    });
  };

  const onClickTax = (e) => {
    setShowTaxDropDown(true);
    pickerRef?.current?.focusDropDown();
  };

  const onChangeTax = (e, data) => {
    let obj = {
      tax: data?.uuid ?? null,
      tax_value: null,
      net_value: null,
    };

    setShowTaxDropDown(false);
    updateFlag(obj);
  };

  const onClickManageLinks = (e) => {
    dispatch(setTransactionsOverlayStatus(null));
    navigate("/settings/vats");
  };

  const onClickAway = (e) => {
    setShowTaxDropDown(false);
  };

  const onClick = (e) => {
    e.stopPropagation();
  };

  const updateCardAmount = (originalValue) => {
    let value = parseFloat(originalValue)?.toFixed(2);
    if (
      modalType === "edit" &&
      (value?.trim("") === "" ||
        value?.trim("") === "-" ||
        Number(value) === 0) &&
      transaction_type === "expense" &&
      modalType === "edit"
    ) {
      enqueueSnackbar(t("expense_amount_zero_value_warn"), {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return;
    }

    if (transaction_type === "income") {
      value = Math.abs(value);
    } else {
      value = -Math.abs(value);
    }
    const obj = {
      gross_value: String(value),
    };
    updateFlag(obj);
  };

  const onClickFormular = () => {
    setIsOpenFormuler(true);
  };

  const onSave = (value) => {
    if (transaction_type === "income") {
      updateCardAmount(value);
    }
    if (transaction_type === "expense") {
      value = "-" + value;
      updateCardAmount(value);
    }
    setIsOpenFormuler(false);
  };

  const handleClose = () => {
    setIsOpenFormuler(false);
  };

  const handleClickBtn = () => {
    setIsExpandedDate((prev) => !prev);
  };

  const focusAmount = (value) => {
    if (modalType !== "edit") {
      clearTimeout(hoverInput.current);
      hoverInput.current = setTimeout(() => {
        const element = document.getElementById(
          value === "employee" ? "employee-input" : "currency-input"
        );
        element?.focus();
      }, 1000);
    }
  };

  const onClickDeleteCost = async (type, valueSetId, uuid) => {
    if (modalType === "edit") {
      await deleteItemToValueSet(recurring_Obj?.uuid, valueSetId, uuid, type);
    } else {
      removeItem(type, uuid);
    }
  };

  const onClickSalaryType = (e, value) => {
    updateRecurrenceFlag();
    let obj = {
      ...recurring_Obj,
      salary_type: value,
    };

    if (!value) {
      obj.value = 0;
    }
    setRecurring_Obj(obj);
  };

  const onClickOnGoingCost = (e) => {
    formData.current.type = "ongoing_cost";
    formData.current.formType = "Add";
    formData.current.defaultData = {};
    setAnchorEl(e.currentTarget);
  };

  const onClickOneOffCost = (e) => {
    formData.current.type = "one_off_cost";
    formData.current.formType = "Add";
    formData.current.defaultData = {};
    setAnchorEl(e.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickMenuItem = async (e, _value, item) => {
    const { uuid, type, value, valueSetId, cost_type, label, setLoading } =
      menuItem.current;

    if (_value === 1) {
      formData.current.formType = "Edit";
      formData.current.type = type;
      formData.current.defaultData = {
        cost_type,
        label,
        uuid,
        handleCloseMenu,
        value,
      };
      setAnchorEl(e.currentTarget);
    }
    if (_value === 2) {
      handleCloseMenu();
      setLoading(uuid);
      onClickDeleteCost(type, valueSetId, uuid);
    }
  };

  const onClickMenu = ({
    e,
    setLoading,
    type,
    valueSetId,
    cost_type,
    label,
    value,
    uuid,
  }) => {
    menuItem.current = {
      setLoading,
      type,
      valueSetId,
      cost_type,
      label,
      value,
      uuid,
    };
    setAnchorElMenu(e.currentTarget);
  };

  const renderOptions = useCallback(
    (option) => t(option?.display_name) ?? "",
    [t]
  );

  //lifeCycle method
  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (transactionsOverlayStatus?.from_type === "add_smart_card") {
      setTab("simple");
    }
    if (transactionsOverlayStatus?.payload?.recurringItem?.recurring_type) {
      let recurringItem = {
        ...transactionsOverlayStatus?.payload?.recurringItem,
      };
      if (recurringItem?.recurring_type === "employee") {
        const StaffList = queryClient.getQueryData(getStaffQueryKey());
        const contact = StaffList?.find(
          (o) => o?.uuid === recurringItem?.contact
        );
        if (contact) {
          recurringItem.name = contact?.name;
        }
      }
      setRecurring_Obj(recurringItem);
      setTab(recurringItem?.recurring_type);
      focusAmount(recurringItem?.recurring_type);
    }
  }, [transactionsOverlayStatus]);

  useEffect(() => {
    if (transactionsItem) {
      itemId.current = transactionsItem;
      // setCardItem({ ...transactionsItem });
      if (modalType === "edit" && !transactionsItem?.recurring_rule) {
        setTab("single");
      }
    }
  }, [modalType, transactionsItem]);

  useDebounce(
    () => {
      if (
        recurring_Obj?.recurring_type !== "single" &&
        !recurDateRef.current?.isDateOverlayOpen()
      ) {
        getExpectedList({
          recurring_Obj,
          cardItem,
          setExpectedList,
          transaction_type,
          advanceSeqData,
          totalPreviewCount,
          PausedDuration: isLoan ? PausedDuration.current : null,
          VariableRatesItemList: VariableRatesItemList?.current,
        });
      }
    },
    500,
    [recurring_Obj, isRecurrenceUpdated, isLoan],
    true
  );

  //render functions
  const amountView = () => (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        pr: "1.5rem",
        mt: "1rem",
        ml: "2.4rem",
        width: "100%",
      }}
    >
      <Box sx={{ width: "48%", position: "relative" }}>
        <Icon
          icon={CurrencyPrefixIcon[dataSetData?.currency]}
          size="1.2rem"
          style={{
            height: "fit-content",
            position: "absolute",
            left: "-2.2rem",
            top: "0.2rem",
          }}
          color={Color.theme.blueGrey[700]}
        />
        <AmountView
          disabled={disabled || cardItem?.recurring_rule}
          likeGoogle
          defaultValue={cardItem?.gross_value}
          onBlur={updateCardAmount}
          label={t("label_amount")}
          tooltip={t("tooltip_amount")}
          variant="filled"
          currency={dataSetData?.currency}
          fontSize="0.9rem"
          mb={"0.5rem"}
        />
        {!disabled && !cardItem?.recurring_rule && (
          <Typography
            onClick={onClickFormular}
            variant="h6"
            component={"h6"}
            fontSize={"0.875rem"}
            sx={{
              fontWeight: 400,
              fontFamily: Fonts.Text,
              fontSize: "0.65rem",
              position: "absolute",
              bottom: -10,
              right: 2,
              color: theme.palette.primary.main,
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {" "}
            {t("Enter Formula")}
          </Typography>
        )}
      </Box>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box
          sx={{
            display: "flex",
            width: "48%",
            ml: "1.5rem",
            flexDirection: "column",
          }}
        >
          {cardItem?.source === 4 && !cardItem?.tax && !showTaxDropDown ? (
            <Box
              onClick={onClickTax}
              sx={{
                p: "0.5rem",
                mb: "0.5rem",
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: Color.inputBG,
                },
              }}
            >
              <Typography
                fontSize="0.9rem"
                fontWeight={"fontWeightMediumBold"}
                color="color.description"
              >
                {t("Included tax")}
              </Typography>
              <Typography fontSize="0.9rem">
                {(
                  ((Number(cardItem?.gross_value || 0) -
                    Number(cardItem?.calculated_net_value || 0)) /
                    Number(cardItem?.calculated_net_value || 0)) *
                  100
                ).toFixed(1)}{" "}
                %
              </Typography>
            </Box>
          ) : (
            <Stack sx={{ width: "100%", position: "relative" }}>
              <DropDown
                onClick={onClick}
                ref={pickerRef}
                disablePortal
                hideTitle
                name="tax"
                defaultValue="Tax"
                disabled={cardItem?.recurring_rule}
                value={vat?.find((o1) => o1.uuid === cardItem?.tax) || null}
                onChange={(e, value) => onChangeTax(e, value)}
                options={vat}
                renderOption={(option) =>
                  `${option?.title} (${option?.value}%)` ?? ""
                }
                getOptionLabel={(option) =>
                  `${option?.title} (${option?.value}%)` ?? ""
                }
                label={t("Included tax")}
                tooltip={t("Included tax")}
                likeGoogle
                variant="filled"
                showManage
                manageText={"Manage Tax"}
                fontSize="0.9rem"
                onClickLink={onClickManageLinks}
              />
              <CategoryTaxMsg />
            </Stack>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );

  const dateView = () => {
    return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
            marginLeft: "1.4rem",
            marginTop: "1.5rem",
          }}
        >
          <Icon
            icon={<BsCalendar2Event />}
            fontSize="1.1rem"
            style={{
              height: "fit-content",
              position: "absolute",
              left: "-1rem",
              top: "1.4rem",
            }}
            color={Color.theme.blueGrey[700]}
          />
          <Collapse
            orientation="horizontal"
            in={isExpandedDate}
            collapsedSize={"15rem"}
            sx={{
              width: isExpandedDate ? "100% !important" : "0",
              "& .MuiCollapse-wrapperInner": {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "1rem",
                width: "100%",
              }}
            >
              <Box sx={{ width: isExpandedDate ? "30%" : "100%", ml: "1rem" }}>
                <DateInputPicker
                  name="due_date"
                  id="date"
                  showFullDate
                  label={t("Due Date")}
                  tooltip={t("Due Date")}
                  disabled={disabled || cardItem?.recurring_rule}
                  error={Boolean(error?.due_date)}
                  helperText={error?.due_date}
                  value={
                    cardItem?.due_date ? new Date(cardItem?.due_date) : null
                  }
                  hidePickerBlurEffect
                  onChange={onChangeDate}
                  onBlur={onBlurDate}
                  variant="filled"
                  fontSize="0.9rem"
                  likeGoogle
                  hideTitle
                />
              </Box>
              <Box sx={{ width: isExpandedDate ? "30%" : 0 }}>
                <DateInputPicker
                  name="invoice_date"
                  id="date"
                  showFullDate
                  label={t("Invoice Date")}
                  tooltip={t("Invoice Date")}
                  disabled={disabled || cardItem?.recurring_rule}
                  error={Boolean(error?.invoice_date)}
                  helperText={error?.invoice_date}
                  value={
                    cardItem?.invoice_date
                      ? new Date(cardItem?.invoice_date)
                      : null
                  }
                  hidePickerBlurEffect
                  onChange={onChangeDate}
                  onBlur={onBlurDate}
                  variant="filled"
                  fontSize="0.9rem"
                  likeGoogle
                  hideTitle
                />
              </Box>
              <Box
                sx={{ width: isExpandedDate && tab !== "simple" ? "30%" : 0 }}
              >
                <TitleInput
                  value={cardItem?.time_to_pay || ""}
                  name="time_to_pay"
                  hideTitle
                  type="number"
                  onChange={onChangeTimeToPay}
                  onBlur={onBlurTimeToPay}
                  likeGoogle
                  variant="filled"
                  label={t("time_to_pay")}
                  fontSize="0.9rem"
                  placeholder={t("time_to_pay")}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Collapse>
          {(cardItem?.state !== "Booked") & !isExpandedDate ? (
            <ToggleButton
              isExpanded={isExpandedDate}
              onClick={handleClickBtn}
              style={{ marginLeft: "1rem" }}
              moreText={t("Add Invoice Date")}
              lessText={t("Remove Invoice Date")}
            />
          ) : null}
        </div>
    );
  };

  const simpleView = () => {
    return (
      <TitleInput
        type="number"
        value={recurring_Obj?.growth || ""}
        name="growth"
        onChange={onChangeValue}
        disabled={disabled}
        hideTitle
        likeGoogle
        label={`${t("growth")} (%)`}
        tooltip={t("tooltip_time_growth")}
        variant="filled"
        fontSize="0.9rem"
        sx={{ width: "48%", ml: "1rem" }}
      />
    );
  };

  const advanceView = () => {
    return (
      <Stack sx={{ width: "100%", marginLeft: "0.75rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <DropDown
            star
            disablePortal
            disabled={disabled}
            disableClearable
            name="source"
            value={
              option?.source?.find(
                (o1) => o1.value === recurring_Obj?.source
              ) || null
            }
            onChange={(e, value) => onChangeDropDown(e, value, "source")}
            options={option?.source}
            renderOption={(option) => `${t(option?.display_name)} ` ?? ""}
            getOptionLabel={(option) => `${t(option?.display_name)} ` ?? ""}
            error={showError && !Boolean(recurring_Obj?.source)}
            hideTitle
            likeGoogle
            label={t("label_type")}
            tooltip={
              recurring_Obj?.source === 2
                ? t("tooltip_type_category")
                : recurring_Obj?.source === 3
                  ? t("tooltip_type_revenue")
                  : recurring_Obj?.source === 4
                    ? t("tooltip_type_expense")
                    : ""
            }
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <DropDown
            star
            disablePortal
            disabled={disabled || !recurring_Obj?.source}
            name="average"
            value={
              option?.average?.find(
                (o1) => o1.value === recurring_Obj?.average
              ) || null
            }
            onChange={(e, value) => onChangeDropDown(e, value, "average")}
            options={option?.average}
            renderOption={(option) => `${t(option?.display_name)} ` ?? ""}
            getOptionLabel={(option) => `${t(option?.display_name)} ` ?? ""}
            error={showError && !Boolean(recurring_Obj?.average)}
            hideTitle
            likeGoogle
            label={t("average")}
            tooltip={
              recurring_Obj?.source === 2
                ? t("tooltip_average_category")
                : recurring_Obj?.source === 3
                  ? t("tooltip_average_revenue")
                  : recurring_Obj?.source === 4
                    ? t("tooltip_average_expense")
                    : ""
            }
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <Box sx={{ width: "32%", mb: "1.3rem" }}>
            {recurring_Obj?.source === 2 ? (
              <>
                <Tooltip
                  arrow
                  placement="top"
                  title={t("tooltip_advanced_category")}
                >
                  <Typography
                    variant="h6"
                    component={"h6"}
                    fontSize="0.9rem"
                    sx={{
                      color: Color.black,
                      width: "100%",
                      fontWeight: 700,
                      cursor: "default",
                    }}
                  >
                    {" "}
                    {t("category")}
                  </Typography>
                </Tooltip>

                <CategoryChip
                  width={"10rem"}
                  onClick={onClickAdvanceCategory}
                  onDelete={onDeleteRecurringCategory}
                  showDelete={recurring_Obj?.category}
                  categoryId={recurring_Obj?.category ?? null}
                />
              </>
            ) : null}
          </Box>
        </Stack>

        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            type="number"
            value={recurring_Obj?.multiplier || ""}
            name="multiplier"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_multiplier")}
            tooltip={t("tooltip_multiplier")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.growth || ""}
            name="growth"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={`${t("growth")} (%)`}
            tooltip={t("tooltip_advanced_growth")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
      </Stack>
    );
  };

  const subscriptionView = () => {
    return (
      <Stack sx={{ width: "100%", marginLeft: "0.75rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.users || ""}
            name="users"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.users)}
            hideTitle
            likeGoogle
            label={t("label_pay__users")}
            tooltip={t("tooltip_pay__users")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.average_value || ""}
            name="average_value"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_value)}
            hideTitle
            likeGoogle
            label={t("label_average__user__value")}
            tooltip={t("tooltip_average__user__value")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.new_user_per_month || ""}
            name="new_user_per_month"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.new_user_per_month)}
            hideTitle
            likeGoogle
            label={t("label_new_user_per_month")}
            tooltip={t("tooltip_new_user_per_month")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
        </Stack>

        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            type="number"
            value={recurring_Obj?.users_growth || ""}
            name="users_growth"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_users__growth")}
            tooltip={t("tooltip_users__growth")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.churn || ""}
            name="churn"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_churn__rate")}
            tooltip={t("tooltip_churn__rate")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
      </Stack>
    );
  };

  const shopView = () => {
    return (
      <Stack sx={{ width: "100%", marginLeft: "0.75rem" }}>
        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.average_value || ""}
            name="average_value"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_value)}
            hideTitle
            likeGoogle
            label={t("label_average_shopping_cart")}
            tooltip={t("tooltip_average_shopping_cart")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.users || ""}
            name="users"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.users)}
            hideTitle
            likeGoogle
            label={t("label_pay__users_shop")}
            tooltip={t("tooltip_pay__users_shop")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            type="number"
            value={recurring_Obj?.conversion || ""}
            name="conversion"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_conversion")}
            tooltip={t("tooltip_conversion")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.users_growth || ""}
            name="users_growth"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_users__growth")}
            tooltip={t("tooltip_users__growth")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
      </Stack>
    );
  };

  const clientProductView = () => {
    return (
      <Stack sx={{ width: "100%", marginLeft: "0.75rem" }}>
        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.average_value || ""}
            name="average_value"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_value)}
            hideTitle
            likeGoogle
            label={
              tab === "products"
                ? t("label_product__value")
                : t("label_average__client__value")
            }
            tooltip={
              tab === "products"
                ? t("tooltip_product__value")
                : t("tooltip_average__client__value")
            }
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            star
            type="number"
            value={recurring_Obj?.capacity || ""}
            name="capacity"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.capacity)}
            hideTitle
            likeGoogle
            label={t("capacity")}
            tooltip={
              tab === "products"
                ? t("tooltip_product_capacity")
                : t("tooltip_client_capacity")
            }
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            type="number"
            value={recurring_Obj?.growth || ""}
            name="growth"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={`${t("growth")} (%)`}
            tooltip={
              tab === "products"
                ? t("tooltip_product_growth")
                : t("tooltip_client_growth")
            }
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />

          <TitleInput
            type="number"
            value={recurring_Obj?.payment_default || ""}
            name="payment_default"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_payment_default")}
            tooltip={t("tooltip_payment_default")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
      </Stack>
    );
  };

  const timeView = () => {
    return (
      <Stack sx={{ width: "100%", marginLeft: "0.75rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <TitleInput
            type="number"
            value={recurring_Obj?.average_value || ""}
            name="average_value"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_value)}
            star
            hideTitle
            likeGoogle
            label={t("hourly_rate")}
            tooltip={t("tooltip_hourly_rate")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.workers || ""}
            name="workers"
            onChange={onChangeValue}
            disabled={disabled}
            star
            error={showError && !Boolean(recurring_Obj?.workers)}
            hideTitle
            likeGoogle
            label={t("workers")}
            tooltip={t("tooltip_workers")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.working_hours || ""}
            name="working_hours"
            onChange={onChangeValue}
            disabled={disabled}
            star
            error={showError && !Boolean(recurring_Obj?.working_hours)}
            hideTitle
            likeGoogle
            label={t("working_hours")}
            tooltip={t("tooltip_working_hours")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <TitleInput
            type="number"
            value={recurring_Obj?.holiday || ""}
            name="holiday"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.holiday)}
            star
            hideTitle
            likeGoogle
            label={t("label_holiday")}
            tooltip={t("tooltip_holiday")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.average_vacation_day || ""}
            name="average_vacation_day"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_vacation_day)}
            star
            hideTitle
            likeGoogle
            label={t("label_average_vacation_day")}
            tooltip={t("tooltip_average_vacation_day")}
            variant="filled"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.average_sick_day || ""}
            name="average_sick_day"
            onChange={onChangeValue}
            disabled={disabled}
            error={showError && !Boolean(recurring_Obj?.average_sick_day)}
            star
            hideTitle
            likeGoogle
            label={t("label_average_sick_day")}
            tooltip={t("tooltip_average_sick_day")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
        </Stack>

        <Stack direction="row" alignItems={"center"} sx={{ width: "100%" }}>
          <TitleInput
            type="number"
            value={recurring_Obj?.growth || ""}
            name="growth"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={`${t("growth")} (%)`}
            tooltip={t("tooltip_time_growth")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%" }}
          />
          <TitleInput
            type="number"
            value={recurring_Obj?.payment_default || ""}
            name="payment_default"
            onChange={onChangeValue}
            disabled={disabled}
            hideTitle
            likeGoogle
            label={t("label_payment_default")}
            tooltip={t("tooltip_payment_default")}
            variant="filled"
            fontSize="0.9rem"
            sx={{ width: "32%", ml: "0.85rem" }}
          />
        </Stack>
      </Stack>
    );
  };

  const staffView = () => {
    return (
      <Stack sx={{ width: "100%" }}>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Icon
            icon={<GrMoney />}
            color={Color.theme.blueGrey[700]}
            fontSize={"1.4rem"}
            style={{
              position: "absolute",
              left: "-1.5rem",
              top: "0.25rem",
            }}
          />
          <AmountView
            width={"32%"}
            mb={"0.5rem"}
            fontSize="0.9rem"
            defaultValue={Math.abs(recurring_Obj?.value)}
            name="value"
            onBlur={onBlurValue}
            disabled={!recurring_Obj?.salary_type || disabled}
            error={showError && !Boolean(recurring_Obj?.value)}
            likeGoogle
            label={t("employee_label_value")}
            tooltip={t("employee_tooltip_value")}
            variant="filled"
            currency={dataSetData?.currency}
            sx={{ pl: "0.5rem" }}
          />
          <Tooltip title={t(`employee_salary_type_tooltip`)} placement="top">
            <ThemeToggle
              tooltip={"employee_salary_type_tooltip"}
              value={recurring_Obj?.salary_type}
              onChange={onClickSalaryType}
              options={[
                { value: 1, label: "label_monthly" },
                { value: 2, label: "label_yearly" },
                { value: null, label: "label_off" },
              ]}
              sx={{
                mb: "2rem",
                ml: "1rem",
                "& .MuiToggleButtonGroup-root": {
                  display: "contents",
                },
                "& .MuiButtonBase-root": {
                  flex: 1,
                },
              }}
            />
          </Tooltip>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              position: "relative",
              pl: "1rem",
              width: "30%",
            }}
          >
            <DropDown
              disablePortal
              disabled={disabled}
              fontSize="0.9rem"
              name="tax"
              value={vat?.find((o1) => o1.uuid === recurring_Obj?.tax) || null}
              onChange={(e, value) =>
                onChangeDropDown(e, { value: value?.uuid }, "tax")
              }
              options={vat}
              renderOption={(option) => `${option?.title}` ?? ""}
              getOptionLabel={(option) => option?.title ?? ""}
              hideTitle
              likeGoogle
              label={t("label_tax")}
              tooltip={t("tooltip_tax")}
              variant="filled"
              mb={0}
            />
            <Tooltip
              title={t("employee_freelancer_tax_tooltip")}
              placement="top"
              arrow
            >
              <HelpOutlineOutlinedIcon
                sx={{
                  fontSize: "1rem",
                  ml: "0.5rem",
                  color: theme.palette.primary.main,
                }}
              />
            </Tooltip>
          </Stack>
        </Stack>
        <Stack sx={{ width: "51.5%", mt: "2rem" }}>
          <Stack sx={{ width: "100%" }}>
            <Box
              mb="1rem"
              sx={{
                width: "97%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Icon
                  icon={<HiArrowPathRoundedSquare />}
                  color={Color.theme.blueGrey[700]}
                  fontSize={"1.4rem"}
                  style={{
                    position: "absolute",
                    left: "-1.5rem",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  fontWeight={"fontWeightMediumBold"}
                  ml="1.5rem"
                  color="color.mainTitle"
                  sx={{
                    fontSize: "0.9rem",
                  }}
                >
                  {t("label_ongoing_cost")}
                </Typography>
              </div>

              <CostTotalView
                recurring_Obj={recurring_Obj}
                type="ongoing_cost"
              />
            </Box>
            {recurring_Obj?.value_sets?.[0]?.items?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {RadioInput({
                    t,
                    index,
                    type: "ongoing_cost",
                    name: item?.title,
                    cost_type: item?.cost_type,
                    label: item?.title,
                    uuid: item?.uuid,
                    tooltip: "",
                    radioValue: item?.type,
                    valueSetId: recurring_Obj?.value_sets?.[0]?.uuid,
                    value:
                      tab === "employee"
                        ? Math.abs(item?.value || 0)
                        : item?.value || 0,
                    defaultValue:
                      tab === "employee"
                        ? Math.abs(item?.value || 0)
                        : item?.value || 0,
                    handleRadioChange,
                    onBlurValue,
                    onChangeValue,
                    disabled,
                    theme,
                    onClickMenu,
                  })}
                </React.Fragment>
              );
            })}

            <Button
              onClick={onClickOnGoingCost}
              size="medium"
              fontWeight={"fontWeightMediumBold"}
              sx={{
                width: "fit-content",
                height: "fit-content",
                ml: "1.5rem",
                fontSize: "0.85rem",
                color: theme.palette.color.slate[700],
                backgroundColor: theme.palette.color.slate[100],
                "&:hover": {
                  backgroundColor: theme.palette.color.slate[200],
                },
              }}
            >
              {t("Add Ongoing Cost")}
            </Button>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Box
              mb="1rem"
              sx={{
                mt: "2rem",
                width: "97%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Icon
                  icon={<PiHandshakeFill />}
                  color={Color.theme.blueGrey[700]}
                  fontSize={"1.4rem"}
                  style={{
                    position: "absolute",
                    left: "-1.5rem",
                  }}
                />
                <Tooltip
                  arrow
                  placement={"top"}
                  title={t("tooltip_one_off_cost")}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={"fontWeightMediumBold"}
                    color="color.mainTitle"
                    ml="1.5rem"
                    sx={{
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("label_one_off_cost")}
                  </Typography>
                </Tooltip>
              </div>
              <CostTotalView
                recurring_Obj={recurring_Obj}
                type="one_off_cost"
              />
            </Box>
            {recurring_Obj?.value_sets?.[1]?.items?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {RadioInput({
                    t,
                    index,
                    type: "one_off_cost",
                    name: item?.title,
                    cost_type: item?.cost_type,
                    label: item?.title,
                    uuid: item?.uuid,
                    valueSetId: recurring_Obj?.value_sets?.[1]?.uuid,
                    tooltip: "",
                    radioValue: item?.type,
                    value:
                      tab === "employee"
                        ? Math.abs(item?.value || 0)
                        : item?.value || 0,
                    defaultValue:
                      tab === "employee"
                        ? Math.abs(item?.value || 0)
                        : item?.value || 0,
                    handleRadioChange,
                    onBlurValue,
                    onChangeValue,
                    disabled,
                    dataSetData,
                    theme,
                    onClickMenu,
                  })}
                </React.Fragment>
              );
            })}

            <Button
              onClick={onClickOneOffCost}
              size="medium"
              fontWeight={"fontWeightMediumBold"}
              sx={{
                width: "fit-content",
                height: "fit-content",
                ml: "1.5rem",
                fontSize: "0.85rem",
                color: theme.palette.color.slate[700],
                backgroundColor: theme.palette.color.slate[100],
                "&:hover": {
                  backgroundColor: theme.palette.color.slate[200],
                },
              }}
            >
              {t("Add One Off Cost")}
            </Button>
          </Stack>
          <CustomPopover
            type="anchor"
            anchorEl={anchorEl}
            onClose={onClosePopOver}
            width={"fit-content"}
            border="none"
            justifyContent="flex-start"
            iconSize={"1.375rem"}
            icon
            iconStyle={{ p: 0 }}
            paperStyle={{
              border: 0,
              cursorCol: "pointer",
            }}
            iconView={<></>}
            hoverBG={"transparent"}
          >
            <CostAddOverlay
              type={formData.current?.type}
              formType={formData.current?.formType}
              defaultData={formData.current?.defaultData}
              recurring_Obj={recurring_Obj}
              setRecurring_Obj={setRecurring_Obj}
              modalType={modalType}
              updateRecurrenceFlag={updateRecurrenceFlag}
              error={error}
              setError={setError}
              onClosePopOver={onClosePopOver}
            />
          </CustomPopover>
          {Boolean(anchorElMenu) ? (
            <MenuView
              anchorEl={anchorElMenu}
              open={Boolean(anchorElMenu)}
              options={menuOptions}
              handleClose={handleCloseMenu}
              onClickItem={onClickMenuItem}
              // item={params?.row}
            />
          ) : null}
        </Stack>
      </Stack>
    );
  };

  const leasingView = () => {
    return (
      <TitleInput
        star
        type="number"
        value={recurring_Obj?.deposit_value || ""}
        name="deposit_value"
        onChange={onChangeValue}
        disabled={
          disabled ||
          !recurring_Obj?.value ||
          Number(recurring_Obj?.value) === 0
        }
        hideTitle
        likeGoogle
        label={t("leasing_form_deposit_value")}
        tooltip={t("leasing_form_deposit_value_tooltip")}
        variant="filled"
        fontSize="0.9rem"
        sx={{ width: "32%", ml: "1rem" }}
      />
    );
  };

  const CategoryTaxMsg = ({ sx }) => {
    const selectionCategoriesByID =
      store.getState()?.categorySlice?.selectionCategoriesByID;
    const item = selectionCategoriesByID?.[cardItem?.category]?.[0];
    const taxObj = vat?.find((o1) => item?.tax && o1.uuid === item?.tax);
    if (
      (tab === "single" ? cardItem?.tax : recurring_Obj?.tax) ||
      !cardItem?.category
    ) {
      return null;
    }
    return (
      <Typography
        variant="caption"
        color="color.slate.500"
        sx={{
          position: "absolute",
          bottom: "-0.25rem",
          right: "1rem",
          ...sx,
        }}
      >
        {t("Category tax inherited")}:{" "}
        <b>{item?.tax ? `${taxObj?.value}%` : "-"}</b>
      </Typography>
    );
  };

  const EmployeeView1 = () => {
    const optionsByTitle =
      tab === "employee"
        ? Constant.EmployeeTypeByTitle
        : Constant.LoanTypeByTitle;
    const options =
      tab === "employee" ? Constant.EmployeeType : Constant.LoanType;
    const label =
      tab === "employee" ? t("label_employee_type") : t("label_loan_type");
    const tooltip =
      tab === "employee" ? t("tooltip_employee_type") : t("tooltip_loan_type");
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%", ml: "0.75rem", mt: "1rem" }}
      >
        <DropDown
          star
          disablePortal
          disabled={disabled}
          name="employee_type"
          value={
            optionsByTitle?.[recurring_Obj?.employee_type]
              ? t(recurring_Obj?.employee_type)
              : recurring_Obj?.employee_type
          }
          options={options}
          renderOption={renderOptions}
          getOptionLabel={renderOptions}
          error={showError && !Boolean(recurring_Obj?.employee_type)}
          hideTitle
          likeGoogle
          tooltip={tooltip}
          label={label}
          variant="filled"
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          createDynamicText
          onChange={(event, newValue) => {
            updateRecurrenceFlag();
            if (typeof newValue === "string") {
              setRecurring_Obj({
                ...recurring_Obj,
                employee_type: newValue,
              });
            } else if (newValue && newValue?.inputValue) {
              setRecurring_Obj({
                ...recurring_Obj,
                employee_type: newValue?.inputValue,
              });
            } else {
              setRecurring_Obj({
                ...recurring_Obj,
                employee_type: newValue?.display_name,
              });
            }
          }}
          fontSize="0.9rem"
          sx={{ width: "48%" }}
        />
        {tab === "employee" ? (
          <DropDown
            star
            disablePortal
            disabled={disabled}
            name="employee_role"
            value={recurring_Obj?.employee_role}
            groupBy={(option) => option?.category}
            options={Constant.EmployeeRole}
            hideTitle
            likeGoogle
            label={t("label_employee_role")}
            tooltip={t("tooltip_employee_role")}
            variant="filled"
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={renderOptions}
            onChange={(event, newValue) => {
              updateRecurrenceFlag();
              if (typeof newValue === "string") {
                setRecurring_Obj({
                  ...recurring_Obj,
                  employee_role: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setRecurring_Obj({
                  ...recurring_Obj,
                  employee_role: newValue.inputValue,
                });
              } else {
                setRecurring_Obj({
                  ...recurring_Obj,
                  employee_role: newValue?.display_name,
                });
              }
            }}
            createDynamicText
            fontSize="0.9rem"
            sx={{ width: "48%" }}
          />
        ) : null}
      </Stack>
    );
  };

  const View1 = () => {
    let amount_label = "label_value";
    let amount_tooltip = "tooltip_value";
    let isAmountInputReadOnly = true;

    if (tab === "simple") {
      isAmountInputReadOnly = false;
    }
    if (isLoan) {
      isAmountInputReadOnly = false;
      amount_label = "loan_form_value";
      amount_tooltip = "loan_form_value_tooltip";
    }
    if (isLeasing) {
      isAmountInputReadOnly = false;
      amount_label = "label_leasing_value";
      amount_tooltip = "tooltip_leasing_value";
    }
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "1rem",
          ml: "0.75rem",
          position: "relative",
        }}
      >
        <Icon
          icon={
            isLoan || isLeasing ? (
              <TbBuildingBank />
            ) : (
              CurrencyPrefixIcon[dataSetData?.currency]
            )
          }
          size="1rem"
          style={{
            height: "fit-content",
            position: "absolute",
            left: "-2.2rem",
            top: recurring_Obj?.value?.toString() ? "0.2rem" : "0.75rem",
          }}
          color={Color.theme.blueGrey[700]}
        />

        {isAmountInputReadOnly ? (
          <TitleInput
            star
            value={t("Automatically calculated")}
            name="value"
            fontSize="0.9rem"
            disabled={true}
            hideTitle
            likeGoogle
            label={t(amount_label)}
            tooltip={t(amount_tooltip)}
            variant="filled"
            currency={dataSetData?.currency}
            sx={{ width: "32%" }}
          />
        ) : (
          <AmountView
            width={"32%"}
            mb={"0.5rem"}
            fontSize="0.9rem"
            defaultValue={recurring_Obj?.value}
            name="value"
            onBlur={onBlurAmount}
            disabled={disabled}
            likeGoogle
            label={t(amount_label)}
            tooltip={t(amount_tooltip)}
            variant="filled"
            currency={dataSetData?.currency}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "66%", ml: 1 }}
        >
          {isLoan || isLeasing ? (
            recurring_Obj?.loan_calculation_method === 2 || isLeasing ? (
              <TitleInput
                type="number"
                fontSize="0.9rem"
                value={recurring_Obj?.payment_default}
                error={showError && !Boolean(recurring_Obj?.payment_default)}
                name="payment_default"
                onChange={onChangeValue}
                disabled={disabled}
                hideTitle
                likeGoogle
                label={
                  isLeasing
                    ? t("label_total_leasing_months")
                    : t("loan_form_number_of_months")
                }
                tooltip={
                  isLeasing
                    ? t("tooltip_total_leasing_months")
                    : t("loan_form_number_of_months_tooltip")
                }
                variant="filled"
                sx={{ width: "48%" }}
              />
            ) : (
              <TitleInput
                type="number"
                value={recurring_Obj?.average_value || ""}
                name="average_value"
                onChange={onChangeValue}
                disabled={disabled}
                error={showError && !Boolean(recurring_Obj?.average_value)}
                star
                hideTitle
                likeGoogle
                label={t("loan_form_monthly_repayment_amount")}
                tooltip={t("loan_form_monthly_repayment_amount_tooltip")}
                variant="filled"
                fontSize="0.9rem"
                sx={{ width: "48%" }}
              />
            )
          ) : (
            <Stack sx={{ width: "48%", position: "relative" }}>
              <DropDown
                disablePortal
                disabled={disabled}
                fontSize="0.9rem"
                name="tax"
                value={
                  vat?.find((o1) => o1.uuid === recurring_Obj?.tax) || null
                }
                onChange={(e, value) =>
                  onChangeDropDown(e, { value: value?.uuid }, "tax")
                }
                options={vat}
                renderOption={(option) => `${option?.title}` ?? ""}
                getOptionLabel={(option) => option?.title ?? ""}
                hideTitle
                likeGoogle
                label={t("label_tax")}
                tooltip={t("tooltip_tax")}
                variant="filled"
                sx={{ width: "100%" }}
              />
              <CategoryTaxMsg />
            </Stack>
          )}

          {isLoan || isLeasing ? (
            <TitleInput
              type="number"
              fontSize="0.9rem"
              value={recurring_Obj?.interest_rate}
              name="interest_rate"
              onChange={onChangeValue}
              error={showError && !Boolean(recurring_Obj?.interest_rate)}
              disabled={
                disabled ||
                (isLoan && VariableRatesItemList?.current?.length > 0)
              }
              hideTitle
              likeGoogle
              label={t("loan_form_interest_rate")}
              tooltip={t("loan_form_interest_rate_tooltip")}
              variant="filled"
              sx={{ width: "48%" }}
            />
          ) : (
            <TitleInput
              type="number"
              fontSize="0.9rem"
              value={recurring_Obj?.discount}
              name="discount"
              onChange={onChangeValue}
              disabled={disabled}
              hideTitle
              likeGoogle
              label={t("label_discount")}
              tooltip={t("tooltip_discount")}
              variant="filled"
              sx={{ width: "48%" }}
            />
          )}
        </Stack>
      </Box>
    );
  };

  const View2 = () => {
    const period =
      option?.period?.find((o1) => o1.value === recurring_Obj?.period) || null;
    const repetition =
      (isLoan || isLeasing ? option?.installments : option?.repetition)?.find(
        (o1) => o1.value === recurring_Obj?.repetition
      ) || null;
    const RepetitionOption =
      isLoan || isLeasing ? option?.installments : option?.repetition;
    const loan_calculation_method = option?.loan_calculation_method?.find(
      (o1) => o1.value === (recurring_Obj?.loan_calculation_method || 1)
    );
    return (
      <Stack
        direction={"row"}
        alignItems={"start"}
        justifyContent={"space-between"}
        sx={{
          width: "100%",
          ml: "0.75rem",
          mt: "1.5rem",
          position: "relative",
        }}
      >
        <Icon
          icon={
            isLoan || isLeasing ? <PiMathOperationsBold /> : <BsCalendar2Week />
          }
          fontSize={"1.3rem"}
          color={Color.theme.blueGrey[700]}
          style={{
            position: "absolute",
            left: "-2.2rem",
            top: period ? "0.75rem" : "1.2rem",
          }}
        />
        <Stack sx={{ width: "66%" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%", my: "0.5rem" }}
          >
            {isLoan ? (
              <DropDown
                star
                disablePortal
                disableClearable
                disabled={disabled}
                name="loan_calculation_method"
                value={loan_calculation_method}
                onChange={(e, value) =>
                  onChangeDropDown(e, value, "loan_calculation_method")
                }
                options={option?.loan_calculation_method}
                renderOption={renderOptions}
                getOptionLabel={renderOptions}
                hideTitle
                likeGoogle
                label={t("loan_form_calculation_method")}
                tooltip={t("loan_form_calculation_method_tooltip")}
                variant="filled"
                fontSize="0.9rem"
                sx={{ width: "48%" }}
              />
            ) : isLeasing ? null : (
              <DropDown
                star
                disablePortal
                disabled={disabled}
                name="period"
                value={period}
                onChange={(e, value) => onChangeDropDown(e, value, "period")}
                options={option?.period}
                renderOption={renderOptions}
                getOptionLabel={renderOptions}
                error={showError && !Boolean(recurring_Obj?.period)}
                hideTitle
                likeGoogle
                label={t("label_period")}
                tooltip={t("tooltip_period")}
                variant="filled"
                fontSize="0.9rem"
                sx={{ width: "48%" }}
              />
            )}
            <DropDown
              star
              disablePortal
              disabled={disabled}
              name={"repetition"}
              value={repetition}
              onChange={(e, value) => onChangeDropDown(e, value, "repetition")}
              options={RepetitionOption}
              renderOption={renderOptions}
              getOptionLabel={renderOptions}
              error={showError && !Boolean(recurring_Obj?.repetition)}
              hideTitle
              likeGoogle
              label={
                isLoan || isLeasing
                  ? t("loan_form_installments")
                  : t("label_repetition")
              }
              tooltip={
                isLoan || isLeasing
                  ? t("loan_form_installments_tooltip")
                  : t("tooltip_repetition")
              }
              variant="filled"
              fontSize="0.9rem"
              sx={{ width: "48%" }}
            />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <DateInputPicker
              ref={recurDateRef}
              name="start_date"
              showFullDate
              label={t(
                isLoan || isLeasing
                  ? "loan_form_repayment_start_date"
                  : "label_start_date"
              )}
              tooltip={t(
                isLoan || isLeasing
                  ? "loan_form_repayment_start_date_tooltip"
                  : "tooltip_start_date"
              )}
              minDate={startOfYear(
                subYears(new Date(recurring_Obj?.start_date), 2)
              )}
              maxDate={endOfYear(
                addYears(new Date(recurring_Obj?.start_date), 6)
              )}
              error={showError && !Boolean(recurring_Obj?.start_date)}
              value={
                recurring_Obj?.start_date
                  ? new Date(recurring_Obj?.start_date)
                  : new Date()
              }
              hidePickerBlurEffect
              onChange={onChangeRangeDate}
              onBlur={onBlurRangeDate}
              variant="filled"
              likeGoogle
              hideTitle
              fontSize="0.9rem"
              sx={{ width: "48%" }}
            />
            {isLoan || isLeasing ? null : (
              <DateInputPicker
                ref={recurDateRef}
                name="end_date"
                label={t("loan_form_end_date")}
                tooltip={t("loan_form_end_date_tooltip")}
                minDate={new Date(recurring_Obj?.start_date)}
                maxDate={addYears(new Date(recurring_Obj?.start_date), 6)}
                error={showError && !Boolean(recurring_Obj?.end_date)}
                value={
                  recurring_Obj?.end_date
                    ? new Date(recurring_Obj?.end_date)
                    : null
                }
                hidePickerBlurEffect
                onChange={onChangeRangeDate}
                onBlur={onBlurRangeDate}
                disabled={isLoan || isLeasing}
                variant="filled"
                likeGoogle
                hideTitle
                fontSize="0.9rem"
                sx={{ width: "48%" }}
              />
            )}
          </Stack>
        </Stack>
        <Preview
          sx={{ width: "32%" }}
          tab={tab}
          expectedList={expectedList}
          totalPreviewCount={totalPreviewCount}
          recurring_Obj={recurring_Obj}
          VariableRatesItemList={VariableRatesItemList.current}
        />
      </Stack>
    );
  };

  const View3 = () => {
    return (
      <Box
        sx={{
          width: "100%",
          position: "relative",
          mt: "2rem",
        }}
      >
        {tab === "employee" || isLoan || isLeasing ? null : (
          <Icon
            icon={
              tab === "subscription" ? (
                <PiUsersThreeBold />
              ) : tab === "shop" ? (
                <ShoppingCartIcon />
              ) : tab === "client" ? (
                <PeopleAltIcon />
              ) : tab === "products" ? (
                <CategoryIcon />
              ) : tab === "time" ? (
                <HourglassTopIcon />
              ) : (
                <PiMathOperationsBold />
              )
            }
            size="1rem"
            style={{
              height: "fit-content",
              position: "absolute",
              left: "-1.5rem",
              top: "0.75rem",
            }}
            color={Color.theme.blueGrey[700]}
          />
        )}
        {tab === "simple" ? simpleView() : null}
        {tab === "advanced" ? advanceView() : null}
        {tab === "employee" ? staffView() : null}
        {isLeasing ? leasingView() : null}
        {isLoan ? (
          <VariableRatesView
            modalType={modalType}
            recurring_Obj={recurring_Obj}
            setRecurring_Obj={setRecurring_Obj}
            setIsRecurrenceUpdated={setIsRecurrenceUpdated}
            PausedDuration={PausedDuration}
            VariableRatesItemList={VariableRatesItemList}
            showError={showError}
            setShowError={setShowError}
          />
        ) : null}
        {isLoan ? (
          <PauseDurationsView
            modalType={modalType}
            recurring_Obj={recurring_Obj}
            setRecurring_Obj={setRecurring_Obj}
            setIsRecurrenceUpdated={setIsRecurrenceUpdated}
            PausedDuration={PausedDuration}
            PausedDurationItemList={PausedDurationItemList}
            VariableRatesItemList={VariableRatesItemList}
          />
        ) : null}
        {isLoan ? (
          <LoanSpecialPaymentsView
            modalType={modalType}
            recurring_Obj={recurring_Obj}
            setRecurring_Obj={setRecurring_Obj}
            setIsRecurrenceUpdated={setIsRecurrenceUpdated}
            PausedDuration={PausedDuration}
            SpecialPaymentsItemList={SpecialPaymentsItemList}
            cardItem={cardItem}
            setCardItem={setCardItem}
            isLoanSpecialPayments={isLoanSpecialPayments}
          />
        ) : null}
        {tab === "subscription" ? subscriptionView() : null}
        {tab === "shop" ? shopView() : null}
        {tab === "client" || tab === "products" ? clientProductView() : null}
        {tab === "time" ? timeView() : null}
      </Box>
    );
  };

  return (
    <div
      style={{
        height: "fit-content",
        backgroundColor: "transparent",
        position: "relative",
        marginTop: "2rem",
      }}
    >
      <div style={{ display: "flex", width: "100%", marginLeft: ".75rem" }}>
        {hideEditFormTabBar ? null : (
          <Tabs
            value={tab}
            scrollButtons={true}
            variant={"scrollable"}
            onChange={handleChange}
            aria-label="wrapped label tabs"
            sx={{
              ml: cardItem.state !== "Planned" ? "1.8rem" : "-1rem",
              minHeight: "auto",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTabScrollButton-horizontal": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${Color.white} !important`,
                display: cardItem.state !== "Planned" ? "none" : "flex",
              },
              "& .MuiButtonBase-root": {
                minHeight: "auto",
                maxWidth: "fit-content",
                minWidth: "auto",
                width: "fit-content",
                color: Color.theme.blueGrey[700],
                fontFamily: Fonts.Text,
                textTransform: "initial",
                borderRadius: 1.5,
                p: "0.8rem",
                "&:hover": {
                  backgroundColor: Color.theme.grey[50],
                  color: Color.theme.blueGrey[900],
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.2rem",
                },
              },

              ".Mui-selected": {
                backgroundColor: `${theme.palette.primary[50]}!important`,
                color: `${theme.palette.primary.main} !important`,
              },
            }}
          >
            {tabs?.map((item, index) => {
              if (
                (Constant?.isProd || !beta_enabled) &&
                (item?.value === "leasing" || item?.value === "loan")
              ) {
                return null;
              }
              if (cardItem.state !== "Planned" && cardItem.state !== "Booked") {
                if (
                  cardItem.state === "Ignored" ||
                  cardItem.state === "Written-off"
                ) {
                  if (item?.value !== "single") {
                    if (item?.value !== "employee") {
                      return null;
                    }
                  }
                } else if (item?.value !== "single") {
                  return null;
                }

                if (!isStaffView && item?.value === "employee") {
                  return null;
                }
              }

              if (
                transaction_type === "income" &&
                (item?.value === "employee" ||
                  item?.value === "loan" ||
                  item?.value === "leasing")
              ) {
                return null;
              }
              return (
                <Tab
                  key={item?.value}
                  disabled={index !== 0 && cardItem?.source !== 1}
                  icon={item?.icon}
                  iconPosition={item?.iconPosition}
                  value={item?.value}
                  sx={{ ml: index === 0 ? 0 : "1rem" }}
                  label={
                    <Tooltip
                      arrow
                      title={t(`${item?.tooltip}`)}
                      placement="top"
                    >
                      <span>{t(`${item?.display_name}`)}</span>
                    </Tooltip>
                  }
                />
              );
            })}
          </Tabs>
        )}
      </div>
      {tab === "employee" ? (
        <StaffInputView
          showError={showError}
          cardItem={cardItem}
          setCardItem={setCardItem}
          disabled={disabled}
          updateRecurrenceFlag={updateRecurrenceFlag}
          recurring_Obj={recurring_Obj}
          setRecurring_Obj={setRecurring_Obj}
          modalType={modalType}
          hideEditFormTabBar={hideEditFormTabBar}
        />
      ) : null}

      <Stack
        justifyContent="space-between"
        paddingLeft={tab === "single" ? 0 : "1.7rem"}
      >
        <FormulrForm
          open={isOpenFormuler}
          handleClose={handleClose}
          onSave={onSave}
        />
        {tab === "single" ? (
          <Stack flexDirection={"column"} width={"100%"}>
            {amountView()}
            {dateView()}
            <DueDateWarnView cardItem={cardItem} />
          </Stack>
        ) : (
          <>
            {tab === "employee" ? EmployeeView1() : View1()}
            {View2()}
            {tab === "loan" ? EmployeeView1() : null}
            {View3()}
          </>
        )}
      </Stack>
    </div>
  );
};

export default RecurringFormView;
const DueDateWarnView = ({ cardItem }) => {
  const { t } = useTranslation();
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );
  return (
    <>
      {isExpire(cardItem, selectionCategoriesByID, recurring_rulesById) && (
        <Typography
          sx={{
            display: "inline-flex",
            alignItems: "center",
            pl: "3.25rem",
            pr: "1.5rem",
            color: Color.red500,
            fontSize: "0.7rem",
            fontFamily: Fonts.Text,
          }}
        >
          {t("This payment seems late, so we take due date for calculations.")}
        </Typography>
      )}
    </>
  );
};
