import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  setStepIndex,
  startTour,
  setSteps,
} from "../../../../store/slices/tour.js";
import HelpOverlay from "../../../../components/HelpOverlay.js/index.js";
import EmployeeHeader from "../Component/EmployesHeader.js";
import WarnView from "../../../../components/WarnView.js";
import { Constant } from "../../../../Helper/Constant.js";
import CostRatioReturns from "../CostRatioReturns.js";
import useWidth from "../../../../hooks/useWidth.js";
import { remToPx } from "../../../../Helper/data.js";
import IncomeStatement from "../IncomeStatement.js";
import Images from "../../../../Helper/Image.js";
import SuccessReport from "../SuccessReport.js";

const tabOptions = [
  { id: 1, path: "balance-sheet", tab: "Tab_Title_balance_sheet" },
  { id: 2, path: "Income-statement", tab: "Tab_Title_Income_statement" },
  {
    id: 3,
    path: "success-report",
    tab: "Tab_Title_Success_report",
    className: "dashboard_overview_2_1_step",
  },
  { id: 4, path: "cost-ratio&returns", tab: "Tab_Title_Cost_ratio" },
];

const Report = ({ allowJoyRideReports, setAllowJoyRideReports }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentWidth = useWidth();
  const s1745 = useMediaQuery("(min-width:1745px)");

  //state
  const [tab, setTab] = useState(1);
  let width = remToPx(
    currentWidth,
    tab === 1 || tab === 2 ? 93.75 : s1745 ? 125 : 115
  );

  useEffect(() => {
    let array = location.pathname.split("/");
    let key = array[array.length - 1];
    let options = tabOptions;
    let filter = options?.find((o1) => o1.path === key);
    if (filter) {
      setTab(filter?.id);
    }
  }, [location.pathname]);

  const insightsData = useMemo(() => {
    if (tab === 2) {
      return {
        id: 1,
        image: Images.income_statement_placeholder,
        linkText: "Categories - Documentation",
        text: "Categories",
        link: `https://finban.io/documentation/categories/variable-fix-cost`,
        description: "Learn how to use categories for this chart.",
      };
    }
    if (tab === 3) {
      return {
        id: 1,
        image: Images.success_report_placeholder,
        linkText: "Success Report - Documentation",
        text: "Success Report",
        link: `https://finban.io/documentation/reports/success-report/`,
        description: "Learn more about success report.",
      };
    }
    if (tab === 4) {
      return {
        id: 1,
        image: Images.cost_ratio_placeholder,
        linkText: "Cost Ratio & Returns - Documentation",
        text: "Cost Ratio & Returns",
        link: `https://finban.io/documentation/reports/setup-cost-ratio-returns/`,
        description: "Learn more about cost ratio & returns.",
      };
    }
  }, [tab]);

  //function
  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      let options = tabOptions;
      let [key] = options?.filter((o1) => o1.id === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(key.path);
      let url = array.join("/");
      navigate(url);
    }
  };

  useEffect(() => {
    if (allowJoyRideReports) {
      setTab(2);
      dispatch(setSteps({ key: "dashboard_overview", t }));
      dispatch(setStepIndex(2));
      dispatch(startTour());
      setAllowJoyRideReports(false);
    }
  }, [allowJoyRideReports, tab]);

  return (
    <>
      <Box
        sx={{
          width: width,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {tabOptions?.map((item) => (
              <Tab
                key={item.id}
                value={item?.id}
                label={t(item?.tab)}
                className={item?.className}
              />
            ))}
          </Tabs>
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.5rem" }}>
          
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "50%",
            justifyContent: "end",
          }}
        >
          <EmployeeHeader
            width={"fit-content"}
            hideOption
            hideInsight
            hideStateOption={tab === 1 || tab === 2}
            hideDateChange={tab === 1 || tab === 2}
          />
          {tab !== 1 ? (
            <HelpOverlay
              hideDefault
              hideHeader
              text={insightsData?.text}
              data={[insightsData]}
              fontSize="1.4rem"
            />
          ) : null}
        </Box>
      </Box>
      {/* {tab === 1 && <BalanceSheetStates />} //eur */}
      {tab === 2 && <IncomeStatement />}
      {tab === 3 && Constant.isTest && <SuccessReport />}
      {tab === 4 && Constant.isTest && <CostRatioReturns />}
    </>
  );
};

export default Report;
