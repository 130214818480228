import { Box, Link, Paper } from "@mui/material";
import { Color, Fonts, Images } from "../../../../Helper";
import Placeholder from "../../../../components/Placeholder";

const Forecast = () => {
  return (
    <Box sx={{ position: "relative", mt: "1.5rem" }}>
      <Paper
        sx={{
          "& .css-mcts47-MuiPaper-root": {
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          },
          width: "93.75rem",
          borderRadius: "5px",
          backgroundColor: Color.white,
          maxHeight: "80%",
          height: "50rem",
          position: "relative",
        }}
      >
        <Placeholder
          width="99.5%"
          image={Images.forcast_placeholder}
          insightText={"Company"}
          description={
            <span>
              {" "}
              Forecast coming soon. Want it earlier?{" "}
              <Link
                color="inherit"
                href={"https://finban.io/contact-us/"}
                target="_blank"
                sx={{
                  fontFamily: Fonts.Text,
                  color: Color.themeColor2,
                }}
              >
                Talk to us.
              </Link>
            </span>
          }
        />
      </Paper>
    </Box>
  );
};
export default Forecast;
