import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  addMonths,
  subMonths,
  endOfWeek,
  subWeeks,
  addWeeks,
  format,
} from "date-fns";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

import useUpdateEffect from "../../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setDataSetData, setdatasetList } from "../../../../store/slices/board";
import { formatDateToLocal, getTailwindColor } from "../../../../Helper/data";
import EndPoints from "../../../../APICall/EndPoints";
import { Constant } from "../../../../Helper";
import { queryClient } from "../../../../App";
import APICall from "../../../../APICall";
import theme from "../../../../theme";
import store from "../../../../store";

const MonthSkipView = ({ color = "slate" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timerRunning = useRef(null);

  //redux
  const isWeeklyTable = useSelector((state) => state.boardSlice?.isWeeklyTable);

  //lifecycle
  useUpdateEffect(() => {
    onClickHome();
  }, [isWeeklyTable]);

  //function
  // const setAllowColumnFetch = (date) => {
  //   if (date) {
  //     let inarray = global.allowFetch?.Inflow || [];
  //     let outarray = global.allowFetch?.Outflow || [];
  //     inarray.push(date);
  //     outarray.push(date);
  //     global.allowFetch = { Inflow: inarray, Outflow: outarray };
  //   } else {
  //     global.allowFetch = { Inflow: [], Outflow: [] };
  //     let options = {
  //       predicate: (query) =>
  //         query.queryKey[0] === "transactions" &&
  //         query.queryKey[1]?.apiType === "normal_monthly_transactions",
  //       type: "active",
  //     };
  //     queryClient.invalidateQueries(options);
  //   }
  // };

  const onClickRightArrow = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (isWeeklyTable) {
      dataset_start_date = format(
        addWeeks(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(
          addWeeks(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        addMonths(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(
          addMonths(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    }

    // setAllowColumnFetch(
    //   format(startOfMonth(new Date(dataset_end_date)), "yyyy-MM-dd")
    // );
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickRightArrow2 = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (isWeeklyTable) {
      dataset_start_date = format(
        startOfWeek(addMonths(new Date(dataSetData?.start_date), 1)), //only 1 month skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(
          addWeeks(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        addMonths(new Date(dataSetData?.start_date), Constant.column_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(
          addMonths(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch();
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickHome = () => {
    let dataset_start_date = null;
    let dataset_end_date = null;

    if (isWeeklyTable) {
      dataset_start_date = format(
        subWeeks(startOfWeek(new Date()), Constant.start_column_skip_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(
          addWeeks(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        subMonths(startOfMonth(new Date()), Constant.start_column_skip_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(
          addMonths(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch();for kanban only did used
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickLeftArrow = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (isWeeklyTable) {
      dataset_start_date = format(
        subWeeks(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(
          addWeeks(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        subMonths(
          new Date(dataSetData?.start_date),
          Constant.start_column_skip_count
        ),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(
          addMonths(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch(dataset_start_date);
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const onClickLeftArrow2 = () => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let dataset_start_date = null;
    let dataset_end_date = null;
    if (isWeeklyTable) {
      dataset_start_date = format(
        subMonths(new Date(dataSetData?.start_date), 1), //only 1 month skip
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfWeek(
          addWeeks(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    } else {
      dataset_start_date = format(
        subMonths(new Date(dataSetData?.start_date), Constant.column_count),
        "yyyy-MM-dd"
      );
      dataset_end_date = format(
        endOfMonth(
          addMonths(new Date(dataset_start_date), Constant.column_count)
        ),
        "yyyy-MM-dd"
      );
    }
    // setAllowColumnFetch();
    updateDataSetData(dataset_start_date, dataset_end_date);
  };

  const updateDataSetData = (s_date, e_date) => {
    const dataSetData = store?.getState()?.boardSlice?.dataSetData;

    let obj = {
      start_date: s_date,
      end_date: e_date,
    };
    dispatch(setDataSetData({ partial: obj }));
    if (timerRunning.current) clearTimeout(timerRunning.current);

    const dataSetList = store?.getState()?.boardSlice?.dataSetList;
    timerRunning.current = setTimeout(() => {
      let data = [...dataSetList];
      const index = dataSetList.findIndex((o1) => o1.uuid === dataSetData.uuid);
      if (index > -1) {
        data[index] = { ...data[index], ...obj };
      }
      dispatch(setdatasetList(data));
      updateDatasetByID(dataSetData?.uuid, obj);
    }, 5000);
  };

  //api
  const updateDatasetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  return (
    <ButtonGroup
      className={"kanban_4_step"}
      variant="text"
      aria-label="text button group"
      sx={{
        p: "4px",
        borderRadius: theme.borderRadius.main,
        mr: "1rem",
        "& .MuiSvgIcon-root": {
          fontSize: "1.6rem",
          color: getTailwindColor(color, 600),
        },
        "& .MuiButtonBase-root": {
          py: "3px",
          "&:hover": {
            "& svg": {
              color: theme.palette.primary.main,
            },
          },
        },
        border: `1px solid ${getTailwindColor(color, 300)}`,
        backgroundColor: theme.palette.color.white,
      }}
    >
      <Tooltip arrow title={t("tooltip_filter_left_Most_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_left_Arrow")} position="top">
        <Button
          onClick={onClickLeftArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_home")} position="top">
        <Button
          onClick={onClickHome}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <HomeIcon />
        </Button>
      </Tooltip>
      <Tooltip arrow title={t("tooltip_filter_right_Arrow")} position="top">
        <Button
          onClick={onClickRightArrow}
          sx={{
            borderColor: `${getTailwindColor(color, 300)} !important`,
          }}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Tooltip>
      <Tooltip
        arrow
        title={t("tooltip_filter_right_Most_Arrow")}
        position="top"
      >
        <Button
          onClick={onClickRightArrow2}
          sx={{
            borderColor: `${getTailwindColor(color, 200)} !important`,
          }}
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
export default MonthSkipView;
