import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { isValid, setDate,  } from "date-fns";
import { Box, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import addYears from "date-fns/addYears";
import format from "date-fns/format";
import { useEffect } from "react";
import * as React from "react";

import DateInputPicker from "../../../components/Overlay/DateInputPicker";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setPopupStatus4 } from "../../../store/slices/datasets";
import DropDown from "../../../components/Overlay/DropDown";
import { Color, Fonts } from "../../../Helper";
import initialData from "../../../Helper/data";

export default function SettingForm({
  open,
  onSave,
  type = "",
  groupedCard = null,
  fromSequence = false,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const state = useSelector((state) => state.globalSlice?.state);
  const costunits = useSelector((state) => state.globalSlice.costunits);
  const vat = useSelector((state) => state.globalSlice.vat);

  //state
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  let title = "";
  if (type === "state") {
    title = "Select State";
  }
  if (type === "scenario") {
    title = "Select Scenario";
  }
  if (type === "due_date" || type === "clone_at_date") {
    title = "Select Due Date";
  }
  if (type === "invoice_date") {
    title = "Select Invoice Date";
  }
  if (type === "cost_unit") {
    title = "Select Cost Unit";
  }
  if (type === "date_range") {
    title = "Select Date Range";
  }
  if (type === "tax") {
    title = "Select Tax";
  }

  useEffect(() => {
    if (open) {
      if (type === "date_range") {
        setSelectedValue({
          start_date: null,
          end_date: null,
        });
      } else {
        setSelectedValue(null);
      }
    }
  }, [open, type]);

  const onChangeDropDown = async (event, value) => {
    setSelectedValue({ ...value, title: t(value?.title) });
  };

  const onChangeDate = (value) => {
    setSelectedValue({
      [type === "invoice_date" ? type : "due_date"]:
        value && value !== "Invalid Date"
          ? format(new Date(value), "yyyy-MM-dd")
          : null,
    });
  };

  const onChangeRangeDate = (value, name) => {
    setShowError(false);
    if (name === "start_date") {
      let day = value ? new Date(value).getDate() : "1";
      let start_date = null;
      if (day > 28) {
        start_date = value ? format(setDate(value, 28), "yyyy-MM-dd") : null;
      } else {
        start_date = value ? format(value, "yyyy-MM-dd") : null;
      }

      setSelectedValue({
        ...selectedValue,
        [name]: start_date,
        end_date:
          selectedValue?.end_date &&
          start_date &&
          new Date(selectedValue.end_date) <= new Date(start_date)
            ? null
            : selectedValue.end_date,
      });
    } else {
      setSelectedValue({
        ...selectedValue,
        [name]: value ? format(value, "yyyy-MM-dd") : null,
      });
    }
  };

  const onClickSave = () => {
    if (type === "date_range") {
      if (
        isValid(new Date(selectedValue?.start_date)) &&
        isValid(new Date(selectedValue?.end_date))
      ) {
        onSave(selectedValue, fromSequence);
      } else {
        setShowError(true);
      }
    } else {
      onSave(selectedValue, fromSequence);
    }
  };

  const onClose = () => {
    dispatch(
      setPopupStatus4({
        open: false,
        anchorEl: false,
      })
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 1303,
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: Fonts.Text,
            textTransform: "capitalize",
            backgroundColor: Color.HeaderBG,
          }}
        >
          {t(title)}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "25rem",
            height: type === "date_range" ? "12.5rem" : "9.375rem",
            pt: `2rem !important`,
            backgroundColor: Color.BodyBG,
            ...theme.thinScrollBar,
          }}
        >
          <Box sx={{ width: "100%", position: "relative" }}>
            {type === "tax" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={vat}
                renderOption={(option) => `${option?.title}` ?? ""}
                getOptionLabel={(option) => `${option?.title}` ?? ""}
                placeholder={t("tax")}
              />
            ) : null}
            {type === "state" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={state.filter(
                  (o1) => !initialData.bookedState.includes(o1?.title)
                )}
                placeholder={t("State")}
                getOptionLabel={(option) => t(option?.title) ?? ""}
                renderOption={(option) => t(option?.title) ?? ""}
              />
            ) : null}

            {type === "scenario" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={scenario}
                placeholder={t("Scenario")}
                getOptionLabel={(item) =>
                  (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
                }
                renderOption={(item) =>
                  (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
                }
              />
            ) : null}

            {type === "cost_unit" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={costunits}
                placeholder={t("Cost_Unit")}
              />
            ) : null}

            {type === "invoice_date" ||
            type === "due_date" ||
            type === "clone_at_date" ? (
              <DateInputPicker
                name={type === "invoice_date" ? type : "due_date"}
                label={
                  type === "invoice_date" ? t("Invoice Date") : t("Due Date")
                }
                showFullDate
                error={
                  showError &&
                  !Boolean(
                    selectedValue[type === "invoice_date" ? type : "due_date"]
                  )
                }
                value={
                  selectedValue &&
                  selectedValue[type === "invoice_date" ? type : "due_date"]
                    ? new Date(
                        selectedValue[
                          type === "invoice_date" ? type : "due_date"
                        ]
                      )
                    : undefined
                }
                onChange={onChangeDate}
                variant="filled"
                likeGoogle
                hideTitle
              />
            ) : null}

            {type === "date_range" ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <DateInputPicker
                    name="start_date"
                    label={t("label_start_date")}
                    tooltip={t("tooltip_start_date")}
                    showFullDate
                    error={showError && !Boolean(selectedValue?.start_date)}
                    value={selectedValue?.start_date}
                    onChange={onChangeRangeDate}
                    variant="filled"
                    likeGoogle
                    hideTitle
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mt: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <DateInputPicker
                    name="end_date"
                    label={t("label_end_date")}
                    tooltip={t("tooltip_end_date")}
                    disabled={!selectedValue?.start_date}
                    minDate={
                      selectedValue?.start_date
                        ? new Date(selectedValue?.start_date)
                        : null
                    }
                    maxDate={addYears(new Date(selectedValue?.start_date), 6)}
                    value={selectedValue?.end_date}
                    error={showError && !Boolean(selectedValue?.end_date)}
                    onChange={onChangeRangeDate}
                    variant="filled"
                    likeGoogle
                    hideTitle
                  />
                </Box>
              </>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
          <TailwindButton type="cancel" text={t("Cancel")} onClick={onClose} />
          <TailwindButton text={t("save")} onClick={onClickSave} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
